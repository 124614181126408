import React, { useEffect, useState } from 'react';
import 'instantsearch.css/themes/reset.css';
import { StationsList } from '../StationsList/StationsList';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LeftNav } from '../LeftNav';
import { Home } from '../Home/Home';
import { Box, Grid } from 'grommet';
import { HorizontalPlayer } from '../../Core/Player/HorizontalPlayer';
import { SongRequests } from '../SongRequests/SongRequests';
import { flowRight as compose } from 'lodash';
import { ArtistsList, Size } from '../ArtistsList/ArtistsList';
import { AlbumsList } from '../AlbumsList/AlbumsList';
import { LabelsList } from '../LabelsList/LabelsList';
import { Search } from '../Search/Search';
import { Segment } from '../Segment/Segment';
import { LikedTracksList } from '../LikedTracksList/LikedTracksList';
import { BlacklistedTracksList } from '../BlacklistedTracksList/BlacklistedTracksList';
import { RecentlyPlayed } from '../RecentlyPlayed/RecentlyPlayed';
import { SettingsContext, SettingsKeys, withSettingsContext } from '../../Core/Settings/Settings';
import { theme } from '../../Styled';
import { PlatformContext, Tier, URLS, withPlatformContext } from '../../Core/Platform';
import { PlaylistsList } from '../PlaylistsList/PlaylistsList';
import { DevPage } from '../Dev/DevPage';
import { RouteComponentProps, withRouter } from 'react-router';
import { addStickyBreadcrumb } from '../../Util';
import { Modal } from '../Modal';
import { Text } from 'grommet';
import { Table } from '../Table';
import { premiumBenefits } from '../Table/commonProps';
import pricing from '../../Util/pricing';
import { PlayerMode } from '../../../../common/src/Core/Analytics';
import { PlayerContext, withPlayerContext } from '../../../../common/src/Core/Player/PlayerContext';
import setExpirationDate, { ExpirationDate } from '../../Util/setExpirationDate';
import StorageKeys from '../../Util/StorageKeys';
import moment from 'moment';
import { CurationsPage } from '../../Pages/Curations';
import { CurrentUser_CurrentUserQuery } from '../CurrentUser/types/CurrentUser_CurrentUserQuery';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { StyledToastContainer } from '../../Styled/ToastContainer';
import 'react-toastify/dist/ReactToastify.css';
import { ExistingUserLegalModal } from '../ExistingUserLegalModal';
import { addUserToHubSpotMarketing, setUserAgreementPreferences } from '../../Util/userPreferences';
import { GiftCard } from '../GiftCard';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { Button } from '../Button';
import { StyledUpgradeBanner } from './styled';
import { StatsPage } from '../StatsPage';
import { CompactNowPlaying } from '../CompactNowPlaying';
import { Header } from '../Header';

const currentUserQuery = loader('../CurrentUser/currentUser.graphql');

interface GraphQLProps {
  data: QueryControls & CurrentUser_CurrentUserQuery;
}

interface PretzelPagePresentationProps {
  setPlayerMode: (playerMode: string) => void;
}

type PropsFromSettings = Pick<SettingsContext, SettingsKeys.SongRequestQueueVisible>;
type PropsFromContext = Pick<PlatformContext, 'unloadApp' | 'platformDetails' | 'pretzelUser' | 'openExternalLink'>;

type Props = PropsFromContext & PropsFromSettings & RouteComponentProps & PretzelPagePresentationProps & GraphQLProps;

export const PretzelPagePresentation = ({ data, platformDetails, pretzelUser, setPlayerMode, openExternalLink }: Props): JSX.Element => {
  const [isUserLicenseModalOpen, setIsUserLicenseModalOpen] = useState(false);

  const [state, setState] = useState<any>({
    isModalOpen: false,
    isYouTubeChannelSelectModalOpen: false,
  });

  const handleUserPreferencesSubmit = async (hasAgreedToUserAgreementDate: boolean, hasOptedOutOfMarketing: boolean) => {
    await setUserAgreementPreferences(hasAgreedToUserAgreementDate, hasOptedOutOfMarketing);

    if (!hasOptedOutOfMarketing) {
      await addUserToHubSpotMarketing();
    }

    setIsUserLicenseModalOpen(false);

    if (!data.currentUser.youtubeChannel && pretzelUser.jwt.tier === Tier.Admin) {
      setState({
        ...state,
        isYouTubeChannelSelectModalOpen: true,
      });
    }
  };

  useEffect(() => {
    if (!data.currentUser) {
      return;
    }

    if (!data.currentUser.consent || data.currentUser.consent?.legal === null) {
      setIsUserLicenseModalOpen(true);
    } else if (!data.currentUser.youtubeChannel && pretzelUser.jwt.tier === Tier.Admin) {
      setState({
        ...state,
        isYouTubeChannelSelectModalOpen: true,
      });
    }
  }, [data.currentUser]);

  addStickyBreadcrumb('pd', platformDetails.description);

  const modalExpirationDate: ExpirationDate = {
    amount: 1,
    unit: 'M',
  };

  const sr = false; // Turn off Song Requests here for now.
  const topnavEnd = 2;
  const mainEnd = sr ? 1 : 2;
  let areas = [
    { name: 'topnav', start: [0, 0], end: [topnavEnd, 0] },
    { name: 'leftnav', start: [0, 1], end: [0, 1] },
    { name: 'main', start: [1, 1], end: [mainEnd, 1] },
    { name: 'player', start: [0, 2], end: [2, 2] },
  ];

  if (sr) {
    areas = areas.concat({ name: 'requests-header', start: [2, 0], end: [2, 0] }, { name: 'requests-content', start: [2, 1], end: [2, 1] });
  }

  const renderUpsellModal = () => {
    if (pretzelUser.premium) return null;

    const modalKey = localStorage.getItem(StorageKeys.UPSELL_MODAL);
    const isAfterExipirationDate = () => {
      moment().isAfter(setExpirationDate(modalKey, modalExpirationDate));
    };

    /**
     * TODO:
     * This will be reinstated later
     * If the modalKey does not exist (first time user)...
     * OR the modalKey does exist (previously closed the modal) AND the expiration date has passed
     * ... then render the modal
     */
    if (!modalKey || (modalKey && isAfterExipirationDate())) {
      return (
        <Modal
          buttons={[
            {
              color: 'outline',
              label: 'Find out more',
              externalLink: URLS.PRICING,
            },
            {
              color: 'secondary',
              label: 'Upgrade to Premium',
              externalLink: `${URLS.RECURLY_PREMIUM_MONTHLY}/${pretzelUser.jwt.hash}?utm_source=pretzel&utm_medium=modal&utm_campaign=premium_upsell`,
            },
          ]}
          content={<Table {...premiumBenefits} />}
          isOpen={true}
          setIsOpen={() => setState({ isModalOpen: false })}
          title={'Level up with Premium!'}
          footerContent={<Text size="small">Don't worry, if its not for you; You can cancel at any time!</Text>}
          subtitle={`Get Premium for only $${pricing.monthly} per month`}
          onClose={() => localStorage.setItem(StorageKeys.UPSELL_MODAL, new Date().toString())}
          heapid="modal-upsell"
          testid="modal-upsell"
        />
      );
    } else {
      return null;
    }
  };

  const [openCompactControls, setOpenCompactControls] = React.useState<boolean>(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = React.useState<boolean>(false);
  const contentRef = React.useRef(null);
  const { width } = useWindowSize();

  const toggleCompactControls = () => {
    setOpenCompactControls(!openCompactControls);
  };

  const setPlayerHeight = () => {
    let miniControlsOffset = 50;
    let size = 0;

    if (width >= theme.appBreakpoints.large) {
      size = 105;
    }
    if (width >= theme.appBreakpoints.extraSmall && width < theme.appBreakpoints.large) {
      size = 130;
    }
    if (width < theme.appBreakpoints.extraSmall) {
      size = 90;
    }
    // if the user has toggled on compact controls and resizes the screen we need to override it
    if (width >= theme.appBreakpoints.medium && openCompactControls) {
      return size + 'px';
    }
    return (openCompactControls ? size + miniControlsOffset : size) + 'px';
  };

  if (width >= theme.appBreakpoints.small && openFiltersDrawer) {
    setOpenFiltersDrawer(false);
  }

  setPlayerMode(width < theme.appBreakpoints.medium ? PlayerMode.Compact : PlayerMode.Library);

  const setCompactModeStyling = () => {
    return width < theme.appBreakpoints.extraSmall ? '48px' : '64px';
  };

  return (
    <>
      <Grid fill rows={[setCompactModeStyling(), 'flex', setPlayerHeight()]} columns={[setCompactModeStyling(), 'flex', '265px']} areas={areas}>
        <Header />

        <Box gridArea="leftnav" background={{ color: theme.colors.bg }}>
          <LeftNav />
        </Box>

        <Box background={{ color: theme.colors.bodyBg }} gridArea="main">
          {width >= theme.appBreakpoints.small && <GiftCard />}
          {pretzelUser.jwt.tier === 'free' && (
            <StyledUpgradeBanner className="upgrade-banner">
              <p className="upgrade-banner__text">Want even more features?</p>
              <Button
                className="upgrade-banner__button"
                color="secondary"
                onClick={() => openExternalLink(URLS.PRICING)}
                data-heapid="upgrade-button"
                data-testid="upgrade-button"
              >
                Upgrade to Premium
              </Button>
            </StyledUpgradeBanner>
          )}
          <Switch>
            <Redirect from="/" to="/home" exact />
            <Route path="/home" component={width < theme.appBreakpoints.extraSmall ? CompactNowPlaying : Home} />
            <Route path="/stations" component={StationsList} />
            <Route path="/curations" component={width < theme.appBreakpoints.extraSmall ? CompactNowPlaying : CurationsPage} />
            <Route path="/artists" render={props => <ArtistsList {...props} size={Size.Large} />} />
            <Route path="/albums" component={AlbumsList} />
            <Route path="/segment/:segmentId/:trackId?" component={Segment} />
            <Route path="/labels" component={LabelsList} />
            <Route path="/playlists" component={PlaylistsList} />
            <Route path="/search" component={Search} />
            <Route path="/likes" component={LikedTracksList} />
            <Route path="/dislikes" component={BlacklistedTracksList} />
            <Route path="/recent" component={RecentlyPlayed} />
            <Route path="/__dev" component={DevPage} />
            <Route path="/stats" component={StatsPage} />
          </Switch>
        </Box>

        {sr && (
          <Box gridArea="requests-content">
            <SongRequests />
          </Box>
        )}
        <Box gridArea="player" style={{ position: 'relative', backgroundColor: theme.colors.tooltipBlack, zIndex: 10 }}>
          <HorizontalPlayer toggleCompactControls={toggleCompactControls} openCompactControls={openCompactControls} contentRef={contentRef} />
        </Box>
      </Grid>

      <StyledToastContainer theme="dark" autoClose={3000} hideProgressBar newestOnTop />

      <ExistingUserLegalModal isOpen={isUserLicenseModalOpen} handleSubmit={handleUserPreferencesSubmit} />
    </>
  );
};

function mapContextToProps(c: PlatformContext): PropsFromContext {
  return {
    unloadApp: c.unloadApp,
    platformDetails: c.platformDetails,
    pretzelUser: c.pretzelUser,
    openExternalLink: c.openExternalLink,
  };
}

function mapSettingsToProps(c: SettingsContext): PropsFromSettings {
  return {
    songRequestQueueVisible: c.songRequestQueueVisible,
  };
}

function mapPlayerContextToProps(c: PlayerContext) {
  return {
    setPlayerMode: c.setPlayerMode,
  };
}

export const PretzelPage = compose(
  withRouter,
  graphql(currentUserQuery),
  withPlayerContext(mapPlayerContextToProps),
  withSettingsContext(mapSettingsToProps),
  withPlatformContext(mapContextToProps)
)(PretzelPagePresentation);
