import styled from 'styled-components';
import { Box } from 'grommet';
import { theme } from '../../../../../../Styled';

export const Wrapper = styled(Box)`
  background-color: ${theme.colors.bodyBg};
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  bottom: 64px;
  color: ${theme.colors.light1};
  display: flex;
  font-size: 12px;
  height: fit-content;
  left: -12px;
  max-height: 50vh;
  min-width: max-content !important;
  padding-bottom: 7px;
  padding: ${theme.space.md};
  position: absolute;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
`;

export const StyledMenuList = styled.div`
  max-height: calc(24px * 5);
  overflow: auto;
`;

export const PopupItem = styled(Box)`
  box-shadow: none !important;
  cursor: pointer;

  :not([data-testid='new-playlist-option']) {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    height: 24px;
    transition: 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      color: ${theme.colors.primary};
    }
  }
`;

const tailSize = '10px';
const tailBorder = '11px';

export const BottomTail = styled.div`
  border-left: ${tailSize} solid transparent;
  border-right: ${tailSize} solid transparent;
  border-top: ${tailSize} solid ${theme.colors.bodyBg};
  bottom: -${tailSize};
  height: 0;
  left: 24px;
  position: absolute;
  width: 0;

  :before {
    content: '';
    border-left: ${tailBorder} solid transparent;
    border-right: ${tailBorder} solid transparent;
    border-top: ${tailBorder} solid rgba(255, 255, 255, 0.1);
    bottom: -2px;
    left: -${tailBorder};
    z-index: -1;
    position: absolute;
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.space.sm};
  position: relative;

  input {
    font-family: ${theme.font.familySecondary} !important;
    height: 40px !important;
    padding-right: 1.5rem !important;

    :focus,
    :active {
      border-color: ${theme.colors.primary} !important;
    }

    + svg {
      position: absolute;
      top: 30px;
      right: ${theme.space.sm};

      :hover path {
        fill: ${theme.colors.primary};
      }
    }
  }

  span {
    align-items: center;
    display: flex;
    margin-top: 4px;

    svg {
      fill: red;
      margin-left: -3px;
      margin-right: 4px;
      width: 16px;
    }
  }
`;

export const StyledPlaylistName = styled.span`
  font-family: ${theme.font.family};
  font-weight: 700;
  color: ${theme.colors.tertiary};
`;
