import * as React from 'react';
import { PretzelPage } from '../PretzelPage/PretzelPage';

let lastPage: string | undefined;

export default function ResponsivePage(props: any) {
  const newPage = window.location.pathname;

  if (newPage !== lastPage) {
    // @ts-ignore
    if (window.ga) {
      // @ts-ignore
      window.ga('set', 'page', newPage);
      // @ts-ignore
      window.ga('send', 'pageview');
    }
    lastPage = newPage;
  }

  return <PretzelPage {...props} />;
}
