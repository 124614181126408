import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, theme } from '../../Styled';
import useWindowSize from '../../Util/hooks/use-window-size';
import { Button } from '../Button';
import { PlayButton } from '../PlayButton';
import {
  StyledCarousel,
  StyledCarouselArrow,
  StyledCarouselArrowContainer,
  StyledCarouselButton,
  StyledCarouselButtonContainer,
  StyledCarouselTrack,
  StyledHeroContainer,
  StyledHeroContent,
  StyledHeroImage,
  StyledHeroImageContainer,
  StyledHR,
  StyledLabel,
  StyledPlayContainer,
  StyledHeading,
} from './styled';
import { CarouselProps } from './types';
import useDragToScroll from './useDragToScroll';

export const Carousel: FC<CarouselProps> = ({
  children,
  heroSlides,
  showArrowByDefault = true,
  id,
  numberOfSlides,
  showButtons = true,
  slideSize,
  variant,
  ...rest
}) => {
  const [activeButton, setActiveButton] = useState<number>(1);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [showArrow, setShowArrow] = useState<boolean>(showArrowByDefault);
  const trackRef = useRef<HTMLDivElement>();
  const { width } = useWindowSize();

  const handleScroll = (left: number) => {
    // Firefox doesnt like behavior smooth
    if (window.navigator.userAgent.includes('Firefox')) {
      trackRef.current.scroll({ left });
    } else {
      trackRef.current.scroll({ left, behavior: 'smooth' });
    }
  };

  const handleOnScroll = (e: React.UIEvent<HTMLElement>) => {
    // Typescript compiler not playing nicely with this so we have to cast to HTMLElement
    const target = e.target as HTMLElement;

    // Width of carousel element in px
    if (target.scrollLeft > 180) {
      setHasScrolled(true);
    }

    if (target.scrollLeft === 0) {
      setHasScrolled(false);
    }
  };

  const handleHeroOnClick = (slideIndex: number, back = false, isBtn = false) => {
    if (back) {
      setActiveSlide(!isBtn ? slideIndex - 1 : slideIndex);
    } else {
      setActiveSlide(!isBtn ? slideIndex + 1 : slideIndex);
    }
  };

  const scrollTo = (position: 'Start' | 'Middle' | 'End') => {
    const { clientWidth, scrollWidth } = trackRef.current;
    let widthToSlide;

    switch (position) {
      case 'Start':
        handleScroll(0);
        setActiveButton(1);
        setHasScrolled(false);
        break;

      case 'Middle':
        widthToSlide = (scrollWidth - clientWidth) / 2;
        handleScroll(widthToSlide);
        setActiveButton(2);
        break;

      case 'End':
        widthToSlide = scrollWidth - clientWidth;
        handleScroll(widthToSlide);
        setActiveButton(3);
        break;

      default:
        console.error('You must specify a position to scroll to.');
        break;
    }
  };

  useEffect(() => {
    if (variant !== 'hero') return;

    const autoSlideTimer = setTimeout(() => {
      if (variant !== 'hero') return;

      if (activeSlide === heroSlides.length - 1) {
        handleHeroOnClick(0, false, true);
      } else {
        handleHeroOnClick(activeSlide + 1, false, true);
      }
    }, 6000);

    if (!isHovered) {
      autoSlideTimer;
    } else {
      clearTimeout(autoSlideTimer);
    }
  }, [activeSlide, isHovered]);

  useMemo(() => {
    if (!trackRef.current) return null;

    if (trackRef.current.scrollWidth > trackRef.current.clientWidth) {
      setShowArrow(true);
    } else {
      setShowArrow(showArrowByDefault);
    }
  }, [width]);

  const renderHeroCarousel = () => (
    <StyledCarousel hero={variant === 'hero'}>
      <StyledCarouselTrack hero={variant === 'hero'} id={id} numberOfSlides={numberOfSlides} ref={trackRef} slideSize={slideSize}>
        {heroSlides.map(({ button, description, guid, image, showPlay = true, title, type }, index) => (
          <StyledHeroContainer
            activeSlide={activeSlide === index}
            key={title}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <StyledHeroContent>
              <StyledHR />
              <StyledLabel>{`Featured ${type}`}</StyledLabel>

              <StyledHeading variant="Featured">{title}</StyledHeading>

              <p
                style={{
                  fontSize: '1rem',
                  marginBottom: theme.space.md,
                }}
              >
                {description}
              </p>

              <StyledPlayContainer>
                {showPlay && <PlayButton idToPlay={guid} data-heapid="play-button" data-testid={`featured-${type.toLowerCase()}-play-button`} />}

                <Button
                  color={button.color}
                  externalLink={button.externalLink}
                  onClick={button.onClick}
                  data-heapid="featured-explore"
                  data-testid={`featured-${type.toLowerCase()}-button`}
                >
                  {button.label}
                </Button>
              </StyledPlayContainer>
            </StyledHeroContent>

            <StyledHeroImageContainer>
              <StyledHeroImage image={image} />
              <StyledCarouselButtonContainer hero={variant === 'hero'}>
                {heroSlides.map((slide, index) => (
                  <StyledCarouselButton
                    isActive={index === activeSlide}
                    key={slide.title}
                    onClick={() => handleHeroOnClick(index, false, true)}
                    data-heapid="carousel-navigation"
                    data-testid={`${id}-navigation-${index}`}
                  />
                ))}
              </StyledCarouselButtonContainer>

              <StyledCarouselArrowContainer>
                {index !== 0 ? (
                  <div
                    tabIndex={1}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleHeroOnClick(index, true);
                      }
                    }}
                    onClick={() => handleHeroOnClick(index, true)}
                    data-heapid="carousel-previous"
                    data-testid={`${id}-previous-arrow-${index}`}
                  >
                    <Icon size={32} name="ArrowLeft" />
                  </div>
                ) : (
                  <div />
                )}

                {index !== heroSlides.length - 1 ? (
                  <div
                    tabIndex={1}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleHeroOnClick(index);
                      }
                    }}
                    onClick={() => handleHeroOnClick(index)}
                    data-heapid="carousel-next"
                    data-testid={`${id}-next-arrow-${index}`}
                  >
                    <Icon size={32} name="ArrowRight" />
                  </div>
                ) : (
                  <div />
                )}
              </StyledCarouselArrowContainer>
            </StyledHeroImageContainer>
          </StyledHeroContainer>
        ))}
      </StyledCarouselTrack>
    </StyledCarousel>
  );

  const renderDefaultCarousel = () => {
    useDragToScroll({
      reference: trackRef,
    });

    return (
      <StyledCarousel showArrow={showArrow} onScroll={handleOnScroll}>
        {showArrow && hasScrolled && (
          <StyledCarouselArrow position="left" onClick={() => scrollTo('Start')}>
            <Icon size={24} name="ArrowLeft" />
          </StyledCarouselArrow>
        )}

        {showButtons && (
          <StyledCarouselButtonContainer>
            <StyledCarouselButton isActive={1 === activeButton} onClick={() => scrollTo('Start')} />
            <StyledCarouselButton isActive={2 === activeButton} onClick={() => scrollTo('Middle')} />
            <StyledCarouselButton isActive={3 === activeButton} onClick={() => scrollTo('End')} />
          </StyledCarouselButtonContainer>
        )}

        <StyledCarouselTrack id={id} numberOfSlides={numberOfSlides} ref={trackRef} slideSize={slideSize}>
          {children}
        </StyledCarouselTrack>

        {showArrow && (
          <StyledCarouselArrow position="right" onClick={() => scrollTo('End')}>
            <Icon size={24} name="ArrowRight" />
          </StyledCarouselArrow>
        )}
      </StyledCarousel>
    );
  };

  return variant === 'hero' ? renderHeroCarousel() : renderDefaultCarousel();
};
