import styled from 'styled-components/macro';

export const ColumnContainer = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;

  & > * {
    flex: 1 100%;
  }
`;
