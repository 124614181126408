import React, { useState, useCallback } from 'react';
import { Box, Image, Text, Heading } from 'grommet';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { withRouter, match } from 'react-router';
import { loader } from 'graphql.macro';
import { theme } from '../../Styled';
import { toUrl } from '../../Util';
import { SegmentQuery } from './types/SegmentQuery';
import { BasicTrackFragment } from '../../Core/Player/Queries/types/BasicTrackFragment';
import './style.css';
import { StationArt } from '../../Styled/StationArt';
import styled from 'styled-components/macro';
import { PlayerContext, withPlayerContext } from '../../Core/Player/PlayerContext';
import Clipboard from 'react-clipboard.js';
import { useMutation, useQuery } from '@apollo/client';
import { Redirect, useHistory } from 'react-router-dom';
import { PlayerControlsDeletePlaylist, PlayerControlsDeletePlaylistVariables } from './types/PlayerControlsDeletePlaylist';
import { UserPlaylistsListQuery } from '../PlaylistsList/types/UserPlaylistsListQuery';
import { DropdownItemConfig, DropdownItemProps } from '../../Styled/TrackList/TrackDropdown';
import { sendAnalyticsEvent } from '../../Styled/TrackList/Utils';
import { AnalyticsEventType } from '../../Core/Analytics';
import { RemoveTrackFromPlaylist, RemoveTrackFromPlaylistVariables } from '../../Core/Player/Queries/types/RemoveTrackFromPlaylist';
import { LoadingSpinner } from '../LoadingSpinner';
import { Footer } from '../Footer';
import { Modal } from '../Modal';
import { Button } from '../Button';
import { SegmentHeader } from './Components/SegmentHeader';
import { TrackList } from '../TrackList';
import { SegmentType } from './types';
import { Logo } from '../../../../assets/icons';
import { Placeholder } from '../../Components/PlaylistsList/Components/Styles';

const segmentGql = loader('./segment.graphql');
const deletePlaylistGql = loader('./delete-playlist.graphql');
const userPlaylistsGql = loader('../PlaylistsList/userPlaylists.graphql');
const removeTrackFromPlaylistMutation = loader('../../Core/Player/Queries/remove-track-from-playlist.graphql');

interface ParamProps {
  segmentId: string;
  trackId?: string;
}

interface RouterProps {
  match: match<ParamProps>;
}

interface GraphqlProps {
  data: QueryControls & SegmentQuery;
}

type PropsFromPlayer = Pick<PlayerContext, 'playSegment' | 'activeSegment' | 'showModal' | 'closeModal'>;

type Props = GraphqlProps & RouterProps & PropsFromPlayer;

const MenuClipboard = styled(Clipboard)`
  background: transparent;
  border: none;
  color: #f8f8f8;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
`;

export const StyledClipboard = styled(Clipboard)`
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  color: ${theme.colors.baseWhite};
  &:hover {
    color: ${theme.colors.brandGreen2};
  }
`;

const DoesUserOwnPlaylist = (segmentGuid: string, userPlaylistsData: UserPlaylistsListQuery) =>
  userPlaylistsData.userPlaylists.edges.some(el => el.node.id == segmentGuid);

const SegmentImplementation = (props: Props) => {
  let playlistId: string | undefined = undefined;
  const { data } = props;
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { data: userPlaylists } = useQuery(userPlaylistsGql);
  const isUserPlaylistOwner = data.segment && userPlaylists ? DoesUserOwnPlaylist(data.segment.id, userPlaylists) : false;

  const [deletePlaylist] = useMutation<PlayerControlsDeletePlaylist, PlayerControlsDeletePlaylistVariables>(deletePlaylistGql, {
    update: (cache, mutationResult) => {
      const cacheData = cache.readQuery<UserPlaylistsListQuery>({ query: userPlaylistsGql });
      const edges = cacheData.userPlaylists.edges.filter(value => value.node.id !== data.segment.id);
      const userPlaylists = {
        ...cacheData.userPlaylists,
        edges,
      };
      cache.writeQuery({
        query: userPlaylistsGql,
        data: { userPlaylists },
      });
    },
  });

  const [removeTrackFromPlaylist] = useMutation<RemoveTrackFromPlaylist, RemoveTrackFromPlaylistVariables>(removeTrackFromPlaylistMutation, {
    update: (cache, mutationResult) => {
      const data = cache.readQuery<SegmentQuery>({ query: segmentGql, variables: { guid: playlistId } });
      if (!data || !data.segment || !data.segment.tracks || !data.segment.tracks.edges) return;
      const edges = data.segment.tracks.edges.filter(value => value?.node?.id !== /*track?.id*/ playlistId);
      const segment = {
        ...data.segment,
        tracks: {
          ...data.segment.tracks,
          edges,
        },
      };
      cache.writeQuery({
        query: segmentGql,
        data: { segment },
      });
    },
  });

  function handlePlay() {
    props.playSegment({ id: props.match.params.segmentId });
  }

  function handleDelete() {
    if (data.segment.__typename === SegmentType.Playlist) {
      setIsModalOpen(true);
    }
  }

  function renderDeleteModal(): React.ReactNode {
    return (
      <>
        <Heading level="2" style={{ color: 'white' }}>
          Are you sure you want to delete this playlist?
        </Heading>
        <Button color="outline" id="button-delete-playlist-modal-cancel" onClick={props.closeModal}>
          Cancel
        </Button>
        <Button color="danger" id="button-delete-playlist-modal-confirm" onClick={actuallyDeletePlaylist} title="Delete">
          Delete
        </Button>
      </>
    );
  }

  function actuallyDeletePlaylist() {
    props.closeModal();
    if (data.segment.__typename === 'Playlist') {
      deletePlaylist({ variables: { id: data.segment.id } }).then(() => {
        history.push('/playlists');
      });
    }
  }

  function onMore() {
    const guid = props.match.params.segmentId;
    const after = props.data.segment.tracks.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'segment tracks',
      currentLength: props.data.segment.tracks.edges.length,
      guid,
      after,
    });
    setLoadingMore(true);

    props.data.fetchMore({
      variables: { guid, after },
      updateQuery: (previousQueryResult: SegmentQuery, { fetchMoreResult }) => {
        setLoadingMore(false);
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          segment: {
            ...previousQueryResult.segment,
            tracks: Object.assign({}, fetchMoreResult.segment.tracks, {
              edges: [...previousQueryResult.segment.tracks.edges, ...fetchMoreResult.segment.tracks.edges],
            }),
          },
        };
      },
    });
  }

  if (data.loading) {
    return <LoadingSpinner />;
  }
  if (data.error) {
    throw data.error;
  }

  if (!data.segment) {
    return <Redirect to="/" />;
  }

  let art = null;
  let subHeaderText = '';
  switch (data.segment.__typename) {
    case 'Album':
    case 'Artist':
    case 'Label':
    // @ts-ignore
    case 'CuratedPlaylist':
      art = <Image src={toUrl(data.segment.artworkGuid)} width="100%" style={{ borderRadius: '2%' }} />;
      break;

    case 'Station':
      art = <StationArt color1={data.segment.color1} color2={data.segment.color2} theIcon={data.segment.icon} />;
      subHeaderText = data.segment.description;
      break;

    case 'Playlist':
      playlistId = data.segment.id;

      // If the user has 0 tracks in their playlist, artworkGuid defaults to "0.0"
      if (data.segment.artworkGuid === '0.0') {
        art = (
          <Placeholder>
            <Logo width={100} />
          </Placeholder>
        );
      } else {
        art = <Image src={toUrl(data.segment.artworkGuid)} width="100%" style={{ borderRadius: '2%' }} />;
      }

      subHeaderText = data.segment.description;
      break;

    case 'Mix':
      break;
  }

  const tracks: BasicTrackFragment[] = data.segment.tracks.edges.map(t => t.node);
  const hasMore = data.segment.tracks.pageInfo.hasNextPage;
  const stats = data.segment.stats;

  let shareUrl: string = `https://play.pretzel.rocks/segment/${props.match.params.segmentId}`;
  if (process.env.REACT_APP_CONTEXT !== 'production' && process.env.REACT_APP_DEPLOY_PRIME_URL) {
    shareUrl = `${process.env.REACT_APP_DEPLOY_PRIME_URL}/segment/${props.match.params.segmentId}`;
  }

  const dropdownItems: DropdownItemConfig[] = [
    {
      id: 'share',
      render: ({ track }) => <MenuClipboard data-clipboard-text={`${shareUrl}/${track.id}`}>Copy Song Link</MenuClipboard>,
      onClick: ({ track }: DropdownItemProps) => {},
    },
  ];

  if (playlistId) {
    dropdownItems.push({
      id: 'remove-from-playlist',
      render: () => <span>Remove from Playlist</span>,
      onClick: ({ track }: DropdownItemProps) => {
        removeTrackFromPlaylist({
          variables: {
            trackId: track.id,
            playlistId: playlistId || '',
          },
        });
        sendAnalyticsEvent(AnalyticsEventType.RemoveFromPlaylist, track);
      },
    });
  }

  const handleRemoveTrackFromPlaylist = (track: BasicTrackFragment) => {
    removeTrackFromPlaylist({
      variables: {
        trackId: track.id,
        playlistId: playlistId || '',
      },
    });
    sendAnalyticsEvent(AnalyticsEventType.RemoveFromPlaylist, track);
  };

  return (
    <Box fill pad="medium" overflow="auto">
      <div style={{ flexGrow: 1 }}>
        <SegmentHeader
          data={data.segment}
          subHeaderText={subHeaderText}
          art={art}
          handlePlay={handlePlay}
          shareUrl={shareUrl}
          handleDelete={handleDelete}
          showDropdownMenu={isUserPlaylistOwner}
        />
        <TrackList
          title="Tracks"
          fit="content"
          tracks={tracks}
          hasMore={hasMore}
          onMore={onMore}
          additionalDropdownItems={dropdownItems}
          highlightId={props.match.params.trackId}
          removeTrackFromPlaylist={isUserPlaylistOwner ? handleRemoveTrackFromPlaylist : undefined}
          loading={props.data.loading}
          type={data.segment.__typename}
          loadingMore={loadingMore}
          shareUrl={shareUrl}
        />
        <Modal
          content={<></>}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          title="Are you sure you want to delete this playlist?"
          buttons={[
            {
              color: 'outline',
              label: 'Cancel',
              onClick: () => setIsModalOpen(false),
            },
            {
              color: 'danger',
              label: 'Delete',
              onClick: actuallyDeletePlaylist,
            },
          ]}
          heapid="modal-delete-playlist"
          testid="modal-delete-playlist"
        />
      </div>
      <Footer />
    </Box>
  );
};

function mapContextToProps(c: PlayerContext): PropsFromPlayer {
  return {
    activeSegment: c.activeSegment,
    playSegment: c.playSegment,
    showModal: c.showModal,
    closeModal: c.closeModal,
  };
}

export const Segment = compose(
  withRouter,
  withPlayerContext(mapContextToProps),
  graphql(segmentGql, {
    options: (props: RouterProps) => {
      return {
        // We want to refresh every time we navigate here, but this seems to cause the entire page to reload whenever
        // we are playing the segment and click "next"
        //fetchPolicy: 'cache-and-network',
        variables: {
          guid: props.match.params.segmentId,
        },
      };
    },
  })
)(SegmentImplementation);
