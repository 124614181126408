import React, { FC } from 'react';
import { Carousel } from '../../../../Components/Carousel';
import { Section } from '../../../../Components/Home/Components/Section';
import { PlaylistCard } from '../../../../Components/PlaylistsList/Components/PlaylistCard';
import { FeaturedPlaylistsProps } from './types';

export const FeaturedPlaylists: FC<FeaturedPlaylistsProps> = ({ playlists, handleNonPremiumClick }) => {
  return (
    <Section title="Featured playlists">
      <Carousel showArrowByDefault={false} id="featured-playlists-grid" numberOfSlides={4} showButtons={false} slideSize="minmax(355px, 1fr)">
        {playlists?.map((playlist, index) => {
          if (index >= 4) return null;

          return (
            <PlaylistCard
              id={playlist.id}
              key={playlist.id}
              playlist={playlist}
              size="100%"
              handleNonPremiumClick={handleNonPremiumClick}
              widescreen
            />
          );
        })}
      </Carousel>
    </Section>
  );
};
