import { BasicTrackFragment } from '../../Core/Player/Queries/types/BasicTrackFragment';
import { SetTrackBlacklistedInput, SetTrackBlacklistedMutation } from '../../Core/Player/Components/PlayerControls/schema';
import * as React from 'react';
import { ForbiddenIcon, ForbiddenRow, StyledButton } from './Styled';
import { Icon } from '../Icon';
import { joinStringsGrammatically } from '../../Util';
import { sendAnalyticsEvent } from './Utils';
import { AnalyticsEventType } from '../../Core/Analytics';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import { DropdownItemConfig, TrackDropdown } from './TrackDropdown';
import { Box } from 'grommet';
import { PlayTokenFragment } from '../../Core/Player/Queries/types/PlayTokenFragment';

const dislikeTrackMutation = loader('../../Core/Player/Queries/set-track-blacklisted.graphql');

interface ForbiddenTrackItemProps {
  track: BasicTrackFragment;
  playToken: PlayTokenFragment | null;
  additionalDropdownItems?: DropdownItemConfig[];
  next: () => void;
  index: number;
  key: string;
  youtubeRestricted: boolean;
  instrumentalRestricted: boolean;
  explicitRestricted: boolean;
  disliked: boolean;
  freeTierRestricted: boolean;
  highlight?: boolean;
}

export function ForbiddenTrackItem(props: ForbiddenTrackItemProps) {
  const {
    track,
    index,
    youtubeRestricted,
    instrumentalRestricted,
    explicitRestricted,
    disliked,
    freeTierRestricted,
    playToken,
    additionalDropdownItems,
    next,
    highlight,
  } = props;

  const [dislikeTrack] = useMutation<SetTrackBlacklistedMutation, SetTrackBlacklistedInput>(dislikeTrackMutation);

  const [isOpen, setOpen] = useState(false);

  function toggleDislike(event: React.MouseEvent<any>) {
    // @ts-ignore
    event.target.parentElement.blur();
    dislikeTrack({
      variables: {
        trackId: track.id,
        value: !track.self.blacklisted,
      },
    });
    sendAnalyticsEvent(AnalyticsEventType.Dislike, track);
  }

  let youtubeContent;
  if (youtubeRestricted) {
    youtubeContent = (
      <ForbiddenIcon
        highlight={highlight}
        className={'pretzel-icon-youtube-safe'}
        title="This track is not safe for YouTube, and you have the YouTube Safe filter turned on"
      />
    );
  }
  let instrumentalContent;
  if (instrumentalRestricted) {
    instrumentalContent = (
      <ForbiddenIcon
        highlight={highlight}
        className={'pretzel-icon-no-vocals'}
        title="This track has vocals, and you have the Instrumental Only filter turned on"
      />
    );
  }
  let explicitContent;
  if (explicitRestricted) {
    explicitContent = (
      <ForbiddenIcon
        style={{ color: '#a4a4a4' }}
        className={'pretzel-icon-explicit'}
        title="This track is explicit, and you have the Allow Mature filter turned off"
      />
    );
  }
  let dislikeContent;
  if (disliked) {
    dislikeContent = (
      <StyledButton onClick={toggleDislike} title="Remove from Dislike">
        <Icon name="thumbs_down" size={18} isDisabled={false} />
      </StyledButton>
    );
    // dislikeContent = <ForbiddenIcon
    //   className={'pretzel-icon-thumbs_down'}
    //   title="This track has been disliked" />
  }
  let freeTierContent;
  if (freeTierRestricted) {
    freeTierContent = (
      <ForbiddenIcon
        className={'pretzel-icon-radio'}
        title="Free Tier users are not able to play individual songs. Upgrade to premium by clicking this icon next to the player"
      />
    );
  }

  function onClickMenu(event: React.MouseEvent) {
    event.stopPropagation(); // Don't trigger the row's `onClick`
    setOpen(true);
    document.addEventListener('click', onClickOut);
  }

  function onClickOut() {
    setOpen(false);
    document.removeEventListener('click', onClickOut);
  }

  return (
    <ForbiddenRow key={`sr-${track.id}`} title="This track is disabled due to filters" highlight={highlight}>
      <td
        style={{
          width: '46px',
          textAlign: 'center',
          fontStyle: 'normal',
        }}
      >
        {(index + 1).toString()}
      </td>
      <td>{track.title}</td>
      <td>{joinStringsGrammatically(track.artists.map(a => a.name))}</td>
      <td>{track.album && track.album.title}</td>
      <td>
        <Box onClick={onClickMenu} style={{ position: 'relative', textAlign: 'right' }} focusIndicator={false}>
          <Icon name="more" size={20} />
          {isOpen && <TrackDropdown playToken={playToken} track={track} additionalItems={additionalDropdownItems} next={next} />}
        </Box>
      </td>
      <td className="reason" colSpan={4}>
        {instrumentalContent}
        {youtubeContent}
        {explicitContent}
        {dislikeContent}
        {freeTierContent}
      </td>
    </ForbiddenRow>
  );
}
