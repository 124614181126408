import styled from 'styled-components/macro';
import { Box } from 'grommet';
import { theme } from '../../Styled';

const ButtonBox = styled(Box)`
  color: ${theme.colors.textParagraph};
  cursor: pointer;
  min-width: auto !important;
  outline: none;
  padding-left: ${theme.space.sm};
  transition: background-color 0.1s ease;

  ${theme.mediaQueries.small} {
    margin-left: auto;
  }

  &:hover {
    background-color: ${theme.colors.bgSectionsAltHover};
    color: ${theme.colors.brandGreen};
  }
`;

const StyledLabel = `
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
  color: ${theme.colors.grey};
`;

const StyledInput = `
  background: ${theme.colors.bodyBg} !important;
  border: ${theme.borders.style} !important;
  border-radius: 0.25rem !important;
  margin-top: 0.5rem;
  height: 40px;
  padding: 0.6875rem 1rem;
  color: ${theme.colors.grey};
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-content: center;
  position: relative;
  font-size: 0.875rem;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  background-color: ${theme.colors.sidebarBlack};
  border: 2px solid ${theme.colors.brandGreen};
  box-sizing: border-box;
  border-radius: 1px;
`;

const StyledCheck = styled.div`
  position: absolute;
  left: 4px;
  top: -2px;
  transform: scaleX(-1) rotate(-42deg);

  :after {
    content: 'L';
  }
`;

const StyledSidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
`;

const StyledSidebarFooterBuildVersion = styled.div`
  margin-top: 2rem;
  margin-bottom: 5px;
`;

const StyledSidebarFooterButtonWrapper = styled.div`
  margin-top: 2rem;

  button:last-of-type {
    margin-left: 1rem;
  }
`;

const StyledSidebarFooterUser = styled.div`
  margin-top: 1.5rem;

  span {
    font-weight: 700;
  }
`;

export {
  ButtonBox,
  StyledLabel,
  StyledInput,
  StyledCheckboxWrapper,
  StyledCheckbox,
  StyledCheck,
  StyledSidebarFooter,
  StyledSidebarFooterBuildVersion,
  StyledSidebarFooterButtonWrapper,
  StyledSidebarFooterUser,
};
