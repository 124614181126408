import Close from '../../../../assets/icons/Close';
import React from 'react';
import { ISidebarProps, StyledSidebarWrapper, StyledContentWrapper } from './Styled';

const Sidebar = ({ open, setOpenSidebar, children, direction }: ISidebarProps) => {
  const handleClose = () => {
    // This should probably be handled in the app state somewhere rather than the DOM being updated directly but kicking the can down the road for now.
    const element = document.getElementById('giftcard');

    if (element) {
      element.classList.remove('settings');
    }

    setOpenSidebar(!open);
  };

  return (
    <StyledSidebarWrapper open={open} direction={direction}>
      <Close
        color="white"
        width="2rem"
        height="2rem"
        onClick={handleClose}
        style={{ alignSelf: 'flex-end', cursor: 'pointer', flexShrink: 0, margin: '0 2rem 2rem 0' }}
      />
      <StyledContentWrapper>{children}</StyledContentWrapper>
    </StyledSidebarWrapper>
  );
};

export default Sidebar;
