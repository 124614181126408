import React, { useState } from 'react';
import { theme, Icon } from '../../../../Styled';
import { flowRight as compose } from 'lodash';
import { PlatformContext, Tier, withPlatformContext } from '../../../../Core/Platform';
import { DropdownContainer, DropdownItem, ButtonContainer } from '../../../../Styled/DropdownMenu';
import {
  StyledSegmentHeader,
  SegmentHeaderImageContainer,
  TypeNameContainer,
  NameContainer,
  DurationContainer,
  ButtonsContainer,
  SegmentInfoContainer,
  buttonStyles,
} from './styled';
import { CopyToClipboard, minutesOrHours } from '../../../../Util';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { SegmentHeaderProps } from './types';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';
import { ShareToastConfig } from '@pretzel-aux/common/src/Styled/ToastContainer';
import { toast } from 'react-toastify';
import { UpgradeToPremiumModal } from '@pretzel-aux/common/src/Components/UpgradeToPremiumModal';
import { isUserEntitled } from '@pretzel-aux/common/src/Util/Entitlements';

type PropsFromPlatform = Pick<PlatformContext, 'pretzelUser'>;

type Props = SegmentHeaderProps & PropsFromPlatform;

export const SegmentHeaderPresentation = ({
  data,
  pretzelUser,
  subHeaderText,
  art,
  handlePlay,
  shareUrl,
  handleDelete,
  showDropdownMenu = true,
}: Props) => {
  const { width } = useWindowSize();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isCuratedPlaylist = data.__typename === 'CuratedPlaylist';
  const isProOrAdmin = isUserEntitled(pretzelUser?.jwt.tier, [Tier.ZeroCpm, Tier.Pro, Tier.Admin]);

  const onClickMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuVisible(true);
    document.addEventListener('click', onClickOut);
  };

  const onClickOut = () => {
    setMenuVisible(false);
    document.removeEventListener('click', onClickOut);
  };

  const handleShare = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    CopyToClipboard(shareUrl);
    toast(data.__typename?.split(/(?=[A-Z])/).join(' ') + ' link copied', ShareToastConfig);
  };

  return (
    <StyledSegmentHeader>
      <SegmentHeaderImageContainer>{art}</SegmentHeaderImageContainer>
      <SegmentInfoContainer>
        <TypeNameContainer>{data.__typename?.split(/(?=[A-Z])/).join(' ')}</TypeNameContainer>
        <NameContainer>
          <h1 style={{ fontSize: width <= theme.appBreakpoints.small ? '1rem' : 'inherit' }}>{data.name}</h1>
          {data.__typename === 'Playlist' && showDropdownMenu && (
            <div>
              <ButtonContainer onClick={onClickMenu} aria-haspopup="true" aria-expanded={menuVisible}>
                <Icon name="more" size={20} />
                {menuVisible && (
                  <DropdownContainer>
                    <DropdownItem tabIndex={0} onClick={handleDelete} aria-label="Delete playlist">
                      Delete playlist <Icon name="Delete" size={22} />
                    </DropdownItem>
                  </DropdownContainer>
                )}
              </ButtonContainer>
            </div>
          )}
        </NameContainer>
        {width > theme.appBreakpoints.small && (
          <DurationContainer style={{ margin: '0.5rem 0' }}>
            {data.stats.totalCount} tracks for a total of {minutesOrHours(data.stats.duration)} of music.
          </DurationContainer>
        )}
        <ButtonsContainer>
          <StyledButton
            style={buttonStyles}
            color="secondary"
            onClick={isCuratedPlaylist && !isProOrAdmin ? () => setIsModalOpen(true) : handlePlay}
            aria-label="Play all tracks"
            data-heapid="segment-primary"
            data-testid="segment-primary-button"
          >
            {isCuratedPlaylist && !isProOrAdmin ? 'Upgrade to play' : 'Play all tracks'}
          </StyledButton>
          <StyledButton style={buttonStyles} color="outline" aria-label="Share tracks" onClick={handleShare}>
            Share tracks
          </StyledButton>
        </ButtonsContainer>
      </SegmentInfoContainer>
      <UpgradeToPremiumModal
        displayCloseIcon={false}
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        buttons={[
          {
            color: 'outline',
            onClick: () => {
              setIsModalOpen(false);
            },
            label: 'Cancel',
          },
        ]}
        pro={isCuratedPlaylist}
      />
    </StyledSegmentHeader>
  );
};

function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export const SegmentHeader = compose(withPlatformContext(mapPlatformToProps))(SegmentHeaderPresentation);
