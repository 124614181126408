import * as React from "react";

function SvgSailingBoat1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10.621 4.818L18.536 8.6a.25.25 0 00.357-.243c-.241-3.657-1.319-6.689-2.34-7.849A1.506 1.506 0 0014.525.3a30.971 30.971 0 00-4.057 3.728.5.5 0 00.153.789zM6.872 9.762l11.713 5.6a.25.25 0 00.358-.225v-4.361a.5.5 0 00-.284-.451L9.378 5.887a.5.5 0 00-.609.142 32.334 32.334 0 00-2.107 3.02.5.5 0 00.21.713zM21.985 20.474a1.006 1.006 0 00-.851-.474h-1.691a.5.5 0 01-.5-.5v-1.986a.5.5 0 00-.284-.452L6.078 11.046a.5.5 0 00-.662.224 29.135 29.135 0 00-1.931 4.811 1 1 0 00.642 1.237c.175.058 4.232 1.38 12.264 1.536a.563.563 0 01.552.564.581.581 0 01-.581.581H2.866A1 1 0 002.1 21.64C4.051 23.972 7.785 24 7.943 24h12.191a.994.994 0 00.895-.553l1-2a1 1 0 00-.044-.973z" />
    </svg>
  );
}

export default SvgSailingBoat1;
