import * as React from "react";

function SvgRadioAntenna1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20.753 8.009a.261.261 0 01-.077-.018L1.38.075a1 1 0 00-.76 1.85l13.635 5.594a.25.25 0 01-.1.481H3.5A3.5 3.5 0 000 11.5v9A3.5 3.5 0 003.5 24h17a3.5 3.5 0 003.5-3.5v-9a3.5 3.5 0 00-3.247-3.491zm-.253 4.482a2 2 0 11-2-2 2 2 0 012 2zm0 4.018a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zm-4 2.991h4a.75.75 0 010 1.5h-4a.75.75 0 010-1.5zM13.491 16A5.5 5.5 0 118 10.5a5.495 5.495 0 015.491 5.5z" />
      <path d="M6.94 13a.75.75 0 00.75.75h.61a.75.75 0 100-1.5h-.61a.75.75 0 00-.75.75zM4.873 15.418a.75.75 0 001.027-.276l.3-.527a.75.75 0 00-1.3-.752l-.3.53a.75.75 0 00.273 1.025zM5.9 16.863a.75.75 0 00-1.3.752l.3.527a.75.75 0 101.3-.749zM8.31 18.25H7.7a.75.75 0 100 1.5h.61a.75.75 0 100-1.5zM11.127 16.582a.749.749 0 00-1.023.275l-.306.528a.75.75 0 001.3.752l.3-.531a.749.749 0 00-.271-1.024zM10.1 15.137a.75.75 0 101.3-.752l-.3-.528a.75.75 0 10-1.3.749z" />
    </svg>
  );
}

export default SvgRadioAntenna1;
