import styled from 'styled-components/macro';
import { theme } from '../../../Styled';

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(${({ colors }: { colors: string[] }) => `145deg, ${colors[0]}, ${colors[1]}`});
  opacity: 0.9;
`;

export const Placeholder = styled.div`
  align-items: center;
  background-color: ${theme.colors.primary};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  border-radius: 0.25rem;
`;
