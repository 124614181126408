import * as React from 'react';
import { Box, Heading } from 'grommet';
import { ClipLoader } from 'halogenium';
import { flowRight as compose } from 'lodash';
import { PlayerContext, withPlayerContext } from '../Core/Player/PlayerContext';
import { LoadMoreButton } from './LoadMoreButton';
import { SettingsContext, SettingsKeys, withSettingsContext } from '../Core/Settings/Settings';
import { theme } from './';
import { PlatformContext, withPlatformContext } from '../Core/Platform';
import { BasicTrackFragment } from '../Core/Player/Queries/types/BasicTrackFragment';
import { StyledRow } from './TrackList/Styled';
import { ForbiddenTrackItem } from './TrackList/ForbiddenTrackItem';
import { TrackItem } from './TrackList/TrackItem';
import { DropdownItemConfig } from './TrackList/TrackDropdown';
import { LoadingSpinner } from '../Components/LoadingSpinner';

interface PublicProps {
  title: string;
  fit?: 'content' | 'container';
  additionalDropdownItems?: DropdownItemConfig[];
  tracks: BasicTrackFragment[];
  loading: boolean;
  hasMore: boolean;
  highlightId?: string;
  onMore: () => void;
  clickNotification?: (track: BasicTrackFragment) => void;
  loadingMore: boolean;
}

type PropsFromPlatform = Pick<PlatformContext, 'pretzelUser'>;
type PropsFromPlayerContext = Pick<PlayerContext, 'playToken' | 'playTrack' | 'next'>;
type PropsFromSettingsContext = Pick<SettingsContext, SettingsKeys.InstrumentalOnly | SettingsKeys.AllowExplicit | SettingsKeys.YoutubeSafe>;

type Props = PublicProps & PropsFromPlayerContext & PropsFromSettingsContext & PropsFromPlatform;

function TrackListPresentation(props: Props) {
  function renderTracks() {
    const {
      allowExplicit,
      clickNotification,
      youtubeSafe,
      instrumentalOnly,
      highlightId,
      pretzelUser,
      playToken,
      playTrack,
      additionalDropdownItems,
      next,
      tracks,
    } = props;

    return tracks.map((track, i) => {
      const explicitForbidden: boolean = !!track.explicit && !allowExplicit;
      const youtubeForbidden: boolean = !track.youtubeSafe && youtubeSafe;
      const instrumentalForbidden: boolean = !track.instrumental && instrumentalOnly;
      const isPremium = pretzelUser.jwt.tier !== 'free';
      const forbidden: boolean = explicitForbidden || youtubeForbidden || instrumentalForbidden || track.self.blacklisted || isPremium;

      if (forbidden) {
        return (
          <ForbiddenTrackItem
            track={track}
            index={i}
            key={track.id}
            playToken={playToken}
            next={next}
            additionalDropdownItems={additionalDropdownItems}
            highlight={track.id === highlightId}
            youtubeRestricted={youtubeForbidden}
            explicitRestricted={explicitForbidden}
            instrumentalRestricted={instrumentalForbidden}
            disliked={track.self.blacklisted}
            freeTierRestricted={!isPremium}
          />
        );
      } else {
        return (
          <TrackItem
            track={track}
            index={i}
            key={track.id}
            playToken={playToken}
            playTrack={playTrack}
            additionalDropdownItems={additionalDropdownItems}
            next={next}
            highlight={track.id === highlightId}
            clickNotification={clickNotification}
          />
        );
      }
    });
  }

  const style = props.fit === 'content' ? undefined : { overflow: 'auto', padding: '30px', height: '100%' };

  if (props.loading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={style}>
      <Heading level="2">{props.title}</Heading>
      <table>
        <tbody>{renderTracks()}</tbody>
      </table>
      <Box justify="center" align="center" margin="20px">
        {props.hasMore && <LoadMoreButton onMore={props.onMore} loading={props.loadingMore} />}
      </Box>
    </div>
  );
}

function mapPlayerContextToProps(c: PlayerContext): PropsFromPlayerContext {
  return {
    playToken: c.playToken,
    playTrack: c.playTrack,
    next: c.next,
  };
}

function mapSettingsContextToProps(c: SettingsContext): PropsFromSettingsContext {
  return {
    instrumentalOnly: c.instrumentalOnly,
    allowExplicit: c.allowExplicit,
    youtubeSafe: c.youtubeSafe,
  };
}

function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export const TrackList: React.ComponentClass<PublicProps> = compose(
  withPlatformContext(mapPlatformToProps),
  withSettingsContext(mapSettingsContextToProps),
  withPlayerContext(mapPlayerContextToProps)
)(TrackListPresentation);
