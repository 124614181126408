import * as React from 'react';
import { Box } from 'grommet';
import { LoadMoreButton } from './LoadMoreButton';
import { TileList } from './TileRow';
import { theme } from './';
import { LoadingSpinner } from '../Components/LoadingSpinner';
import { Footer } from '../Components/Footer';
import styled from 'styled-components';
import PageWrapper from './Page';

const MusicListWrapper = styled.div`
  padding: ${theme.space.md};
  ${theme.mediaQueries.large} {
    padding: ${theme.space.lg} ${theme.space.lg} 0;
  }
`;

type Thing = {};

interface PublicProps {
  title: string;
  fit?: 'content' | 'container';
  items: Thing[] | null;
  hasMore: boolean;
  onMore: () => void;
  loading: boolean;
  circle?: boolean;
  tileSize?: number;
}

const MusicList = ({ fit, title, tileSize, items, hasMore, onMore, loading }: PublicProps) => {
  const overflow = fit === 'content' ? '' : 'auto';

  return (
    <PageWrapper title={title}>
      <TileList gutter={24} tileWidth={tileSize ?? 180} rowGap={32}>
        {items}
      </TileList>

      <Box justify="center" align="center" margin={theme.space.lg}>
        {hasMore && <LoadMoreButton onMore={onMore} loading={loading} />}
      </Box>

      {loading && <LoadingSpinner />}

      <Footer />
    </PageWrapper>
  );
};

export { MusicList };
