import { useQuery } from '@apollo/client';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { LabelCard } from './Components/LabelCard';
import { LabelsListQuery } from './types/LabelsListQuery';
import { LoadingSpinner } from '../LoadingSpinner';
import { MusicList, theme } from '../../Styled';

const labelsGqlQuery = loader('./labels.graphql');

export const LabelsList = (): JSX.Element => {
  const [state, setState] = useState<boolean>(false);
  const response = useQuery<LabelsListQuery>(labelsGqlQuery);
  const { width } = useWindowSize();

  const setTileSize = (): number => {
    if (width >= theme.appBreakpoints.small && width < theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.small);
    }
    if (width >= theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.normal);
    }
    return parseInt(theme.card.size.large);
  };

  const setLabelCardSize = (): string => {
    if (width <= theme.appBreakpoints.medium) {
      return theme.card.size.featured;
    }
    return theme.card.size.large;
  };

  const labels = response.loading
    ? null
    : response.data.labels.edges.map(labelEdge => <LabelCard key={labelEdge.node.id} label={labelEdge.node} size={setLabelCardSize()} />);
  const hasMore = response.data?.labels?.pageInfo?.hasNextPage;

  const onMore = (): void => {
    const after = response.data.labels.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'label',
      currentLength: response.data.labels.edges.length,
      after,
    });
    setState(true);
    response.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: LabelsListQuery, { fetchMoreResult }) => {
        setState(false);

        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          labels: Object.assign({}, fetchMoreResult.labels, {
            edges: [...previousQueryResult.labels.edges, ...fetchMoreResult.labels.edges],
          }),
        };
      },
    });
  };

  if (response.loading) {
    return <LoadingSpinner />;
  }

  return <MusicList title="Labels" items={labels} hasMore={hasMore} onMore={onMore} circle={true} loading={state} tileSize={setTileSize()} />;
};
