import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

export interface ISidebarProps {
  open: boolean;
  children: React.ReactNode;
  setOpenSidebar?: (open: boolean) => void;
  direction?: string;
}

const width = '100vw';

const StyledSidebarWrapper = styled.div<ISidebarProps>`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0 2.5rem 2rem;
  background-color: ${theme.colors.sidebarBlack};
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100%;
  right: ${({ open }) => (open ? '0' : `-${width}`)};
  width: ${width};
  max-width: 455px;
  transition: right 0.4s ease;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export { StyledSidebarWrapper, StyledContentWrapper };
