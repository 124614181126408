import React from 'react';
import { flowRight as compose } from 'lodash';
import styled from 'styled-components';
import { theme } from '../../../../Styled';
import { Box, Heading } from 'grommet';
import { SettingsContext, SettingsKeys, withSettingsContext } from '../../../../Core/Settings/Settings';
import { OS, PlatformContext, withPlatformContext } from '../../../../Core/Platform';
import { KeybindConfiguration, keybindOptions } from './KeyAction';
import KeybindContainer from './Components/KeybindContainer';
import AddKeybindButton from './Components/AddKeybindButton';

const KeybindsWrapper = styled.div`
  align-content: center;
`;

const Notice = styled.div`
  color: ${theme.colors.baseMuted};
`;

type PropsFromSettings = Pick<
  SettingsContext,
  SettingsKeys.BindMediaHotkeys | 'setBindMediaHotkeys' | SettingsKeys.GlobalHotKeyConfiguration | 'setGlobalHotKeys'
>;

type PropsFromPlatform = Pick<PlatformContext, 'features' | 'platformDetails'>;

type Props = PropsFromSettings & PropsFromPlatform;

interface State {}

export class GlobalWindowPresentation extends React.Component<Props, State> {
  render() {
    if (!this.props.features.customKeybinds) {
      return null;
    }

    return (
      <div>
        <p>Global Keybinds</p>
        <p>These Keybinds should be active as long as Pretzel is open.</p>
        <KeybindsWrapper>
          {this.renderGlobalOptions()}
          <AddKeybindButton addAKeybind={this.addAKeybind} />
        </KeybindsWrapper>
      </div>
    );
  }

  renderGlobalOptions() {
    return this.props.globalHotKeyConfiguration.map((option: KeybindConfiguration, index: number) => {
      return (
        <KeybindContainer
          key={index}
          index={index}
          keybindConfig={option}
          actions={keybindOptions}
          macOS={this.props.platformDetails.os === OS.Mac}
          updateAction={this.updateAction}
          updateValue={this.updateValue}
          updateAccelerator={this.updateAccelerator}
          deleteKeybind={this.deleteKeybind}
        />
      );
    });
  }

  updateAccelerator = (index: number, accelerator: string) => {
    const newKeybinds = this.props.globalHotKeyConfiguration.slice();
    newKeybinds[index].accelerator = accelerator;
    this.updateKeybindErrors(newKeybinds);
    this.props.setGlobalHotKeys(newKeybinds);
  };

  addAKeybind = () => {
    const newKeybinds = this.props.globalHotKeyConfiguration.slice();
    newKeybinds.push({ error: false });
    this.props.setGlobalHotKeys(newKeybinds);
  };

  updateAction = (index: number, action: string) => {
    const newKeybinds = this.props.globalHotKeyConfiguration.slice();
    newKeybinds[index].selectedAction = action;
    const actionData = keybindOptions.filter(actionObj => actionObj.value === action)[0];
    if (!actionData.isValue) {
      delete newKeybinds[index].value;
    } else {
      newKeybinds[index].value = actionData.defaultValue;
    }
    this.props.setGlobalHotKeys(newKeybinds);
  };

  updateValue = (index: number, value: string | number) => {
    const newKeybinds = this.props.globalHotKeyConfiguration.slice();
    newKeybinds[index].value = value;
    this.props.setGlobalHotKeys(newKeybinds);
  };

  updateKeybindErrors = (binds: KeybindConfiguration[]) => {
    // We should really do a non O(n^2) algorithm, but the number
    // of keybinds is likely to be small enough that performance on this
    // is really a non-issue
    binds.forEach((bind: KeybindConfiguration, index: number) => {
      const compare = acceleratorToComparison(bind.accelerator);
      const duplicate = binds.find((b: KeybindConfiguration, i: number) => {
        if (i === index) {
          return false;
        }
        return acceleratorToComparison(b.accelerator) === compare;
      });
      bind.error = !!duplicate;
    });
  };

  deleteKeybind = (index: number) => {
    const newKeybinds = this.props.globalHotKeyConfiguration.slice();
    newKeybinds.splice(index, 1);
    this.updateKeybindErrors(newKeybinds);
    this.props.setGlobalHotKeys(newKeybinds);
  };
}

function acceleratorToComparison(accelerator: string): string {
  return (
    accelerator &&
    accelerator
      .split('+')
      .sort()
      .join('|')
      .toLowerCase()
  );
}

function mapContextToProps(c: SettingsContext): PropsFromSettings {
  return {
    bindMediaHotkeys: c[SettingsKeys.BindMediaHotkeys],
    setBindMediaHotkeys: c.setBindMediaHotkeys,
    globalHotKeyConfiguration: c[SettingsKeys.GlobalHotKeyConfiguration] || [],
    setGlobalHotKeys: c.setGlobalHotKeys,
  };
}
function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    features: c.features,
    platformDetails: c.platformDetails,
  };
}

export const GlobalWindow = compose(
  withSettingsContext(mapContextToProps),
  withPlatformContext<PropsFromPlatform>(mapPlatformToProps)
)(GlobalWindowPresentation);
