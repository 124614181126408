import * as React from 'react';

const SvgVolume3 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 21H14C13.1716 21 12.5 21.6716 12.5 22.5V25.5C12.5 26.3284 13.1716 27 14 27H17.5L23.647 33.146C23.79 33.2885 24.0046 33.3311 24.1912 33.2539C24.3777 33.1767 24.4996 32.9949 24.5 32.793V15.207C24.4996 15.0051 24.3777 14.8233 24.1912 14.7461C24.0046 14.6689 23.79 14.7115 23.647 14.854L17.5 21Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M32 16C34.2312 18.0619 35.4999 20.9619 35.4999 24C35.4999 27.0381 34.2312 29.9381 32 32" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M29.5 18C31.3755 19.4248 32.4769 21.6447 32.4769 24C32.4769 26.3553 31.3755 28.5752 29.5 30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M27.5 20.5C30.177 22.447 30.169 25.56 27.5 27.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgVolume3;
