import * as React from "react";

function SvgDuration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16c-.036 8.822-7.178 15.964-15.997 16H16zm0-29.152C8.736 2.848 2.848 8.736 2.848 16S8.736 29.152 16 29.152c7.264 0 13.152-5.888 13.152-13.152-.018-7.256-5.896-13.134-13.15-13.152H16zm7.808 12.768l-.672-2.848-5.696 1.312V7.232h-2.88v10.496z" />
    </svg>
  );
}

export default SvgDuration;
