import * as React from 'react';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { HomeDataQuery } from '../Home/types/HomeDataQuery';
import { Heading } from '../Heading';
import { StatisticsContainer, StatisticsContent, StatisticsGrid } from './styled';
import moment from 'moment';

const homeDataQuery = loader('../Home/homedata.graphql');

interface GraphqlProps {
  data: QueryControls & HomeDataQuery;
}

type Props = GraphqlProps;

class StatsPresentation extends React.Component<Props, {}> {
  public render() {
    if (!this.props.data.homeData) {
      return null;
    }
    return (
      <StatisticsContainer>
        <StatisticsContent>
          <div>
            {renderStyledText('Pretzel Catalog')}
            <span>Proof is in the stats: let's check some out.</span>
          </div>

          <StatisticsGrid>
            <div>
              <strong>{this.props.data.homeData.catalogStats?.twitchCount.toLocaleString()}</strong>
              <span>Twitch-Safe Tracks</span>
            </div>
            <div>
              <strong>{this.props.data.homeData.catalogStats?.youtubeCount.toLocaleString()}</strong>
              <span>YouTube-Safe Tracks</span>
            </div>
            <div>
              <strong>{secondsToDisplay(this.props.data.homeData.catalogStats?.twitchDuration).toLocaleString()}</strong>
              <span>Twitch Hours of music</span>
            </div>
            <div>
              <strong>{secondsToDisplay(this.props.data.homeData.catalogStats?.youtubeDuration).toLocaleString()}</strong>
              <span>YouTube Hours of music</span>
            </div>
            <div>
              <strong>
                {(
                  secondsToDisplay(this.props.data.homeData.catalogStats?.twitchDuration) +
                  secondsToDisplay(this.props.data.homeData.catalogStats?.youtubeDuration)
                ).toLocaleString()}
              </strong>
              <span>Total Hours of music</span>
            </div>
          </StatisticsGrid>
        </StatisticsContent>
      </StatisticsContainer>
    );
  }
}

function renderStyledText(text: string) {
  const filteredTitle = text.replace(/[\W]*\S+[\W]*$/, '');
  const lastWordOfTitle = text.split(' ').pop();

  return (
    <Heading variant="H2">
      {filteredTitle} <strong>{lastWordOfTitle}</strong>
    </Heading>
  );
}

function secondsToDisplay(seconds: number): number {
  const hours = moment.duration(seconds, 'seconds').as('hours');
  return Math.round(hours);
}

export const StatsPage = compose(graphql(homeDataQuery))(StatsPresentation);
