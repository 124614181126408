import styled from 'styled-components';
import { theme } from '../../Styled/theme';

export const tableStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  backgroundColor: theme.colors.bodyBg,
};

export const StyledPlaylistContainer = styled.div`
  position: relative;
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.space.lg};
  margin-top: ${theme.space.lg};
`;

export const StyledTable = styled.table`
  .track-list-row {
    border-bottom: 0.063rem solid rgba(255, 255, 255, 0.1);
    background-color: ${theme.colors.bodyBg} !important;
  }

  .mobile-track-row {
    align-items: start;
  }

  .track-list-row .download-button:hover {
    svg {
      fill: transparent !important;

      path {
        stroke: ${theme.colors.brandGreen} !important;
      }
    }
  }

  .track-list-row .remove-track-button:hover {
    svg {
      fill: transparent !important;

      path {
        stroke: ${theme.colors.brandGreen} !important;
      }
    }
  }

  .highlighted-track-row {
    border: 0.125rem solid ${theme.colors.brand};
  }

  .forbidden-row .track-row-info {
    font-style: italic;
  }

  .tablet-track-row-info {
  }

  .mobile-track-row-info {
    margin-left: 1rem;
    width: 100%;
    padding-right: 1rem;
    padding-top: 0.1rem;
  }

  .forbidden-row {
    color: ${theme.colors.dark5};

    svg {
      color: ${theme.colors.dark5} !important;

      path[stroke] {
        stroke: ${theme.colors.dark5} !important;
      }

      path[fill] {
        fill: ${theme.colors.dark5} !important;
      }
    }
  }

  .forbidden-row:hover {
    color: ${theme.colors.dark5} !important;
  }

  .forbidden-row .share-track-button {
    svg {
      fill: ${theme.colors.dark5} !important;
    }
  }

  .forbidden-row .share-track-button:hover {
    svg {
      fill: ${theme.colors.brandGreen} !important;
    }
  }

  .forbidden-row {
    .explicit-icon {
      rect[fill] {
        fill: ${theme.colors.dark5} !important;
      }

      path[fill] {
        fill: ${theme.colors.bgMain} !important;
      }
    }
  }

  .forbidden-row .not-explicit {
    svg {
      path {
        fill: ${theme.colors.bgMain} !important;
      }
    }
  }

  .track-list-row:hover {
    color: ${theme.colors.brandGreen};
  }

  .not-explicit {
    svg {
      rect {
        fill: ${theme.colors.dark5};
      }
    }
  }

  .not-instrumental {
    svg {
      path {
        stroke: ${theme.colors.dark5} !important;
      }
    }
  }

  .desktop-track-playing-button {
    margin-left: 0.625rem;
  }

  .tablet-track-playing-button {
    margin-left: -1.3rem;
  }

  .mobile-track-playing-button {
    margin-left: -1.3rem;
  }

  .control-button {
    color: ${theme.colors.baseWhite};

    svg:hover {
      stroke: ${theme.colors.brandGreen};
    }
  }

  .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    :focus-visible {
      outline: rgba(59, 153, 252, 0.7) auto 5px;
      outline-color: rgba(59, 153, 252, 0.7);
      outline-style: auto;
      outline-width: 0.313rem;
    }
  }

  .forbidden-icon {
    margin-bottom: 0.438rem;
    margin-left: 0.313rem;
    color: ${theme.colors.brandGreen} !important;
  }

  .desktop-track-play-button {
    margin-left: -0.625rem;
  }

  .tablet-track-play-button {
    margin-left: -1.3rem;
  }

  .mobile-track-play-button {
    margin-left: -1.3rem;
  }

  .mobile-more {
    width: 100%;
    position: relative;

    > svg {
      margin-right: 0.25rem;
    }
  }

  .popover-visible {
    height: 120px;
  }

  .download-disabled {
    cursor: not-allowed;

    svg {
      path {
        stroke: ${theme.colors.dark5};
      }
    }
  }
`;

export const ForbiddenIcon = styled.i`
  color: ${theme.colors.bgSectionsAlt};
  font-size: 1.25rem;
  height: 20px;
  cursor: default;
`;
