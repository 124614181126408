import * as React from "react";

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 18 32" {...props}>
      <path d="M17.12.224v4.704h-2.784q-1.536 0-2.08.64t-.544 1.92v3.392h5.248l-.704 5.28h-4.544v13.568H6.24V16.16H1.696v-5.28H6.24V6.976q0-3.328 1.856-5.152T13.056 0q2.624 0 4.064.224z" />
    </svg>
  );
}

export default SvgFacebook;
