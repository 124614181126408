import playerContext, { PlayerContext } from '@pretzel-aux/common/src/Core/Player/PlayerContext';
import { ActiveBar } from '@pretzel-aux/common/src/Core/Player/Components/ActiveBar';
import React, { useContext } from 'react';
import { StyledCompactActiveBar } from './styled';

export const CompactActiveBar = () => {
  const { activeSegment } = useContext<PlayerContext>(playerContext);

  return (
    <StyledCompactActiveBar>
      <ActiveBar segment={activeSegment} />
    </StyledCompactActiveBar>
  );
};
