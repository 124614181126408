import React, { Fragment, useEffect } from 'react';
import loadingSpinner from '@pretzel-aux/assets/images/loading.gif';
import { URLS } from '@pretzel-aux/common/src/Core/Platform';
import {
  StyledFooterText,
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledLoader,
  StyledOption,
  StyledSearchButton,
  StyledSearchResults,
  StyledText,
  StyledValidation,
  TextInputFix,
  TickIcon,
} from './styled';
import { IContent, IOption, ISelectOption, IYouTubeChannelSelectModalProps, IYouTubeChannelSelectModalState, Step } from './types';
import { ModalButtons } from '../Modal/types';

interface IValidatedProps {
  isValid: boolean;
  youtubeChannelEntry: string;
}

const Validated = ({ isValid, youtubeChannelEntry }: IValidatedProps) => {
  return (
    <StyledValidation style={{ opacity: youtubeChannelEntry ? 1 : 0 }}>
      <div>
        {isValid ? (
          <Fragment>
            <TickIcon />
            <p>Validated</p>
          </Fragment>
        ) : (
          <Fragment>
            <p>
              <span>X</span>Not valid
            </p>
          </Fragment>
        )}
      </div>
    </StyledValidation>
  );
};

interface IStepsProps {
  state: IYouTubeChannelSelectModalState;
  setState: (state: IYouTubeChannelSelectModalState) => void;
  modal: IYouTubeChannelSelectModalProps;
  onSubmit: () => Promise<void>;
  selectedChannelOption: IOption;
  setSelectedChannelOption: (option: IOption) => void;
  youtubeChannelEntry: string;
  setYoutubeChannelEntry: (entry: string) => void;
  channelValidity: boolean;
  setChannelValidity: (validity: boolean) => void;
  deleteOption: () => void;
  onSearch: () => Promise<ISelectOption[]>;
}

const Steps = ({
  state,
  setState,
  modal,
  onSubmit,
  selectedChannelOption,
  setSelectedChannelOption,
  youtubeChannelEntry,
  setYoutubeChannelEntry,
  channelValidity,
  setChannelValidity,
  deleteOption,
  onSearch,
}: IStepsProps) => {
  const { setOpen, onClose } = modal;
  let selectedContent: IContent;

  useEffect(() => {
    if (state.selectedOption) {
      setYoutubeChannelEntry(state.selectedOption.channelName);
      setSelectedChannelOption(state.selectedOption);
      // Clear the options once a channel is selected
      setState({
        ...state,
        options: [],
      });
    }
  }, [state.selectedOption]);

  const validateYoutubeChannel = (channelId: string) => {
    if (isYouTubeChannelId(channelId)) {
      return true;
    }
    return getChannelIdFromYouTubeChannelUrl(channelId);
  };

  const isYouTubeChannelId = (channelId: string) => {
    return channelId.length == 24 && channelId.startsWith('UC');
  };

  const getChannelIdFromYouTubeChannelUrl = (channel: string) => {
    try {
      new URL(channel);
      const splitChannel = channel.split('/');

      if (!channel.includes('youtube.com') || splitChannel[3] != 'channel') {
        return false;
      }
      const channelId = splitChannel[splitChannel.length - 1];
      return isYouTubeChannelId(channelId);
    } catch (e) {
      return false;
    }
  };

  const OutlineBtn = (func?: () => void): ModalButtons => {
    return {
      color: 'outline',
      label: 'Close',
      onClick: () => {
        setOpen(false);
        if (func) func();
      },
    };
  };

  if (state.step === Step.Complete) {
    selectedContent = {
      buttons: [OutlineBtn(() => onClose && onClose(state.selectedOption))],
      content: <StyledText>Thank you, the YouTube channel "{state.selectedOption.channelName}" has been linked to your account.</StyledText>,
      title: "You're all set!",
    };
  } else if (state.step === Step.YoutubeServerError) {
    selectedContent = {
      buttons: [OutlineBtn()],
      content: <StyledText>Something went wrong looking up YouTubes API - Please try again tomorrow</StyledText>,
      title: 'Oops! Something went wrong.',
    };
  } else if (state.step === Step.Error) {
    selectedContent = {
      buttons: [OutlineBtn()],
      content: (
        <StyledText>
          Please{' '}
          <a href={URLS.HELP} target="_blank" rel="noopener noreferrer">
            Contact support
          </a>
          .
        </StyledText>
      ),
      title: 'Oops! Something went wrong.',
    };
  } else if (state.step === Step.Search) {
    selectedContent = {
      buttons: [
        OutlineBtn(),
        {
          color: 'secondary',
          disabled: !selectedChannelOption,
          label: "Let's go!",
          onClick: onSubmit,
        },
      ],
      content: (
        <TextInputFix>
          <StyledText>Why do we need this? Simple. We need to clear your channel so you can use our awesome music.</StyledText>
          <StyledLabel htmlFor="yt-select">YouTube channel</StyledLabel>
          <StyledInputWrapper>
            <StyledInput
              placeholder="Enter your YouTube channel ID or URL"
              value={youtubeChannelEntry || ''}
              onChange={evt => {
                setYoutubeChannelEntry(evt.target.value);
                setChannelValidity(validateYoutubeChannel(evt.target.value));
              }}
              options={state.options}
            />
            <StyledSearchButton
              onClick={async () => {
                selectedChannelOption ? deleteOption() : onSearch();
              }}
            >
              {selectedChannelOption ? 'Delete' : 'Search'}
            </StyledSearchButton>

            <Validated isValid={channelValidity} youtubeChannelEntry={youtubeChannelEntry} />

            <StyledText>
              <a href="https://support.google.com/youtube/answer/3250431" target="_blank" rel="noopener noreferrer">
                Need help finding your YouTube channel ID?
              </a>
            </StyledText>

            {state.options && (
              <StyledSearchResults
                style={{
                  display: state.options.length > 0 ? 'block' : 'none',
                }}
              >
                {state.options.map(option => {
                  return (
                    <StyledOption
                      key={option.channelId}
                      onClick={() => {
                        setState({
                          ...state,
                          selectedOption: {
                            channelId: option.channelId,
                            channelImageUrl: option.channelImageUrl,
                            channelName: option.channelName,
                          },
                        });
                      }}
                    >
                      <img alt={`${option.channelName}-channel-logo`} src={option.channelImageUrl} />
                      {option.channelName}
                    </StyledOption>
                  );
                })}
              </StyledSearchResults>
            )}
          </StyledInputWrapper>
        </TextInputFix>
      ),
      footerContent: (
        <StyledFooterText>
          Don't have a YouTube channel?{' '}
          <a href={URLS.HELP} target="_blank" rel="noopener noreferrer">
            Contact support
          </a>
          .
        </StyledFooterText>
      ),
      title: 'Time to add your YouTube channel!',
    };
  } else {
    selectedContent = {
      content: <StyledLoader src={loadingSpinner} alt="loading" height={160} width={160} />,
    };
  }

  return selectedContent;
};

export default Steps;
