import * as React from "react";

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M24.64 12.064L19.936 7.36c-.192-.224-.64-.224-.96 0l-2.112 2.144-7.904 7.872c-.096.128-.096.224-.096.32L7.264 24c-.128.224 0 .416.192.64.128.096.32.224.448.224h.192l6.304-1.6c.096 0 .224-.128.32-.128L24.736 13.12a.676.676 0 00.224-.416c-.096-.32-.224-.544-.32-.64zm-10.464 9.472l-3.712-3.712 6.912-6.944 3.744 3.744-6.944 6.912zm-4.352-2.432L12.8 22.08l-4.16 1.056 1.184-4.032zm12.256-5.44L18.336 9.92l1.184-1.184 3.744 3.744-1.184 1.184z" />
    </svg>
  );
}

export default SvgEdit;
