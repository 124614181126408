import styled from 'styled-components';
import { theme } from '../../Styled';

export const StyledTrendingTracksContainer = styled.section`
  align-items: center;
  display: grid;
  grid-gap: ${theme.space.lg};
  grid-template-columns: 1fr;
  margin-bottom: 4rem;
  margin-top: 2.5rem;

  ${theme.mediaQueries.extraLarge} {
    grid-template-columns: 320px 1fr;
  }
`;

export const StyledTrendingTracksContent = styled.div`
  h2 {
    margin-bottom: ${theme.space.md};
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const StyledTrendingTracks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  ${theme.mediaQueries.large} {
    margin-left: 8rem;

    > div:nth-child(3),
    > div:nth-child(4) {
      transform: translateX(-4rem);
    }

    > div:nth-child(5),
    > div:nth-child(6) {
      transform: translateX(-2rem);
    }
  }
`;
