import { Component } from 'react';
import * as React from 'react';
import moment from 'moment';
import {
  BasePlatformProvider,
  PlatformContext,
  PretzelUser,
  parsePretzelJWT,
  osFromNavigator,
} from '@pretzel-aux/common/src/Core/Platform';
import { getCookieItem, deleteCookieItem } from '@pretzel-aux/common/src/Util/useCookie';
import qs from 'querystring';
import { IpcRequest, RemoveGlobalShortcutParams } from '@pretzel-aux/common/src/Core/IPC/IPCRequest';
import { IpcService } from '@pretzel-aux/common/src/Core/IPC/IPCService';
import {
  GLOBAL_SHORTCUT_EXECUTED,
  GLOBAL_SHORTCUT_REGISTRATION,
  GLOBAL_SHORTCUT_REMOVAL,
  GlobalShortcutPayload,
  GlobalShortcutParams,
} from '@pretzel-aux/common/src/Core/IPC/IPCRequest';
import { UserAgreementStorageKeys } from '../types';
import { setUserAgreementPreferences, addUserToHubSpotMarketing } from '@pretzel-aux/common/src/Util/userPreferences';

export interface PretzelRocksContext {
  auxToken?: string;
}

interface PublicProps {
  unloadApp: () => void;
}

const url = process.env.REACT_APP_API_URL || 'https://api.pretzel.rocks';

const version = 1;
const IS_ELECTRON = /electron/i.test(navigator.userAgent);
const jwtRefreshThresholdDays = 7;

export class PretzelRocksProvider extends Component<PublicProps, PlatformContext & PretzelRocksContext> {
  constructor(props: PublicProps) {
    super(props);
    this.state = {
      loaded: false,
      showingAuth: false,
      platformDetails: {
        description: `play-${IS_ELECTRON ? 'desktop' : 'web'}-${osFromNavigator()}`,
        os: osFromNavigator(),
      },
      features: {
        compactMode: false,
        songRequests: false,
        customKeybinds: IS_ELECTRON,
        systemKeybinds: IS_ELECTRON,
        writeToFile: IS_ELECTRON,
        showDesktopDownloads: !IS_ELECTRON,
      },
      loadData: (key: string) => this.loadData(key),
      saveData: (key: string, value: any) => this.saveData(key, value),
      notify: (message: string) => this.notify(message),
      openExternalLink: (link: string) => this.openExternalLink(link),
      setPretzelUser: (pretzelUser: PretzelUser) => this.setPretzelUser(pretzelUser),
      getPlatformAuthorizationToken: () => this.getPlatformAuthorizationToken(),
      registerHotkey: (key: string, callback: () => void, local?: boolean) => this.registerHotkey(key, callback, local),
      unregisterHotkey: (key: string) => this.unregisterHotkey(key),
      unloadApp: props.unloadApp,
      isUpgradeToPremiumModalOpen: false,
      setIsUpgradeToPremiumModalOpen: this.setIsUpgradeToPremiumModalOpen,

      sendIPC: this.sendIPC,
      showAuthorizationWindow: (
        url: string,
        options: { width: number; height: number },
        eventHandler: (event: AuthorizationWindowEvent) => void,
      ) => this.showAuthorizationWindow(url, options, eventHandler),
    };
  }
  keys: Record<string, (e: KeyboardEvent | GlobalShortcutPayload) => void> = {};
  ipcService = new IpcService();

  setIsUpgradeToPremiumModalOpen = (isOpen: boolean) => {
    this.setState({
      ...this.state,
      isUpgradeToPremiumModalOpen: isOpen,
    });
  };

  loadData = async (key: string): Promise<string> => {
    return window.localStorage.getItem(key) || '';
  };

  saveData = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
  };

  sendIPC = <T extends unknown>(channel: string, message: IpcRequest) => {
    if (IS_ELECTRON) {
      console.log(`Sending IPC on ${channel}`, message);
      return this.ipcService.send<T>(channel, message);
    } else {
      console.log(`Ignoring IPC for`, channel);
      // This allows the web version to behave the same way with IPC as Slobs does
      return Promise.resolve({} as T);
    }
  };

  notify = (message: string) => {
    // if (this.state.api) {
    //   // @ts-ignore
    //   this.state.api.Notifications.push({
    //     message,
    //   });
    // }
  };

  openExternalLink = (link: string) => {
    window.open(link, '_blank');
    return true;
  };

  getPlatformAuthorizationToken = (): string => {
    return '';
  };

  registerHotkey = (key: string, callback: () => void, local?: boolean) => {
    if (local) {
      this.keys[key] = callback;
    } else {
      const action = `global-${key}`;
      const params: GlobalShortcutParams = {
        type: GLOBAL_SHORTCUT_REGISTRATION,
        accelerator: key,
        payload: { action },
      };
      this.keys[action] = callback;
      this.sendIPC<string>(GLOBAL_SHORTCUT_REGISTRATION, { params });
    }
  };

  unregisterHotkey = (key: string) => {
    delete this.keys[key];
    const params: RemoveGlobalShortcutParams = {
      type: GLOBAL_SHORTCUT_REMOVAL,
      accelerator: key,
    };
    this.sendIPC<string>(GLOBAL_SHORTCUT_REMOVAL, { params });
  };

  handleDocumentKey = (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.target && event.target['type'] === 'text') {
      // Ignoring keys when event.target is an input
      return;
    }
    console.log('Handle Document Key', event);
    if (this.keys[event.key]) {
      this.keys[event.key](event);
    }
  };

  handleGlobalKey = (_event: any, payload: GlobalShortcutPayload) => {
    console.log('Received Global Key', payload);
    if (this.keys[payload.action]) {
      this.keys[payload.action](payload);
    }
  };

  showAuthorizationWindow = (
    url: string,
    options: { width: number; height: number },
    eventHandler: (event: AuthorizationWindowEvent) => void,
  ) => {
    // if (this.state.api) {
    //   return this.state.api.Authorization.showAuthorizationWindow(url, options, eventHandler);
    // }
  };

  setPretzelUserDetails = async (jwt: string) => {
    const response = await fetch(`${url}/api/v${version}/account`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const pretzelUserDetails = await response.json();
    this.setState({ ...this.state, pretzelUserDetails });
  };

  setPretzelUser = (pretzelUser: PretzelUser) => {
    this.setPretzelUserDetails(pretzelUser.pretzelJWT);

    this.setState({ pretzelUser });
    window.localStorage.setItem('pretzel_app_token', pretzelUser.pretzelJWT);
    // @ts-ignore
    window.ga('set', 'userId', pretzelUser.id);
  };

  public async componentDidMount() {
    const hash = qs.parse(window.location.hash.substring(1));
    if (hash && hash.token) {
      // @ts-ignore
      const pretzelUser = parsePretzelJWT(hash.token);
      this.setPretzelUser(pretzelUser);
    }

    document.addEventListener('keyup', this.handleDocumentKey);
    if (IS_ELECTRON) {
      // @ts-ignore
      this.ipcService.on(GLOBAL_SHORTCUT_EXECUTED, this.handleGlobalKey);
    }

    try {
      const token = window.localStorage.getItem('pretzel_app_token');
      if (token) {
        let pretzelUser;
        try {
          pretzelUser = parsePretzelJWT(token);
        } catch (e) {
          console.log('Looks like someone tried messing with their token.... Knaughty, knaughty!');
          e.status = 401;
          throw e;
        }
        const { exp, guid, hash } = pretzelUser.jwt;
        const expMoment = moment.unix(exp);
        const now = moment();

        const hasAgreedToLicense = getCookieItem(UserAgreementStorageKeys.USER_LICENSE_AGREEMENT_TIME) === 'true';
        const hasOptedOutOfMarketing = getCookieItem(UserAgreementStorageKeys.OPTED_OUT_OF_MARKETING) === 'true';

        if (hasAgreedToLicense) {
          await setUserAgreementPreferences(hasAgreedToLicense, hasOptedOutOfMarketing);

          if (!hasOptedOutOfMarketing) {
            await addUserToHubSpotMarketing();
          }
        }

        deleteCookieItem(UserAgreementStorageKeys.USER_LICENSE_AGREEMENT_TIME);
        deleteCookieItem(UserAgreementStorageKeys.OPTED_OUT_OF_MARKETING);

        if (expMoment.diff(now, 'days') > jwtRefreshThresholdDays && guid && hash) {
          this.setPretzelUser(pretzelUser);
          return { authToken: token, newToken: false };
        } else {
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await fetch(`${url}/api/v${version}/refresh`, options);
          if (!response.ok) {
            const error = Error(response.statusText);
            //error.status = response.status;
            throw error;
          }
          try {
            const { token } = await response.json();
            try {
              pretzelUser = parsePretzelJWT(token);
            } catch (e) {
              console.log('Looks like someone tried messing with their token.... Knaughty, knaughty!');
              e.status = 401;
              throw e;
            }
            this.setPretzelUser(pretzelUser);
            return { authToken: token, newToken: true };
          } catch (e) {
            throw Error(e);
          }
        }
      } else {
        return { authToken: null, newToken: false };
      }
    } catch (e) {
      return { authToken: null, newToken: false, error: e };
    }
  }

  render() {
    return <BasePlatformProvider value={this.state}>{this.props.children}</BasePlatformProvider>;
  }
}
