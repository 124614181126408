import React from 'react';
import keycodes from 'keycode';

import KeybindComponent from './Keybind';
import { KeyAction, KeybindConfiguration, Option } from '../KeyAction';

interface Accelerator {
  code: string;
  modifier: boolean;
}

interface Props {
  index: number;
  keybindConfig: KeybindConfiguration;
  actions: KeyAction[];
  updateAction: any;
  updateValue: any;
  updateAccelerator: any;
  deleteKeybind: any;
  macOS: boolean;
}

interface State {
  key: string;
  keyString: string;
  modifiers: string[];
  capturing: boolean;
}

class Keybind extends React.Component<Props, State> {
  state = {
    key: '',
    keyString: '',
    modifiers: [] as string[],
    capturing: false,
  };

  deleteKeybind = () => {
    this.props.deleteKeybind(this.props.index);
  };

  updateAction = (action: Option) => {
    this.props.updateAction(this.props.index, action.value);
  };

  updateValue = (value: string | number) => {
    this.props.updateValue(this.props.index, value);
  };

  updateAccelerator = (accelerator: string) => {
    this.props.updateAccelerator(this.props.index, accelerator);
  };

  activateKeyCapture = () => {
    this.setState({ capturing: true });
    window.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('keydown', this.onKeyDown);
  };

  deactivateKeyCapture = () => {
    this.setState({ capturing: false });
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('keydown', this.onKeyDown);
  };

  onKeyUp = (event: KeyboardEvent) => {
    const code = this.translateToAccelerator(event);
    if (!code || !code.code) {
      return;
    }
    event.preventDefault();
    const modifiers = this.state.modifiers.slice();
    let key = this.state.key;
    if (code.modifier) {
      modifiers.splice(modifiers.indexOf(code.code), 1);
    } else {
      key = '';
    }
    this.setState({ modifiers, key });
  };

  onKeyDown = (event: KeyboardEvent) => {
    if (event.repeat) {
      return;
    }
    event.preventDefault();
    const modifiers = this.state.modifiers.slice();
    let key = this.state.key;
    const code = this.translateToAccelerator(event);
    if (!code || !code.code) {
      return;
    }
    if (code.modifier) {
      if (modifiers.indexOf(code.code) === -1) {
        modifiers.push(code.code);
      }
    } else {
      key = code.code;
    }
    let keyString = this.state.keyString;
    if (key) {
      if (modifiers.length > 0) {
        keyString = `${modifiers.join('+')}+${key}`;
      } else {
        keyString = key;
      }
    }
    if (keyString.toLowerCase() === 'esc') {
      keyString = '';
      modifiers.length = 0;
      // @ts-ignore
      event.target.blur();
    }
    this.setState({ modifiers, key, keyString });
    this.updateAccelerator(this.state.keyString);
  };

  translateToAccelerator = (event: KeyboardEvent): Accelerator => {
    const key = keycodes(event) && keycodes(event).replace('numpad', '');
    if (!key) {
      return undefined;
    }
    if (key.toLowerCase().indexOf('lock') !== -1 || key.toLowerCase().indexOf('break') !== -1) {
      return undefined;
    }
    const codePre = key
      .toLowerCase()
      .replace('right ', '')
      .replace('left ', '')
      .trim();
    let code;
    let modifier = false;
    switch (codePre) {
      case 'shift':
        code = 'Shift';
        modifier = true;
        break;
      case 'alt':
        code = 'Alt';
        modifier = true;
        break;
      case 'ctrl':
        code = 'Ctrl';
        modifier = true;
        break;
      case 'command':
        code = this.props.macOS ? 'Command' : 'Super';
        modifier = true;
        break;
      case '+':
        code = 'Plus';
        break;
      case 'up':
        code = 'Up';
        break;
      case 'down':
        code = 'Down';
        break;
      case 'left':
        code = 'Left';
        break;
      case 'right':
        code = 'Right';
        break;
      case 'page up':
        code = 'PageUp';
        break;
      case 'page down':
        code = 'PageDown';
        break;
      case 'home':
        code = 'Home';
        break;
      case 'end':
        code = 'End';
        break;
      case 'enter':
        code = 'Enter';
        break;
      case 'insert':
        code = 'Insert';
        break;
      case 'delete':
        code = 'Delete';
        break;
      case 'backspace':
        code = 'Backspace';
        break;
      case 'tab':
        code = 'Tab';
        break;
      case 'space':
        code = 'Space';
        break;
      default:
        code = key.toUpperCase().trim();
        break;
    }
    return { code, modifier };
  };

  formatKey = (keyname: string) => {
    let key;
    switch (keyname.toLowerCase()) {
      case 'shift':
        key = this.props.macOS ? '⇧' : 'SHIFT';
        break;
      case 'command':
        key = '⌘';
        break;
      case 'alt':
        key = this.props.macOS ? '⌥' : 'ALT';
        break;
      case 'escape':
        key = this.props.macOS ? '⎋' : 'ESC';
        break;
      case 'plus':
        key = '+';
        break;
      case 'super':
        key = 'META';
        break;
      default:
        key = keyname.toUpperCase();
        break;
    }
    return key;
  };

  render() {
    const { keybindConfig, actions } = this.props;
    const { accelerator, error, selectedAction, value } = keybindConfig;
    let keybindString = '';
    let valueText = 'Value';
    if (accelerator) {
      const keys = accelerator.split('+');
      const formatedKeys = keys.map(key => this.formatKey(key));
      keybindString = formatedKeys.join(' + ');
    }
    const actionData = actions.filter(actionObj => actionObj.value === selectedAction)[0];
    let actionValue = Object.assign({}, actionData);
    let actionNote;
    if (actionData && actionData.isValue) {
      if (actionValue.valueType === 'dropdown') {
        const option = actionData.dropdown.find((o: Option) => o.value === value);
        actionNote = option && option.note;
      } else if (actionValue.valueType === 'audio') {
        valueText = 'Output device';
      }
    }
    return (
      <KeybindComponent
        action={selectedAction}
        note={actionNote}
        value={value}
        valueText={valueText}
        bind={keybindString}
        actions={actions}
        actionValue={actionValue}
        deleteKeybind={this.deleteKeybind}
        updateAction={this.updateAction}
        updateValue={this.updateValue}
        activateKeyCapture={this.activateKeyCapture}
        deactivateKeyCapture={this.deactivateKeyCapture}
        error={error}
        capturing={this.state.capturing}
      />
    );
  }
}

export default Keybind;
