import * as React from 'react';

function SvgNext(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4801 24.4969H30.7201" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.9602 30.4969L30.7202 24.4969L24.9602 18.4969" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34.5604 18.4969V30.4969" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SvgNext;
