import React, { FC } from 'react';
import { StyledTrendingTracks, StyledTrendingTracksContainer, StyledTrendingTracksContent } from './styled';
import { TrendingTracksProps } from './types';
import { TrendingTrackCard } from './Components/TrendingTrackCard';
import RenderHighlightedHeading from '../../Util/RenderHighlightedHeading';
import { Button } from '../Button';
import { useHistory } from 'react-router-dom';

export const TrendingTracks: FC<TrendingTracksProps> = ({ tracks, children, handleNonPremiumClick }) => {
  const history = useHistory();

  return (
    <StyledTrendingTracksContainer>
      <StyledTrendingTracksContent>
        <RenderHighlightedHeading text={'Trending tracks'} />
        <p>Need some inspiration? Take a scroll through our top user picks of the week</p>
        <Button color="secondary" onClick={() => history.push('/segment/2ahnM')}>
          Explore tracks
        </Button>
      </StyledTrendingTracksContent>

      <StyledTrendingTracks>
        {tracks?.map(track => (
          <TrendingTrackCard key={track.id} track={track} handleNonPremiumClick={handleNonPremiumClick} />
        ))}
      </StyledTrendingTracks>
    </StyledTrendingTracksContainer>
  );
};
