import { Box, Image } from 'grommet';
import React, { useContext } from 'react';
import { AlbumsListQuery_albums_edges_node } from '../types/AlbumsListQuery';
import { Heading } from '../../Heading';
import playerContext, { PlayerContext } from '../../../Core/Player/PlayerContext';
import { CardType, MusicCard, theme } from '../../../Styled';
import { ArtworkSize, minutesOrHours, toUrl } from '../../../Util';

type Props = {
  album: AlbumsListQuery_albums_edges_node;
  size?: string;
};

export const AlbumCard = ({ album, size }: Props): JSX.Element => {
  const playerContextObj = useContext<PlayerContext>(playerContext);
  const isPlaying = playerContextObj.activeSegment && playerContextObj.activeSegment.id === album.id;

  const handleClick = (): void => {
    if (playerContextObj.playing && playerContextObj.activeSegment.id === album.id) {
      playerContextObj.pause();
    } else {
      playerContextObj.playSegment({ id: album.id });
    }
  };

  const art = <Image src={toUrl(album.artworkGuid, ArtworkSize.Medium)} width="100%" />;

  const text = (
    <Box margin={`${theme.space.md} 0 0 0`}>
      <Heading variant="H4">{album.name}</Heading>
    </Box>
  );

  return (
    <MusicCard
      active={isPlaying}
      art={art}
      isPlaying={playerContextObj.playing}
      linkTo={`/segment/${album.id}`}
      onClick={handleClick}
      text={text}
      meta={{
        hours: minutesOrHours(album.stats.duration),
        tracks: album.stats.totalCount,
      }}
      cardType={CardType.Album}
      cardName={album.name}
      size={size}
    />
  );
};
