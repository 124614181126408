import React from 'react';
import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

interface ISearchAnimationProps {
  openSearchDropdown: boolean;
  children: React.ReactElement;
}

const SearchAnimationContainer = styled.div<ISearchAnimationProps>`
  position: absolute;
  left: 0;
  top: 4rem;
  background-color: ${theme.colors.bg};
  width: 232px;
  height: 72px;
  z-index: ${({ openSearchDropdown }) => (openSearchDropdown ? 1 : -1)};
  padding: 1rem;
  opacity: ${({ openSearchDropdown }) => (openSearchDropdown ? 1 : 0)};
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  transition: opacity 300ms ease-in-out;

  input {
    width: 100% !important;
  }
`;

const SearchAnimation = ({ openSearchDropdown, children }: ISearchAnimationProps) => {
  return <SearchAnimationContainer openSearchDropdown={openSearchDropdown}>{children}</SearchAnimationContainer>;
};

export default SearchAnimation;
