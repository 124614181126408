import * as React from "react";

function SvgHouseChimney1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <defs>
        <style>
          {
            ".house-chimney-1_svg__a{fill:none;stroke:#000;strokeLinecap:round;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <path
        className="house-chimney-1_svg__a"
        d="M10 15.5h4a1 1 0 011 1v7H9v-7a1 1 0 011-1z"
      />
      <path
        className="house-chimney-1_svg__a"
        d="M19.5 8.4V3.5A.5.5 0 0019 3h-2a.5.5 0 00-.5.5v2.14l-4.162-3.829a.5.5 0 00-.678 0l-11 10.321A.5.5 0 001 13h2.5v9.5a1 1 0 001 1h15a1 1 0 001-1V13H23a.5.5 0 00.339-.868z"
      />
    </svg>
  );
}

export default SvgHouseChimney1;
