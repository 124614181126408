import * as React from "react";

function SvgBrandTextHoriz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 112 32" {...props}>
      <path d="M32.736 22.848V10.272c0-.48.384-.896.864-.896h5.056c6.464.032 6.464 10.048 0 10.048H36.16v3.424h-3.424zm5.888-10.4H36.16v3.872h2.464c1.888 0 2.016-3.84 0-3.872zm17.536 9.92v.48h-3.872l-2.304-3.968h-1.792v3.968h-3.424V10.272c0-.48.384-.896.864-.896h5.056c5.28.032 6.24 6.4 2.848 8.704l2.624 4.288zm-5.504-9.92h-2.464v3.328h2.464c1.888.032 1.984-3.296 0-3.328zm14.88 0h-4.992v1.92h4.576v3.072h-4.576v2.368h5.184v3.04H57.12V10.272c0-.48.416-.896.896-.896h7.52v3.072zm16.352 7.328l4.64-9.12c.32-.576-.128-1.28-.768-1.28H67.808a.916.916 0 00-.896.896v2.208h3.328v10.4h3.392v-10.4h8.128l-5.28 10.4h10.752v-3.104h-5.344zm15.392-7.328h-4.992v1.92h4.576v3.072h-4.576v2.368h5.152v3.04h-8.576V10.272c0-.48.384-.896.864-.896h7.552v3.072zm5.248 7.328h4.896v3.104h-8.352V9.376h3.488c-.032 6.976-.032 4.64-.032 10.4zM25.792 4.192l-11.584 6.464c-.064.064-.128.16-.128.256v5.408l2.688 1.696V12.48c0-.096.064-.192.16-.256l6.208-3.456c.16-.096.384.032.384.256V19.296a2.813 2.813 0 01-1.984 2.816c-.768.224-1.6.128-2.272-.288l-6.176-3.936-.032-.032a5.568 5.568 0 00-4.672-.48c-1.312.448-2.4 1.408-3.072 2.656a5.518 5.518 0 00-.416 4.032c.416 1.44 1.344 2.624 2.656 3.328.8.448 1.728.704 2.624.704.544 0 1.056-.096 1.6-.256a5.566 5.566 0 003.392-2.912l.096-.224-2.304-1.472-.224.544c-.352.704-.992 1.248-1.728 1.472s-1.504.16-2.176-.224a2.655 2.655 0 01-1.344-1.696c-.416-1.408.384-2.944 1.792-3.424.288-.096.608-.16.928-.16.512 0 .992.16 1.44.416l6.208 3.936v.032c.864.512 1.856.8 2.88.8.512 0 1.056-.096 1.568-.224a5.52 5.52 0 003.904-5.408V4.448a.283.283 0 00-.416-.256z" />
    </svg>
  );
}

export default SvgBrandTextHoriz;
