import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { AudioOutputTitle, AudioOutputWrapper, AudioSelectorWrapper, SelectWrapper } from './Styles';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { customDropDownStyles } from '../CustomSelectStyles';
import { AudioQuality, SettingsContext, withSettingsContext } from '../../../../../../Core/Settings/Settings';

interface PropsFromContext {
  audioQuality: AudioQuality;
  setAudioQuality: (value: AudioQuality) => void;
}

type Props = PropsFromContext;

interface SelectOption {
  value: string;
  label: string;
}

export class AudioQualitySelectorPresentation extends React.Component<Props> {
  public render() {
    const options = Object.keys(AudioQuality).map(key => {
      let label = key;
      if (label == 'VeryHigh') {
        label = 'Very High';
      }

      return { label, value: AudioQuality[key as any] };
    });
    const value = options.find(option => option.value === this.props.audioQuality);
    return (
      <AudioOutputWrapper>
        <AudioOutputTitle>Audio quality</AudioOutputTitle>
        <AudioSelectorWrapper>
          <SelectWrapper className="no-drag">
            {/*
              // @ts-ignore  This is not typed right at all  */}
            <Select
              name="outputs"
              value={value}
              options={options}
              onChange={this.handleQualityChanged}
              isSearchable={false}
              isClearable={false}
              className="audioQuality"
              classNamePrefix="options-select"
              placeholder="Normal"
              styles={customDropDownStyles}
            />
          </SelectWrapper>
        </AudioSelectorWrapper>
      </AudioOutputWrapper>
    );
  }

  private handleQualityChanged = (o: ValueType<SelectOption>) => {
    // @ts-ignore
    this.props.setAudioQuality(o.value);
  };
}

function mapContextToProps(c: SettingsContext): PropsFromContext {
  return {
    audioQuality: c.audioQuality,
    setAudioQuality: c.setAudioQuality,
  };
}

export const AudioQualitySelector = compose(withSettingsContext(mapContextToProps))(AudioQualitySelectorPresentation);
