import styled from 'styled-components/macro';
import { theme } from '../';
import { Box, Heading } from 'grommet';

interface StyledRowProps {
  highlight?: boolean;
}

export const StyledRow = styled('tr')`
  ${(props: StyledRowProps) => (props.highlight ? `color: ${theme.colors.bgSectionsAlt};` : '')}

  td {
    border-bottom: 1px solid ${theme.colors.bgSectionsAlt};
    ${props => (props.highlight ? `background-color: ${theme.colors.brandGreen};` : '')}
  }

  &:first-of-type td {
    border-top: 1px solid ${theme.colors.bgSectionsAlt};
  }

  &:hover {
    color: ${props => (props.highlight ? theme.colors.bgSectionsAlt : theme.colors.brandGreen)};
  }
`;

export const DropdownBox = styled.div`
  padding-top: 7px;
  position: absolute;
  top: 20px;
  right: 0;
  min-width: max-content;
  z-index: 2;
  display: flex;
  text-align: left;
  font-size: 12px;

  div {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const DropdownItem = styled(Box)`
  padding: 4px 16px;
  cursor: pointer;

  :hover {
    background: ${theme.colors.bgSectionsAlt};
  }
`;

export const HoverShow = styled.span`
  display: none;

  ${StyledRow}:hover & {
    display: inline;
  }
`;

export const HoverHide = styled.span`
  ${StyledRow}:hover & {
    display: none;
  }
`;

export const TopTail = styled.div`
  position: absolute;
  top: 0;
  right: 4px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${theme.colors.bgSections};
`;

export const ForbiddenRow = styled('tr')`
  ${(props: StyledRowProps) => (props.highlight ? `color: ${theme.colors.bgSectionsAlt};` : '')}
  td {
    border-bottom: 1px solid ${theme.colors.bgSectionsAlt};
    color: #909090;
    font-style: italic;
    ${props => (props.highlight ? `background-color: ${theme.colors.brandGreen};` : '')}
  }

  td.reason {
    text-decoration: none;
  }

  &:first-of-type td {
    border-top: 1px solid ${theme.colors.bgSectionsAlt};
  }

  &:hover {
    color: ${props => (props.highlight ? theme.colors.bgSectionsAlt : theme.colors.brandGreen)};
  }
`;

export const ForbiddenIcon = styled.i`
  ${(props: StyledRowProps) => (props.highlight ? `color: ${theme.colors.bgSectionsAlt};` : '')}
  color: ${(props: StyledRowProps) => (props.highlight ? theme.colors.bgSectionsAlt : theme.colors.brandGreen2)};
  font-size: 20px;
  height: 20px;
  cursor: default;
`;

export const StyledButton = styled('button')`
  padding: 0;
  border: none;
  height: 20px;
  width: 20px;
  font-size: 20px;
  margin: 6px;
  text-align: center;
  background: transparent;
  color: ${theme.colors.baseWhite};
  cursor: pointer;
  &:hover {
    color: ${theme.colors.brandGreen2};
  }
  z-index: 3;
  position: relative;
`;
