import styled from 'styled-components/macro';
import { theme } from '../../../../Styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ArtWrapper = styled.div`
  width: 230px;
  height: 230px;
  @media (max-width: 470px) {
    width: 100%;
    height: auto;
    min-height: 230px;
  }
  position: relative;
`;

export const Art = styled.img`
  width: 230px;
  height: 230px;
  object-fit: cover;
  display: flex;
  align-self: center;
  @media (max-width: 470px) {
    width: 100%;
    height: auto;
    min-height: 230px;
  }
  border-radius: 4px;
`;

export const AddToPlaylistWrapper = styled.div`
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 50%;
  background-color: ${theme.colors.baseBackground2};
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
`;

export const AddIcon = styled.i`
  color: ${theme.colors.baseWhite};
  font-size: 22px;
  padding-right: 2px;
`;

export const SongRequestOverlay = styled.div`
  height: 25px;
  background: rgba(27, 179, 164, 0.9);
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  padding: 0 10px;
`;

export const SRName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
`;

export const SRAmount = styled.div`
  text-align: right;
  font-weight: 600;
  margin-left: 5px;
`;
