import * as React from "react";

function SvgFullVersion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M31.04 6.144H.96a.831.831 0 00-.832.832v18.048c0 .459.372.832.832.832h30.08a.832.832 0 00.832-.832V6.976a.831.831 0 00-.832-.832zM1.792 7.808h3.616v16.384H1.792zm5.344 16.384V7.808h7.2v16.384zm23.136 0H16V7.808h14.272z" />
    </svg>
  );
}

export default SvgFullVersion;
