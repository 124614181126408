import styled from 'styled-components/macro';
import { theme } from '../../../../Styled';

export const ProgressBar = styled.div`
  height: 4px;
  margin: 0 6px 1px 6px;
  flex: 1;
  background: ${theme.colors.textDefaultButtons};
  overflow: hidden;
  position: relative;
  border-radius: 2px;
`;

export const ProgressFill = styled.div`
  height: 4px;
  background: linear-gradient(90deg, ${theme.colors.gradientStart}, ${theme.colors.gradientEnd});
  transition: transform 1s linear;
  transform: translateX(${(props: { fill: number }) => -100 + props.fill}%);
`;

export const Time = styled.p`
  margin: 5px;
  color: ${theme.colors.textMuted};
  font-size: 12px;
  -webkit-app-region: no-drag;
`;
