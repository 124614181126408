import * as React from "react";

function SvgVideoGamePacmanEnemy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M12 0a9.01 9.01 0 00-9 9v11.5A3.386 3.386 0 006.25 24a3.142 3.142 0 002.4-1.144.5.5 0 01.753-.012 3.493 3.493 0 005.192 0 .5.5 0 01.753.012A3.142 3.142 0 0017.75 24 3.386 3.386 0 0021 20.5V9a9.01 9.01 0 00-9-9zm-1.5 11.75a1.75 1.75 0 01-3.5 0v-2.5a1.75 1.75 0 013.5 0zm6.5 0a1.75 1.75 0 01-3.5 0v-2.5a1.75 1.75 0 013.5 0z" />
    </svg>
  );
}

export default SvgVideoGamePacmanEnemy;
