import * as React from "react";

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M6.688 9.408c.352 0 .736.16.896.352l8.512 9.76 8.48-9.76c.384-.352 1.088-.352 1.632 0 .384.352.384 1.088.192 1.44l-9.408 10.848c-.352.544-1.248.544-1.632 0L5.952 11.2c-.512-.352-.352-1.088 0-1.44.192-.192.544-.192.736-.352z" />
    </svg>
  );
}

export default SvgArrowDown;
