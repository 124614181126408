import { Box, Image } from 'grommet';
import React, { useContext } from 'react';
import { LabelsListQuery_labels_edges_node } from '../types/LabelsListQuery';
import { Heading } from '../../Heading';
import platformContext, { PlatformContext } from '../../../Core/Platform';
import playerContext, { PlayerContext } from '../../../Core/Player/PlayerContext';
import { CardType, MusicCard, theme } from '../../../Styled';
import { ArtworkSize, minutesOrHours, toUrl } from '../../../Util';
import { isUserEntitled } from '../../../Util/Entitlements';

type Props = {
  label: LabelsListQuery_labels_edges_node;
  size?: string;
};

export const LabelCard = ({ label, size }: Props): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const playerContextObj = useContext<PlayerContext>(playerContext);
  const isPlaying = playerContextObj.activeSegment && playerContextObj.activeSegment.id === label.id;

  const handleClick = (): void => {
    if (playerContextObj.playing && playerContextObj.activeSegment.id === label.id) {
      playerContextObj.pause();
    } else {
      playerContextObj.playSegment({ id: label.id });
    }
  };

  const art = <Image src={toUrl(label.artworkGuid, ArtworkSize.Medium)} style={{ borderRadius: theme.card.borderRadius }} width="100%" />;

  const text = (
    <Box margin={`${theme.space.md} 0 0 0`}>
      <Heading variant="H4">{label.name}</Heading>
    </Box>
  );

  return (
    <MusicCard
      active={isPlaying}
      art={art}
      isPlaying={playerContextObj.playing}
      linkTo={`/segment/${label.id}`}
      onClick={handleClick}
      text={text}
      meta={{
        hours: minutesOrHours(label.stats.duration),
        tracks: label.stats.totalCount,
      }}
      cardType={CardType.Label}
      cardName={label.name}
      isLocked={!isUserEntitled(platformContextObj.pretzelUser.jwt.tier, label.entitlements)}
      size={size}
    />
  );
};
