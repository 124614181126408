import { Component } from 'react';
import { PretzelRocksProvider } from './Context/PretzelRocks';
import React from 'react';
import { PretzelRocksAuthenticatedApolloProvider } from './GraphQL/PretzelRocksAuthenticatedApolloProvider';
import * as Sentry from '@sentry/browser';
import uuid from 'uuid';
import platform from 'platform';
import { Hotkeys } from '@pretzel-aux/common/src/Core/Hotkeys/Hotkeys';
import algoliasearch from 'algoliasearch/lite';
import aa from 'search-insights';
import { InstantSearch } from 'react-instantsearch-dom';
import { SettingsProvider } from '@pretzel-aux/common/src/Core/Settings/Settings';
import { PlayerProvider } from '@pretzel-aux/common/src/Core/Player/PlayerContext';
import { Analytics, AnalyticsEnv } from '@pretzel-aux/common/src/Core/Analytics';
import { SongRequestAlerts } from '@pretzel-aux/common/src/Core/Alerts/SongRequestAlerts';
import { Box } from 'grommet';
import { theme, Icon } from '@pretzel-aux/common/src/Styled';
import { SongStatusWriter } from '@pretzel-aux/common/src/Core/SongStatusWriter/SongStatusWriter';
import { MediaSessionUpdater } from '@pretzel-aux/common/src/Core/Alerts/MediaSessionUpdater';
import Heap from '@pretzel-aux/common/src/Core/Heap';
import { UpgradeToPremiumModal } from '@pretzel-aux/common/src/Components/UpgradeToPremiumModal';
import { Button } from '@pretzel-aux/common/src/Components/Button';
import Stonly from '@pretzel-aux/common/src/Core/Stonly';

const appHash = process.env.REACT_APP_HASH;
const branch = process.env.REACT_APP_BRANCH;

Sentry.init({
  dsn: 'https://1041a5b927ce4f41a9c8890ea265d516@o363271.ingest.sentry.io/5373729',
  release: `pretzel-aux@${appHash}`,
  environment: branch,
  // Turn off console intercept in dev mode.
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: !!branch })],
});

const searchClient = algoliasearch('2QTWT50E7D', 'faba9a4e682a2be41be17800a9273292');
aa('init', { appId: '2QTWT50E7D', apiKey: 'faba9a4e682a2be41be17800a9273292' });

const versionSha = `${(appHash && appHash.substring(0, 7)) || 'devHash'}`;

let localDeviceDisplayName = window.localStorage.getItem('pretzel_app_device_displayName');
let localDeviceUDID = window.localStorage.getItem('pretzel_app_device_id');

if (!localDeviceDisplayName || localDeviceDisplayName === '') {
  localDeviceDisplayName = `${platform.name} on SLOBS`;
  window.localStorage.setItem('pretzel_app_device_displayName', localDeviceDisplayName);
}

if (!localDeviceUDID) {
  localDeviceUDID = uuid();
  window.localStorage.setItem('pretzel_app_device_id', localDeviceUDID);
}

const environment: AnalyticsEnv = {
  type: 'desktop',
  version: `${appHash || 'dev'}`,
  browser: 'PretzelRocks',
  os: (platform.os && platform.os.toString()) || 'rocks',
  locale: window.navigator.language,
  versionSha,
};

Analytics.defineEnvironment(environment);
interface PublicProps {
  unloadApp: () => void;
}

interface State {
  error: boolean;
}

export class PretzelRocksBase extends Component<PublicProps, State> {
  state: State = { error: false };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        // @ts-ignore
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return (
        <Box align="center" fill="horizontal" pad="large">
          <Box style={{ color: theme.colors.brandGreen2 }} width="300px" pad="20px">
            <Icon name="brand-text" size={140} />
          </Box>
          <h1>Oh shoot, something went wrong!</h1>
          <Box direction="row-responsive" width="400px" gap="medium">
            <Box basis="1/2" align="center">
              <h3>Tell us what happened</h3>
              <Button id="button-submit-report" color="outline" onClick={() => Sentry.showReportDialog()}>
                Submit Report
              </Button>
            </Box>
            <Box basis="1/2" align="center">
              <h3>I don't have time for that</h3>
              <Button id="button-reload-app" color="outline" onClick={this.props.unloadApp}>
                Just Reload the App
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <PretzelRocksProvider unloadApp={this.props.unloadApp}>
        <PretzelRocksAuthenticatedApolloProvider>
          <SettingsProvider>
            <PlayerProvider>
              <Heap />
              <Stonly />
              <UpgradeToPremiumModal />
              <InstantSearch searchClient={searchClient} indexName="prod_tracks">
                {this.props.children}
                <Hotkeys />
                <SongRequestAlerts />
                <SongStatusWriter />
                <MediaSessionUpdater />
              </InstantSearch>
            </PlayerProvider>
          </SettingsProvider>
        </PretzelRocksAuthenticatedApolloProvider>
      </PretzelRocksProvider>
    );
  }
}
