import * as React from "react";

function SvgViewers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M26.784 26.656l-1.984-.224v-.576c0-2.976-2.24-5.696-5.632-6.816l.608-1.92c4.16 1.312 7.008 4.832 7.008 8.672.064.256 0 .576 0 .864zm-21.568.064c0-.288-.064-.608-.064-.864 0-3.84 2.848-7.36 7.008-8.672l.608 1.92c-3.392 1.056-5.632 3.84-5.632 6.816 0 .192 0 .448.064.672l-1.984.128zM16 20.16c-3.968 0-7.2-3.232-7.2-7.2s3.232-7.264 7.2-7.264 7.2 3.232 7.2 7.2-3.232 7.264-7.2 7.264zm0-12.48c-2.912 0-5.216 2.368-5.216 5.216s2.368 5.216 5.216 5.216c2.912 0 5.216-2.368 5.216-5.216S18.912 7.68 16 7.68zm0 23.648C7.392 31.328.384 24.32.384 15.744S7.392.064 16 .064 31.616 7.072 31.616 15.68 24.608 31.328 16 31.328zm0-29.28c-7.52 0-13.632 6.144-13.632 13.632S8.544 29.28 16 29.28c7.552 0 13.632-6.144 13.632-13.6S23.552 2.048 16 2.048z" />
    </svg>
  );
}

export default SvgViewers;
