import * as React from 'react';
import { Box, Image, Paragraph, Text } from 'grommet';
import { CSSProperties } from 'react';
import { SongRequesterList } from './SongRequesterList';
import { joinStringsGrammatically, toUrl } from '../../../Util';
import { theme } from '../../../Styled';
import { SongRequestQueueQuery_currentUser_songRequestQueue } from '../types/SongRequestQueueQuery';

interface PublicProps {
  request: SongRequestQueueQuery_currentUser_songRequestQueue;
}

type Props = PublicProps;

export class SongRequestDisplay extends React.Component<Props> {
  public render() {
    const artists = joinStringsGrammatically(this.props.request.track.artists.map(artist => artist.name));
    const lineStyles: CSSProperties = {
      lineHeight: '16px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };

    return (
      <Box pad="8px">
        <Box direction="row" margin={{ bottom: '8px' }} overflow="auto">
          <Box width="50px" height="50px" margin={{ right: '8px' }}>
            <Image fit="cover" src={toUrl(this.props.request.track.artworkGuid)} />
          </Box>
          <Box justify="center">
            <Text style={{ fontSize: '14px', ...lineStyles }} title={this.props.request.track.title}>
              {this.props.request.track.title}
            </Text>
            {artists && (
              <Paragraph title={artists} margin="0" style={lineStyles}>
                <Text size="10px" color={theme.colors.textMuted}>
                  by
                </Text>{' '}
                <Text size="12px" color={theme.colors.textParagraph}>
                  {artists}
                </Text>
              </Paragraph>
            )}
          </Box>
        </Box>
        <SongRequesterList request={this.props.request} />
        <Box border="bottom" style={{ borderColor: theme.colors.textDefaultButtons }} />
      </Box>
    );
  }
}
