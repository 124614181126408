import * as React from 'react';

function SvgThumbsUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32 25.659V25.659C32.5359 25.659 33.0311 25.9449 33.299 26.409C33.567 26.8731 33.567 27.4449 33.299 27.909C33.0311 28.3731 32.5359 28.659 32 28.659H31C31.8284 28.659 32.5 29.3306 32.5 30.159C32.5 30.988 31.828 31.159 31 31.159H24.5C21.649 31.159 21 30.659 17.5 30.159V21.659C19.95 21.659 24 17.159 24 13.159C24 11.578 26.189 10.989 27 13.878C27.5 15.659 26 19.159 26 19.159H34C34.8284 19.159 35.5 19.8306 35.5 20.659C35.5 21.488 34.828 22.659 34 22.659H33C33.8284 22.659 34.5 23.3306 34.5 24.159C34.5 24.9874 33.8284 25.659 33 25.659H32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 20.159H17.5V32.159H12.5V20.159Z" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.25 29.659C15.1119 29.659 15 29.7709 15 29.909C15 30.0471 15.1119 30.159 15.25 30.159C15.3881 30.159 15.5 30.0471 15.5 29.909C15.5 29.7709 15.3881 29.659 15.25 29.659"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgThumbsUp;
