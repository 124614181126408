import * as React from "react";

function SvgApple(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 32" {...props}>
      <path d="M24.864 21.696q-.672 2.24-2.176 4.448-2.304 3.52-4.608 3.52-.864 0-2.496-.576-1.536-.576-2.688-.576-1.088 0-2.528.608T8 29.728q-2.72 0-5.376-4.64Q0 20.416 0 16.096q0-4.064 2.016-6.656t5.088-2.592q1.28 0 3.136.544t2.464.544q.8 0 2.56-.608 1.824-.608 3.104-.608 2.112 0 3.808 1.152.928.64 1.856 1.792-1.408 1.184-2.048 2.112-1.152 1.664-1.152 3.68 0 2.208 1.216 4t2.816 2.24zM18.176.736q0 1.088-.544 2.432-.512 1.344-1.664 2.464-.96.96-1.92 1.28-.64.224-1.856.32.064-2.656 1.408-4.576Q14.912.736 18.048 0q.032.064.064.192t.032.192 0 .192.032.16z" />
    </svg>
  );
}

export default SvgApple;
