import * as React from 'react';

function SvgStPatricks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg id="Light" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <defs>
        <style>{'.cls-1{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;}'}</style>
      </defs>
      <title>casino-lucky-clover</title>
      <path className="cls-1" d="M18.65,8.713,12,9.473l.68-6.72a2.5,2.5,0,1,1,4.98.5l-.06.55.49-.06a2.5,2.5,0,0,1,.56,4.97Z" />
      <path className="cls-1" d="M5.35,8.713l6.65.76-.68-6.72a2.5,2.5,0,0,0-4.98.5l.06.55-.49-.06a2.5,2.5,0,0,0-.56,4.97Z" />
      <path className="cls-1" d="M18.65,10.233,12,9.473l.68,6.72a2.5,2.5,0,1,0,4.98-.5l-.06-.55.49.06a2.5,2.5,0,0,0,.56-4.97Z" />
      <path className="cls-1" d="M5.35,10.233,12,9.473l-.68,6.72a2.5,2.5,0,0,1-4.98-.5l.06-.55-.49.06a2.5,2.5,0,0,1-.56-4.97Z" />
      <path className="cls-1" d="M12,19.5V20a3.5,3.5,0,0,0,3.5,3.5" />
    </svg>
  );
}

export default SvgStPatricks;
