import * as React from "react";

function SvgAmazon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M27.712 26.368q.256-.128.448-.064t.192.32-.256.576q-.224.288-.8.8T25.6 29.216t-2.528 1.312-3.36 1.024-4.096.448q-2.112 0-4.224-.544T7.648 30.08t-3.072-1.856-2.368-1.888T.704 24.8q-.16-.192-.192-.32t.032-.192.128-.128.224-.032.192.064q3.424 2.08 5.376 2.976 6.944 3.136 14.24 1.6 3.392-.704 7.008-2.4zm3.68-2.08q.192.288.032 1.248t-.48 1.856q-.608 1.472-1.536 2.208-.288.256-.448.16t0-.448q.352-.8.768-2.176t.128-1.76q-.096-.096-.288-.192t-.48-.096-.512-.064-.64 0-.544.032-.576.064-.384.032q-.096.032-.224.032t-.192.032-.16 0-.128 0h-.224l-.032-.032-.032-.032q-.128-.288.832-.736t1.824-.512q.832-.128 1.952-.032t1.344.416zm-7.04-7.904q0 .576.256 1.152t.576 1.024.672.832.576.576l.224.192-4.032 4q-.736-.672-1.44-1.344T20.16 21.76l-.352-.352q-.192-.192-.416-.576-.704 1.056-1.76 1.824t-2.272 1.12-2.496.416-2.464-.384-2.08-1.152-1.504-2.016-.544-2.912q0-1.504.512-2.752t1.28-2.08 1.888-1.472T12.16 10.4t2.304-.608 2.144-.352 1.76-.096V7.072q0-1.152-.352-1.728-.608-.96-2.176-.96-.096 0-.288.032t-.736.192-.992.544-.992 1.056-.864 1.728l-5.248-.48q0-1.088.384-2.144T8.32 3.296 10.24 1.6 12.928.448 16.352 0q1.76 0 3.232.448t2.304 1.088 1.44 1.504.8 1.536.224 1.312v10.496zm-12 .384q0 1.536 1.248 2.368 1.184.8 2.496.384 1.504-.448 2.016-2.176.256-.8.256-1.824v-2.88q-1.056.032-1.984.224t-1.888.576-1.568 1.28-.576 2.048z" />
    </svg>
  );
}

export default SvgAmazon;
