import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

export const StyledCompactActiveBar = styled.div`
  span {
    font-size: 14px;
  }

  padding-bottom: ${theme.space.sm};
`;
