import { theme } from '../../Styled/theme';
import styled from 'styled-components';
import { EPlacement, TooltipProps } from './types';

const tipWidth = 7.5;
const tipWidthPx = `${tipWidth}px`;

const ArrowBottom = (color?: string) => {
  return `
    left: calc(50% - ${tipWidthPx});
    bottom: -${tipWidthPx};
    border-left: ${tipWidthPx} solid transparent;
    border-right: ${tipWidthPx} solid transparent;
    border-top: ${tipWidthPx} solid ${color ? color : theme.colors.brandGreen};
  `;
};

const ArrowTop = (color?: string) => {
  return `
    left: calc(50% - ${tipWidthPx});
    top: -${tipWidthPx};
    border-left: ${tipWidthPx} solid transparent;
    border-right: ${tipWidthPx} solid transparent;
    border-bottom: ${tipWidthPx} solid ${color ? color : theme.colors.brandGreen};
  `;
};

const ArrowLeft = (color?: string) => {
  return `
    left: -${tipWidth}px;
    top: calc(50% - ${tipWidthPx});
    border-top: ${tipWidthPx} solid transparent;
    border-bottom: ${tipWidthPx} solid transparent;
    border-right: ${tipWidthPx} solid ${color ? color : theme.colors.brandGreen}
  `;
};

const ArrowRight = (color?: string) => {
  return `
    right: -${tipWidth}px;
    top: calc(50% - ${tipWidthPx});
    border-top: ${tipWidthPx} solid transparent;
    border-bottom: ${tipWidthPx} solid transparent;
    border-left: ${tipWidthPx} solid ${color ? color : theme.colors.brandGreen}
  `;
};

const ArrowPlacement = (placement: EPlacement, color?: string) => {
  if (placement === EPlacement.TOP) {
    return ArrowBottom(color);
  }
  if (placement === EPlacement.RIGHT) {
    return ArrowLeft(color);
  }
  if (placement === EPlacement.LEFT) {
    return ArrowRight(color);
  }
  return ArrowTop(color);
};

const TooltipPlacement = (placement: EPlacement) => {
  if (placement === EPlacement.TOP) {
    return `transform: translateY(-100%) translateX(-10%);`;
  }
  if (placement === EPlacement.RIGHT) {
    return `transform: translateY(1%) translateX(40px);`;
  }
  if (placement === EPlacement.BOTTOM) {
    return `transform: translateY(150%) translateX(-10%);`;
  }
  return `transform: translateY(1%) translateX(-100%);`;
};

const setColor = (color?: string) => {
  return `
    background-color: ${color ? color : theme.colors.brandGreen};
    border: 1px solid ${color ? color : theme.colors.brandGreen};
  `;
};

export const StyledTooltip = styled('div')<TooltipProps>`
  position: absolute;
  display: flex;
  z-index: 5;
  width: max-content;
  ${(props: { color?: string }) => setColor(props.color)}
  color: ${(props: { fontColor?: string }) => (props.fontColor ? props.fontColor : theme.colors.tooltipBlack)}};
  font-family: "proxima-nova", sans-serif;
  font-size: 0.625rem;
  font-weight: 500;
  padding: 0.3125rem 0.5rem;
  max-width: 8rem;
  border-radius: 0.25rem;
  text-align: center;
  ${(props: { placement?: EPlacement }) => TooltipPlacement(props.placement)}
  ${(props: { styles?: string }) => props.styles}

  :after {
    content: '';
    position: absolute;
    ${(props: { placement?: EPlacement; color?: string }) => ArrowPlacement(props.placement, props.color)}
  }
`;
