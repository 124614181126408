import * as React from "react";

function SvgAddPlaylist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 32" {...props}>
      <path d="M7.872 7.584h20.672c.512 0 1.056-.384 1.056-1.056s-.384-1.056-1.056-1.056H7.872c-.544.128-1.056.672-1.056 1.184s.512.928 1.056.928zm20.672 5.472H7.872c-.544 0-1.056.416-1.056 1.088S7.2 15.2 7.872 15.2h20.672c.512 0 1.056-.416 1.056-1.056s-.544-1.088-1.056-1.088zm0 7.488h-2.816v-2.816c0-.544-.384-1.056-1.056-1.056s-1.056.384-1.056 1.056v2.816H20.8c-.544 0-1.056.384-1.056 1.056s.384 1.056 1.056 1.056h2.816v2.688c0 .512.384 1.056 1.056 1.056s1.056-.416 1.056-1.056v-2.816h2.816c.512 0 1.056-.384 1.056-1.056s-.544-.928-1.056-.928zm-12 0H7.872c-.544 0-1.056.384-1.056 1.056s.384 1.056 1.056 1.056h8.672c.512 0 1.056-.384 1.056-1.056s-.544-1.056-1.056-1.056z" />
    </svg>
  );
}

export default SvgAddPlaylist;
