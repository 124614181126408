import { theme } from '../../Styled';
import styled from 'styled-components';

export const StyledTitle = styled.span`
  display: inline-block;
  color: ${theme.colors.baseWhite};
  margin-bottom: ${theme.space.md};
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.space.md};
`;

export const StyledSpan = styled.span`
  display: inline-block;
  margin-left: ${theme.space.md};
`;

export const StyledLink = styled.a`
  color: ${theme.colors.brandGreen} !important;
`;
