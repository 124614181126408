import * as React from "react";

function SvgBeachPalmWater(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.33 19.588c.625.086 1.552-.743 1.822-1.086a2.5 2.5 0 014.089.227 2.016 2.016 0 001.412.832 2.681 2.681 0 001.829-1 2.584 2.584 0 012-1.006 2.507 2.507 0 012.252 1.4.353.353 0 00.028.05 1.169 1.169 0 00.963.555 1.765 1.765 0 001.39-.788l.017-.027a2.414 2.414 0 012.133-1.2A2.511 2.511 0 0121.4 18.73s.629.887 1.273.819a2.4 2.4 0 01.744.015.5.5 0 00.583-.494v-2.164a.5.5 0 00-.243-.429A21.789 21.789 0 0012 13.406a27.137 27.137 0 00-2.919.129.5.5 0 01-.547-.565 18.087 18.087 0 011.234-4.423l.253-.621a.5.5 0 01.793-.186l3.657 3.219a.5.5 0 00.815-.253 4.152 4.152 0 00-1.276-3.675 5.111 5.111 0 00-1.435-1.06.5.5 0 01-.02-.887l3.651-2a.5.5 0 00-.013-.884 4.205 4.205 0 00-3.8.322 5.064 5.064 0 00-1.5 1.235.5.5 0 01-.836-.092 5.044 5.044 0 00-1.2-1.533A4.209 4.209 0 005.214.992a.5.5 0 00-.2.86L7.553 4.08a.5.5 0 01-.287.874 5.208 5.208 0 00-1.7.457A4.147 4.147 0 003.02 8.359a.5.5 0 00.661.536l4.211-1.476a.232.232 0 01.245.379 13.022 13.022 0 00-2.97 6.243.5.5 0 01-.338.37 21.288 21.288 0 00-4.586 2.066.5.5 0 00-.243.429v2.206a.5.5 0 00.587.493 2.459 2.459 0 01.743-.017z" />
      <path d="M1.417 23.094a4.113 4.113 0 01-.549-.036 1 1 0 01.264-1.982c1.683.227 3.183-1.626 3.2-1.645a1.042 1.042 0 011.638.091 3.462 3.462 0 002.683 1.538 4.2 4.2 0 003.026-1.6 1.046 1.046 0 011.714.159 2.725 2.725 0 002.336 1.44 3.283 3.283 0 002.677-1.515 1.048 1.048 0 011.715-.023c.009.013 1.118 1.729 2.746 1.513a1 1 0 01.266 1.982A4.511 4.511 0 0119.6 21.89a.5.5 0 00-.64 0 5 5 0 01-3.226 1.173 4.449 4.449 0 01-3.044-1.17.5.5 0 00-.636-.027 5.626 5.626 0 01-3.4 1.2 5.047 5.047 0 01-3.28-1.252.5.5 0 00-.632-.01 5.489 5.489 0 01-3.325 1.29z" />
    </svg>
  );
}

export default SvgBeachPalmWater;
