import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { theme } from '@pretzel-aux/common/src/Styled';
import AudioSetting from './AudioOutputKeybindSelector';
import { KeyAction, formatForDisplay, formatForStore } from '../KeyAction';
import { customDropDownStyles } from '../../Audio/Components/CustomSelectStyles';
import { NumberSetting } from '../../../Components/NumberSetting/NumberSetting';
import { StyledInput } from '../../../../CurrentUser/Styles';

const Delete = styled.div`
  color: ${theme.colors.baseWhite};
  cursor: pointer;
  height: 24px;
  position: absolute;
  right: -0.5rem;
  top: -0.75rem;
  width: 24px;

  &:hover {
    color: #e70034;
  }
`;

const Icon = styled.i`
  font-size: 1.5rem;
  cursor: pointer;
`;

const Input = styled.input`
  // This is hacky and needs revisited. 
  ${StyledInput}
  border-color: ${(props: CustomInputProps) => getBorderColor(props)};
  color: ${theme.colors.offWhite} !important;
  height: 40px !important;
  padding: 0.6875rem 1rem !important;
`;

const OptionNote = styled.div`
  padding-top: 8px;
  font-size: 13px;
  color: ${theme.colors.baseMuted};
`;

const StyledLabelAndInput = styled.div`
  * {
    font-family: ${theme.font.familySecondary} !important;
  }

  &:nth-of-type(n + 3) {
    margin-top: 1rem;
  }

  label {
    font-size: 0.75rem;

    & ~ * {
      margin-top: 0.5rem;
    }
  }
`;

const Wrapper = styled.div`
  background-color: #1a242c;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  width: 95%;

  &:not(:first-of-type) {
    margin-top: 2.25rem;
  }
`;

type CustomInputProps = {
  error: boolean;
  capturing: boolean;
};

type KeybindProps = {
  action: any;
  value: any;
  note: any;
  valueText: any;
  bind: any;
  actions: any;
  updateAction: any;
  updateValue: any;
  actionValue: any;
  deleteKeybind: any;
  activateKeyCapture: any;
  deactivateKeyCapture: any;
  capturing: boolean;
  error: any;
};

type ValuePickerProps = {
  actionValue: KeyAction;
  value?: string | number;
  updateValue: (a: any) => void;
};

function getBorderColor(props: CustomInputProps): string {
  if (props.error) {
    return '#e70034 !important';
  }

  return `rgba(255, 255, 255, 0.1) !important`;
}

const Keybind = ({
  value,
  note,
  valueText,
  bind,
  actions,
  updateAction,
  updateValue,
  actionValue,
  deleteKeybind,
  activateKeyCapture,
  deactivateKeyCapture,
  error,
  capturing,
}: KeybindProps) => {
  return (
    <>
      <Wrapper>
        <Delete onClick={deleteKeybind}>
          <Icon className="pretzel-icon-close_circle" />
        </Delete>
        <StyledLabelAndInput>
          <label>Action</label>
          <Select
            name="outputs"
            value={actionValue}
            options={actions}
            onChange={updateAction}
            isSearchable={false}
            isClearable={false}
            className="keybindActions"
            getOptionValue={(o: any) => o.value}
            getOptionLabel={(o: any) => o.label}
            placeholder="Unassigned"
            styles={customDropDownStyles}
          />
        </StyledLabelAndInput>
        {actionValue && actionValue.valueType && (
          <StyledLabelAndInput>
            <ValuePicker actionValue={actionValue} value={value} updateValue={updateValue} />
          </StyledLabelAndInput>
        )}
        <StyledLabelAndInput>
          <label>Keybind</label>
          <Input
            type="text"
            value={bind}
            onChange={e => e.preventDefault()}
            onFocus={activateKeyCapture}
            onBlur={deactivateKeyCapture}
            error={error}
            capturing={capturing}
          />
        </StyledLabelAndInput>
      </Wrapper>
      {note ? <OptionNote>{note}</OptionNote> : null}
    </>
  );
};

const ValuePicker = ({ actionValue, value, updateValue }: ValuePickerProps) => {
  let valuePicker = null;
  if (actionValue.valueType === 'number') {
    valuePicker = (
      <NumberSetting
        title={actionValue.valueTitle}
        min={actionValue.minValue}
        max={actionValue.maxValue}
        pattern={actionValue.pattern}
        units={value === 1 ? actionValue.valueUnit : actionValue.valueUnitPlural}
        value={formatForDisplay(actionValue, value as number)}
        onChange={val => updateValue(formatForStore(actionValue, val))}
      />
    );
  } else if (actionValue.valueType === 'dropdown') {
    // @ts-ignore
    valuePicker = (
      <Select
        name="outputs"
        value={value}
        options={actionValue.dropdown as any}
        onChange={updateValue}
        isSearchable={false}
        isClearable={false}
        className="keybindActions"
        getOptionValue={(o: any) => o.value}
        getOptionLabel={(o: any) => o.label}
        placeholder="Setting"
        styles={customDropDownStyles}
      />
    );
  } else if (actionValue.valueType === 'audio') {
    valuePicker = <AudioSetting value={value} onChange={updateValue} />;
  }
  return valuePicker;
};

export default Keybind;
