import React from 'react';
import { Heading } from '../Components/Heading';

const RenderHighlightedHeading = ({ text }: { text: string }) => {
  const filteredTitle = text.replace(/[\W]*\S+[\W]*$/, '');
  const lastWordOfTitle = text.split(' ').pop();

  return (
    <Heading variant="H2">
      {filteredTitle}
      <strong> {lastWordOfTitle}</strong>
    </Heading>
  );
};

export default RenderHighlightedHeading;
