import styled from 'styled-components/macro';
import { theme } from '../../../../../../Styled';

export const PermissionOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
export const PermissionWrapper = styled.div`
  width: auto;
  max-width: 300px;
  text-align: center;
`;
export const PermissionContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  color: #51586a;
  font-size: 14px;
`;
export const PermissionBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
`;
export const PermissionButtons = styled.div``;
export const PermissionText = styled.p`
  &:first-child {
    padding-bottom: 10px;
  }
`;
export const PermissionButton = styled.button`
  float: left;
  width: 100%;
  padding: 15px;
  border: solid #f5f5f5;
  border-width: 1px 0 0;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.brandGreen2};
    color: ${theme.colors.baseWhite};
  }
  font-weight: bold;
`;
