import * as React from "react";

function SvgInstrumentHarmonica(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.957 10.8l-1.736-3.909A1.5 1.5 0 0020.851 6h-.17a.25.25 0 00-.205.107.247.247 0 00-.03.228l.964 2.652a1.5 1.5 0 01.09.513v1A1.5 1.5 0 0120 12H4a1.5 1.5 0 01-1.5-1.5v-1a1.5 1.5 0 01.09-.513l.964-2.652a.247.247 0 00-.03-.228A.25.25 0 003.319 6h-.17a1.5 1.5 0 00-1.37.891L.043 10.8A.507.507 0 000 11v6.5a1 1 0 001 1h.878a.25.25 0 01.236.167A2 2 0 004 20h16a2 2 0 001.886-1.333.25.25 0 01.236-.167H23a1 1 0 001-1V11a.507.507 0 00-.043-.2zM7.25 16.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zM5 16.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm8.25.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zm8.75-.25a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25z" />
      <path d="M4.015 9.459A.245.245 0 004 9.544v.706a.25.25 0 00.25.25h15.5a.25.25 0 00.25-.25v-.706a.245.245 0 00-.015-.085l-1.506-4.143A2 2 0 0016.6 4H7.4a2 2 0 00-1.88 1.316z" />
    </svg>
  );
}

export default SvgInstrumentHarmonica;
