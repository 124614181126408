import React from 'react';
import { flowRight as compose } from 'lodash';
import { PlatformContext, withPlatformContext } from '../../Core/Platform';
import { SettingsContext, withSettingsContext } from '../../Core/Settings/Settings';
import { PlayerContext, withPlayerContext } from '../../Core/Player/PlayerContext';
import { Table } from './components/Table';
import { ITrack } from './types';
import { generateColumns } from './utils/generateColumns';
import { returnViewportType } from '../../../../common/src/Util/responsive';
import useWindowSize from '../../../../common/src/Util/hooks/use-window-size';
import { LoadMoreButton } from '../../Styled/LoadMoreButton';
import { generateData } from './utils/generateData';
import { LoadMoreContainer } from './styled';
import { LoadingSpinner } from '../LoadingSpinner';
import { TrackListProps, PublicProps, PropsFromPlatform, PropsFromPlayerContext, PropsFromSettingsContext } from './types';

export const TrackListPresentation = (props: TrackListProps) => {
  const { type, onMore, loadingMore, shareUrl, highlightId, togglePause, removeTrackFromPlaylist } = props;

  const { width } = useWindowSize();
  const viewport = returnViewportType(width);

  const columns = generateColumns(type, shareUrl, removeTrackFromPlaylist, viewport);
  const data: ITrack[] = generateData(props, viewport);

  if (props.loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Table columns={columns} data={data} highlightId={highlightId} togglePause={togglePause} viewport={viewport} />
      {props.hasMore && (
        <LoadMoreContainer>
          <LoadMoreButton onMore={onMore} loading={loadingMore} />
        </LoadMoreContainer>
      )}
    </div>
  );
};

function mapPlayerContextToProps(c: PlayerContext): PropsFromPlayerContext {
  return {
    playToken: c.playToken,
    togglePause: c.togglePause,
    playTrack: c.playTrack,
    playing: c.playing,
    next: c.next,
  };
}

function mapSettingsContextToProps(c: SettingsContext): PropsFromSettingsContext {
  return {
    instrumentalOnly: c.instrumentalOnly,
    allowExplicit: c.allowExplicit,
    youtubeSafe: c.youtubeSafe,
  };
}

function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export const TrackList: React.ComponentClass<PublicProps> = compose(
  withPlatformContext(mapPlatformToProps),
  withSettingsContext(mapSettingsContextToProps),
  withPlayerContext(mapPlayerContextToProps)
)(TrackListPresentation);
