import * as React from "react";

function LogoLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 182 42" fill="none" {...props}>
      <path d="M59.9122 14.479C58.4622 14.429 56.9621 14.479 55.5621 14.479C55.5621 16.379 55.5621 19.309 55.5621 21.209H59.9122C63.2322 21.259 63.4322 14.539 59.9122 14.479ZM49.4521 32.719V10.639C49.4521 9.76899 50.1222 9.099 51.0022 9.099H59.9122C71.3022 9.149 71.3022 26.659 59.9122 26.659H55.5121V32.669H49.4521V32.719Z" fill="#EFEFEF"/>
      <path d="M81.0916 14.479C79.6416 14.429 78.1917 14.479 76.7417 14.479C76.7417 16.379 76.7417 18.429 76.7417 20.279H81.0916C84.4016 20.339 84.5616 14.539 81.0916 14.479ZM90.7717 31.789V32.659H83.9917L79.9016 25.729H76.7417V32.659H70.6816V10.629C70.6816 9.759 71.3516 9.08899 72.2316 9.08899H81.0816C90.4016 9.13899 92.1117 20.289 86.1017 24.289L90.7717 31.789Z" fill="#EFEFEF"/>
      <path d="M107.342 14.479H98.542V17.819H106.672V23.159H98.592V27.319H107.702V32.659H92.582V10.629C92.582 9.759 93.252 9.08899 94.132 9.08899H107.442V14.479H107.342Z" fill="#EFEFEF"/>
      <path d="M136.181 27.3189L144.411 11.3489C144.931 10.3189 144.202 9.13892 143.012 9.13892H111.322C110.442 9.13892 109.771 9.80892 109.771 10.6789V14.5289H115.621V32.7089H121.632V14.4789H135.971L126.651 32.6589H145.651V27.3189H136.181Z" fill="#EFEFEF"/>
      <path d="M163.312 14.479H154.512V17.819H162.592V23.159H154.512V27.319H163.622V32.659H148.502V10.629C148.502 9.759 149.172 9.08899 150.052 9.08899H163.362V14.479H163.312Z" fill="#EFEFEF"/>
      <path d="M172.572 27.269H181.222C181.222 29.269 181.222 30.659 181.222 32.709C176.352 32.709 171.332 32.709 166.462 32.709V9.08899C168.482 9.08899 170.502 9.08899 172.572 9.08899C172.572 21.309 172.572 17.259 172.572 27.269Z" fill="#EFEFEF"/>
      <path d="M37.2918 0.0489718L16.8418 11.349C16.6818 11.449 16.5818 11.609 16.5818 11.759V21.209L21.3418 24.189V14.529C21.3418 14.379 21.4418 14.219 21.6018 14.119L32.5218 8.05897C32.8318 7.90897 33.2418 8.10897 33.2418 8.46897V18.379V26.439C33.3418 28.649 31.9518 30.699 29.7218 31.369C28.3718 31.779 26.9218 31.579 25.7318 30.859L14.8118 23.979L14.7618 23.929C12.2718 22.439 9.27183 22.129 6.53183 23.059C4.20183 23.879 2.23183 25.529 1.09183 27.729C-0.0481734 29.939 -0.308175 32.449 0.371825 34.819C1.09183 37.339 2.75183 39.389 5.03183 40.619C6.48183 41.389 8.08183 41.799 9.69183 41.799C10.6218 41.799 11.5518 41.649 12.4918 41.389C15.1318 40.619 17.3118 38.769 18.5018 36.309L18.6618 35.899L14.6218 33.329L14.2118 34.309C13.5918 35.539 12.5018 36.519 11.1618 36.879C9.87183 37.239 8.52183 37.139 7.33183 36.469C6.14183 35.849 5.31183 34.779 4.95183 33.489C4.23183 31.019 5.67183 28.349 8.11183 27.479C8.63183 27.269 9.20182 27.219 9.71182 27.219C10.5918 27.219 11.4718 27.479 12.2518 27.939L23.1718 34.869H23.2218C24.7718 35.789 26.4818 36.259 28.2918 36.259C29.2218 36.259 30.1518 36.109 31.0918 35.849C35.3918 34.569 38.0818 30.659 38.0318 26.399V0.46897C37.9618 0.10897 37.6018 -0.101028 37.2918 0.0489718Z" fill="#EFEFEF"/>
    </svg>
  );
}

export default LogoLarge;
