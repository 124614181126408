import React from 'react';
import { TableProps } from '../../types';
import { tableStyles, StyledTable } from '../../styled';
import { Row, useTable, useResizeColumns, useFlexLayout, useRowSelect } from 'react-table';
import { ITrack } from '../../types';
import { ViewportType } from '../../../../../../common/src/Util/responsive';

export const Table = ({ columns, data, highlightId, viewport }: TableProps) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useResizeColumns,
    useFlexLayout,
    useRowSelect
  );

  const generateRowStyle = (row: Row<ITrack>) => {
    let rowClasses = ['track-list-row'];

    if (viewport === ViewportType.Mobile) {
      rowClasses.push('mobile-track-row');
    }

    if (row.original.track.id === highlightId) {
      rowClasses.push('highlighted-track-row');
    }

    if (row.original.forbidden) {
      rowClasses.push('forbidden-row');
    }

    return rowClasses.join(' ');
  };

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps({
                  style: tableStyles,
                })}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={generateRowStyle(row)} data-testid={`track-list-row-${i}`}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps({
                      style: tableStyles,
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
