import styled from 'styled-components/macro';

export const Gradient = styled.div`
  border-radius: 0.25rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(${({ colors }: { colors: string[] }) => `145deg, ${colors[0]}, ${colors[1]}`});
  opacity: 0.9;
`;
