import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

export const Header = styled.header`
  align-items: center;
  background-color: ${theme.colors.bodyBg};
  display: flex;
  grid-area: topnav;
  padding-right: ${theme.space.md};

  ${theme.mediaQueries.extraSmall} {
    padding-right: ${theme.space.lg};
  }
`;

export const LogoWrapper = styled.div`
  align-items: center;
  background-color: ${theme.colors.primary};
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: ${theme.space.md};
  min-width: 48px;

  svg {
    transform: scale(0.75);
  }

  ${theme.mediaQueries.extraSmall} {
    margin-right: ${theme.space.lg};
    min-width: 64px;

    svg {
      transform: none;
    }
  }
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  margin-right: ${theme.space.md};

  div {
    cursor: pointer;
    display: flex;
    margin-left: -${theme.space.sm};
    position: relative;
    width: 32px;

    :first-child {
      margin-right: ${theme.space.sm};
    }

    :last-child svg {
      transform: rotate(180deg) scale(1.5);
    }

    :hover svg path {
      fill: ${theme.colors.primary};
    }
  }

  svg {
    pointer-events: none;
    transform: scale(1.5);
  }
`;

export const FilterWrapper = styled.div`
  margin-left: auto;

  > div:first-child {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const MobileSearch = styled.div`
  position: relative;
`;

export const MobileSearchIcon = styled.div`
  display: flex;

  svg {
    cursor: pointer;
    fill: none;
    stroke: ${theme.colors.light1};

    :hover {
      stroke: ${theme.colors.primary};
    }
  }
`;
