import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  labeltext?: string;
}

const Instrumental = (props: Props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="instrumental-icon"
      {...props}
    >
      <title id="instrumental-icon">
        {props.labeltext ? props.labeltext : "Instrumental"}
      </title>
      <path
        d="M25.609 28.1253C24.3883 28.5362 23.0444 28.3341 21.9985 27.5824C20.9526 26.8307 20.3327 25.6213 20.333 24.3333V22.6666"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6958 18.6666C21.4786 16.9558 23.3478 16.0217 25.186 16.4229C27.0241 16.824 28.3343 18.4519 28.3331 20.3333V24.3333C28.3339 24.9086 28.21 25.4772 27.9698 26"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9731 26.9947C29.4311 26.1827 29.6701 25.2656 29.6665 24.3333V23"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9997 23V24.3333C18.9871 25.7516 19.545 27.1155 20.5479 28.1184C21.5509 29.1214 22.9147 29.6792 24.3331 29.6667C25.2615 29.6704 26.175 29.4334 26.9844 28.9787"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.333 29.6666V31.6666"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6665 16.6666L31.3332 31.3333"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Instrumental;
