import * as React from "react";

function SvgRepeat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M28.704 16l-3.424-3.936L21.856 16h2.88c0 4.8-3.936 8.736-8.736 8.736-2.56 0-4.896-1.056-6.624-2.976-.32-.416-.736-.416-1.152-.224-.32.32-.32.768-.128 1.184 1.92 2.24 4.8 3.616 7.808 3.616 5.632 0 10.336-4.576 10.336-10.336h2.464zm-21.44 0c0-4.8 3.936-8.736 8.736-8.736 2.464 0 4.896 1.056 6.496 2.88.32.32.864.32 1.184.096.32-.32.32-.736.096-1.184-1.92-2.24-4.8-3.392-7.68-3.392C10.464 5.664 5.76 10.24 5.76 16H3.296l3.424 3.936L10.144 16h-2.88z" />
    </svg>
  );
}

export default SvgRepeat;
