import * as React from "react";

function SvgGoogle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 27 32" {...props}>
      <path d="M13.728 14.048h12.928q.224 1.184.224 2.272 0 3.872-1.632 6.912T20.608 28t-6.88 1.728q-2.816 0-5.344-1.088T4 25.728t-2.912-4.384T0 16t1.088-5.344T4 6.272 8.384 3.36t5.344-1.088q5.344 0 9.184 3.616l-3.744 3.584q-2.176-2.144-5.44-2.144-2.304 0-4.256 1.184t-3.104 3.136T5.216 16t1.152 4.352 3.104 3.136 4.256 1.184q1.536 0 2.848-.448t2.144-1.056 1.472-1.472.896-1.568.416-1.376h-7.776v-4.704z" />
    </svg>
  );
}

export default SvgGoogle;
