import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { Hit } from 'react-instantsearch-core';
import { connectStateResults, connectHits } from 'react-instantsearch-dom';
import { Box, Heading } from 'grommet';
import { TileRow } from '../../../Styled';

type Ninety9LivesHit<T> = Hit<T> & { guid: string };

interface PublicProps<T> {
  title: string;
  hitComponent: React.ComponentType<{ hit: Ninety9LivesHit<T> }>;
  areTracksLoading: boolean;
}

interface SearchProps<T> {
  hits: Ninety9LivesHit<T>[];
  searching: boolean;
}

type Props<T> = SearchProps<T> & PublicProps<T>;

export function SearchListImplementation<T>(props: Props<T>) {
  const { title, hitComponent, hits, searching, areTracksLoading } = props;
  const Component = hitComponent;

  const items = hits.map((hit, i) => <Component key={hit.guid} hit={hit} />);

  if (searching || areTracksLoading) {
    // We'll display a loading spinner within the <SearchMusicList/> component.
    return null;
  }

  return (
    <>
      <Heading level="2">{title}</Heading>
      {hits.length > 0 ? (
        <TileRow gutter={15} tileWidth={160}>
          {items}
          {/* These are here to ensure there's always empty spaceholders in the row, so icons don't get crazy */}
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </TileRow>
      ) : (
        <Box pad={{ bottom: 'medium' }}>No Results</Box>
      )}
    </>
  );
}

export const SearchList = compose(connectHits, connectStateResults)(SearchListImplementation);
