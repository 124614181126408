import * as React from "react";

function SvgChristmasTreeTop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M18.228 20a.5.5 0 00-.014-.4L16.7 16.485a.5.5 0 00-.625-.25L5.8 20.088a1 1 0 00-.559.521l-.916 2.011a1.012 1.012 0 00.088.928.976.976 0 00.821.452h2.619a1 1 0 00.351-.064l9.736-3.65a.5.5 0 00.288-.286zM6.866 18.087l8.466-3.175a.5.5 0 00.274-.686l-1.367-2.817a.5.5 0 00-.626-.25l-4.5 1.688a1 1 0 00-.559.521l-2 4.381a.251.251 0 00.045.275.248.248 0 00.267.063zM19.665 22.589l-.355-.731a.5.5 0 00-.625-.25L13.6 23.516a.25.25 0 00.088.484h5.083a.979.979 0 00.823-.452 1.031 1.031 0 00.071-.959zM11.785 7h-.006a.5.5 0 00-.449.292l-1.47 3.216a.25.25 0 00.315.338l2.693-1.01a.5.5 0 00.274-.686l-.907-1.868a.5.5 0 00-.45-.282zM8.68 6.35c.459.458 1.568.134 2.787-.714a.5.5 0 01.571 0c1.219.848 2.327 1.172 2.786.714s.134-1.567-.714-2.785a.5.5 0 010-.572c.848-1.217 1.172-2.327.714-2.785s-1.567-.134-2.786.714a.5.5 0 01-.571 0C10.248.074 9.139-.25 8.68.208s-.133 1.568.72 2.785a.5.5 0 010 .572c-.853 1.218-1.177 2.328-.72 2.785z" />
    </svg>
  );
}

export default SvgChristmasTreeTop;
