import * as React from 'react';

const SvgCompactArtists = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.6248 25.0164C29.6248 26.5082 29.0322 27.9389 27.9773 28.9937C26.9225 30.0486 25.4918 30.6412 24 30.6412C22.5082 30.6412 21.0776 30.0486 20.0227 28.9937C18.9679 27.9389 18.3752 26.5082 18.3752 25.0164V19.3916C18.3752 17.8998 18.9679 16.4692 20.0227 15.4143C21.0776 14.3595 22.5082 13.7668 24 13.7668C25.4918 13.7668 26.9225 14.3595 27.9773 15.4143C29.0322 16.4692 29.6248 17.8998 29.6248 19.3916V25.0164Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5003 23.1414V25.0163C16.4914 26.0036 16.6793 26.9829 17.0531 27.8968C17.4268 28.8107 17.9789 29.641 18.6771 30.3392C19.3753 31.0374 20.2056 31.5894 21.1195 31.9632C22.0334 32.3369 23.0127 32.5249 24 32.516C24.9873 32.5247 25.9665 32.3367 26.8803 31.9629C27.7942 31.5891 28.6244 31.0371 29.3226 30.3389C30.0208 29.6407 30.5729 28.8105 30.9467 27.8966C31.3205 26.9828 31.5085 26.0036 31.4997 25.0163V23.1414"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24 32.5161V35.3285" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5002 25.0164H31.4996" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29.6249 19.3916H25.875" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29.6249 21.2666H25.875" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29.6249 23.1414H25.875" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SvgCompactArtists;
