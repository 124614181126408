import * as React from "react";

function Expand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path d="M0,23a1,1,0,0,0,1,1H6a1,1,0,0,0,.707-1.707l-1.439-1.44a.249.249,0,0,1,0-.353l3.616-3.616a1.25,1.25,0,1,0-1.768-1.768L3.5,18.731a.25.25,0,0,1-.354,0L1.707,17.292A1,1,0,0,0,0,18Z" />
      <path d="M24,1a1,1,0,0,0-1-1H18a1,1,0,0,0-.707,1.707l1.439,1.439a.25.25,0,0,1,0,.354L15.116,7.115a1.25,1.25,0,0,0,1.768,1.768L20.5,5.267a.25.25,0,0,1,.354,0l1.439,1.439A1,1,0,0,0,23,7a.987.987,0,0,0,.383-.076A1,1,0,0,0,24,6Z" />
      <path d="M0,6a1,1,0,0,0,.617.924A.987.987,0,0,0,1,7a1,1,0,0,0,.707-.293L3.323,5.09,7.116,8.883A1.25,1.25,0,1,0,8.884,7.115L5.268,3.5a.25.25,0,0,1,0-.354L6.707,1.706A1,1,0,0,0,6,0H1A1,1,0,0,0,0,1Z" />
      <path d="M24,18a1,1,0,0,0-1.707-.707l-1.439,1.439a.25.25,0,0,1-.354,0l-3.616-3.616a1.25,1.25,0,1,0-1.768,1.768L18.732,20.5a.249.249,0,0,1,0,.353l-1.439,1.44A1,1,0,0,0,18,24h5a1,1,0,0,0,1-1Z" />
    </svg>
  );
}

export default Expand;
