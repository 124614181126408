import styled from 'styled-components/macro';
import { theme } from '../../../../../../Styled';

export const AudioOutputWrapper = styled.div`
  margin: 10px;
`;
export const AudioOutputTitle = styled.p`
  color: ${theme.colors.baseWhite};
  font-size: 14px;
  margin-bottom: 5px;
`;
export const AudioOutputRefresh = styled.button`
  color: ${theme.colors.baseWhite};
  background: transparent;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    color: ${theme.colors.brandGreen2};
  }
`;
export const AudioSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
export const SelectWrapper = styled.div`
  width: 98%;
`;
