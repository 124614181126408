import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { WRITE_TRACK_INFO, WriteTrackInfoParams } from '../IPC/IPCRequest';
import { SettingsContext, SettingsKeys, withSettingsContext } from '../Settings/Settings';
import { getNowPlayingStringForFileOutput, joinStringsGrammatically, toUrl } from '../../Util';
import { PlayerContext, withPlayerContext } from '../Player/PlayerContext';
import { PlatformContext, withPlatformContext } from '../Platform';

type PropsFromPlatform = Pick<PlatformContext, 'sendIPC'>;

type PropsFromSettings = Pick<
  SettingsContext,
  | SettingsKeys.WriteCoverFile
  | SettingsKeys.WriteCoverToFile
  | SettingsKeys.WriteToFilePausedFormat
  | SettingsKeys.WriteToFilePaused
  | SettingsKeys.WriteToFileFormat
  | SettingsKeys.WriteTrackInfoToFile
  | SettingsKeys.WriteTrackInfoFile
  | SettingsKeys.WriteTrackJsonToFile
  | SettingsKeys.WriteTrackJsonFile
>;

type PropsFromPlayer = Pick<PlayerContext, 'playToken' | 'playing'>;

type Props = PropsFromPlayer & PropsFromSettings & PropsFromPlatform;

export class SongStatusWriterPresentation extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>): void {
    const {
      writeTrackInfoToFile,
      writeCoverToFile,
      writeTrackInfoFile,
      writeCoverFile,
      writeTrackJsonToFile,
      writeTrackJsonFile,
      playToken,
      playing,
    } = this.props;
    if (writeTrackInfoToFile || writeCoverToFile || writeTrackJsonFile) {
      const params: WriteTrackInfoParams = { type: WRITE_TRACK_INFO };
      if (writeTrackInfoToFile) {
        params.filename = writeTrackInfoFile;
        const { writeToFileFormat, writeToFilePausedFormat, writeToFilePaused } = this.props;
        params.contents = getNowPlayingStringForFileOutput(playToken, writeToFileFormat, playing, writeToFilePaused, writeToFilePausedFormat);
      }
      if (writeCoverToFile) {
        params.coverFilename = writeCoverFile;
        params.coverURL = this.getImageUrl();
      }
      if (writeTrackJsonToFile && playToken) {
        params.jsonFilename = writeTrackJsonFile;
        params.jsonString = JSON.stringify({
          track: {
            title: playToken.track.title,
            artistsString: joinStringsGrammatically(playToken.track.artists.map(artist => artist.name)),
            artists: playToken.track.artists.map(artist => ({
              name: artist.name,
            })),
            release: { title: playToken.track.album?.title },
            liked: playToken.track.self.liked,
          },
          player: {
            playing,
          },
        });
      }
      this.props.sendIPC(WRITE_TRACK_INFO, { params });
    }
  }

  public render(): React.ReactDOM {
    return null;
  }

  private getImageUrl = (): string => {
    const { playToken } = this.props;
    if (playToken) {
      return toUrl(playToken.track.artworkGuid);
    }
    return '';
  };
}

function mapPlayerToProps(c: PlayerContext): PropsFromPlayer {
  return {
    playToken: c.playToken,
    playing: c.playing,
  };
}

function mapSettingsToProps(c: SettingsContext): PropsFromSettings {
  return {
    [SettingsKeys.WriteTrackInfoToFile]: c[SettingsKeys.WriteTrackInfoToFile],
    [SettingsKeys.WriteTrackInfoFile]: c[SettingsKeys.WriteTrackInfoFile],
    [SettingsKeys.WriteTrackJsonToFile]: c[SettingsKeys.WriteTrackJsonToFile],
    [SettingsKeys.WriteTrackJsonFile]: c[SettingsKeys.WriteTrackJsonFile],
    [SettingsKeys.WriteToFileFormat]: c[SettingsKeys.WriteToFileFormat],
    [SettingsKeys.WriteToFilePaused]: c[SettingsKeys.WriteToFilePaused],
    [SettingsKeys.WriteToFilePausedFormat]: c[SettingsKeys.WriteToFilePausedFormat],
    [SettingsKeys.WriteCoverToFile]: c[SettingsKeys.WriteCoverToFile],
    [SettingsKeys.WriteCoverFile]: c[SettingsKeys.WriteCoverFile],
  };
}

function mapPlatformToProps(sl: PlatformContext): PropsFromPlatform {
  return {
    sendIPC: sl.sendIPC,
  };
}

export const SongStatusWriter = compose(
  withPlatformContext(mapPlatformToProps),
  withPlayerContext(mapPlayerToProps),
  withSettingsContext(mapSettingsToProps)
)(SongStatusWriterPresentation);
