import React, { useContext } from 'react';
import { StyledCompactNowPlaying } from './styled';
import { Box } from 'grommet';
import playerContext, { PlayerContext } from '@pretzel-aux/common/src/Core/Player/PlayerContext';
import { LargeAlbumArt } from '@pretzel-aux/common/src/Core/Player/Components/LargeAlbumArt/LargeAlbumArt';
import { TrackInfo } from '@pretzel-aux/common/src/Core/Player/Components/TrackInfo/TrackInfo';
import { CompactActiveBar } from '../CompactActiveBar';

export const CompactNowPlaying = () => {
  const { playToken } = useContext<PlayerContext>(playerContext);

  return (
    <StyledCompactNowPlaying>
      <Box style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <CompactActiveBar />
        <LargeAlbumArt playToken={playToken} />
        <TrackInfo />
      </Box>
    </StyledCompactNowPlaying>
  );
};
