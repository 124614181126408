import * as React from 'react';
import { Box } from 'grommet';
import { QueryControls } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router';
import { flowRight as compose } from 'lodash';
import moment from 'moment';
import { theme } from '../../Styled';
import { StationsListQuery } from '../StationsList/types/StationsListQuery';
import { ArtistsListQuery } from '../ArtistsList/types/ArtistsListQuery';
import { TopPlayedStations } from './Components/TopPlayedStations';
import { PopularArtists } from './Components/PopularArtists';
import { TrendingLabels } from './Components/TrendingLabels';
import { Footer } from '../Footer';
import { Carousel } from '../Carousel';
import { CarouselProps } from '../Carousel/types';
import { PretzelJwt, URLS, withPlatformContext } from '../../Core/Platform';
import { Countdown } from '../Countdown';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';

// Carousel Images
import PremiumImage from '../../../../assets/images/heroCarousel/Premium.jpg';
import BlankAndJonesImage from '../../../../assets/images/heroCarousel/BlankAndJones.png';
import ChillBeatsImage from '../../../../assets/images/heroCarousel/Chillbeats.jpg';
import HopelessRecordsImage from '../../../../assets/images/heroCarousel/HopelessRecords.jpg';

interface GraphqlProps {
  data: QueryControls & StationsListQuery & ArtistsListQuery;
}

type PropsFromPlatform = {
  userTier: PretzelJwt['tier'];
};

type Props = GraphqlProps & RouteComponentProps & PropsFromPlatform;

const HomePresentation = ({ history, userTier }: Props) => {
  const { width } = useWindowSize();
  const isBeforeEndOfDec = moment().isBefore('2021-12-31 23:59');
  const heroSlides: CarouselProps['heroSlides'] = [
    {
      title: 'Chill Beats',
      type: 'Label',
      description: 'Chill Beats Music releases the smoothest lofi hip-hop, jazzhop, chillhop, & instrumental beats',
      button: {
        color: 'secondary',
        label: 'Explore label',
        onClick: () => history.push('/segment/66sJEyo'),
      },
      guid: '66sJEyo',
      image: ChillBeatsImage,
    },
    {
      title: 'Blank & Jones',
      type: 'Artist',
      description: 'Blank & Jones are living legends in the electronic music genre',
      button: {
        color: 'secondary',
        label: 'Explore artist',
        onClick: () => history.push('/segment/DKTXAeB'),
      },
      guid: 'DKTXAeB',
      image: BlankAndJonesImage,
    },
    {
      title: 'Hopeless Records',
      type: 'Label',
      description: 'Making a positive impact through music',
      button: {
        color: 'secondary',
        label: 'Explore label',
        onClick: () => history.push('/segment/WJsQX8E'),
      },
      guid: 'WJsQX8E',
      image: HopelessRecordsImage,
    },
    {
      title: 'Did someone say exclusive?',
      type: 'Premium',
      description: 'Upgrade to Pretzel Premium and gain exclusive access to our entire music catalog and more!',
      button: {
        color: 'secondary',
        label: 'Get Premium',
        externalLink: URLS.PRICING,
      },
      image: PremiumImage,
      showPlay: false,
    },
  ];

  return (
    <Box overflow="hidden auto" pad={theme.space.lg}>
      {userTier === 'free' && isBeforeEndOfDec && <Countdown />}
      {width >= theme.appBreakpoints.large && <Carousel heroSlides={heroSlides} id="hero-carousel" variant="hero" />}
      <TopPlayedStations />
      <PopularArtists />
      <TrendingLabels />
      <Footer />
    </Box>
  );
};

export const Home = compose(
  withPlatformContext(c => ({
    userTier: c.pretzelUser.jwt.tier,
  }))
)(HomePresentation);
