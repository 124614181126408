import * as React from 'react';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { Box, Text } from 'grommet';
import { Heading } from '../../../../common/src/Components/Heading';
import { TrackList } from '../TrackList';
import { LikedTracksQuery } from './types/LikedTracksQuery';
import { Footer } from '../Footer';
import { LoadingSpinner } from '../LoadingSpinner';
import PageWrapper from '../../Styled/Page';

const likedTracksQuery = loader('./liked-tracks.graphql');

interface GraphqlProps {
  data: QueryControls & LikedTracksQuery;
}

type Props = GraphqlProps;
interface State {
  loadingMore: boolean;
}

export class LikedTracksListPresentation extends React.Component<Props, State> {
  state: State = {
    loadingMore: false,
  };

  public render() {
    const tracks = this.props.data.likedTracks && this.props.data.likedTracks.edges.map(edge => edge.node);

    if (this.props.data.loading) {
      return <LoadingSpinner />;
    }

    if (tracks && tracks.length > 0) {
      // Due to caching, a user can dislike a track in the player and it won't remove itself from the track
      // component, so we can filter here to solve that issue
      const filteredTracks = tracks.filter(track => track.self.liked);

      return (
        <PageWrapper title="Likes">
          <TrackList
            title="Likes"
            tracks={filteredTracks}
            hasMore={this.props.data.likedTracks && this.props.data.likedTracks.pageInfo.hasNextPage}
            onMore={this.onMore}
            loading={this.props.data.loading}
            loadingMore={this.state.loadingMore}
          />
        </PageWrapper>
      );
    }

    return (
      <Box pad="medium" height="100%">
        <Box align="center" justify="center" height="100%" width="100%" style={{ flexGrow: 1 }}>
          <Heading variant="H1">You haven't liked anything!</Heading>
          <Text>
            Clicking <i className="pretzel-icon-thumbs_up" /> will add a song to this list. Listen to all of your liked songs on the Liked station.
          </Text>
        </Box>
        <Footer />
      </Box>
    );
  }

  private onMore = () => {
    const after = this.props.data.likedTracks.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'track',
      currentLength: this.props.data.likedTracks.edges.length,
      after,
    });
    this.setState({ loadingMore: true });
    this.props.data.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: LikedTracksQuery, { fetchMoreResult }) => {
        this.setState({ loadingMore: false });
        if (!fetchMoreResult) {
          return previousQueryResult;
        }

        return {
          likedTracks: Object.assign({}, fetchMoreResult.likedTracks, {
            edges: [...previousQueryResult.likedTracks.edges, ...fetchMoreResult.likedTracks.edges],
          }),
        };
      },
    });
  };
}

export const LikedTracksList = compose(
  graphql(likedTracksQuery, {
    options: {
      variables: {
        after: '',
      },
      fetchPolicy: 'network-only',
    },
  })
)(LikedTracksListPresentation);
