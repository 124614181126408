import * as React from 'react';
import { InputWrapper, NumberInput, SelectorWrapper } from './Styles';
import { theme } from '../../../../Styled';

interface PublicProps {
  max: number;
  min: number;
  onChange: (value: number | null) => void;
  pattern: any;
  title: string;
  units: string;
  value: number;
}

type Props = PublicProps;

export class NumberSettingPresentation extends React.Component<Props> {
  state = {};

  public render() {
    return (
      <>
        <label>{this.props.title}</label>
        <SelectorWrapper>
          <InputWrapper className="no-drag">
            <NumberInput
              // readOnly
              min={this.props.min || 0}
              max={this.props.max}
              value={this.props.value}
              onChange={this.props.onChange}
              format={num => `${num} ${this.props.units}`}
              pattern={this.props.pattern}
              inputMode="numeric"
              strict
              snap
              style={{
                wrap: {
                  width: '100%',
                },
                'input:not(.form-control)': {
                  paddingLeft: 10,
                },
                arrowUp: {
                  borderBottomColor: theme.colors.baseWhite,
                },
                arrowDown: {
                  borderTopColor: theme.colors.baseWhite,
                },
                btn: {
                  cursor: 'pointer',
                  // @ts-ignore
                  WebkitAppRegion: 'no-drag',
                  background: 'transparent',
                  boxShadow: 'none',
                },
                'btn:active': {
                  boxShadow: 'none',
                },
                input: {
                  color: 'transparent',
                  textShadow: '0 0 0 #fff',
                },
              }}
            />
          </InputWrapper>
        </SelectorWrapper>
      </>
    );
  }
}

export const NumberSetting = NumberSettingPresentation;
