import * as React from 'react';
import { PlayTokenFragment } from '../Core/Player/Queries/types/PlayTokenFragment';
import { BasicTrackArtistFragment } from '../Core/Player/Queries/types/BasicTrackArtistFragment';

export function formatTime(time: number | null): string {
  if (time === null) return '--:--';
  const minutes = (time - (time % 60)) / 60;
  const seconds = time % 60;
  const secondsString = seconds > 9 ? seconds : `0${seconds}`;
  return `${minutes}:${secondsString}`;
}

export function joinStringsGrammatically(items: string[]): string {
  if (items.length < 2) return items.toString();
  const last = items.pop();
  return `${items.join(', ')} & ${last}`;
}

export function joinDomsGrammatically(items: React.ReactNode[]): React.ReactNode {
  switch (items.length) {
    case 0:
    case 1:
      return items;
    case 2:
      items.splice(1, 0, ' & ');
      return items;
    default:
      return items.reduce<React.ReactNode[]>((accumulator, value, index) => {
        if (index === items.length - 1) {
          accumulator.push(' & ');
        } else if (index !== 0) {
          accumulator.push(', ');
        }
        accumulator.push(value);
        return accumulator;
      }, [] as React.ReactNode[]);
  }
}

export function minutesOrHours(seconds: number): string {
  const minutes = seconds / 60;
  const hours = minutes / 60;
  if (hours > 2) {
    return `${Math.floor(hours)} hours`;
  }
  return `${Math.floor(minutes)} minutes`;
}

export function getNowPlayingStringForFileOutput(
  playToken: PlayTokenFragment | null,
  writeToFileFormat: string,
  playing: boolean,
  writeToFilePaused: boolean,
  writeToFilePausedFormat: string
): string {
  if (playToken) {
    const trackName = playToken.track.title;
    const artists = joinStringsGrammatically(playToken.track.artists.map((artist: BasicTrackArtistFragment) => artist.name));
    const albumName = playToken.track.album ? playToken.track.album.title : '';
    let format = writeToFileFormat || '{title} - {artist}';
    if (writeToFilePaused && !playing) {
      format = writeToFilePausedFormat;
    }
    return format
      .replace(/{artist}/g, artists)
      .replace(/{title}/g, trackName)
      .replace(/{album}/g, albumName)
      .replace(/{pretzel}/g, '🥨');
  }
  return '';
}
