import React, { useEffect } from 'react';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { theme } from '../../../Styled';
import { flowRight as compose } from 'lodash';
import { Hit } from 'react-instantsearch-core';
import aa from 'search-insights';
import { connectStateResults, connectHits, connectPagination } from 'react-instantsearch-dom';
import { Track } from '../schema';
import { Box, Heading } from 'grommet';
import { loader } from 'graphql.macro';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TrackList } from '../../TrackList';
import { SaturateTracksQuery } from '../types/SaturateTracksQuery';
import { BasicTrackFragment } from '../../../Core/Player/Queries/types/BasicTrackFragment';

const saturateQuery = loader('../search.graphql');

interface PublicProps {
  title: string;
  setTracksLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SearchProps {
  hits: Hit<Track>[];
  searching: boolean;
  currentRefinement: number;
  nbPages: number;
  refine: (refinement: number) => void;
}

interface GraphqlProps {
  data: QueryControls & SaturateTracksQuery;
}

type Props = SearchProps & PublicProps & GraphqlProps;

export function SearchMusicListImplementation(props: Props) {
  const { title, searching, data, hits, setTracksLoading } = props;
  let tracks: BasicTrackFragment[] = [];
  if (!data.loading && !data.error && data.saturateTracks) {
    tracks = data.saturateTracks;
  }

  useEffect(() => {
    // Lifting this state up a level to inform the <SearchList/> component not to render while tracks are loading in.
    setTracksLoading(data.loading);
  }, [data.loading]);

  function handleTrackClicked(track: BasicTrackFragment) {
    let objectID = '';
    let queryID = '';
    let position = 0;

    for (let h of hits) {
      if (h.guid === track.id) {
        objectID = h.objectID;
        // @ts-ignore The __queryID and __position properties of hit are not listed in the types but they exist
        queryID = h.__queryID;
        // @ts-ignore
        position = h.__position;
        break;
      }
    }

    if (objectID && queryID) {
      aa('clickedObjectIDsAfterSearch', {
        eventName: 'track_played_from_search',
        objectIDs: [objectID],
        queryID,
        index: 'prod_tracks',
        positions: [position],
      });
    }
  }

  if (searching || data.loading) {
    return <LoadingSpinner variant="static" />;
  }

  return (
    <div style={{ margin: '-30px' }}>
      {/* Tracklist has a 30px padding that we have to counter */}
      {tracks && tracks.length ? (
        <Box style={{ overflow: 'auto', padding: theme.space.lg }}>
          <TrackList
            title={title}
            tracks={tracks}
            loading={searching || data.loading}
            hasMore={false}
            loadingMore={false}
            onMore={() => false}
            clickNotification={handleTrackClicked}
            // commenting out the 'more' for now
            // hasMore={currentRefinement < nbPages}
            // loadingMore={searching || data.loading}
            // onMore={() => { refine(currentRefinement+1); }}
          />
        </Box>
      ) : (
        <Box pad="30px">
          <Heading level="2">{title}</Heading>
          <Box>No Results</Box>
        </Box>
      )}
    </div>
  );
}

export const SearchMusicList = compose(
  connectPagination,
  connectHits,
  connectStateResults,
  graphql(saturateQuery, {
    options: (props: SearchProps) => {
      const searchId = props.hits.map(h => h.guid).join(',');
      return {
        variables: {
          guids: searchId,
        },
      };
    },
  })
)(SearchMusicListImplementation);
