import React, { FC } from 'react';
import { StyledHeading, StyledSection, StyledSectionHeader } from './styled';
import { SectionProps } from './types';

export const Section: FC<SectionProps> = ({ children, title, viewMoreLink, ...rest }) => {
  const filteredTitle = title.replace(/[\W]*\S+[\W]*$/, '');
  const lastWordOfTitle = title.split(' ').pop();

  return (
    <StyledSection {...rest}>
      <StyledSectionHeader>
        <a href={viewMoreLink}>
          <StyledHeading variant="H2">
            {filteredTitle} <strong>{lastWordOfTitle}</strong>
          </StyledHeading>
        </a>
      </StyledSectionHeader>

      {children}
    </StyledSection>
  );
};
