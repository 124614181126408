import * as React from "react";

export default function ElectronicsResistor(
  props: React.SVGProps<SVGSVGElement>
) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M14.5,20a1,1,0,0,1-.984-.825l-1.27-7.109a.25.25,0,0,0-.492,0l-1.27,7.109a1,1,0,0,1-1.968,0L7.216,11.9a.25.25,0,0,0-.492,0l-.242,1.287A1,1,0,0,1,5.5,14L1,14.012H1a1,1,0,0,1,0-2L4.463,12a.25.25,0,0,0,.245-.2l1.31-6.983A1,1,0,0,1,7,4H7a1,1,0,0,1,.98.824l1.27,7.109a.25.25,0,0,0,.492,0l1.27-7.109a1,1,0,0,1,1.968,0l1.27,7.109a.25.25,0,0,0,.492,0l1.27-7.109A1,1,0,0,1,17,4h0a1,1,0,0,1,.982.815l1.31,6.983a.25.25,0,0,0,.245.2L23,12.012a1,1,0,0,1,0,2h0L18.5,14a1,1,0,0,1-.979-.816L17.276,11.9a.25.25,0,0,0-.492,0l-1.3,7.276A1,1,0,0,1,14.5,20Z" />
    </svg>
  );
}
