import { useMutation } from '@apollo/client';
import checkUsername from '@pretzel-aux/common/src/Api/check-username';
import setUsername from '@pretzel-aux/common/src/Api/set-username';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';
import settingsContext, { SettingsContext } from '@pretzel-aux/common/src/Core/Settings/Settings';
import { theme } from '@pretzel-aux/common/src/Styled';
import { loader } from 'graphql.macro';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledLabelAndInput, StyledProfileContent } from './Styles';
import { StyledCheckboxWrapper, StyledCheckbox, StyledCheck } from '../../Styles';
import platformContext, { PlatformContext } from '../../../../Core/Platform';
import { RefreshAccount } from '../../../../Core/Settings/Queries/types/RefreshAccount';

const RefreshAccountQuery = loader('../../../../Core/Settings/Queries/refresh-jwt.graphql');

export const Profile = () => {
  const pc = useContext<PlatformContext>(platformContext);
  const sc = useContext<SettingsContext>(settingsContext);
  const [refreshAccount] = useMutation<RefreshAccount, {}>(RefreshAccountQuery);
  const [showAffirmations, setShowAffirmations] = useState<boolean>(false);
  const [displayNameEdited, setDisplayNameEdited] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>(pc.pretzelUser?.jwt.display_name || 'Your name here');
  const [nameAvailable, setNameAvailable] = useState<boolean>(false);

  useEffect((): void => {
    setShowAffirmations(sc.dailyAffirmations);
  }, [sc.dailyAffirmations]);

  useEffect((): void => {
    if (displayNameEdited) {
      checkName(displayName);
    }
  }, [displayName]);

  const checkName = async (name: string): Promise<void> => {
    setDisplayNameEdited(true);
    setDisplayName(name);
    try {
      const availabilty = await checkUsername(displayName);
      setNameAvailable(availabilty.available);
    } catch (e) {
      console.error(e);
    }
  };

  const setName = async (): Promise<void> => {
    const setUsernameResponse = await setUsername(displayName);

    if (!setUsernameResponse) {
      const errorMessage = 'Error setting username!';
      console.error(errorMessage);
      toast(errorMessage);
      return;
    }

    const refreshAccountResponse = await refreshAccount();
    const newToken = refreshAccountResponse?.data?.refreshAccount?.token || '';

    pc.saveData('pretzel_app_token', newToken);
    pc.unloadApp();
  };

  const cancel = () => {
    setDisplayNameEdited(false);
    setDisplayName(pc.pretzelUser?.jwt.display_name || 'Your name here');
  };

  return (
    <StyledProfileContent>
      <StyledLabelAndInput>
        Display name
        <input
          value={displayName}
          placeholder={displayName}
          style={{ marginLeft: '1px', marginRight: '1px' }}
          onChange={evt => {
            setDisplayName(evt.currentTarget.value);
            setDisplayNameEdited(true);
          }}
        />
        <span style={{ marginTop: '0.5rem', opacity: displayNameEdited ? 1 : 0 }}>Username {nameAvailable ? 'available' : 'unavailable'}</span>
      </StyledLabelAndInput>
      <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <StyledButton
          color="outline"
          style={{ height: '40px', width: '104px' }}
          onClick={async () => {
            if (nameAvailable) setName();
          }}
        >
          Save
        </StyledButton>
        <StyledButton color="none" onClick={e => cancel()}>
          Cancel
        </StyledButton>
      </div>
      <StyledCheckboxWrapper>
        <StyledCheckbox
          onClick={e => sc.setDailyAffirmations(!showAffirmations)}
          style={{ backgroundColor: showAffirmations ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
        >
          {showAffirmations && <StyledCheck />}
        </StyledCheckbox>
        Show daily affirmations
      </StyledCheckboxWrapper>
    </StyledProfileContent>
  );
};
