import * as React from "react";

function SvgInstrumentSaxophone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.226 11.539L16.189 5.5a1 1 0 00-1.708.707V14.5a1 1 0 01-2 0V13a.25.25 0 00-.25-.25h-1.25a.75.75 0 010-1.5h1.25a.25.25 0 00.25-.25V9.5a.25.25 0 00-.25-.25h-1.25a.75.75 0 010-1.5h1.25a.25.25 0 00.25-.25v-1c0-3.771-5.077-6.5-8.5-6.5H2.606a1.125 1.125 0 000 2.25h1.375a2.5 2.5 0 012.5 2.5V17.5c0 3.463 3.505 6.5 7.5 6.5s7.5-3.037 7.5-6.5V14a1 1 0 01.52-.878 1 1 0 00.225-1.583z" />
    </svg>
  );
}

export default SvgInstrumentSaxophone;
