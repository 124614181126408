import styled from 'styled-components';
import { theme } from '../../Styled';
import { IOption } from './types';

export const StyledFooterText = styled.p`
  margin-bottom: 0;
  a {
    color: ${theme.colors.brandGreen} !important;
  }
`;

export const StyledLabel = styled.label`
  display: inline-block;
  margin-top: ${theme.space.lg};
  margin-bottom: 0.5rem;
  width: 100%;
  color: ${theme.colors.grey};
`;

export const StyledLoader = styled.img`
  border-radius: 10rem;
  display: block;
  margin: 0 auto;
`;

export const StyledOption = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 0.25rem;

  :nth-of-type(1) {
    margin-top: 0.25rem;
  }

  img {
    border-radius: 10rem;
    height: 2rem;
    margin-right: 0.5rem;
    width: 2rem;
  }
`;

export const StyledText = styled.p`
  margin: ${theme.space.md} 0 0 0;
  text-align: center;
  color: ${theme.colors.grey};
`;

export const TextInputFix = styled.div`
  position: relative;
  input {
    user-select: all;
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<{ options: Array<IOption> }>`
  align-items: center;
  background-color: rgba(26, 36, 44, 1);
  flex-wrap: wrap;
  height: 2.75rem;
  width: 100%;
  font-size: 0.95rem;
  color: ${theme.colors.baseWhite};
  background: ${theme.colors.bodyBg};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;

  :focus {
    outline: none;
    border: 1px solid ${theme.colors.brandGreen};
  }

  ${({ options }) => {
    if (options.length > 0) {
      return `
        border-color: ${theme.colors.brandGreen};
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `;
    }
    return '';
  }}
`;

export const StyledSearchButton = styled.button`
  position: absolute;
  right: 1px;
  top: 3px;
  height: 2.25rem;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.offWhite};
  line-height: 15px;
  background-color: ${theme.colors.bodyBg};
  border: none;
  cursor: pointer;
  padding: 0 0.75rem;
`;

export const StyledSearchResults = styled.div`
  position: absolute;
  background: ${theme.colors.bodyBg};
  border: 1px solid ${theme.colors.brandGreen};
  border-top: none;
  box-sizing: border-box;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.5rem 1rem;
  height: 6rem;
  overflow-y: scroll;
  z-index: 1;
  width: 100%;
  top: 43px;
`;

export const StyledValidation = styled.div`
  display: flex;
  margin-top: 3px;

  div,
  p {
    display: flex;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: ${theme.colors.grey};
    margin: 0 6px 0 2px;

    span {
      color: ${theme.colors.red};
      margin-right: 6px;
    }
  }
`;

export const TickIcon = styled.div`
  transform: rotate(45deg);
  height: 0.5rem;
  width: 0.25rem;
  border-bottom: 1.25px solid ${theme.colors.brandGreen};
  border-right: 1.25px solid ${theme.colors.brandGreen};
  margin-right: 8px;
`;
