import { useQuery } from '@apollo/client';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { AlbumCard } from './Components/AlbumCard';
import { AlbumsListQuery } from './types/AlbumsListQuery';
import { LoadingSpinner } from '../LoadingSpinner';
import { MusicList, theme } from '../../Styled';

const albumsGqlQuery = loader('./albums.graphql');

export const AlbumsList = () => {
  const [state, setState] = useState<boolean>(false);
  const response = useQuery<AlbumsListQuery>(albumsGqlQuery, {
    variables: {
      after: '',
    },
  });
  const { width } = useWindowSize();

  const setTileSize = (): number => {
    if (width >= theme.appBreakpoints.small && width < theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.small);
    }
    if (width >= theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.normal);
    }
    return parseInt(theme.card.size.large);
  };

  const setAlbumCardSize = (): string => {
    if (width <= theme.appBreakpoints.medium) {
      return theme.card.size.featured;
    }
    return theme.card.size.large;
  };

  const onMore = (): void => {
    const after = response.data.albums.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'album',
      currentLength: response.data.albums.edges.length,
      after,
    });
    setState(true);

    response.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: AlbumsListQuery, { fetchMoreResult }) => {
        setState(false);
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          albums: Object.assign({}, fetchMoreResult.albums, {
            edges: [...previousQueryResult.albums.edges, ...fetchMoreResult.albums.edges],
          }),
        };
      },
    });
  };

  const albums = response.loading
    ? null
    : response.data.albums.edges.map(albumEdge => <AlbumCard key={albumEdge.node.id} album={albumEdge.node} size={setAlbumCardSize()} />);
  const hasMore = response.data?.albums?.pageInfo?.hasNextPage;

  if (response.loading) {
    return <LoadingSpinner />;
  }

  return <MusicList title="Albums" items={albums} hasMore={hasMore} onMore={onMore} loading={state} tileSize={setTileSize()} />;
};
