import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

const StyledWhatsPlayingContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
`;

const StyledPlusAndMinus = styled.div`
  display: flex;

  button,
  div {
    background: ${theme.colors.bodyBg};
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 40px;
    width: 40px;
    color: ${theme.colors.baseWhite};
  }

  div {
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0;
    align-items: center;
    justify-content: center;
    width: 100px;
  }

  button {
    font-size: 20px;
  }

  button:nth-of-type(1) {
    border-radius: 4px 0 0 4px;
  }

  button:nth-of-type(2) {
    border-radius: 0 4px 4px 0;
  }

  button:hover {
    cursor: pointer;
  }
`;

export { StyledWhatsPlayingContent, StyledPlusAndMinus };
