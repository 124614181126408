import * as React from "react";

function SvgEditPlaylist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 36 32" {...props}>
      <path d="M7.872 7.584h20.672c.512 0 1.056-.384 1.056-1.056s-.384-1.056-1.056-1.056H7.872c-.544.128-1.056.672-1.056 1.184s.512.928 1.056.928zm20.672 5.472H7.872c-.544 0-1.056.416-1.056 1.088S7.2 15.2 7.872 15.2h20.672c.512 0 1.056-.416 1.056-1.056s-.544-1.088-1.056-1.088zm-12 7.488H7.872c-.544 0-1.056.384-1.056 1.056s.384 1.056 1.056 1.056h8.672c.512 0 1.056-.384 1.056-1.056s-.544-1.056-1.056-1.056zm8 5.312c-2.4 0-4.416-1.984-4.416-4.384s2.016-4.416 4.416-4.416 4.384 2.016 4.384 4.416c0 2.4-1.984 4.384-4.384 4.384zm0-7.072c-1.472 0-2.688 1.216-2.688 2.688s1.216 2.656 2.688 2.656c1.472 0 2.656-1.184 2.656-2.656s-1.184-2.688-2.656-2.688z" />
    </svg>
  );
}

export default SvgEditPlaylist;
