import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  labeltext?: string;
}

function SvgExplicit(props: Props) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="explicit-button"
      {...props}
    >
      <title id="explicit-button">
        {props.labeltext ? props.labeltext : "Explicit"}
      </title>
      <rect x="16" y="16" width="16" height="16" rx="1" fill="#EFEFEF" />
      <path
        d="M21.1939 28H26.8579V26.524H22.8979V24.664H26.7739V23.2H22.8979V21.46H26.8579V19.996H21.1939V28Z"
        fill="#232E37"
      />
    </svg>
  );
}

export default SvgExplicit;
