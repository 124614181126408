import { theme } from '@pretzel-aux/common/src/Styled/theme';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${theme.mediaQueries.medium} {
    justify-content: center;
  }
`;

const ControlsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const DesktopAdditionalControls = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0;
`;

export { Wrapper, ControlsWrapper, DesktopAdditionalControls };
