import * as React from 'react';
import { Box } from 'grommet';
import { ClipLoader } from 'halogenium';
import { theme } from './';
import { Button } from '../Components/Button';
import { StyledMenuList } from '../Core/Player/Components/PlayerControls/Components/AddToPlaylist/styled';

interface PublicProps<Thing> {
  circle?: boolean;
  hasMore: boolean;
  items: Thing[] | null;
  loading: boolean;
  onMore: () => void;
  title: string;
}

export class MenuList<Thing> extends React.Component<PublicProps<Thing>> {
  public render() {
    const items = this.props.items === null ? this.renderPlaceholders() : this.props.items;

    return (
      <StyledMenuList>
        {items}
        <Box justify="center" align="center">
          {this.props.hasMore && (
            <Button id="button-menulist-load-more" color="primary" disabled={this.props.loading} onClick={this.props.onMore}>
              Load More
            </Button>
          )}
          {this.props.loading && <ClipLoader color={theme.colors.brandGreen} size="16px" />}
        </Box>
      </StyledMenuList>
    );
  }

  private renderPlaceholders() {
    return [1, 2, 3].map(i => <Box key={i} />);
  }
}
