import * as React from "react";

function SvgHeadphonesCustomerSupportHuman(
  props: React.SVGProps<SVGSVGElement>
) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M24,14a4.493,4.493,0,0,0-3-4.239V9A9,9,0,0,0,3,9v.761a4.5,4.5,0,0,0,0,8.483A1.5,1.5,0,0,0,5,16.83V9A7,7,0,1,1,19,9V16.83a1.494,1.494,0,0,0,.5,1.1V18.5c0,1.474-1.291,2-2.5,2H15.222a2,2,0,1,0,0,2H17c2.65,0,4.5-1.645,4.5-4v-.478A4.5,4.5,0,0,0,24,14Z" />
      <path d="M15.144,14.045a.833.833,0,0,1,.7-.377.823.823,0,0,1,.454.135.833.833,0,0,1,.243,1.154,5.418,5.418,0,0,1-9.08,0,.834.834,0,0,1,1.4-.912,3.75,3.75,0,0,0,6.288,0Z" />
      <circle cx="8.667" cy="9.501" r="1.667" />
      <circle cx="15.333" cy="9.501" r="1.667" />
    </svg>
  );
}

export default SvgHeadphonesCustomerSupportHuman;
