import styled from 'styled-components';

const StyledSubscriptionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-top: 0.5rem;
  }

  margin-top: 1.375rem;
`;

const PlanHeading = styled.div`
  display: flex;
  align-items: center;
`;

const PlanHeadingAdditionalStyles = { fontFamily: 'Sora', fontSize: '1.75rem', lineHeight: '2.1875rem', marginLeft: '0.625rem' };

const UpgradeBtnStyles = { height: '40px', width: '55%' };

const StyledProUpgradeWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

export { StyledSubscriptionContent, PlanWrapper, PlanHeading, PlanHeadingAdditionalStyles, UpgradeBtnStyles, StyledProUpgradeWrapper };
