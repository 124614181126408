import * as React from 'react';
import { Box } from 'grommet';
import { ControlItem } from '../PlayerControls/Components/ControlItem/ControlItem';
import { VolumeControl } from '../PlayerControls/Components/VolumeControl/VolumeControl';
import { theme } from '@pretzel-aux/common/src/Styled/theme';

interface IVolumeProps {
  muted: boolean;
  audioVolume: number;
  setVolume: (vol: number, instant?: boolean, faded?: boolean, visible?: boolean) => void;
  handleMute: (event: React.MouseEvent<HTMLElement>) => void;
  orientation?: string;
  volumeBarVisible: boolean;
  tooltip?: boolean;
}

const Volume = ({ muted, audioVolume, volumeBarVisible, setVolume, handleMute, orientation, tooltip }: IVolumeProps) => {
  const [onHoverColor, setOnHoverColor] = React.useState<string>(theme.colors.offWhite);

  const getMuteName = () => {
    return muted ? 'Mute' : audioVolume > 0.4 ? (audioVolume > 0.8 ? 'Volume3' : 'Volume2') : 'Volume';
  };

  return (
    <Box
      direction="row"
      style={{ alignItems: 'center', position: 'relative' }}
      onMouseEnter={() => setOnHoverColor(theme.colors.brandGreen)}
      onMouseLeave={() => setOnHoverColor(theme.colors.offWhite)}
    >
      <ControlItem isLoading={false} isDisabled={false} name={getMuteName()} onClick={handleMute} size={24} heapid="mute" testid="mute-button" />
      <VolumeControl
        volumeBarVisible={volumeBarVisible}
        setVolume={setVolume}
        volume={audioVolume}
        orientation={orientation}
        color={onHoverColor}
        tooltip={tooltip}
      />
    </Box>
  );
};

export default Volume;
