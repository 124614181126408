import styled from 'styled-components/macro';
import NumericInput from 'react-numeric-input';
import { theme } from '../../../../Styled';
import { StyledInput } from '../../../CurrentUser/Styles';

export const Wrapper = styled.div`
  margin: 10px;
`;
export const Title = styled.p`
  color: ${theme.colors.baseWhite};
  font-size: 14px;
  margin-bottom: 5px;
`;
export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;
export const InputWrapper = styled.div`
  width: 100%;
`;

export const NumberInput = styled(NumericInput)`
  // This is hacky and needs revisited. 
  ${StyledInput}
  color: ${theme.colors.offWhite} !important;
  height: 40px !important;
  margin: 0;
  padding: 0.6875rem 1rem !important;
`;
