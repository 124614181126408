import axios from 'axios';

export const setUsername = async (username: string): Promise<string | undefined> => {
  const body = { username };
  const options = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
    },
  };

  try {
    return await axios.put(`https://api.pretzel.rocks/api/v1/account/username`, body, options);
  } catch (error) {
    return undefined;
  }
};

export default setUsername;
