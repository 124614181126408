import { useState } from 'react';

let cookieDomain = '.pretzel.wtf';

switch (process.env.NODE_ENV) {
  case 'production':
    cookieDomain = '.pretzel.rocks';
    break;
  case 'test':
    cookieDomain = '.netlify.app';
    break;
}

export const getCookieItem = (key: string) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');

const setCookieItem = (key: string, value: string, numberOfDaysTillExpire: number) => {
  const now = new Date();
  now.setTime(now.getTime() + numberOfDaysTillExpire * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/; domain=${cookieDomain}`;
};

export const deleteCookieItem = (key: string) => {
  const now = new Date();
  now.setTime(now.getTime() + -999);
  document.cookie = `${key}=; expires=${now.toUTCString()}; path=/; domain=${cookieDomain}`;
};

type IReturn<T> = [T, (value: T) => void];

export const useCookie = <T>(
  key: string,
  defaultValue: T,
  valueToString: (value: T) => string,
  stringToValue: (value: string) => T,
  numberOfDaysTillExpire: number = 10
): IReturn<T> => {
  const getCookie = () => stringToValue(getCookieItem(key)) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: T) => {
    setCookie(value);
    setCookieItem(key, valueToString(value), numberOfDaysTillExpire);
  };

  return [cookie, updateCookie];
};
