import { fetchRetry } from '../../Util';

export interface AnalyticsUser {
  guid: string;
  hash: string;
  displayName: string;
}
export interface AnalyticsEnv {
  type: string;
  version: string;
  browser: string;
  os: string;
  locale: string;
  versionSha: string;
}

export interface PlayerAnalyticsEventResource {
  source: {
    guid: string;
    type: string;
    name: string;
  };
  token: string;
  guid: string;
  type: AnalyticsEventResourceType;
}

export interface TracklistAnalyticsEventResource {
  type: AnalyticsEventResourceType;
  guid: string;
}

export enum AnalyticsEventResourceType {
  Track = 'track',
}

export interface PlayerResolution {
  height: number;
  width: number;
}

export enum PlayerMode {
  Init = 'Init',
  Compact = 'Compact',
  Library = 'Library',
}

export interface PlayerAnalyticsEvent {
  type: AnalyticsEventType;
  resource: PlayerAnalyticsEventResource;
  source: string;
  state: string;
  volume: number;
  playerMode: PlayerMode;
  playerResolution: PlayerResolution;
}

export interface TracklistAnalyticsEvent {
  type: AnalyticsEventType;
  resource: TracklistAnalyticsEventResource;
  source: string;
}

export enum AnalyticsEventType {
  Dislike = 'dislike',
  Like = 'like',
  End = 'end',
  Heartbeat = 'heartbeat',
  MobileLoad = 'mobileLoad',
  Play = 'play',
  Previous = 'previous',
  Restart = 'restart',
  Skip = 'skip',
  Null = 'null',
  AddToPlaylist = 'addToPlaylist',
  RemoveFromPlaylist = 'removeFromPlaylist',
  Download = 'download',
}

class AnalyticsService {
  private environment?: AnalyticsEnv;
  private user?: AnalyticsUser;

  defineEnvironment = (env: AnalyticsEnv) => {
    this.environment = env;
  };

  defineUser = (user: AnalyticsUser) => {
    this.user = user;
  };

  defineSession = (environment: AnalyticsEnv, user: AnalyticsUser) => {
    this.defineEnvironment(environment);
    this.defineUser(user);
  };

  clearSession = () => {
    this.user = undefined;
  };

  captureEvent = (event: PlayerAnalyticsEvent | TracklistAnalyticsEvent) => {
    const { environment, user } = this;
    const playerEvent = {
      user,
      environment,
      ...event,
    };

    if (process.env.NODE_ENV === 'development') {
      console.debug('Analytics Event', playerEvent);
    } else {
      const requestData: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event: playerEvent }),
        headers: { 'Content-Type': 'application/json' },
      };
      const p = fetchRetry(`https://commander.pretzel.rocks/playerEvent`, requestData);
      p.catch(() => console.warn('Unable to submit playerEvent:', requestData));
    }
  };
}

export const Analytics = new AnalyticsService();
