import { ReactNode } from 'react';
import { ModalButtons } from '../Modal/types';

export enum Step {
  Complete = 'Complete',
  Error = 'Error',
  Loading = 'Loading',
  Search = 'Search',
  // Possible API quota issue
  YoutubeServerError = 500,
}

export interface IContent {
  buttons?: ModalButtons[];
  content: JSX.Element;
  footerContent?: JSX.Element;
  title?: string;
}

export interface IOption {
  channelId: string;
  channelImageUrl: string;
  channelName: string;
}

export interface ISelectOption extends IOption {
  label: ReactNode;
  value: string;
}

export interface ISnippet {
  channelId: string;
  channelTitle: string;
  thumbnails: {
    default: {
      url: string;
    };
  };
}

export interface IYouTubeChannel {
  snippet: ISnippet;
}

export interface IYouTubeChannelSelectModalProps {
  getYouTubeChannels?: (searchTerm: string) => Promise<IOption[] | Error>;
  onClose?: (selectedOption: IOption) => void;
  setOpen: (value: boolean) => void;
  setYouTubeChannel?: (id: string, name: string) => Promise<void | Error>;
}

export interface IYouTubeChannelSelectModalState {
  step: Step;
  options: IOption[];
  selectedOption?: IOption;
}
