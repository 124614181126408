import * as React from "react";

function SvgBits(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 32" {...props}>
      <path d="M30.24 23.392v-.64c0-.224 0-.224-.224-.448v-.224L16.32.672a1.664 1.664 0 00-2.621-.003l-.003.004L.192 22.081v.224c0 .192 0 .192-.224.416v1.12c.224.224.224.448.448.448l13.472 7.488c.224.224.448.224.896.224a1.305 1.305 0 00.868-.227l-.004.003 13.504-7.488.224-.224.192-.224v-.224c.448 0 .672 0 .672-.224zM15.008 4.64l8.384 13.472-7.712-4.416a2.494 2.494 0 00-1.777.006l.017-.006-7.712 4.416zm0 24.064L4.864 23.168l10.144-5.952 10.144 5.952z" />
    </svg>
  );
}

export default SvgBits;
