import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Box, Text } from 'grommet';
import React, { useContext } from 'react';
import { AffirmationQuery } from './types/AffirmationQuery';
import settingsContext, { SettingsContext } from '../../Core/Settings/Settings';
import { theme } from '../../Styled';

const affirmationGqlQuery = loader('./affirmation.graphql');

export const Affirmation = (): JSX.Element => {
  const settingsContextObj = useContext<SettingsContext>(settingsContext);
  const response = useQuery<AffirmationQuery>(affirmationGqlQuery);
  const displayAffirmation = response.data && !response.loading && settingsContextObj.dailyAffirmations;

  return displayAffirmation ? (
    <Box pad={{ horizontal: '14px' }} fill justify="center" align="end">
      <Text size="small" color={theme.colors.textParagraph}>
        {response.data.affirmation}
      </Text>
    </Box>
  ) : (
    <Box />
  );
};
