import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { theme } from '.';
import { Heading } from '../Components/Heading';
import { PageIntro } from '../Components/PageIntro';

export const StyledPageWrapper = styled.main`
  height: 100%;
  overflow: hidden auto;
  padding: 0 ${theme.space.lg};
  position: relative;
`;

interface Props {
  title: string;
  description?: string;
}

const PageWrapper: FC<Props> = ({ children, ...props }) => (
  <StyledPageWrapper>
    <PageIntro {...props} />
    {children}
  </StyledPageWrapper>
);

export default PageWrapper;
