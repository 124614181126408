import * as React from "react";

function SvgListBullets1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M8.5 5H23a1 1 0 000-2H8.5a1 1 0 000 2zM23 11H8.5a1 1 0 000 2H23a1 1 0 000-2zM23 19H8.5a1 1 0 000 2H23a1 1 0 000-2z" />
      <rect x={1} y={2.498} rx={0.5} ry={0.5} />
      <path d="M3.5 17.5h-2A1.5 1.5 0 000 19v2a1.5 1.5 0 001.5 1.5h2A1.5 1.5 0 005 21v-2a1.5 1.5 0 00-1.5-1.5zM4 21a.5.5 0 01-.5.5h-2A.5.5 0 011 21v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z" />
    </svg>
  );
}

export default SvgListBullets1;
