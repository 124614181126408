import { theme } from '../../Styled';
import styled from 'styled-components';
import { Focus } from '../../Styled/Focus';

export const StyledPlayButton = styled.div<{ playing?: boolean; isTrack?: boolean }>`
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${theme.colors.light1};
  cursor: pointer;
  display: flex;
  height: ${({ isTrack }) => (isTrack ? '40px' : '64px')};
  width: ${({ isTrack }) => (isTrack ? '40px' : '64px')};
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    border-color: ${theme.colors.primary};

    svg path {
      fill: ${theme.colors.primary};
    }
  }

  svg {
    path {
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  .hero-carousel-play-icon {
    height: ${({ isTrack }) => (isTrack ? '40px' : '64px')};
  }

  .hero-carousel-pause-icon {
    height: ${({ isTrack }) => (isTrack ? '16px' : '24px')};
    width: 100%;
  }

  ${Focus};
`;
