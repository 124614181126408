import styled from 'styled-components';
import { StyledLabel, StyledInput } from '../../Styles';

const StyledAudioContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabelAndSelect = styled.label`
  ${StyledLabel};
  margin-top: 1.5rem;
  font-size: 0.85rem;

  select {
    ${StyledInput};
    padding: 0 1rem;
    font-family: 'proxima-nova';

    :after {
      position: absolute;
      content: '';
      top: 14px;
      right: 10px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: #fff transparent transparent transparent;
    }
  }
`;

export { StyledAudioContent, StyledLabelAndSelect };
