import { useState, useEffect } from 'react';

interface IWindowDimensions {
  width: number;
  height: number;
}

export default function useWindowSize(): IWindowDimensions {
  const getWindowDimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowSize, setWindowSize] = useState<IWindowDimensions>(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
