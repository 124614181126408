import * as React from "react";

function SvgVideoGameGamasutra(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10 20H8a.5.5 0 00-.5.5V22a1 1 0 001 1H10a1 1 0 001-1v-1a1 1 0 00-1-1zM16 20h-2a1 1 0 00-1 1v1a1 1 0 001 1h1.5a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5zM4 4h1.5a.5.5 0 00.5-.5V2a1 1 0 00-1-1H4a1 1 0 00-1 1v1a1 1 0 001 1zM18.5 4H20a1 1 0 001-1V2a1 1 0 00-1-1h-1a1 1 0 00-1 1v1.5a.5.5 0 00.5.5z" />
      <path d="M23 10h-1.5a.5.5 0 01-.5-.5v-2a1 1 0 00-1-1h-1.5A.5.5 0 0118 6V5a1 1 0 00-1-1h-1.5a1 1 0 00-1 1v1a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V5a1 1 0 00-1-1H7a1 1 0 00-1 1v1a.5.5 0 01-.5.5H4a1 1 0 00-1 1v2a.5.5 0 01-.5.5H1a1 1 0 00-1 1v7.5a1.5 1.5 0 003 0v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a1.5 1.5 0 003 0v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2a1.5 1.5 0 003 0v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a1.5 1.5 0 003 0V11a1 1 0 00-1-1zM8.5 11a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm9 0a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z" />
    </svg>
  );
}

export default SvgVideoGameGamasutra;
