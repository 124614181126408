import { css } from 'styled-components';
import { theme } from '.';

export const Focus = css`
  outline: none;
  position: relative;

  &:before {
    border: 1px solid ${theme.colors.primary};
    content: '';
    height: calc(100% + ${theme.space.lg});
    opacity: 0;
    left: -${theme.space.md};
    position: absolute;
    top: -${theme.space.md};
    width: calc(100% + ${theme.space.lg});
  }

  &:focus-visible:before {
    opacity: 1;
  }
`;
