import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Heading } from '../../Heading';
import { SettingsDrawerWrapper, DrawerContent } from './styled';
import { ConnectedSearchBox } from '../../Search/Components/SearchBox';
import { Settings as CoreSettings } from '../../../Core/Player/Components/PlayerControls/Components/Settings/Settings';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { theme } from '@pretzel-aux/common/src/Styled';
import platformContext, { PlatformContext, URLS } from '@pretzel-aux/common/src/Core/Platform';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';

interface ISettingsDrawerProps {
  // Closes the drawer on enter for the search box
  setOpenFiltersDrawer: (open: boolean) => void;
}

const SettingsDrawer = ({ setOpenFiltersDrawer }: ISettingsDrawerProps) => {
  const history = useHistory();
  const { width } = useWindowSize();
  const { pretzelUser, openExternalLink } = useContext<PlatformContext>(platformContext);

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  const handleClickPremium = () => {
    openExternalLink(URLS.PRICING);
  };

  return (
    <SettingsDrawerWrapper>
      <DrawerContent>
        {pretzelUser.jwt.tier === 'free' && width < theme.appBreakpoints.extraSmall && (
          <StyledButton
            color="secondary"
            style={{ height: '40px', width: '100%', marginBottom: '30px' }}
            onClick={handleClickPremium}
            data-heapid="upgrade-button"
            data-testid="upgrade-button"
          >
            Upgrade to Premium
          </StyledButton>
        )}
        <ConnectedSearchBox width="100%" callBack={() => setOpenFiltersDrawer(false)} />
        <CoreSettings />
        {width < theme.appBreakpoints.extraSmall && (
          <>
            <Heading variant="H3">
              <span onClick={() => history.push('/recent')}>Recent tracks</span>
            </Heading>
            <Heading variant="H3">
              <span onClick={() => history.push('/likes')}>Liked tracks</span>
            </Heading>
            <Heading variant="H3">
              <span onClick={() => history.push('/dislikes')}>Disliked tracks</span>
            </Heading>
          </>
        )}
      </DrawerContent>
    </SettingsDrawerWrapper>
  );
};

export default SettingsDrawer;
