import styled from 'styled-components/macro';
import { theme } from '../../../../../Styled';

export const NavigationButtonContainer = styled.li`
  position: relative;

  :hover {
    svg {
      path[stroke] {
        stroke: ${theme.colors.brandGreen};
      }

      path[fill] {
        fill: ${theme.colors.brandGreen};
      }
    }
  }

  a.active {
    svg {
      path[stroke] {
        stroke: ${theme.colors.brandGreen};
      }

      path[fill] {
        fill: ${theme.colors.brandGreen};
      }
    }
  }
`;

export const Badge = styled.div`
  background: ${theme.colors.secondary};
  color: ${theme.colors.baseWhite};
  border-radius: 2px;
  font-size: 0.5rem;
  font-weight: 600;
  position: absolute;
  height: 15px;
  line-height: 15px;
  width: 30px;
  left: 1.1rem;
  top: 3.2rem;
  text-align: center;
  z-index: 1;
`;
