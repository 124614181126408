import * as React from 'react';

function SvgGiftBox2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4961 11.5C24.4961 12.0523 24.0484 12.5 23.4961 12.5H2.49609C1.94381 12.5 1.49609 12.0523 1.49609 11.5V9.5C1.49609 8.94772 1.94381 8.5 2.49609 8.5H23.4961C24.0484 8.5 24.4961 8.94772 24.4961 9.5V11.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4922 23.5C22.4922 24.0523 22.0445 24.5 21.4922 24.5H4.49219C3.9399 24.5 3.49219 24.0523 3.49219 23.5V12.5H22.4922V23.5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.49219 8.5V24.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.4961 24.5V8.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4192 4.84045C17.6552 5.60545 13.9922 6.50045 13.9922 6.50045C13.9922 6.50045 14.8922 2.83745 15.6522 2.07345C16.1428 1.56163 16.872 1.35524 17.5581 1.53392C18.2442 1.71261 18.78 2.24842 18.9587 2.93455C19.1374 3.62068 18.931 4.34979 18.4192 4.84045Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56384 4.8406C8.32784 5.6056 11.9908 6.5006 11.9908 6.5006C11.9908 6.5006 11.0908 2.8376 10.3308 2.0736C9.56224 1.33678 8.3456 1.34961 7.59272 2.10249C6.83984 2.85537 6.82701 4.07201 7.56384 4.8406V4.8406Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgGiftBox2;
