import * as React from "react";

function SvgCreateNew(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M21.728 15.2H16.8v-4.928c0-.416-.544-.8-.928-.672-.416 0-.672.384-.672.672V15.2h-4.928c-.416 0-.8.544-.672.928 0 .416.416.672.672.672H15.2v4.928c0 .416.544.8.928.672.416 0 .672-.416.672-.672V16.8h4.928c.416 0 .8-.544.672-.928 0-.416-.256-.672-.672-.672z" />
    </svg>
  );
}

export default SvgCreateNew;
