import { Mutation } from '../../../GraphQL';
import { PlatformContext } from '../../../Platform';
import {
  SetTrackLikedInput,
  SetTrackLikedMutation,
  SetTrackBlacklistedInput,
  SetTrackBlacklistedMutation,
} from '../../Components/PlayerControls/schema';
import { PlayerContext } from '../../PlayerContext';

export interface PlayerGraphQLProps {
  likeTrack: Mutation<SetTrackLikedInput, SetTrackLikedMutation>;
  dislikeTrack: Mutation<SetTrackBlacklistedInput, SetTrackBlacklistedMutation>;
  blacklistTrack: Mutation<SetTrackBlacklistedInput, SetTrackBlacklistedMutation>;
}

export interface PlayerState {
  loadingLike: boolean;
  volumeBarVisible: boolean;
  loadingDislike?: boolean;
  showAddToPlaylist: boolean;
  loadingBlacklist?: boolean;
}

export interface ICompactPlayerControls {
  loadingLike: boolean;
  loadingNext: boolean;
  likedActive: boolean;
  handleLike: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loadingBlacklist: boolean;
  handleBlacklist: (event: React.MouseEvent<HTMLButtonElement>) => void;
  blackListActive: boolean;
  premium: boolean;
  skipBack: (seconds: number) => void;
  handlePrevious: (event: React.MouseEvent<HTMLElement>, previous: () => void) => void;
  previous: () => void;
  playing: boolean;
  togglePause: () => void;
  handleTogglePause: (event: React.MouseEvent<HTMLElement>, togglePause: () => void) => void;
}

export function mapPlayerContextToProps(c: PlayerContext): PlayerPropsFromContext {
  return {
    loadingNext: c.loadingNext,
    next: c.next,
    playToken: c.playToken,
    activeSegment: c.activeSegment,
    sendAnalyticsEvent: c.sendAnalyticsEvent,
    seekTo: c.seekTo,
    audioVolume: c.audioVolume,
    setVolume: c.setVolume,
    increaseVolume: c.increaseVolume,
    decreaseVolume: c.decreaseVolume,
    toggleMute: c.toggleMute,
    muted: c.muted,
    previous: c.previous,
    playing: c.playing,
    togglePause: c.togglePause,
    skipBack: c.skipBack,
    skipForward: c.skipForward,
  };
}

export function mapPlatformContextToProps(c: PlatformContext): TPlayerPropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export type PlayerPropsFromContext = Pick<
  PlayerContext,
  | 'playToken'
  | 'activeSegment'
  | 'sendAnalyticsEvent'
  | 'loadingNext'
  | 'next'
  | 'seekTo'
  | 'audioVolume'
  | 'setVolume'
  | 'increaseVolume'
  | 'decreaseVolume'
  | 'toggleMute'
  | 'muted'
  | 'previous'
  | 'playing'
  | 'togglePause'
  | 'skipBack'
  | 'skipForward'
>;

export type TPlayerPropsFromPlatform = Pick<PlatformContext, 'pretzelUser'>;

export type PlayerProps = PlayerPropsFromContext & PlayerGraphQLProps & PlatformContext;

export type ILikeAndDislikeProps = Pick<PlayerProps, 'loadingNext' | 'playToken' | 'likeTrack' | 'sendAnalyticsEvent' | 'dislikeTrack' | 'next'>;
