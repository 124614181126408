import * as React from 'react';

function SvgArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24.2355 30.8308L24.4565 30.6098C24.6029 30.4634 24.6029 30.226 24.4565 30.0795L18.8785 24.5015H30.625C30.8321 24.5015 31 24.3336 31 24.1265V23.814C31 23.607 30.8321 23.439 30.625 23.439H18.8785L24.4565 17.8611C24.6029 17.7147 24.6029 17.4772 24.4565 17.3308L24.2355 17.1098C24.089 16.9634 23.8516 16.9634 23.7052 17.1098L17.1098 23.7051C16.9634 23.8516 16.9634 24.089 17.1098 24.2355L23.7052 30.8308C23.8516 30.9772 24.089 30.9772 24.2355 30.8308Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgArrow;
