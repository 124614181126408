import { theme } from "@pretzel-aux/common/src/Styled";
import * as React from "react";

function SvgMore(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg onMouseEnter={() => setFill(theme.colors.brandGreen)} onMouseLeave={() => setFill(theme.colors.offWhite)} width="24" height="44" viewBox="0 0 24 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3334 17.6667C12.2539 17.6667 13.0001 16.9205 13.0001 16C13.0001 15.0796 12.2539 14.3334 11.3334 14.3334C10.4129 14.3334 9.66675 15.0796 9.66675 16C9.66675 16.9205 10.4129 17.6667 11.3334 17.6667Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3334 23.6667C12.2539 23.6667 13.0001 22.9205 13.0001 22C13.0001 21.0796 12.2539 20.3334 11.3334 20.3334C10.4129 20.3334 9.66675 21.0796 9.66675 22C9.66675 22.9205 10.4129 23.6667 11.3334 23.6667Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.3334 29.6667C12.2539 29.6667 13.0001 28.9205 13.0001 28C13.0001 27.0796 12.2539 26.3334 11.3334 26.3334C10.4129 26.3334 9.66675 27.0796 9.66675 28C9.66675 28.9205 10.4129 29.6667 11.3334 29.6667Z" fill={fill} />
    </svg>
  );
}

export default SvgMore;
