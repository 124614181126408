import * as React from "react";

const Delete = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="delete-button"
    >
      <title id="delete-button">Delete</title>
      <path
        d="M11.5 3.25L10.594 11.8545C10.5405 12.3633 10.1116 12.7497 9.6 12.75H4.4C3.88818 12.75 3.45899 12.3635 3.4055 11.8545L2.5 3.25"
        stroke="#EFEFEF"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 3.25H12.75"
        stroke="#EFEFEF"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 3.25V1.75C4.75 1.47386 4.97386 1.25 5.25 1.25H8.75C9.02614 1.25 9.25 1.47386 9.25 1.75V3.25"
        stroke="#EFEFEF"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.5V10.75"
        stroke="#EFEFEF"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 5.5L9 10.75"
        stroke="#EFEFEF"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 5.5L5 10.75"
        stroke="#EFEFEF"
        stroke-width="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Delete;
