import * as React from "react";

function SvgPlaylist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M11.456 6.944h16.672v1.984H11.456V6.944zm0 8h16.672v1.984H11.456v-1.984zm0 8h16.672v1.984H11.456v-1.984zm-6.784-16.8c1.056-1.088 2.784-1.088 3.872 0s1.056 2.784 0 3.84-2.816 1.088-3.872 0-1.056-2.784 0-3.84zm0 8c1.056-1.088 2.784-1.088 3.872 0s1.056 2.784 0 3.84c-1.088 1.088-2.816 1.088-3.872 0s-1.056-2.784 0-3.84zm0 8c1.056-1.088 2.784-1.088 3.872 0a2.723 2.723 0 010 3.84c-1.088 1.088-2.816 1.088-3.872 0-1.056-1.184-1.056-2.784 0-3.84z" />
    </svg>
  );
}

export default SvgPlaylist;
