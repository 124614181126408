import * as React from 'react';
import { Box, Heading } from 'grommet';
import { Icon } from '../Icon';
import { HoverHide, HoverShow, StyledRow } from './Styled';
import { CopyToClipboard, formatTime, joinStringsGrammatically } from '../../Util';
import { PlayTokenFragment } from '../../Core/Player/Queries/types/PlayTokenFragment';
import { BasicTrackFragment } from '../../Core/Player/Queries/types/BasicTrackFragment';
import { DropdownItemConfig, TrackDropdown } from './TrackDropdown';

interface TrackItemProps {
  playToken: PlayTokenFragment | null;
  track: BasicTrackFragment;
  index: number;
  next: () => void;
  clickNotification?: (track: BasicTrackFragment) => void;
  additionalDropdownItems?: DropdownItemConfig[];
  playTrack: (track: BasicTrackFragment) => void;
  highlight?: boolean;
}

interface TrackItemState {
  open: boolean;
}

export class TrackItem extends React.Component<TrackItemProps, TrackItemState> {
  state: TrackItemState = {
    open: false,
  };

  public render() {
    const { track, index, playToken, additionalDropdownItems, next, highlight } = this.props;
    const { open } = this.state;
    const isPlaying = playToken && playToken.track.id === track.id;
    const firstColumnContents = isPlaying ? (
      <Icon name="volume_3" size={16} />
    ) : (
      <>
        <HoverHide>{(index + 1).toString()}</HoverHide>
        <HoverShow>
          <Icon name="tracklist_play" size={16} />
        </HoverShow>
      </>
    );
    return (
      <StyledRow key={`sr-${track.id}`} onClick={this.onClick} highlight={highlight}>
        <td style={{ width: '46px', textAlign: 'center' }}>{firstColumnContents}</td>
        <td>{track.title}</td>
        <td>{joinStringsGrammatically(track.artists.map(a => a.name))}</td>
        <td>{track.album && track.album.title}</td>
        <td>
          <Box onClick={this.onClickMenu} style={{ position: 'relative', textAlign: 'right' }} focusIndicator={false}>
            <Icon name="more" size={20} />
            {open && <TrackDropdown playToken={playToken} track={track} additionalItems={additionalDropdownItems} next={next} />}
          </Box>
        </td>
        <td>{formatTime(track.duration)}</td>
      </StyledRow>
    );
  }

  private onClick = () => {
    console.debug('Immediately Playing', this.props.track);
    this.props.playTrack(this.props.track);
    if (this.props.clickNotification) {
      this.props.clickNotification(this.props.track);
    }
  };

  private onClickMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // Don't trigger the row's `onClick`
    this.setState({ open: true }, () => {
      document.addEventListener('click', this.onClickOut);
    });
  };

  private onClickOut = () => {
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.onClickOut);
    });
  };

  private handleCopy = (event: React.MouseEvent<any>) => {
    CopyToClipboard(`https://prtzl.to/${this.props.track.id}`);
  };
}
