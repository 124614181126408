import * as React from "react";

function SvgWeb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M22.752 12h8.224c.352 1.28.512 2.624.512 4s-.16 2.72-.512 4h-8.224a33.45 33.45 0 000-8zm-11.168 9.984c.672 3.36 2.08 7.04 4.384 9.504h.064c2.336-2.464 3.712-6.144 4.384-9.504h-8.832zm10.88-12h7.84C28.288 5.248 24 1.696 18.816.768c1.888 2.688 3.04 6.016 3.648 9.216zM20.736 12h-9.472a30.435 30.435 0 000 8h9.472a30.435 30.435 0 000-8zm-11.2 9.984h-7.84c2.016 4.768 6.304 8.32 11.488 9.248-1.888-2.688-3.04-6.016-3.648-9.248zm12.928 0c-.608 3.232-1.76 6.56-3.648 9.248 5.184-.928 9.472-4.48 11.488-9.248h-7.84zm-2.048-12c-.672-3.328-2.048-7.008-4.384-9.472H16h-.032c-2.336 2.464-3.712 6.144-4.384 9.472h8.832zM1.024 12c-.352 1.28-.512 2.624-.512 4s.16 2.72.512 4h8.224a33.45 33.45 0 010-8H1.024zm8.512-2.016c.608-3.2 1.76-6.528 3.648-9.216C8 1.696 3.712 5.248 1.696 9.984h7.84z" />
    </svg>
  );
}

export default SvgWeb;
