import axios from 'axios';
import { URI_BASE } from '../Core/GraphQL';
import { DownloadFileType } from '../Core/Settings/Settings';

export const getSignedTrackUrl = async (guid: string, variant: DownloadFileType): Promise<string | undefined> => {
  const options = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
    },
  };

  try {
    return (await axios.get(`${URI_BASE}/download-track?guid=${guid}&variant=${variant}`, options)).data;
  } catch (error) {
    return undefined;
  }
};

export default getSignedTrackUrl;
