import React, { FC } from 'react';
import { flowRight as compose } from 'lodash';
import { PlayerContext, withPlayerContext } from '../../Core/Player/PlayerContext';
import { StyledPlayButton } from './styled';
import { PlayButtonProps, PropsFromPlayer } from './types';
import { Icon } from '../../Styled';

export const PlayButtonFn: FC<PlayButtonProps & PropsFromPlayer> = ({
  activeSegment,
  idToPlay,
  pause,
  playSegment,
  playTrack,
  playing,
  isTrack,
  handleNonPremiumClick,
  playToken,
  ...rest
}) => {
  const handleOnClick = () => {
    if (handleNonPremiumClick) {
      handleNonPremiumClick();
      return;
    }

    if (playing && (activeSegment.id === idToPlay || (playing && playToken.track.id === idToPlay))) {
      pause();
    } else {
      isTrack
        ? playTrack({
            id: idToPlay,
            __typename: 'Track',
          })
        : playSegment({ id: idToPlay });
    }
  };

  const handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === '' || e.key === ' ') e.preventDefault();
    if (e.key === 'Enter' || e.key === '') {
      handleOnClick();
    }
  };

  return (
    <StyledPlayButton isTrack={isTrack} onClick={handleOnClick} onKeyDown={handleOnKeyDown} playing={playing} tabIndex={1} {...rest}>
      {playing && (activeSegment.id === idToPlay || playToken?.track.id === idToPlay) ? (
        <Icon className="hero-carousel-pause-icon" name="Pause2" />
      ) : (
        <Icon className="hero-carousel-play-icon" name="Play" />
      )}
    </StyledPlayButton>
  );
};

const mapContextToProps = (c: PlayerContext): PropsFromPlayer => {
  return {
    activeSegment: c.activeSegment,
    pause: c.pause,
    playing: c.playing,
    playSegment: c.playSegment,
    playTrack: c.playTrack,
    playToken: c.playToken,
  };
};

export const PlayButton = compose(withPlayerContext(mapContextToProps))(PlayButtonFn);
