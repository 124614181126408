export const getBuildVersion = (): string => {
  let buildVersion = 'Pretzel Rocks Play';
  const commit = process.env.REACT_APP_COMMIT?.toString() || '';
  const branch = process.env.REACT_APP_BRANCH?.toString() || '';

  if (commit || branch) {
    buildVersion += `: ${commit.substring(0, 7)} (${branch.split('/').pop()})`;
  }

  const IS_ELECTRON = /electron/i.test(navigator.userAgent) && window.require;
  if (IS_ELECTRON) {
    const electronVersion = window.require('electron').remote.app.getVersion();
    buildVersion += ` | Desktop App: ${electronVersion}`;
  }

  return buildVersion;
};
