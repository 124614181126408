import styled from 'styled-components/macro';
import { theme } from '../../../../../../Styled';

export const Wrapper = styled.div`
  position: relative;
`;

interface StyledButtonProps {
  active?: boolean | null;
  play?: boolean;
}

// For some reason, this component required an interface, either because it's a button, or because of `active`.
export const StyledButton = styled('button')<StyledButtonProps>`
  padding: 0;
  border: none;
  height: ${props => (props.play ? '48px' : '48px')};
  width: ${props => (props.play ? '48px' : '48px')};
  text-align: center;
  background: transparent;
  cursor: pointer;
  z-index: 3;
  position: relative;
  overflow: hidden;

  svg:not(.no-hover-effect) {
    stroke: ${props => (props.active ? theme.colors.brandGreen2 : theme.colors.baseWhite)};

    &:hover {
      path,
      svg {
        stroke: ${theme.colors.brandGreen2};
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
