import * as React from "react";

function SvgRadio(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M14.144 14.144c1.056-1.088 2.784-1.088 3.872 0s1.056 2.784 0 3.84-2.816 1.088-3.872 0-1.088-2.784 0-3.84zm-3.616 8.384c-.256 0-.512-.128-.672-.256-3.456-3.488-3.456-8.928 0-12.416.416-.384 1.088-.384 1.472 0 .416.416.416.928 0 1.344a6.78 6.78 0 000 9.6c.416.384.416 1.056 0 1.472-.256.128-.512.256-.8.256zm10.944 0c-.544 0-.928-.384-.928-1.056 0-.288.128-.544.256-.672a6.78 6.78 0 000-9.6 1.084 1.084 0 010-1.472c.416-.384.928-.384 1.344 0 3.456 3.456 3.456 8.928 0 12.416-.128.256-.416.384-.672.384zM6.4 26.656c-.256 0-.544-.128-.672-.256C0 20.672 0 11.328 5.728 5.6c.288-.416.928-.256 1.344.128.384.416.384.928 0 1.344C2.144 12 2.144 20 7.072 24.928a1.084 1.084 0 010 1.472c-.256.128-.544.256-.672.256zm19.2 0c-.544 0-.928-.384-.928-1.056 0-.256.128-.544.256-.672C29.856 20 29.856 12 24.928 7.072c-.384-.416-.384-1.088 0-1.472.416-.416.928-.416 1.344 0 5.728 5.728 5.728 15.072 0 20.8-.128.128-.416.256-.672.256z" />
    </svg>
  );
}

export default SvgRadio;
