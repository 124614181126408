import * as React from 'react';
import { Box, Image, Text } from 'grommet';
import { theme } from '../../../Styled';
import { getCheerForValue } from '../../../Core/Twitch/Cheers';
import { SongRequestQueueQuery_currentUser_songRequestQueue_requesters } from '../types/SongRequestQueueQuery';
import { SongRequestFragment } from '../../../Core/Player/Queries/types/SongRequestFragment';

interface PublicProps {
  request: SongRequestFragment;
}

type Props = PublicProps;

export function getSongRequesterListHeight(request: SongRequestFragment): string {
  const topPadding = 4;
  const titleHeight = 16;
  const listHeight = 21 * request.requesters.length;
  return `${topPadding + titleHeight + listHeight}px`;
}

export class SongRequesterList extends React.Component<Props> {
  state = {};

  public render() {
    return (
      <Box>
        <Text size="12px" color={theme.colors.textMuted}>
          REQUESTED BY
        </Text>
        <Box>{this.props.request.requesters.map(requester => this.renderRequester(requester))}</Box>
      </Box>
    );
  }

  private renderRequester(requester: SongRequestQueueQuery_currentUser_songRequestQueue_requesters) {
    const cheer = getCheerForValue(requester.bitTotal);
    return (
      <Box direction="row" justify="between" key={requester.displayName}>
        <Text size="small" color={theme.colors.textTitles}>
          {requester.displayName}
        </Text>
        <Text size="small" color={cheer.color}>
          {requester.bitTotal} <Image src={cheer.static} width={16} height={16} />
        </Text>
      </Box>
    );
  }
}
