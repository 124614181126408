import { useQuery } from '@apollo/client';
import { theme } from '@pretzel-aux/common/src/Styled';
import { ArtworkSize } from '@pretzel-aux/common/src/Util';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { ArtistCard } from './Components/ArtistCard';
import { ArtistsListQuery } from './types/ArtistsListQuery';
import { LoadingSpinner } from '../LoadingSpinner';
import { MusicList } from '../../Styled/MusicList';

const artistsGqlQuery = loader('./artists.graphql');

export enum Size {
  Medium,
  Large,
}

type Props = {
  size?: Size;
};

export const ArtistsList = ({ size }: Props): JSX.Element => {
  const [state, setState] = useState<boolean>(false);
  const response = useQuery<ArtistsListQuery>(artistsGqlQuery, {
    variables: {
      after: '',
    },
  });
  const { width } = useWindowSize();
  const hasMore = response.data?.artists?.pageInfo?.hasNextPage;

  let artistItems = null;

  if (!response.loading && response.data?.artists?.edges) {
    artistItems = response.data.artists.edges.map(artistEdge => (
      <ArtistCard key={artistEdge.node.id} artist={artistEdge.node} artworkSize={size == Size.Large ? ArtworkSize.Large : ArtworkSize.Medium} />
    ));
  }

  const onMore = (): void => {
    const after = response.data.artists.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'artist',
      currentLength: response.data.artists.edges.length,
      after,
    });
    setState(true);

    response.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: ArtistsListQuery, { fetchMoreResult }) => {
        setState(false);
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          artists: Object.assign({}, fetchMoreResult.artists, {
            edges: [...previousQueryResult.artists.edges, ...fetchMoreResult.artists.edges],
          }),
        };
      },
    });
  };

  const setTileSize = (): number => {
    if (width >= theme.appBreakpoints.small && width < theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.small);
    }
    if (width >= theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.normal);
    }
    return parseInt(size == Size.Large ? theme.card.size.large : theme.card.size.normal);
  };

  if (response.loading) {
    return <LoadingSpinner />;
  }

  return <MusicList title="Artists" items={artistItems} hasMore={hasMore} onMore={onMore} loading={state} circle tileSize={setTileSize()} />;
};
