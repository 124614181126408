import * as React from 'react';

function SvgRefreshArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <defs>
        <style>{'.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}'}</style>
      </defs>
      <polyline className="a" points="6 14.248 6 18.748 1.5 18.748" />
      <path className="a" d="M5.717,18.751A9.753,9.753,0,0,1,16.5,3" />
      <path className="a" d="M5.712,18.746A9.72,9.72,0,1,1,9,21" />
    </svg>
  );
}

export default SvgRefreshArrow;
