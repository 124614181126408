import * as React from "react";

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 34 32" {...props}>
      <path d="M16.832 26.528L6.432 32l1.984-11.584L0 12.224l11.616-1.696L16.832 0l5.184 10.528 11.616 1.696-8.384 8.192L27.232 32z" />
    </svg>
  );
}

export default SvgStar;
