import { flowRight as compose } from 'lodash';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { StyledInput } from '../../../CurrentUser/Styles';
import { PlayerContext, withPlayerContext } from '../../../../Core/Player/PlayerContext';
import { theme } from '../../../../Styled';
import { getNowPlayingStringForFileOutput } from '../../../../Util';

interface Disableable {
  disabled?: boolean;
}

interface PublicProps {
  title: string;
  disabled: boolean;
  formatString: string;
  onChange: (newFormatString: string) => {};
}

interface State {}

type PropsFromPlayer = Pick<PlayerContext, 'playToken'>;

type Props = PublicProps & PropsFromPlayer;

const CustomTooltip = styled(ReactTooltip)`
  background-color: ${theme.colors.baseBackground2} !important;
  &.show {
    opacity: 1 !important;
  }
  &.place-left {
    &:after {
      border-left-color: ${theme.colors.baseBackground2} !important;
      border-left-style: solid !important;
      border-left-width: 6px !important;
    }
  }
  text-align: center;
`;

const Info = styled.div`
  ${StyledInput}
  color: ${theme.colors.baseMuted};
  font-size: 1.5rem;
  margin-top: 0;
  padding: 10px 20px 0 20px;

`;

const Input = styled.input`
  // This is hacky and needs revisited. 
  ${StyledInput}
  color: ${props => (props.disabled ? theme.colors.baseMuted : theme.colors.baseWhite)} !important;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 40px !important;
  margin: 0;
  padding: 0.6875rem 1rem !important;
`;

const InputWrapper = styled.div`
  width: 100%;

  &:not(:only-of-type) > input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  & ~ div {
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  padding: 0;
  margin: 0;
`;

const SectionWrapper = styled.div`
  margin-top: 1rem;
`;

const Title = styled.label<Disableable>`
  color: ${props => (props.disabled ? theme.colors.baseMuted : theme.colors.baseWhite)};
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 0.5rem;
`;

export class GeneralPresentation extends React.Component<Props, State> {
  state = {};

  public render() {
    const { title, disabled, playToken, formatString, onChange } = this.props;
    const previewString = getNowPlayingStringForFileOutput(playToken, formatString, true, false, '');
    return (
      <>
        <SectionWrapper>
          <Title disabled={disabled}>{title}</Title>
          <Wrapper>
            <InputWrapper>
              <Input disabled={disabled} type="text" value={formatString || ''} onChange={event => onChange(event.target.value)} />
            </InputWrapper>
            <Info data-tip data-for="tags">
              ?
            </Info>
            <CustomTooltip id="tags" place="left" effect="solid" offset={{ right: 10 }}>
              <p>Supported tags:</p>
              <List>
                <ListItem>{`{album}`}</ListItem>
                <ListItem>{`{artist}`}</ListItem>
                <ListItem>{`{title}`}</ListItem>
                <ListItem>{`{pretzel}`}</ListItem>
              </List>
            </CustomTooltip>
          </Wrapper>
        </SectionWrapper>
        <SectionWrapper>
          <Title disabled={disabled}>{title} preview</Title>
          <Wrapper>
            <InputWrapper>
              <Input disabled={disabled} type="text" readOnly value={previewString} />
            </InputWrapper>
          </Wrapper>
        </SectionWrapper>
      </>
    );
  }
}

function mapPlayerToProps(c: PlayerContext): PropsFromPlayer {
  return {
    playToken: c.playToken,
  };
}

export const OutputFormatter = compose(withPlayerContext(mapPlayerToProps))(GeneralPresentation);
