export enum EPlacement {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export interface TooltipProps {
  text: string;
  show?: boolean;
  fontColor?: string;
  color?: string;
  placement?: EPlacement;
  styles?: string;
}
