import * as React from "react";

function SvgFeatureKeybinds(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M26.624 32H5.376C2.432 32 0 29.568 0 26.624V5.376C0 2.432 2.432 0 5.376 0h21.248C29.568 0 32 2.432 32 5.376v21.248C32 29.568 29.568 32 26.624 32zM5.376 1.792a3.578 3.578 0 00-3.584 3.584v21.248c0 1.984 1.6 3.584 3.584 3.584h21.248c1.984 0 3.584-1.6 3.584-3.584V5.376c0-1.984-1.6-3.584-3.584-3.584H5.376zm20.352 23.936H6.272c-.544 0-.896-.352-.896-.896V5.376c0-.544.352-.896.896-.896h19.36c.544 0 .896.352.896.896v19.36c.096.544-.352.992-.8.992zm-18.56-1.792h17.568V6.272H7.168v17.664zm4.224-6.56H9.6V9.504c0-.544.352-.896.896-.896h1.696c1.888 0 3.488 1.504 3.488 3.488s-1.504 3.488-3.488 3.488h-.8v1.792zm0-3.584h.8c.896 0 1.696-.704 1.696-1.696s-.704-1.696-1.696-1.696h-.8v3.392z" />
    </svg>
  );
}

export default SvgFeatureKeybinds;
