import styled from 'styled-components/macro';
import * as React from 'react';
import {
  AddPlaylist,
  AddToPlaylist,
  AdvertisingMegaphone,
  Albums,
  Amazon,
  Apple,
  Arrow,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Artist,
  Artists,
  Bandcamp,
  BeachPalmWater,
  Bits,
  Brand,
  BrandText,
  BrandTextHoriz,
  Catalog,
  ChatAnnouncement,
  Check,
  Checkmark,
  ChillJazz,
  ChristmasSnowflake,
  ChristmasTreeTop,
  Close,
  CloseCircle,
  CocktailMartini,
  CompactAlbums,
  CompactArtists,
  CompactHome,
  CompactLabels,
  CompactPlaylists,
  CompactStations,
  ConcertDj,
  ConcertSing,
  ConcertSpeakers,
  Copy,
  Cowboy,
  CreateNew,
  Curations,
  Delete,
  Dholak,
  DiscoBall,
  Discord,
  Dislike,
  Download,
  Duration,
  Earth3,
  Edit,
  EditPlaylist,
  ElectronicsResistor,
  EmptyPlaylist,
  Expand,
  Explicit,
  Facebook,
  FeatureBot,
  FeatureKeybinds,
  FeatureOverlays,
  FeatureSettings,
  FeatureWp,
  Film,
  Fireworks,
  FullVersion,
  Funk,
  GiftBox,
  GiftBox2,
  Google,
  GuitarHeadstock,
  Headphones1,
  HeadphonesCustomerSupportHuman,
  HeadphonesPlug,
  HeadphonesWave,
  Home,
  HouseChimney1,
  HouseChimney1Alternate,
  HouseChimney2,
  IcChevronLeft,
  IcChevronRight,
  IceCream,
  IceCreamBowl,
  IcSearch24Px,
  Instagram,
  Instrumental,
  InstrumentBanjo,
  InstrumentClassicalPiano,
  InstrumentContrabass,
  InstrumentGuitar,
  InstrumentHarmonica,
  InstrumentMaracas1,
  InstrumentPianoKeys,
  InstrumentSaxophone,
  Labels,
  LabelsIcon,
  Ladybug,
  LandmarkMountain,
  LatinDance,
  Lederhosen,
  Like,
  Like1Alternate,
  Link,
  ListAdd,
  ListBullets,
  ListBullets1,
  ListRemove,
  Livestreamer,
  Lock,
  Logo,
  LogoLarge,
  LoveFire,
  Medium,
  Medium1,
  MicrophoneKaraoke,
  MicrophoneSingWoman,
  Minimize,
  MobileListen,
  ModernMusicBassGuitar,
  ModernMusicDjTape,
  ModernMusicDrums,
  Money,
  MoodRock,
  More,
  MoreCircle,
  MusicGenreAlbumAnarchist,
  MusicGenreChoose,
  Mute,
  Next,
  Notifications,
  NoVocals,
  Online,
  PartyMusicDance,
  PartyMusicDanceWoman,
  Pause,
  Pause2,
  Play,
  PlayerPlay,
  Playlist,
  PlaylistAlbumAlternate,
  Playlists,
  Previous,
  Radio,
  Radioactive,
  RadioAntenna1,
  RadioStereo,
  Recent,
  RecentlyPlayed,
  Refresh,
  RefreshArrow,
  ReligionChristianity,
  ReligionDevil,
  Repeat,
  RotateBack,
  SailingBoat1,
  SeafoodFish,
  Search,
  Settings,
  SettingsVertical1,
  Shrink,
  Shuffle,
  Singer,
  SkipBack,
  SkipForward,
  SkipOnHover,
  Skull,
  SmileySmileAlternate,
  SongRequest,
  Soundcloud,
  SoundcloudFa,
  Spotify,
  SrQueue,
  Star,
  Stations,
  StPatricks,
  Tags,
  ThumbsDown,
  ThumbsUp,
  ToolsHammerHold,
  TracklistPlay,
  TrashCan,
  Trophy,
  Twitch,
  Twitter,
  Update,
  UploadCircle,
  VideoGameGamasutra,
  VideoGamePacmanEnemy,
  Viewers,
  Volume,
  Volume2,
  Volume3,
  WalkmanCassette,
  WalkmanHeadphones,
  WaterDam,
  Web,
  Windows,
  YogaMeditate1,
  Youtube,
  YoutubeSafe,
} from '../../../assets/icons';

interface IconProperties {
  name: string;
  color?: string;
  noFill?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: number;
  labeltext?: string;
  heapid?: string;
  svgColour?: string;
}
interface WrappedProperties {
  className?: string;
}

const PretzelIcon = (props: IconProperties & WrappedProperties) => {
  const TheIcon = LibraryIcons[props.name];
  if (TheIcon) return <TheIcon className={props.className} aria-labelledby={props.labeltext} data-heapid={props.heapid} />;
  return <i className={`${props.className} pretzel-icon-${props.name}`} data-heapid={props.heapid} />;
};

export const Icon = styled(PretzelIcon)<IconProperties>`
  color: ${({ color }) => (color ? color : 'inherit')};
  fill: ${({ color, noFill }) => {
    if (color) {
      return color;
    }

    if (noFill) {
      return null;
    }

    return 'inherit';
  }};
  opacity: ${({ isLoading, isDisabled }) => (isLoading || isDisabled ? 0.3 : 1)};
  transition: opacity 2s;
  font-size: ${({ size }) => (size ? `${size}px` : 'inherit')};
  width: ${({ size }) => (size ? `${size}px` : 'auto')};
  position: relative;

  stroke: ${({ svgColour }) => {
    if (svgColour) {
      return svgColour;
    }

    return 'inherit';
  }};
`;

type SvgIconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export const LibraryIcons: Record<string, SvgIconType> = {
  AddPlaylist: AddPlaylist,
  AddToPlaylist: AddToPlaylist,
  AdvertisingMegaphone: AdvertisingMegaphone,
  Albums: Albums,
  Amazon: Amazon,
  Apple: Apple,
  Arrow: Arrow,
  ArrowDown: ArrowDown,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  ArrowUp: ArrowUp,
  Artist: Artist,
  Artists: Artists,
  Bandcamp: Bandcamp,
  BeachPalmWater: BeachPalmWater,
  Bits: Bits,
  Brand: Brand,
  BrandText: BrandText,
  BrandTextHoriz: BrandTextHoriz,
  Catalog: Catalog,
  ChatAnnouncement: ChatAnnouncement,
  Check: Check,
  Checkmark: Checkmark,
  ChillJazz: ChillJazz,
  ChristmasSnowflake: ChristmasSnowflake,
  ChristmasTreeTop: ChristmasTreeTop,
  Close: Close,
  CloseCircle: CloseCircle,
  CocktailMartini: CocktailMartini,
  CompactAlbums: CompactAlbums,
  CompactArtists: CompactArtists,
  CompactHome: CompactHome,
  CompactLabels: CompactLabels,
  CompactPlaylists: CompactPlaylists,
  CompactStations: CompactStations,
  ConcertDj: ConcertDj,
  ConcertSing: ConcertSing,
  ConcertSpeakers: ConcertSpeakers,
  Copy: Copy,
  Cowboy: Cowboy,
  CreateNew: CreateNew,
  Curations: Curations,
  Delete: Delete,
  Dholak: Dholak,
  DiscoBall: DiscoBall,
  Discord: Discord,
  Dislike: Dislike,
  Download: Download,
  Duration: Duration,
  Earth3: Earth3,
  Edit: Edit,
  EditPlaylist: EditPlaylist,
  ElectronicsResistor: ElectronicsResistor,
  EmptyPlaylist: EmptyPlaylist,
  Expand: Expand,
  Explicit: Explicit,
  Facebook: Facebook,
  FeatureBot: FeatureBot,
  FeatureKeybinds: FeatureKeybinds,
  FeatureOverlays: FeatureOverlays,
  FeatureSettings: FeatureSettings,
  FeatureWp: FeatureWp,
  Film: Film,
  Fireworks: Fireworks,
  FullVersion: FullVersion,
  Funk: Funk,
  GiftBox: GiftBox,
  GiftBox2: GiftBox2,
  Google: Google,
  GuitarHeadstock: GuitarHeadstock,
  Headphones1: Headphones1,
  HeadphonesCustomerSupportHuman: HeadphonesCustomerSupportHuman,
  HeadphonesPlug: HeadphonesPlug,
  HeadphonesWave: HeadphonesWave,
  Home: Home,
  HouseChimney1: HouseChimney1,
  HouseChimney1Alternate: HouseChimney1Alternate,
  HouseChimney2: HouseChimney2,
  IcChevronLeft: IcChevronLeft,
  IcChevronRight: IcChevronRight,
  IceCream: IceCream,
  IceCreamBowl: IceCreamBowl,
  IcSearch24Px: IcSearch24Px,
  Instagram: Instagram,
  Instrumental: Instrumental,
  InstrumentBanjo: InstrumentBanjo,
  InstrumentClassicalPiano: InstrumentClassicalPiano,
  InstrumentContrabass: InstrumentContrabass,
  InstrumentGuitar: InstrumentGuitar,
  InstrumentHarmonica: InstrumentHarmonica,
  InstrumentMaracas1: InstrumentMaracas1,
  InstrumentPianoKeys: InstrumentPianoKeys,
  InstrumentSaxophone: InstrumentSaxophone,
  Labels: Labels,
  LabelsIcon: LabelsIcon,
  Ladybug: Ladybug,
  LandmarkMountain: LandmarkMountain,
  LatinDance: LatinDance,
  Lederhosen: Lederhosen,
  Like: Like,
  Like1Alternate: Like1Alternate,
  Link: Link,
  ListAdd: ListAdd,
  ListBullets: ListBullets,
  ListBullets1: ListBullets1,
  ListRemove: ListRemove,
  Livestreamer: Livestreamer,
  Lock: Lock,
  Logo: Logo,
  LogoLarge: LogoLarge,
  LoveFire: LoveFire,
  Medium: Medium,
  Medium1: Medium1,
  MicrophoneKaraoke: MicrophoneKaraoke,
  MicrophoneSingWoman: MicrophoneSingWoman,
  Minimize: Minimize,
  MobileListen: MobileListen,
  ModernMusicBassGuitar: ModernMusicBassGuitar,
  ModernMusicDjTape: ModernMusicDjTape,
  ModernMusicDrums: ModernMusicDrums,
  Money: Money,
  MoodRock: MoodRock,
  More: More,
  MoreCircle: MoreCircle,
  MusicGenreAlbumAnarchist: MusicGenreAlbumAnarchist,
  MusicGenreChoose: MusicGenreChoose,
  Mute: Mute,
  Next: Next,
  Notifications: Notifications,
  NoVocals: NoVocals,
  Online: Online,
  PartyMusicDance: PartyMusicDance,
  PartyMusicDanceWoman: PartyMusicDanceWoman,
  Pause: Pause,
  Pause2: Pause2,
  Play: Play,
  PlayerPlay: PlayerPlay,
  Playlist: Playlist,
  PlaylistAlbumAlternate: PlaylistAlbumAlternate,
  Playlists: Playlists,
  Previous: Previous,
  Radio: Radio,
  Radioactive: Radioactive,
  RadioAntenna1: RadioAntenna1,
  RadioStereo: RadioStereo,
  Recent: Recent,
  RecentlyPlayed: RecentlyPlayed,
  Refresh: Refresh,
  RefreshArrow: RefreshArrow,
  ReligionChristianity: ReligionChristianity,
  ReligionDevil: ReligionDevil,
  Repeat: Repeat,
  RotateBack: RotateBack,
  SailingBoat1: SailingBoat1,
  SeafoodFish: SeafoodFish,
  Search: Search,
  Settings: Settings,
  SettingsVertical1: SettingsVertical1,
  Shrink: Shrink,
  Shuffle: Shuffle,
  Singer: Singer,
  SkipBack: SkipBack,
  SkipForward: SkipForward,
  SkipOnHover: SkipOnHover,
  Skull: Skull,
  SmileySmileAlternate: SmileySmileAlternate,
  SongRequest: SongRequest,
  Soundcloud: Soundcloud,
  SoundcloudFa: SoundcloudFa,
  Spotify: Spotify,
  SrQueue: SrQueue,
  Star: Star,
  Stations: Stations,
  StPatricks: StPatricks,
  Tags: Tags,
  ThumbsDown: ThumbsDown,
  ThumbsUp: ThumbsUp,
  ToolsHammerHold: ToolsHammerHold,
  TracklistPlay: TracklistPlay,
  TrashCan: TrashCan,
  Trophy: Trophy,
  Twitch: Twitch,
  Twitter: Twitter,
  Update: Update,
  UploadCircle: UploadCircle,
  VideoGameGamasutra: VideoGameGamasutra,
  VideoGamePacmanEnemy: VideoGamePacmanEnemy,
  Viewers: Viewers,
  Volume: Volume,
  Volume2: Volume2,
  Volume3: Volume3,
  WalkmanCassette: WalkmanCassette,
  WalkmanHeadphones: WalkmanHeadphones,
  WaterDam: WaterDam,
  Web: Web,
  Windows: Windows,
  YogaMeditate1: YogaMeditate1,
  Youtube: Youtube,
  YoutubeSafe: YoutubeSafe,
};
