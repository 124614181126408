import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { SettingsContainer, UpgradeButton } from './Styles';
import { SettingsContext, withSettingsContext } from '../../../../../Settings/Settings';
import { PlatformContext, withPlatformContext, URLS, STORAGE_KEYS } from '../../../../../Platform';
import { PlayerContext, withPlayerContext } from '../../../../PlayerContext';
import { Toggle } from '../../../../../../Components/Toggle';
import { ControlItem } from '../ControlItem/ControlItem';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { theme } from '@pretzel-aux/common/src/Styled';
import { Tooltip } from '@pretzel-aux/common/src/Components/Tooltip';
import { CurrentUser_CurrentUserQuery } from 'common/src/Components/CurrentUser/types/CurrentUser_CurrentUserQuery';
import { QueryControls } from '@apollo/client/react/hoc';
import YouTubeChannelSelectModal from '@pretzel-aux/common/src/Components/YouTubeChannelSelectModal/YouTubeChannelSelectModal';

interface ContextFromProps {
  instrumentalOnly: boolean;
  allowExplicit: boolean;
  youtubeSafe: boolean;
  notifyInChat: boolean;
  setInstrumentalOnly: (value: boolean) => void;
  setAllowExplicit: (value: boolean) => void;
  setYoutubeSafe: (value: boolean) => void;
  setNotifyInChat: (value: boolean) => void;
}

interface IPassedProps {
  horizontalPlayer?: boolean;
  data?: QueryControls & CurrentUser_CurrentUserQuery;
}

type PropsFromPlatform = Pick<PlatformContext, 'pretzelUser' | 'openExternalLink'>;
type PropsFromPlayer = Pick<PlayerContext, 'onFiltersChange'>;

type Props = IPassedProps & ContextFromProps & PropsFromPlatform & PropsFromPlayer;

const OptionsDrawerPresentation = ({
  pretzelUser,
  horizontalPlayer,
  notifyInChat,
  youtubeSafe,
  allowExplicit,
  instrumentalOnly,
  setInstrumentalOnly,
  openExternalLink,
  onFiltersChange,
  setAllowExplicit,
  setNotifyInChat,
  setYoutubeSafe,
  data,
}: Props) => {
  const isPastEndOfDay = (() => {
    const youtubeModalRetryDate = window.sessionStorage.getItem(STORAGE_KEYS.YOUTUBE_MODAL_RETRY_DATE);
    if (!youtubeModalRetryDate) {
      return true;
    }
    const today = new Date();
    return today.toDateString() > new Date(youtubeModalRetryDate).toDateString();
  })();

  const { width } = useWindowSize();
  const [displayYoutubeCaptureModal, setDisplayYoutubeCaptureModal] = React.useState<boolean>(false);

  const handleClickPremium = () => {
    openExternalLink(URLS.PRICING);
  };

  const handleToggleInstrumental = () => {
    setInstrumentalOnly(!instrumentalOnly);
    onFiltersChange();
  };

  const handleToggleExplicit = () => {
    setAllowExplicit(!allowExplicit);
    onFiltersChange();
  };

  const handleToggleNotify = () => {
    setNotifyInChat(!notifyInChat);
  };

  const handleToggleYoutube = () => {
    setYoutubeSafe(!youtubeSafe);
    onFiltersChange();
  };

  const upgradeText = 'Upgrade to premium to disable the chat bot';
  const isPremium = pretzelUser?.jwt.tier !== 'free';

  React.useEffect(() => {
    if (isPastEndOfDay && youtubeSafe && data && data?.currentUser && !data?.currentUser?.youtubeChannel) {
      setDisplayYoutubeCaptureModal(true);
    }
  }, [youtubeSafe]);

  return (
    <SettingsContainer isMusicPlayer={horizontalPlayer}>
      {horizontalPlayer && (
        <Tooltip show text={!isPremium ? upgradeText : 'Notify in chat'} styles={!isPremium ? 'left: -1.65rem;' : 'left: -0.4rem;'}>
          <ControlItem
            title="Notify in Chat"
            isLoading={false}
            isDisabled={!isPremium}
            active={isPremium ? notifyInChat : true}
            name="Notifications"
            size={48}
            onClick={handleToggleNotify}
            heapid="notify-chat"
            testid="notify-chat-button"
          />
        </Tooltip>
      )}
      {!horizontalPlayer && (
        <React.Fragment>
          <Toggle
            className={width < theme.appBreakpoints.small ? 'drawer-settings' : 'first'}
            label="YouTube Safe"
            onChange={handleToggleYoutube}
            selected={youtubeSafe}
            value="youtube"
          />
          <Toggle
            className={width < theme.appBreakpoints.small ? 'drawer-settings' : ''}
            label="Allow Mature"
            onChange={handleToggleExplicit}
            selected={allowExplicit}
            value="allowmature"
          />
          <Toggle
            className={width < theme.appBreakpoints.small ? 'drawer-settings' : ''}
            label="Instrumental"
            onChange={handleToggleInstrumental}
            selected={instrumentalOnly}
            value="instrumental"
          />
          {!isPremium && (
            <UpgradeButton color="secondary" onClick={handleClickPremium} data-heapid="upgrade-button" data-testid="upgrade-button">
              Upgrade
            </UpgradeButton>
          )}
        </React.Fragment>
      )}
      {displayYoutubeCaptureModal && (
        <YouTubeChannelSelectModal
          onClose={() => {
            data
              .refetch()
              .then(val => {
                if (val.data.currentUser?.youtubeChannel) {
                  setYoutubeSafe(true);
                }
              })
              .finally(() => onFiltersChange());
          }}
          setOpen={val => {
            setDisplayYoutubeCaptureModal(val);
            data
              .refetch()
              .then(val => {
                if (!val.data.currentUser?.youtubeChannel) {
                  setYoutubeSafe(false);
                }
              })
              .finally(() => onFiltersChange());
          }}
        />
      )}
    </SettingsContainer>
  );
};

function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
    openExternalLink: c.openExternalLink,
  };
}

function mapPlayerToProps(c: PlayerContext): PropsFromPlayer {
  return {
    onFiltersChange: c.onFiltersChange,
  };
}

function mapContextToProps(c: SettingsContext): ContextFromProps {
  return {
    instrumentalOnly: c.instrumentalOnly,
    allowExplicit: c.allowExplicit,
    youtubeSafe: c.youtubeSafe,
    notifyInChat: c.notifyInChat,

    setInstrumentalOnly: c.setInstrumentalOnly,
    setAllowExplicit: c.setAllowExplicit,
    setYoutubeSafe: c.setYoutubeSafe,
    setNotifyInChat: c.setNotifyInChat,
  };
}

export const Settings = compose(
  withPlatformContext(mapPlatformToProps),
  withSettingsContext(mapContextToProps),
  withPlayerContext(mapPlayerToProps)
)(OptionsDrawerPresentation);
