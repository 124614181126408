import styled from 'styled-components';
import { StyledLabel, StyledInput } from '../../Styles';

const StyledLabelAndInput = styled.label`
  ${StyledLabel};
  input {
    ${StyledInput};
  }
`;

const StyledProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.375rem;
`;

export { StyledLabelAndInput, StyledProfileContent };
