import React, { useCallback } from 'react';
import { Box } from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Close, Search } from '../../../../../assets/icons';
import { Input } from '../../Input';
import { debounce, set } from 'lodash';

interface SearchProps {
  width?: string;
  callBack?: () => void;
  currentRefinement: string;
  refine: (refinement: string) => void;
}

type Props = SearchProps & RouteComponentProps;

export function SearchBox({ currentRefinement, refine, history, width = '200px', callBack }: Props) {
  const [isSearching, setIsSearching] = React.useState<boolean>(false);

  function onClear() {
    setIsSearching(false);
    (document.getElementById('search-input') as HTMLInputElement).value = '';
    debouncedSearch('');
  }
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    debouncedSearch(event.target.value);
    setIsSearching(true);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (callBack) {
      setTimeout(() => callBack(), 500);
    }
    setIsSearching(true);
  }

  function onFormKeyUp(event: React.KeyboardEvent<HTMLFormElement>) {
    if (event.keyCode === 27) {
      // Escape
      event.currentTarget.reset();
      refine('');
    }
  }

  /*
    See this article for why we are using the useCallback hook around the debounce function.
    https://nodeployfriday.com/posts/react-debounce/
  */
  const debouncedSearch = useCallback(
    debounce((searchVal: string) => {
      history.push('/search');
      refine(searchVal);
    }, 300),
    []
  );

  return (
    <form onSubmit={onSubmit} onKeyUp={onFormKeyUp}>
      <Box style={{ position: 'relative' }}>
        <Input
          placeholder="Search"
          icon={
            isSearching ? (
              <Close width={20} style={{ transform: 'translateX(5px)' }} onClick={onClear} />
            ) : (
              <Search width={40} style={{ transform: 'translateX(10px)' }} />
            )
          }
          defaultValue=""
          onChange={onChange}
          style={{
            borderRadius: '2rem',
            width: width,
          }}
          id="search-input"
        />
      </Box>
    </form>
  );
}

export const ConnectedSearchBox = connectSearchBox(withRouter(SearchBox));
