import * as React from "react";

function SvgReligionDevil(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M19.875 8.75h-3.5a.75.75 0 000 1.5h.732a.25.25 0 01.177.427l-.237.237a4.022 4.022 0 00-1.172 2.828v3.7a2 2 0 01-2.958 1.758 7.71 7.71 0 00-1.417-.64.221.221 0 01-.156-.228l.009-.1a.25.25 0 01.252-.232h1.77a.5.5 0 00.5-.5v-3a5 5 0 00-10 0v3a.5.5 0 00.5.5h1.77a.25.25 0 01.249.229l.443 5.313a.5.5 0 00.5.458h3.08a.5.5 0 00.5-.458l.22-2.633a.25.25 0 01.369-.2 4.4 4.4 0 002.373.733 3.992 3.992 0 004-4v-3.7a2.011 2.011 0 01.586-1.414l.237-.237a.25.25 0 01.427.177V13a.75.75 0 001.5 0V9.5a.75.75 0 00-.754-.75z" />
      <path d="M4.418 3.293a.254.254 0 01.077.243 4.255 4.255 0 00-.12.964 4.5 4.5 0 009 0 4.255 4.255 0 00-.12-.964.252.252 0 01.077-.243A3.2 3.2 0 0014.375 1a1 1 0 00-2-.016.86.86 0 01-.011.108.25.25 0 01-.419.144 4.431 4.431 0 00-6.14 0 .25.25 0 01-.42-.145c0-.038-.008-.069-.009-.091V.986a1 1 0 00-2 .014 3.2 3.2 0 001.042 2.293zM8.875 7a2.5 2.5 0 01-2.5-2.5 2.2 2.2 0 01.013-.231.251.251 0 01.379-.186A6.25 6.25 0 0010.042 5a6.422 6.422 0 00.932-.069.25.25 0 01.275.322A2.494 2.494 0 018.875 7z" />
    </svg>
  );
}

export default SvgReligionDevil;
