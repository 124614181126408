export * from './AuthenticatedApolloProvider';

/**
 * Generic Types
 */
export interface Edge<T> {
  cursor: string;
  node: T;
}

export interface PageInfo {
  endCursor: String;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: String;
}

export interface List<T> {
  __typename: string;
  edges: Edge<T>[];
  pageInfo: PageInfo;
}

export interface MutationInput<T> {
  variables: T;
}

export type Mutation<T, U> = (input: MutationInput<T>) => Promise<MutationOutput<U>>;

export interface MutationOutput<T> {
  data: T;
}

export function buildMutationInput<T>(data: T): MutationInput<T> {
  return {
    variables: data,
  };
}
