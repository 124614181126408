import * as React from "react";

function SvgPause2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-labelledby="pause-icon"
      fill="none"
      height="16"
      viewBox="0 0 10 16"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id="pause-icon">Pause</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0.601624C2.32843 0.601624 3 1.27866 3 2.11382V14.2114C3 15.0465 2.32843 15.7236 1.5 15.7236C0.671573 15.7236 0 15.0465 0 14.2114V2.11382C0 1.27866 0.671573 0.601624 1.5 0.601624ZM8.5 0.601624C9.32843 0.601624 10 1.27866 10 2.11382V14.2114C10 15.0465 9.32843 15.7236 8.5 15.7236C7.67157 15.7236 7 15.0465 7 14.2114V2.11382C7 1.27866 7.67157 0.601624 8.5 0.601624Z"
        fill="#EFEFEF"
      />
    </svg>
  );
}

export default SvgPause2;
