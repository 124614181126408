import * as React from "react";

function SvgMicrophoneSingWoman(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <circle cx={13.752} cy={8.312} r={1} />
      <path d="M16.944 10.419a1.81 1.81 0 01-1.733-.2.75.75 0 00-.917 1.187 3.259 3.259 0 001.958.657 3.3 3.3 0 001.277-.262.75.75 0 10-.585-1.381zM6 18.833a3 3 0 003-3V12.5a3 3 0 00-6 0v3.333a3 3 0 003 3z" />
      <path d="M11 14a1 1 0 00-1 1v1a4 4 0 01-8 0v-1a1 1 0 00-2 0v1a6.011 6.011 0 004.8 5.879.248.248 0 01.2.244V23a1 1 0 002 0v-.877a.248.248 0 01.2-.244A6.01 6.01 0 0012 16v-1a1 1 0 00-1-1z" />
      <path d="M22.985 3.711c-.9-1.863-3.263-4.763-8.917-3.323a.716.716 0 00-.162.037A6.6 6.6 0 009.526 6.1c-.015.069-.024 2.2-.024 2.2a7.008 7.008 0 007 7 7.173 7.173 0 001.392-.137 89.525 89.525 0 01-.763 4.3 1.218 1.218 0 00.66 1.374 1.243 1.243 0 001.419-.232c4.16-3.219 6.136-12.028 3.775-16.894zm-4.813 9.156a.249.249 0 01-.175.218 5.1 5.1 0 01-1.5.222 5.006 5.006 0 01-5-5v-.8a.25.25 0 01.2-.245A6.556 6.556 0 0014.4 6.05a.25.25 0 01.359.059 6.275 6.275 0 001.976 1.841c.977.64 1.765 1.155 1.437 4.917z" />
    </svg>
  );
}

export default SvgMicrophoneSingWoman;
