import * as React from "react";

function SvgSinger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <g>
        <path d="M27.5802,11.69l2.2279-3.0911c-0.3156-0.8912-1.1604-1.5317-2.1722-1.5317   c-1.2717,0-2.3114,1.0397-2.3114,2.3114C25.3245,10.6411,26.3364,11.6622,27.5802,11.69z"></path>
        <path d="M33.4377,14.6605l1.3832,0.8262c0.3899,0.2414,0.9097,0.1578,1.2068-0.2135   c0.3156-0.3992,0.2507-0.9933-0.1486-1.3089l-1.2346-0.984L33.4377,14.6605z"></path>
        <path d="M39.3429,45.6039l-6.2394-30.4793l-1.1047,1.541l5.9887,29.2353   c-0.9764,0.2872-3.1351,1.1625-2.9426,2.1629c0.4364,2.0571,8.8707,0.3922,8.4473-1.6802   C43.3252,45.5668,41.5057,45.2419,39.3429,45.6039z"></path>
        <path d="M27.1506,25.8596l-1.3278-7.5197l4.2053-1.3217c0.4782-0.15,0.8947-0.451,1.1875-0.858l3.9257-5.4536   c0.7488-1.0402,0.5126-2.4902-0.5276-3.239c-1.0398-0.7497-2.4902-0.5126-3.239,0.5276l-3.4747,4.8268l-4.2271,1.3288   l-4.9987,0.8826l-5.672-4.6182l-1.9386-7.6669C10.7491,1.5057,9.4867,0.7547,8.2448,1.067   C7.002,1.3811,6.2495,2.6434,6.5637,3.8858l2.1272,8.4125c0.1224,0.4841,0.3975,0.9156,0.7846,1.2306l6.556,5.3376   c0.0236,0.0193,0.05,0.0322,0.0742,0.0503c1.8785,10.6391,1.5017,6.7048,1.6695,18.4568l-6.2178,7.8623   c-0.795,1.0053-0.6246,2.4648,0.3807,3.2599c1.0051,0.7945,2.4643,0.625,3.2599-0.3807l6.73-8.5095   c0.3313-0.4188,0.5077-0.9392,0.4999-1.4726l-0.1005-7.0179l2.7177-0.4798l3.6872,4.5547l-2.3384,10.193   c-0.2869,1.2492,0.4936,2.4943,1.7428,2.7808c1.2416,0.287,2.4929-0.4883,2.7808-1.7428l2.5913-11.2939   c0.1595-0.6953-0.0095-1.4251-0.4582-1.9794L27.1506,25.8596z"></path>
        <circle cx="20.0261" cy="8.9417" r="4"></circle>
      </g>
    </svg>
  );
}

export default SvgSinger;
