import styled, { css } from 'styled-components/macro';

export const LoadingSpinnerContainer = styled.div<{ variant?: 'fixed' | 'static' }>`
  top: 50%;
  left: 50%;
  position: absolute;
  /* half of loading spinners height added to height of nav bar */
  margin-top: -140px;
  margin-left: -48px;
  z-index: 1;

  ${({ variant }) =>
    variant === 'static' &&
    css`
      margin-left: 0;
      margin-top: 0;
      padding-bottom: 5rem;
      position: static;
      text-align: center;
    `}

  img {
    border-radius: 9rem;
  }
`;

export const CompactLoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
`;
