import { URLS } from '../../Core/Platform';
import React, { FC } from 'react';
import { Checkbox } from '../Checkbox';
import { Modal } from '../Modal';
import { StyledLabel, StyledSpan, StyledTitle, StyledLink } from './styled';
import { ExistingUserLegalModalProps } from './types';

export const ExistingUserLegalModal: FC<ExistingUserLegalModalProps> = ({ isOpen, handleSubmit }) => {
  const [hasAgreedToUserAgreementDate, setHasAgreedToUserAgreementDate] = React.useState(false);
  const [hasOptedOutOfMarketing, setHasOptedOutOfMarketing] = React.useState(false);

  return (
    <Modal
      variant="yellowDark"
      isOpen={isOpen}
      buttons={[
        {
          color: 'secondary',
          disabled: !hasAgreedToUserAgreementDate,
          label: "Let's go!",
          onClick: () => {
            handleSubmit(hasAgreedToUserAgreementDate, hasOptedOutOfMarketing);
          },
        },
      ]}
      title={<StyledTitle>Some legal stuff</StyledTitle>}
      content={
        <div>
          <p>
            At Pretzel, we don't like to break rules (the legal ones anyway!). We promise to keep your details private and NEVER sell to third
            parties.
          </p>
          <div>
            <StyledLabel>
              <Checkbox
                checked={hasAgreedToUserAgreementDate}
                onChange={() => setHasAgreedToUserAgreementDate(!hasAgreedToUserAgreementDate)}
                data-heapid="license-agreement"
                data-testid="license-agreement-checkbox"
              />
              <StyledSpan>
                I confirm that I have read and accept the{' '}
                <StyledLink href={URLS.LICENSE_SERVICES_AGREEMENT} target="_blank">
                  License Services Agreement
                </StyledLink>
              </StyledSpan>
            </StyledLabel>
          </div>
          <div>
            <StyledLabel>
              <Checkbox
                checked={hasOptedOutOfMarketing}
                onChange={() => setHasOptedOutOfMarketing(!hasOptedOutOfMarketing)}
                data-heapid="marketing"
                data-testid="marketing-checkbox"
              />
              <StyledSpan>I'm allergic to Pretzel marketing materials. Please don't send them</StyledSpan>
            </StyledLabel>
          </div>
        </div>
      }
      displayCloseIcon={false}
      heapid="modal-legal"
      testid="modal-legal"
    />
  );
};
