import { IIdentities, IIdentity, IdentityProviderType } from '@pretzel-aux/common/src/@types/Connected';
import getIdentities from '@pretzel-aux/common/src/Api/get-identities';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';
import { StyledHeading } from '@pretzel-aux/common/src/Components/Home/Components/Section/styled';
import { getAuthUrl } from '@pretzel-aux/common/src/Util';
import Discord from '@pretzel-aux/assets/icons/Discord';
import Twitch from '@pretzel-aux/assets/icons/Twitch';
import YouTube from '@pretzel-aux/assets/icons/Youtube';
import React from 'react';
import {
  StyledConnectedContent,
  StyledSocialWrapper,
  SocialDescriptionWrapper,
  ConnectBtnStyles,
  StyledUsernameContainer,
  DefaultAccountIcon,
} from './Styles';
import { Props } from './types';
import YouTubeChannelSelectModal from '../../../YouTubeChannelSelectModal/YouTubeChannelSelectModal';

const Connected = ({ userData }: Props) => {
  const [identities, setIdentities] = React.useState<IIdentities>();
  const [defaultId, setDefaultId] = React.useState<string>();
  const [twitch, setTwitch] = React.useState<IIdentity>();
  const [discord, setDiscord] = React.useState<IIdentity>();
  const [displayYoutubeModal, setDisplayYoutubeModal] = React.useState<boolean>(false);

  const getSocialIdentities = async () => {
    try {
      // Add interface above to request from API here.
      const ids = await getIdentities();
      setIdentities(ids);
      setDefaultId(ids.default);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    const t = identities?.identities.find(item => item.provider === IdentityProviderType.Twitch);
    setTwitch(t);

    const d = identities?.identities.find(item => item.provider === IdentityProviderType.Discord);
    setDiscord(d);
  }, [identities]);

  React.useEffect(() => {
    getSocialIdentities();
  }, []);

  const connect = (provider: string) => {
    window.location.href = getAuthUrl(provider);
  };

  return (
    <StyledConnectedContent>
      <StyledSocialWrapper>
        <SocialDescriptionWrapper>
          <Twitch color="white" width={32} />
          <div>
            <StyledHeading variant="H4">Twitch</StyledHeading>
            <StyledUsernameContainer>
              {twitch ? twitch.username : 'Connect your Twitch and Pretzel activity'}
              {identities?.default === twitch?.id && <DefaultAccountIcon />}
            </StyledUsernameContainer>
          </div>
        </SocialDescriptionWrapper>
        {!twitch && (
          <StyledButton color="outline" style={ConnectBtnStyles} onClick={() => connect(IdentityProviderType.Twitch)}>
            Connect
          </StyledButton>
        )}
      </StyledSocialWrapper>
      <StyledSocialWrapper>
        <SocialDescriptionWrapper>
          <Discord color="white" width={32} />
          <div>
            <StyledHeading variant="H4">Discord</StyledHeading>
            <StyledUsernameContainer>
              {discord ? discord.username : 'Connect your Discord and Pretzel activity'}
              {identities?.default === discord?.id && <DefaultAccountIcon />}
            </StyledUsernameContainer>
          </div>
        </SocialDescriptionWrapper>
        {discord ? (
          <StyledButton color="secondary" style={ConnectBtnStyles} onClick={() => connect(IdentityProviderType.Discord)}>
            Sync
          </StyledButton>
        ) : (
          <StyledButton color="outline" style={ConnectBtnStyles} onClick={() => connect(IdentityProviderType.Discord)}>
            Connect
          </StyledButton>
        )}
      </StyledSocialWrapper>
      <StyledSocialWrapper>
        <SocialDescriptionWrapper>
          <YouTube color="white" width={32} />
          <div>
            <StyledHeading variant="H4">YouTube</StyledHeading>
            <StyledUsernameContainer>
              {userData?.data.currentUser?.youtubeChannel ? userData?.data.currentUser.youtubeChannel.name : 'Connect your YouTube channel'}
            </StyledUsernameContainer>
          </div>
        </SocialDescriptionWrapper>
        {userData.data?.currentUser?.youtubeChannel ? (
          <StyledButton color="secondary" style={ConnectBtnStyles} onClick={() => setDisplayYoutubeModal(true)}>
            Update
          </StyledButton>
        ) : (
          <StyledButton color="outline" style={ConnectBtnStyles} onClick={() => setDisplayYoutubeModal(true)}>
            Connect
          </StyledButton>
        )}
      </StyledSocialWrapper>

      <p style={{ marginTop: '2.5rem' }}>
        Connecting your Pretzel account with Discord will automatically join you to our Discord server. If you have an active Pretzel Premium
        subscription you will also be granted access to the private Premium channel.
      </p>
      <p style={{ marginBottom: '0' }}>
        In case you left the server and rejoined or the Premium role is not correctly assigned, please use the sync function to get your Discord
        account re-connected.
      </p>
      {displayYoutubeModal && (
        <YouTubeChannelSelectModal
          onClose={() => {
            userData.refetch();
          }}
          setOpen={value => {
            setDisplayYoutubeModal(value);
          }}
        />
      )}
    </StyledConnectedContent>
  );
};

export default Connected;
