import * as React from 'react';
import { theme } from '@pretzel-aux/common/src/Styled';

function SvgPause(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg
      onMouseEnter={() => setFill(theme.colors.brandGreen)}
      onMouseLeave={() => setFill(theme.colors.offWhite)}
      width="60"
      height="48"
      viewBox="0 0 60 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="no-hover-effect"
    >
      <circle cx="30" cy="24" r="19.5" stroke={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 16.3464C27.3284 16.3464 28 17.0289 28 17.8709V30.0668C28 30.9087 27.3284 31.5913 26.5 31.5913C25.6716 31.5913 25 30.9087 25 30.0668V17.8709C25 17.0289 25.6716 16.3464 26.5 16.3464ZM33.5 16.3464C34.3284 16.3464 35 17.0289 35 17.8709V30.0668C35 30.9087 34.3284 31.5913 33.5 31.5913C32.6716 31.5913 32 30.9087 32 30.0668V17.8709C32 17.0289 32.6716 16.3464 33.5 16.3464Z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgPause;
