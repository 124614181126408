import * as React from 'react';

const SvgCompactAlbums = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 11H32.8696V32.9172H12V11Z" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.1304 35.109C15.1304 35.3996 15.2403 35.6784 15.436 35.8839C15.6317 36.0894 15.8971 36.2048 16.1738 36.2048H34.9565C35.2332 36.2048 35.4986 36.0894 35.6943 35.8839C35.89 35.6784 35.9999 35.3996 35.9999 35.109V15.3835C35.9999 15.0928 35.89 14.8141 35.6943 14.6086C35.4986 14.4031 35.2332 14.2876 34.9565 14.2876"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.478 24.6985C23.478 25.1344 23.6429 25.5525 23.9365 25.8608C24.23 26.1691 24.6281 26.3423 25.0432 26.3423C25.4584 26.3423 25.8565 26.1691 26.15 25.8608C26.4436 25.5525 26.6085 25.1344 26.6085 24.6985C26.6085 24.2625 26.4436 23.8444 26.15 23.5361C25.8565 23.2279 25.4584 23.0547 25.0432 23.0547C24.6281 23.0547 24.23 23.2279 23.9365 23.5361C23.6429 23.8444 23.478 24.2625 23.478 24.6985V24.6985Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1741 25.7942C16.1741 26.2301 16.339 26.6483 16.6325 26.9565C16.926 27.2648 17.3242 27.438 17.7393 27.438C18.1544 27.438 18.5525 27.2648 18.8461 26.9565C19.1396 26.6483 19.3045 26.2301 19.3045 25.7942C19.3045 25.3582 19.1396 24.9401 18.8461 24.6318C18.5525 24.3236 18.1544 24.1504 17.7393 24.1504C17.3242 24.1504 16.926 24.3236 16.6325 24.6318C16.339 24.9401 16.1741 25.3582 16.1741 25.7942V25.7942Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3044 25.7941V19.4973C19.3045 19.2593 19.3784 19.0278 19.5149 18.8378C19.6514 18.6478 19.8431 18.5096 20.061 18.4442L25.2784 16.8782C25.4337 16.8315 25.5973 16.8234 25.7561 16.8544C25.915 16.8854 26.0648 16.9548 26.1938 17.057C26.3228 17.1592 26.4274 17.2915 26.4994 17.4434C26.5714 17.5953 26.6089 17.7627 26.6088 17.9324V24.6983"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCompactAlbums;
