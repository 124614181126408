import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { PlatformContext, URLS, withPlatformContext } from '../../Core/Platform';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { CSSProperties } from 'react';

interface PublicProps {
  style?: CSSProperties;
}

type PropsFromContext = Pick<PlatformContext, 'pretzelUser' | 'openExternalLink'>;

interface LinkProps {
  children: React.ReactNode;
}

type Props = PublicProps & LinkProps & PropsFromContext;

function UpsellLinkPresentation({ children, pretzelUser, style }: Props) {
  const pricingURL = `${URLS.PRICING}#token=${pretzelUser.pretzelJWT}`;
  return (
    <ExternalLink href={pricingURL} style={style}>
      {children}
    </ExternalLink>
  );
}
function mapContextToProps(c: PlatformContext): PropsFromContext {
  return {
    openExternalLink: c.openExternalLink,
    pretzelUser: c.pretzelUser,
  };
}
export const UpsellLink = compose(withPlatformContext<PropsFromContext>(mapContextToProps))(UpsellLinkPresentation);
