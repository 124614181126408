import * as React from "react";

function SvgNoVocals(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M16 2.88C8.736 2.88 2.88 8.736 2.88 16S8.736 29.12 16 29.12 29.12 23.264 29.12 16 23.264 2.88 16 2.88zm0 24.864C9.504 27.744 4.256 22.4 4.256 16 4.256 9.504 9.6 4.256 16 4.256 22.496 4.256 27.744 9.6 27.744 16A11.731 11.731 0 0116 27.744zM12.256 10.24c1.28 0 2.24.96 2.24 2.24s-.96 2.24-2.24 2.24-2.24-.96-2.24-2.24.96-2.24 2.24-2.24zm7.488 0c1.28 0 2.24.96 2.24 2.24s-.96 2.24-2.24 2.24-2.24-.96-2.24-2.24.96-2.24 2.24-2.24zm.512 7.584c-.192-.32-.64-.448-.96-.224L16 19.744 12.704 17.6c-.32-.224-.768-.096-.96.224-.224.32-.128.736.192.96l2.784 1.792-2.784 1.824c-.32.224-.416.64-.192.96.096.224.32.32.512.32.128 0 .224 0 .32-.096l3.328-2.144 3.296 2.144c.096.096.224.096.32.096a.594.594 0 00.544-.32.72.72 0 00-.224-.96l-2.784-1.824 2.784-1.792c.544-.224.64-.64.416-.96z" />
    </svg>
  );
}

export default SvgNoVocals;
