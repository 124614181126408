import styled from 'styled-components';

const StyledKeybindsWrapper = styled.div`
  margin-top: 1.5rem;

  > div:not(:first-of-type) {
    margin-top: 2.5rem;
  }
`;

export { StyledKeybindsWrapper };
