import React, { FC, useState } from 'react';
import { Box, Heading } from 'grommet';
import { StyledModal, StyledModalCloseIcon, StyledModalContainer, StyledModalFooter, StyledModalTitle, StyledModalSubTitle } from './styled';
import { ModalProps } from './types';
import { theme } from '../../Styled';
import { Button } from '../Button';

export const Modal: FC<ModalProps> = ({
  buttons,
  content,
  displayCloseIcon = true,
  footerContent,
  isOpen,
  onClose,
  setIsOpen,
  subtitle,
  title,
  variant = 'primary',
  heapid,
  testid,
  ...rest
}) => {
  if (!isOpen) return null;

  const getTrackingId = (index: number): string => {
    return index > 0 ? `-${index}` : '';
  };

  return (
    <StyledModalContainer>
      <StyledModal variant={variant} data-heapid={heapid} data-testid={testid}>
        {displayCloseIcon && (
          <StyledModalCloseIcon
            onClick={() => {
              if (onClose) onClose();
              if (setIsOpen) setIsOpen(false);
            }}
          />
        )}

        <Box align="center">
          <StyledModalTitle data-heapid={`${heapid}-title`} data-testid={`${testid}-title`} variant={variant}>
            {title}
          </StyledModalTitle>

          {subtitle && <StyledModalSubTitle>{subtitle}</StyledModalSubTitle>}
        </Box>

        {content}

        {buttons && (
          <StyledModalFooter>
            {buttons.map(({ color, disabled, externalLink, label, onClick }, index) => (
              <Button
                color={color}
                externalLink={externalLink}
                id={`modal-button__${label}`}
                key={`modal-button__${label}`}
                onClick={onClick}
                size={'md'}
                style={{
                  marginRight: index !== buttons.length - 1 ? theme.space.md : 0,
                }}
                disabled={disabled}
                data-heapid={`${heapid}-${label.toLowerCase()}-button`}
                data-testid={`${testid}-button${getTrackingId(index)}`}
              >
                {label}
              </Button>
            ))}
          </StyledModalFooter>
        )}

        <Box align="center" data-heapid={`${heapid}-footer`} data-testid={`${testid}-footer`}>
          {footerContent}
        </Box>
      </StyledModal>
    </StyledModalContainer>
  );
};
