import * as React from "react";

function SvgHouseChimney1Alternate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.707 11.293l-2.634-2.634A.249.249 0 0121 8.482V2a1 1 0 00-1-1h-4a1 1 0 00-1 1 .243.243 0 01-.414.172L12.707.293a1 1 0 00-1.414 0l-11 11A1 1 0 001 13h1.25a.25.25 0 01.25.25V23a1 1 0 001 1H9a1 1 0 001-1v-5a2 2 0 014 0v5a1 1 0 001 1h5.5a1 1 0 001-1v-9.75a.25.25 0 01.25-.25H23a1 1 0 00.707-1.707zM19.5 12v9.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V18a4 4 0 00-8 0v3.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25V12a.97.97 0 00-.653-.929.25.25 0 01-.092-.412l8.068-8.068a.25.25 0 01.354 0l3.116 3.116A1 1 0 0017 5V3.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V9a1 1 0 00.293.707l.952.952a.25.25 0 01-.092.412.972.972 0 00-.653.929z" />
    </svg>
  );
}

export default SvgHouseChimney1Alternate;
