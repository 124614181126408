import React from 'react';
import fallbackArt from '@pretzel-aux/assets/images/default_artwork.png';
import { AddIcon, AddToPlaylistWrapper, Art, ArtWrapper, Wrapper } from './Styles';
import { toUrl } from '../../../../Util';
import { PlayTokenFragment } from '../../Queries/types/PlayTokenFragment';

interface PublicProps {
  playToken: PlayTokenFragment | null;
  addToPlaylist?: () => void;
}

type Props = PublicProps;

export class LargeAlbumArt extends React.Component<Props> {
  public render() {
    const artworkSource = (this.props.playToken && toUrl(this.props.playToken.track.artworkGuid)) || fallbackArt;
    return (
      <Wrapper style={{ marginBottom: '5px' }}>
        <ArtWrapper>
          <Art src={artworkSource} draggable={false} />
          {/*
          <AddToPlaylistWrapper className="no-drag" onClick={this.handleAddToPlaylist}>
            <AddIcon className="pretzel-icon-add-playlist" />
          </AddToPlaylistWrapper>
          {/*
          {props.trackSource && props.trackSource.type === 'song_request' && (
            <SongRequestOverlay>
              <SRName title={props.trackSource.details.requester}>
                <i className="pretzel-icon-song-request" />{' '}
                {props.trackSource.details.requester}
              </SRName>
              <SRAmount>
                {props.trackSource.details.others > 0
                  ? `+ ${props.trackSource.details.others} `
                  : ''}{' '}
                {props.trackSource.details.bit_total}{' '}
                <i className="pretzel-icon-bits" />
              </SRAmount>
            </SongRequestOverlay>
          )}
          */}
        </ArtWrapper>
      </Wrapper>
    );
  }

  private handleAddToPlaylist() {
    if (this.props.addToPlaylist) this.props.addToPlaylist();
  }
}
