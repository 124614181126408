import * as React from "react";

function SvgUpdate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 29 32" {...props}>
      <path d="M28.288 17.408q.768 1.664.448 3.072l-1.088 5.888q-.064.64-.608 1.12t-1.248.48H3.008q-.704 0-1.248-.48t-.608-1.12L.064 20.48q-.256-1.6.448-3.072L5.568 5.44q.704-1.472 2.304-1.472H11.2l-.64 6.528H6.272l8.128 6.72 8.192-6.72H18.24l-.576-6.528h3.264q1.6 0 2.368 1.472zm-2.176 4.224q.064-.704-.32-1.216t-1.088-.512H4.096q-.704 0-1.088.512t-.32 1.216L3.136 24q.064.704.608 1.184t1.184.48h18.944q.704 0 1.248-.48T25.728 24z" />
    </svg>
  );
}

export default SvgUpdate;
