import * as React from "react";

function SvgSrQueue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 48 32" {...props}>
      <path d="M46.592 22.656V22.08c0-.192 0-.192-.192-.384v-.192L35.008 3.36c-.192-.352-.768-.544-1.12-.544s-.96.192-1.152.544L21.344 21.504v.192c0 .192 0 .192-.192.384v.928c.192.192.192.384.384.384l11.392 6.368c.192.192.384.192.768.192s.352 0 .736-.192l11.424-6.368.192-.192.16-.192v-.192c.384 0 .384-.16.384-.16zM33.888 6.72l7.104 11.424-6.56-3.744c-.384-.192-.928-.192-1.504 0L26.4 18.144 33.888 6.72zm0 20.224l-8.608-4.672 8.608-5.056 8.608 5.056-8.608 4.672zM.736 1.856h23.392v2.816H.736V1.856zm0 11.04h15.936v2.816H.736v-2.816zm0 11.04h15.936v2.816H.736v-2.816z" />
    </svg>
  );
}

export default SvgSrQueue;
