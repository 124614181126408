import * as React from "react";

function Cowboy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="1 0 100 100">
      <g>
        <path d="M60.158,7.17l-0.563-2.145c-1.045-3.283-4.49-5.215-7.854-4.305c-3.369,0.9-5.372,4.305-4.633,7.669l0.582,2.144l0,0l0,0   l-5.981,1.581l0.647,2.299l24.491-6.561l-0.65-2.302L60.158,7.17z"></path>
        <path d="M48.234,12.385l0.007,0.034c1.129,3.911,4.703,6.253,8.128,5.327c3.422-0.917,5.323-4.74,4.342-8.691l-0.01-0.034   L48.234,12.385z"></path>
      </g>
      <g>
        <path d="M5.029,33.321l11.11-10.154l-1.62-7.889l24.934,22.827c-2.185,2.805-6.014,5.259-10.198,6.541   c-3.085,0.944-6.47,0.637-7.886,0.62l1.079,2.481c0,0,4.747,0.108,7.494-0.782c4.538-1.47,8.809-4.089,11.314-7.204   c0,0-0.02-0.563-0.02-0.941c0-2.576,2.215-4.75,4.686-4.625c0.479,0.003,6.382,0.007,9.809,0.007V23.713   c0-3.065,2.488-5.553,5.55-5.553c3.067,0,5.556,2.488,5.556,5.553v14.442c0,1.844-0.917,3.469-2.3,4.48h21.698   c2.696,0,4.982,0.961,6.863,2.879c1.882,1.925,2.818,4.234,2.818,6.922v16.401h-6.911V52.665l-3.58-5.535v20.39l3.58,5.646   l-0.026,22.442c0,2.003-1.625,3.625-3.628,3.625c-2.002,0-3.62-1.622-3.62-3.625l-0.027-18.185l0.021-0.017L67.612,60.349   l-1.611,1.301l10.768,13.017l-8.866,22.592c-0.722,1.86-2.818,2.788-4.687,2.066c-1.867-0.722-2.791-2.822-2.07-4.689l7.229-18.414   l-5.428-7.319l-19.576,0.027V46.357l10.448,10.451c0.506,0.506,1.326,0.506,1.832,0s0.506-1.328,0-1.834L42.993,42.315   c-0.374-0.371-0.924-0.475-1.413-0.28c-0.482,0.202-0.799,0.674-0.799,1.197l0.013,52.373c0,2.003-1.622,3.624-3.624,3.624   s-3.624-1.621-3.624-3.624v-26.65H20.739l3.264,10.391c0.6,1.908-0.462,3.944-2.374,4.545c-0.361,0.111-0.729,0.165-1.085,0.165   c-1.541,0-2.974-0.991-3.459-2.539l-4.744-15.1c-0.344-1.1-0.145-2.3,0.536-3.229c0.685-0.931,1.77-1.48,2.923-1.48h12.562   l-5.893-13.954c-1.126-0.091-2.222-0.246-3.243-0.543c-3.405-0.998-5.721-3.034-6.527-5.741c-0.104-0.347-0.152-0.708-0.202-1.069   l-4.498,1.055c-1.314,0.219-2.501-0.091-3.567-0.93c-1.068-0.843-1.594-1.925-1.594-3.247C2.838,35.597,3.569,34.275,5.029,33.321z    M15.011,40.782c0.566,1.891,2.303,3.351,4.896,4.106c0.455,0.135,0.947,0.202,1.433,0.287l-2.721-6.207l-3.776,0.883   C14.87,40.168,14.924,40.481,15.011,40.782z"></path>
      </g>
    </svg>
  );
}

export default Cowboy;
