import { useQuery } from '@apollo/client';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { loader } from 'graphql.macro';
import React, { useContext, useState } from 'react';
import { StationCard } from './Components/StationCard';
import { StationsListQuery, StationsListQuery_stations_edges } from './types/StationsListQuery';
import { LoadingSpinner } from '../LoadingSpinner';
import platformContext, { PlatformContext } from '../../Core/Platform';
import { MusicList, theme } from '../../Styled';

const stationsGqlQuery = loader('./stations.graphql');

export const StationsList = (): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const [state, setState] = useState<boolean>(false);
  const response = useQuery<StationsListQuery>(stationsGqlQuery, {
    variables: {
      first: 80,
    },
  });
  const { width } = useWindowSize();

  const stationCompare = (a: StationsListQuery_stations_edges, b: StationsListQuery_stations_edges) => {
    if (platformContextObj.pretzelUser.jwt.tier === 'free') {
      return b.node.entitlements.indexOf('free') - a.node.entitlements.indexOf('free');
    }
    return 0;
  };

  const setTileSize = (): number => {
    if (width >= theme.appBreakpoints.small && width < theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.small);
    }
    if (width >= theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.normal);
    }
    return parseInt(theme.card.size.large);
  };

  const setStationCardSize = (): string => {
    if (width <= theme.appBreakpoints.medium) {
      return theme.card.size.featured;
    }
    return theme.card.size.large;
  };

  const stations = response.loading
    ? null
    : response.data.stations.edges
        .slice()
        .sort((a, b) => stationCompare(a, b))
        .map(stationEdge => <StationCard key={stationEdge.node.id} station={stationEdge.node} size={setStationCardSize()} />);

  const hasMore = response.data?.stations?.pageInfo?.hasNextPage;

  const onMore = (): void => {
    const after = response.data.stations.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'station',
      currentLength: response.data.stations.edges.length,
      after,
    });
    setState(true);

    response.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: StationsListQuery, { fetchMoreResult }) => {
        setState(false);
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          stations: Object.assign({}, fetchMoreResult.stations, {
            edges: [...previousQueryResult.stations.edges, ...fetchMoreResult.stations.edges],
          }),
        };
      },
    });
  };

  if (response.loading) {
    return <LoadingSpinner />;
  }

  return <MusicList title="Stations" items={stations} hasMore={hasMore} onMore={onMore} loading={state} tileSize={setTileSize()} />;
};
