import * as React from 'react';

export function createContext<Context extends object>(name: string, fallbackState?: Context) {
  const context = React.createContext(fallbackState);
  function withContext<ProvidedProps, P = {}>(mapContextToProps: (c: Context, ownProps: P) => ProvidedProps) {
    return (WrappedComponent: React.ComponentType<P>) => {
      return class WithHOCContextConsumer extends React.Component<P> {
        public static displayName = `With${name}`;
        public render() {
          return (
            <context.Consumer>
              {value => {
                const injectedProps = value ? mapContextToProps(value, this.props) : {};
                return <WrappedComponent {...this.props} {...injectedProps} />;
              }}
            </context.Consumer>
          );
        }
      };
    };
  }

  return { context, withContext };
}
