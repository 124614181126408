import * as React from 'react';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { Alert } from './Files';
import Pusher from 'pusher-js';
import { loader } from 'graphql.macro';
import { PlatformContext, withPlatformContext } from '../Platform';
import { SongRequestQueueQuery } from '../../Components/SongRequests/types/SongRequestQueueQuery';
const songRequestQueueQuery = loader('../../Components/SongRequests/song-requests.graphql');

type PropsFromContext = Pick<PlatformContext, 'notify' | 'pretzelUser'>;

interface PropsFromApollo {
  data: QueryControls & SongRequestQueueQuery;
}

interface RequestMessage {
  songGuid: string;
}

type Props = PropsFromContext & PropsFromApollo;

class SongRequestAlertsPresentation extends React.Component<Props> {
  private audio: HTMLAudioElement = new Audio();
  private pusher?: Pusher.Pusher;
  private channel?: Pusher.Channel;

  constructor(props: Props) {
    super(props);
    this.audio.preload = 'auto';
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    //TODO: Turn Pusher back on when Song Requests get turned back on.
    return;
    if (this.props.pretzelUser && this.props.pretzelUser !== prevProps.pretzelUser) {
      if (this.channel) {
        this.channel.unbind_all();
      }
    }
    if (!this.channel && this.props.pretzelUser) {
      if (this.props.data.currentUser && this.props.data.currentUser.songRequestsEnabled) {
        if (!this.pusher) {
          // TODO: Make pusher hit `dev` if you're hitting a non-prod server -- 3f82f44da897099d1688
          this.pusher = new Pusher('9cace047a8764840eee4', {
            cluster: 'us3',
            // @ts-ignore
            forceTLS: true,
          });
        }
        this.channel = this.pusher!.subscribe(`song-request-by-guid.${this.props.pretzelUser.id}`);
        this.channel!.bind('request', this.handleRequest);
        this.channel!.bind('boost', this.handleBoost);
      }
    }
  }

  public render(): null {
    return null;
  }

  private handleRequest = (data: RequestMessage) => {
    console.debug('Got Request Message', data);
    this.getQueue();
    // this.playAlert(ALERT_1);
    // this.sendMessageToFeed('Got Request');
  };

  private handleBoost = (data: RequestMessage) => {
    console.debug('Got Boost Message', data);
    this.getQueue();
    // this.playAlert(ALERT_11);
    // this.sendMessageToFeed('Got Boost');
  };

  private getQueue() {
    // Instead of spending time getting the messages just right, we'll just be refreshing the queue from the server
    // This specific implementation is wasteful in that it calls it when the component loads, but client.query() doesn't update
    // the cache the way that it should... So we just use the graphql hoc
    this.props.data.refetch();
  }

  private playAlert(alert: Alert) {
    this.audio.src = alert.src;
    this.audio.play();
  }

  private sendMessageToFeed(message: string) {
    this.props.notify(message);
  }
}

function mapContextToProps(c: PlatformContext): PropsFromContext {
  return {
    notify: c.notify,
    pretzelUser: c.pretzelUser,
  };
}

export const SongRequestAlerts = compose(graphql(songRequestQueueQuery), withPlatformContext(mapContextToProps))(SongRequestAlertsPresentation);
