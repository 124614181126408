import * as React from 'react';

export function getBoolean(key: string): boolean | null {
  const value = window.localStorage.getItem(key);
  return value === null ? null : value === 'true';
}

export function setBoolean(key: string, value: boolean) {
  window.localStorage.setItem(key, value.toString());
}

export function getString(key: string): string | null {
  return window.localStorage.getItem(key);
}

export function setString(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

export function getInt(key: string): number | null {
  const value = window.localStorage.getItem(key);
  return value === null ? null : parseInt(value, 10);
}

export function setInt(key: string, value: number) {
  window.localStorage.setItem(key, value.toString());
}

export function getNumber(key: string): number | null {
  const value = window.localStorage.getItem(key);
  return value === null ? null : parseFloat(value);
}

export function setNumber(key: string, value: number) {
  window.localStorage.setItem(key, value.toString());
}

interface Getter<T> {
  (key: string): T | null;
}

export function getValueOrDefault<T>(getter: Getter<T>, key: string, defaultValue: T): T {
  const value = getter(key);
  return value === null ? defaultValue : value;
}
