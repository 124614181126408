import * as React from "react";

function SvgShuffle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M22.944 21.024c-2.368-.224-4.064-.64-5.248-1.6a.836.836 0 00-1.152.192.875.875 0 00.192 1.184c1.408 1.056 3.52 1.696 6.208 1.92v2.656l3.936-3.392-3.936-3.424v2.464zm0-10.048v2.56l3.936-3.392-3.936-3.424v2.656c-7.168.544-8.224 3.744-9.088 6.72-.832 2.88-1.6 5.12-7.872 5.12-.448 0-.864.32-.864.864s.32.864.864.864c7.456 0 8.512-3.328 9.472-6.208.768-2.88 1.504-5.312 7.488-5.76zM5.856 10.88c4.48 0 5.888 1.184 6.624 2.144.096.192.416.32.64.32s.32-.128.544-.224c.32-.32.416-.736.096-1.184-1.504-1.792-3.936-2.656-7.904-2.656-.416 0-.736.32-.736.736s.32.864.736.864z" />
    </svg>
  );
}

export default SvgShuffle;
