import { flowRight as compose } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import * as React from 'react';
import { SignInPage } from 'src/PretzelRocks/GraphQL/SignInPage';
import { AuthenticatedApolloProvider } from '@pretzel-aux/common/src/Core/GraphQL';
import { PretzelUser, PlatformContext, withPlatformContext, parsePretzelJWT } from '@pretzel-aux/common/src/Core/Platform';
import Banned from "@pretzel-aux/common/src/Components/banned";

type PropsFromPlatformContext = Pick<PlatformContext, 'setPretzelUser' | 'pretzelUser'>;
type Props = PropsFromPlatformContext & RouteComponentProps<{}>;

interface State {
  error: boolean;
  loaded: boolean;
  receivedToken: boolean;
  signup: boolean;
  pretzelUser?: PretzelUser;
}

export class StreamlabsToPretzelAuthenticatedApolloProvider extends React.Component<Props, State> {
  state: State = {
    error: false,
    loaded: true,
    receivedToken: false,
    signup: false,
  };

  public render() {
    if (this.props.pretzelUser && this.props.pretzelUser.pretzelJWT) {
      const u = parsePretzelJWT(this.props.pretzelUser.pretzelJWT);

      if (u.jwt.banned) {
        return (<Banned />);
      }
      return (
        <AuthenticatedApolloProvider jwt={this.props.pretzelUser.pretzelJWT}>
          {this.props.children}
        </AuthenticatedApolloProvider>
      );
    } else if (this.state.loaded) {
      return <SignInPage />;
    }
    return null;
  }
}

function mapContextToProps(c: PlatformContext): PropsFromPlatformContext {
  return {
    pretzelUser: c.pretzelUser,
    setPretzelUser: c.setPretzelUser,
  };
}

export const PretzelRocksAuthenticatedApolloProvider = compose(
  withRouter,
  withPlatformContext<PropsFromPlatformContext>(mapContextToProps),
)(StreamlabsToPretzelAuthenticatedApolloProvider);
