import * as React from 'react';

function SvgDiscord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 33 32" {...props}>
      <path
        fill={props.color}
        d="M13.92 13.856c-.768 0-1.344.672-1.344 1.472s.608 1.472 1.344 1.472c.768 0 1.376-.64 1.376-1.472 0-.8-.608-1.472-1.376-1.472zm4.864 0c-.768 0-1.344.672-1.344 1.472s.608 1.472 1.344 1.472c.768 0 1.376-.64 1.376-1.472s-.608-1.472-1.376-1.472zm6.496-11.2H7.392c-1.504 0-2.72 1.248-2.72 2.752v18.016c0 1.536 1.216 2.752 2.72 2.752h15.136l-.704-2.464 1.696 1.6 1.6 1.472 2.88 2.56V5.408c0-1.504-1.216-2.752-2.72-2.752zm-5.152 17.408s-.48-.544-.896-1.056c1.76-.512 2.432-1.6 2.432-1.6-.544.352-1.088.608-1.536.8-.672.256-1.312.448-1.952.576a9.568 9.568 0 01-3.456-.032 9.426 9.426 0 01-1.952-.576 5.094 5.094 0 01-.96-.448c-.032-.032-.096-.032-.128-.064-.032 0-.032-.032-.064-.032-.224-.128-.352-.224-.352-.224s.64 1.056 2.336 1.568c-.416.512-.896 1.088-.896 1.088C9.76 20 8.64 18.048 8.64 18.048c0-4.288 1.92-7.776 1.92-7.776 1.92-1.44 3.744-1.408 3.744-1.408l.128.16c-2.4.704-3.52 1.76-3.52 1.76s.288-.16.8-.384c1.408-.64 2.56-.8 3.008-.832.096-.032.16-.032.256-.032.8-.096 1.728-.128 2.688-.032 1.248.16 2.624.512 4 1.28 0 0-1.056-.992-3.328-1.696l.192-.224s1.824-.032 3.744 1.408c0 0 1.92 3.488 1.92 7.776 0 0-1.12 1.952-4.064 2.016z"
      />
    </svg>
  );
}

export default SvgDiscord;
