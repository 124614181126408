import * as React from "react";

function SvgLivestreamer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 48 32" {...props}>
      <path d="M47.04 2.944c-.384-.224-.896-.224-1.216 0l-8.16 4.96V1.888c0-.64-.576-1.216-1.216-1.216H1.888c-.64 0-1.216.576-1.216 1.216v28.384c0 .672.576 1.248 1.216 1.248h34.464a1.27 1.27 0 001.248-1.248v-6.016l8.128 4.96c.416.224.832.224 1.248 0 .416-.256.64-.672.64-1.088V4.032c0-.512-.224-.896-.576-1.088zm-1.888 23.04l-8.128-4.928c-.416-.256-.832-.256-1.248 0s-.64.672-.64 1.056v7.008h-32V3.136h32v6.976c0 .416.224.832.64 1.088.416.224.928.224 1.248 0l8.128-4.96v19.744zm-18.08-4.832L23.36 8.224h-12l2.944 10.432c-1.888 0-3.36 1.568-3.36 3.392 0 1.888 1.568 3.36 3.36 3.36s3.392-1.568 3.392-3.36c0-.32-.096-.672-.16-.896l-2.88-10.464h6.912l2.208 7.968c-1.888 0-3.36 1.568-3.36 3.392 0 1.888 1.536 3.36 3.36 3.36s3.36-1.568 3.36-3.36c0-.32 0-.576-.064-.896z" />
    </svg>
  );
}

export default SvgLivestreamer;
