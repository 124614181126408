import * as React from 'react';

function SvgThumbsDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 22.341V22.341C15.1716 22.341 14.5 21.6695 14.5 20.841C14.5 20.0126 15.1716 19.341 16 19.341H17C16.1716 19.341 15.5 18.6695 15.5 17.841C15.5 17.012 16.172 16.841 17 16.841H23.5C26.351 16.841 27 17.341 30.5 17.841V26.341C28.05 26.341 24 30.841 24 34.841C24 36.422 21.811 37.011 21 34.122C20.5 32.341 22 28.841 22 28.841H14C13.1716 28.841 12.5 28.1695 12.5 27.341C12.5 26.512 13.172 25.341 14 25.341H15C14.1716 25.341 13.5 24.6695 13.5 23.841C13.5 23.0126 14.1716 22.341 15 22.341H16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M30.5 15.841H35.5V27.841H30.5V15.841Z" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M32.75 18.341C32.8881 18.341 33 18.2291 33 18.091C33 17.953 32.8881 17.841 32.75 17.841C32.6119 17.841 32.5 17.953 32.5 18.091C32.5 18.2291 32.6119 18.341 32.75 18.341"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgThumbsDown;
