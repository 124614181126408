import styled from 'styled-components';
import { theme } from '../../Styled/index';

export const StyledUpgradeBanner = styled.div`
  align-items: center;
  display: flex;
  margin: 1rem 2rem;
  .upgrade-banner__text {
    display: none;
    font-family: ${theme.font.familySecondary};
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 1.5rem;
    ${theme.mediaQueries.small} {
      display: block;
    }
  }
  .upgrade-banner__button {
    height: unset;
    min-height: 2.5rem;
    width: 100%;
    ${theme.mediaQueries.small} {
      width: initial;
    }
  }
  ${theme.mediaQueries.large} {
    display: none;
  }
  @media all and (max-width: ${theme.appBreakpoints.extraSmall}px) {
    display: none;
  }
`;
