import React, { FC, Fragment } from 'react';
import { Heading } from '../Heading';
import { StyledPageIntro } from './styled';
import { PageIntroProps } from './types';

export const PageIntro: FC<PageIntroProps> = ({ title, description }) => (
  <StyledPageIntro>
    <Fragment>
      <Heading variant="H1">{title}</Heading>
      {description && <p>{description}</p>}
    </Fragment>
  </StyledPageIntro>
);
