import * as React from "react";

function SvgConcertDj(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.942 22.088l-1.428-5A1.508 1.508 0 0021.071 16H2.929a1.507 1.507 0 00-1.443 1.088l-1.428 5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.442-1.912zM7.01 22c-1.682 0-3-.879-3-2s1.318-2 3-2 3 .879 3 2-1.318 2-3 2zM17 22c-1.682 0-3-.879-3-2s1.318-2 3-2 3 .879 3 2-1.318 2-3 2z" />
      <circle cx={12} cy={7} r={3} />
      <path d="M16.685 14.5a.507.507 0 00.453-.726 5.669 5.669 0 00-10.276 0 .507.507 0 00.453.726zM18.178 4.344a.5.5 0 01-.332-.3 6.25 6.25 0 00-11.689-.013.5.5 0 01-.336.305 2.5 2.5 0 00.638 4.914H7.5a.5.5 0 00.5-.5V5a.983.983 0 01.078-.386 4.249 4.249 0 017.844 0A1 1 0 0116 5v3.75a.5.5 0 00.5.5h1a2.5 2.5 0 00.678-4.906z" />
    </svg>
  );
}

export default SvgConcertDj;
