import * as React from 'react';

function SvgMute(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 30.5L23.647 33.146C23.79 33.2885 24.0046 33.3311 24.1912 33.2539C24.3777 33.1767 24.4996 32.9949 24.5 32.793V25.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 19.5V15.207C24.4996 15.0051 24.3777 14.8233 24.1912 14.7461C24.0046 14.6689 23.79 14.7115 23.647 14.854L17.5 21H14C13.1716 21 12.5 21.6716 12.5 22.5V25.5C12.5 26.3284 13.1716 27 14 27H16.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27.5 24C27.5 25.3807 26.3807 26.5 25 26.5H24.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 34L33.5 14" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SvgMute;
