import * as React from "react";

function SvgCloseCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M22.688 9.312c-.224-.32-.672-.32-1.024 0l-5.728 5.728-5.6-5.728c-.352-.32-.8-.32-1.024 0s-.32.672 0 1.024l5.728 5.728-5.728 5.728a.538.538 0 000 .896c.128.096.352.224.448.224s.352-.128.448-.224l5.728-5.728 5.728 5.728c.128.096.352.224.448.224s.352-.128.448-.224c.224-.224.224-.672 0-1.024l-5.728-5.728 5.728-5.728c.448-.224.448-.672.128-.896zM16.064.672C7.52.672.672 7.52.672 16.064S7.52 31.456 16.064 31.456s15.392-6.88 15.392-15.392S24.48.672 16.064.672zm0 29.312c-7.648 0-13.92-6.304-13.92-13.92S8.32 2.016 16.064 2.016s13.92 6.304 13.92 13.92-6.304 14.048-13.92 14.048z" />
    </svg>
  );
}

export default SvgCloseCircle;
