import * as React from "react";

function RecentlyPlayed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g>
        <g>
          <g>
            <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"></path>
            <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"></path>
            <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"></path>
            <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"></path>
          </g>
          <g>
            <path d="M84.3,18.1c-8.5-8.5-19.8-13.1-32-13.1c-11.7,0-22.7,4.3-31,12.2l-2.5,2.5l-5.4-5.4c-1.4-1.4-3.7-0.7-4.2,1.2L2.6,42.5     c-0.4,1.8,1.2,3.4,3,3l27.1-6.6c1.9-0.5,2.5-2.8,1.2-4.2l-6.3-6.3l2.6-2.6C43.1,14,63,14.4,75.5,26.9c7,7,10.4,16.6,9.4,26.5     c-1,9.8-6.4,18.6-14.7,24.2c-11.7,7.7-28,6.7-38.8-2.4c-3.1-2.6-5.6-5.7-7.6-9.1c-0.4-0.7-1.2-1.1-1.8-0.8L13.1,69     c-0.4,0.2-0.7,0.5-0.9,0.9c-0.1,0.4-0.1,0.8,0.1,1.1c2,3.9,4.6,7.4,7.7,10.6c8.7,8.9,20.6,13.5,32.5,13.5c9.6,0,19.2-3,27.1-9     c10.5-8,16.9-20,17.8-33C98.3,40,93.5,27.3,84.3,18.1z"></path>
            <path d="M56.2,28.4h-7.8c-0.8,0-1.5,0.7-1.5,1.5v22.5c0,1.9,1.2,3.5,2.9,4.2l17,6.1c0.8,0.3,1.7-0.1,1.9-0.9l2.6-7.3     c0.1-0.4,0.1-0.8-0.1-1.2c-0.2-0.4-0.5-0.6-0.9-0.8l-11.4-4.1c-0.8-0.3-1.4-1.1-1.4-2V29.9C57.7,29,57.1,28.4,56.2,28.4z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RecentlyPlayed;
