import * as React from 'react';
import { theme } from '@pretzel-aux/common/src/Styled';

function SvgPrevious(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg
      onMouseEnter={() => setFill(theme.colors.brandGreen)}
      onMouseLeave={() => setFill(theme.colors.offWhite)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0004 15H29.0004C32.5903 15 35.5004 17.9101 35.5004 21.5C35.5004 25.0899 32.5903 28 29.0004 28H12.5004"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.5004 32.9967L12.5004 27.9967L17.5004 22.9967" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SvgPrevious;
