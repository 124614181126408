import styled from 'styled-components/macro';
import { theme } from '../../Styled';

export const Wrapper = styled.div`
  height: 74px;
  width: 100%;
  ${'' /* font-size: 12px; */}
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 12px;
  background: ${theme.colors.baseBackground};
  z-index: 7;
`;

export const Handle = styled.div`
  width: 30px;
  background: ${theme.colors.grey};
  opacity: 0.5;
  border-radius: 0.5rem;
  height: 0.25rem;
  align-self: center;
  margin: 1rem;
`;
