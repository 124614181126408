import { theme } from '../../Styled';
import styled from 'styled-components';

/**
 * The overall table container
 */
export const StyledTable = styled.table``;

/**
 * Table body
 */
export const StyledTableBody = styled.tbody``;

/**
 * Table header (column headings)
 */
export const StyledTableHead = styled.thead``;

/**
 * The Heading of the overall table, displayed at the
 * top of the table (outside)
 */
export const StyledTableHeading = styled.h3``;

/**
 * Column heading
 */
export const StyledTableTh = styled.th`
  color: ${theme.colors.light1};
  font-size: 1rem;
  height: 40px;
  padding: ${theme.space.sm} ${theme.space.md};
  text-align: center !important;

  :last-child {
    border-left: 2px solid ${theme.colors.primary};
    border-right: 2px solid ${theme.colors.primary};
    border-top: 2px solid ${theme.colors.primary};
  }
`;

/**
 * Cell
 */
export const StyledTableTd = styled.td`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: ${theme.colors.offWhite};
  font-size: 0.75rem;
  height: 40px;
  padding: ${theme.space.sm} ${theme.space.md};
  text-align: center !important;

  :first-child {
    text-align: left !important;
    padding-left: 0;
  }

  :last-child {
    border-left: 2px solid ${theme.colors.primary};
    border-right: 2px solid ${theme.colors.primary};
  }
`;

/**
 * Row
 */
export const StyledTableTr = styled.tr`
  /** Global override */
  background-color: transparent !important;

  :last-child {
    td {
      border-bottom: none;
      :last-child {
        border-bottom: 2px solid ${theme.colors.primary};
      }
    }
  }
`;

/**
 * Temp Icon container
 */
export const Icon = styled.div`
  align-items: center;
  background-color: ${theme.colors.primary};
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: auto;
  width: 20px;
`;
