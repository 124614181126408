import styled from 'styled-components';
import { theme, Icon } from '../Styled';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { Copy, Download, TrashCan } from '../../../assets/icons';
import React from 'react';

export const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast {
    background: ${theme.colors.bg};
    font-family: ${theme.font.family};
    font-size: 1rem;
    min-height: 70px;
    width: 300px;
  }

  .Toastify__toast-icon {
    width: 40px;
    margin-inline-end: 1.563rem;
  }

  .remove-playlist-toast .Toastify__toast-icon {
    margin-inline-end: 1rem;
  }

  .Toastify__progress-bar {
    background: ${theme.colors.brandGreen};
  }
`;

export const DividerContainer = styled.div`
  height: 60px;
  margin: -0.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.875rem;
`;

export const TrashIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;

export const StyledDivider = styled.div`
  width: 5px;
  height: 100%;
  background-color: ${theme.colors.brandGreen};
  border-radius: 1.25rem;
`;

const baseConfig = {
  position: toast.POSITION.BOTTOM_RIGHT,
  style: { bottom: '100px' },
};

export const DownloadToastConfig = {
  ...baseConfig,
  icon: (
    <>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <IconContainer>
        <Download />
      </IconContainer>
    </>
  ),
};

export const RemoveFromPlaylistToastConfig = {
  ...baseConfig,
  className: 'remove-playlist-toast',
  icon: (
    <>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <TrashIconContainer>
        <TrashCan />
      </TrashIconContainer>
    </>
  ),
};

export const ShareToastConfig = {
  ...baseConfig,
  icon: (
    <>
      <DividerContainer>
        <StyledDivider />
      </DividerContainer>
      <IconContainer>
        <Copy />
      </IconContainer>
    </>
  ),
};

export const generateToastConfig = (type: string) => {
  return {
    ...baseConfig,
    icon: (
      <>
        <DividerContainer>
          <StyledDivider />
        </DividerContainer>
        <IconContainer>{type === 'Playlist' ? <Icon size={24} name={type} /> : <Icon name={type} noFill svgColour="white" />}</IconContainer>
      </>
    ),
  };
};
