import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { PlatformContext, withPlatformContext } from '../../Core/Platform';
import { CSSProperties } from 'react';

interface PublicProps {}

interface LinkProps {
  href?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

type PropsFromContext = Pick<PlatformContext, 'openExternalLink'>;

type Props = PublicProps & LinkProps & PropsFromContext;

class ExternalLinkPresentation extends React.Component<Props, {}> {
  onClick = () => {
    return this.props.openExternalLink(this.props.href);
  };

  render() {
    return (
      <a onClick={this.onClick} style={this.props.style}>
        {this.props.children}
      </a>
    );
  }
}
function mapContextToProps(c: PlatformContext): PropsFromContext {
  return {
    openExternalLink: c.openExternalLink,
  };
}
export const ExternalLink = compose(withPlatformContext<PropsFromContext>(mapContextToProps))(ExternalLinkPresentation);
