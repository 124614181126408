import React, { useContext } from 'react';
import {
  FooterContainer,
  MainHeading,
  Link,
  SectionDivider,
  Copyright,
  LinkContainer,
  DownloadLink,
  FooterElement,
  SubHeading,
  SocialLink,
  TopRow,
  BottomRow,
  SocialLinkContainer,
  Logo,
  DownloadTitle,
  DownloadContainer,
} from './styled';
import { Props } from './types';
import { PlayerMode } from '../../Core/Analytics';
import { URLS } from '../../Core/Platform';
import playerContext, { PlayerContext } from '../../Core/Player/PlayerContext';
import { Icon } from '../../Styled';

const currentYear = new Date().getFullYear();

export const Footer = ({ playerMode }: Props): JSX.Element => {
  const playerContextObj = useContext<PlayerContext>(playerContext);
  const mode = playerMode ?? playerContextObj.playerMode;

  if (mode == PlayerMode.Compact) {
    return null;
  }

  return (
    <FooterContainer id="pretzel-footer">
      <TopRow>
        <Logo>
          <div>
            <Icon size={200} name="LogoLarge" />
          </div>
          <SubHeading>Stream &amp; YouTube Safe music for Content Creators</SubHeading>
        </Logo>
        <SectionDivider>
          <MainHeading>Download the desktop app</MainHeading>
          <DownloadContainer>
            <DownloadLink target="_blank" href={URLS.DOWNLOAD_MAC} style={{ marginRight: '3rem' }}>
              <Icon size={40} name="Apple" />
              <DownloadTitle>Mac</DownloadTitle>
            </DownloadLink>
            <DownloadLink target="_blank" href={URLS.DOWNLOAD_WINDOWS}>
              <Icon size={40} name="Windows" />
              <DownloadTitle>Windows</DownloadTitle>
            </DownloadLink>
          </DownloadContainer>
        </SectionDivider>
        <SectionDivider>
          <MainHeading>Resources</MainHeading>
          <LinkContainer>
            <Link href={URLS.AFFILIATE} target="_blank" data-heapid="affiliate" data-testid="resources-affiliate-link">
              Affiliate
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.HELP} target="_blank" data-heapid="support" data-testid="resources-support-link">
              Support
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.FAQS} target="_blank" data-heapid="faqs" data-testid="resources-faqs-link">
              FAQs
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.PRETZEL_BLOG} target="_blank" data-heapid="blog" data-testid="resources-blog-link">
              Blog
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.FEEDBACK} target="_blank" data-heapid="feedback" data-testid="resources-feedback-link">
              Feedback
            </Link>
          </LinkContainer>
        </SectionDivider>
        <FooterElement>
          <MainHeading>Legal</MainHeading>
          <LinkContainer>
            <Link href={URLS.TERMS} target="_blank" data-heapid="terms" data-testid="legal-terms-of-use-link">
              Terms of Use
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.PRIVACY} target="_blank" data-heapid="privacy" data-testid="legal-privacy-policy-link">
              Privacy Policy
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.COOKIE_POLICY} target="_blank" data-heapid="cookie" data-testid="legal-cookie-policy-link">
              Cookie Policy
            </Link>
          </LinkContainer>
          <LinkContainer>
            <Link href={URLS.LICENSE_SERVICES_AGREEMENT} target="_blank" data-heapid="license" data-testid="legal-license-services-agreement-link">
              License Services Agreement
            </Link>
          </LinkContainer>
        </FooterElement>
      </TopRow>
      <BottomRow>
        <Copyright>&copy; {currentYear} PretzelAux, Inc. All rights reserved.</Copyright>
        <SocialLinkContainer>
          <SocialLink href={URLS.TWITCH} target="_blank" data-heapid="twitch" data-testid="social-twitch-link">
            <Icon size={18} name="Twitch" />
          </SocialLink>
          <SocialLink href={URLS.TWITTER} target="_blank" data-heapid="twitter" data-testid="social-twitter-link">
            <Icon size={20} name="Twitter" />
          </SocialLink>
          <SocialLink href={URLS.FACEBOOK} target="_blank" data-heapid="facebook" data-testid="social-facebook-link">
            <Icon size={10} name="Facebook" />
          </SocialLink>
          <SocialLink href={URLS.INSTAGRAM} target="_blank" data-heapid="instagram" data-testid="social-instagram-link">
            <Icon size={10} name="Instagram" />
          </SocialLink>
          <SocialLink href={URLS.PRETZEL_BLOG} target="_blank" data-heapid="medium" data-testid="social-medium-link">
            <Icon size={20} name="Medium1" />
          </SocialLink>
          <SocialLink href={URLS.DISCORD_INVITE} target="_blank" data-heapid="discord" data-testid="social-discord-link">
            <Icon size={20} name="Discord" />
          </SocialLink>
        </SocialLinkContainer>
      </BottomRow>
    </FooterContainer>
  );
};
