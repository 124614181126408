import styled from 'styled-components/macro';
import { theme } from './theme';

export const DropdownContainer = styled.div`
  padding-top: 0.313rem;
  padding-bottom: 0.313rem;
  padding-left: 0;
  list-style: none;
  position: absolute;
  top: 1.25rem;
  left: -5.5rem;
  min-width: max-content;
  z-index: 2;
  display: flex;
  text-align: left;
  font-size: 0.75rem;
  background-color: ${theme.colors.bodyBg};
  border: 0.063rem solid ${theme.colors.greyMuted};
  border-radius: 0.188rem;
  box-shadow: 0px 0.125rem 0.188rem 0.063rem rgba(0, 0, 0, 0.3);

  ${theme.mediaQueries.small} {
    left: 0.75rem;
  }
`;

export const ButtonContainer = styled.button`
  position: relative;
  border: 0;
  color: ${theme.colors.baseWhite};
  cursor: pointer;
  background-color: transparent;

  :focus-visible {
    outline: rgba(59, 153, 252, 0.7) auto 0.313rem;
    outline-color: rgba(59, 153, 252, 0.7);
    outline-style: auto;
    outline-width: 0.313rem;
  }
`;

export const DropdownItem = styled.button`
  padding: 0.375rem 0.625rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  background-color: transparent;
  color: ${theme.colors.baseWhite};

  svg {
    margin-left: 0.313rem;
    margin-bottom: 0.063rem;
  }

  :focus-visible {
    outline: rgba(59, 153, 252, 0.7) auto 0.313rem;
    outline-color: rgba(59, 153, 252, 0.7);
    outline-style: auto;
    outline-width: 0.313rem;
  }

  :hover {
    color: ${theme.colors.darkTeal};

    path {
      stroke: ${theme.colors.darkTeal};
    }
  }
`;
