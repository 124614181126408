import { css } from '@emotion/react';

export interface IQueries {
  extraSmall?: string;
  small?: string;
  medium?: string;
  large?: string;
  extraLarge?: string;
  xxLarge?: string;
}

export const appBreakpoints = {
  extraSmall: 375,
  small: 564,
  medium: 768,
  large: 992,
  extraLarge: 1200,
  xxLarge: 2000,
};

export enum screenSizes {
  small = 576,
  medium = 768,
  large = 992,
  extraLarge = 1200,
  xxLarge = 2000,
}

const mediaQueries: IQueries = {
  extraSmall: `@media all and (min-width: ${appBreakpoints.extraSmall}px)`,
  small: `@media all and (min-width: ${appBreakpoints.small}px)`,
  medium: `@media all and (min-width: ${appBreakpoints.medium}px)`,
  large: `@media all and (min-width: ${appBreakpoints.large}px)`,
  extraLarge: `@media all and (min-width: ${appBreakpoints.extraLarge}px)`,
  xxLarge: `@media all and (min-width: ${appBreakpoints.xxLarge}px)`,
};

/**
 * Used for consistent styling across packages.
 *
 * Things that should be added in future:
 * - borders (size, color, radius)
 * - colors (better separation such as greys, whites)
 * - fontFamilies (primary, secondary)
 * - fontSizes (xs, sm, md, lg, xl)
 * - spacing (margin, padding)
 * - zIndexes (modals, slideouts etc)
 */
const defaultTheme = {
  font: {
    family: 'Sora',
    familySecondary: 'proxima-nova',
    height: '14px',
    size: '12px',
  },

  headings: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '1.75rem',
    },
    h3: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '1rem',
    },
  },

  borders: {
    radius: '8px',
    style: '1px solid rgba(255, 255, 255, 0.1)',
  },

  card: {
    borderRadius: '.25rem',
    size: {
      small: '217px',
      normal: '200px',
      large: '240px',
      featured: '100%',
    },
  },

  colors: {
    backgroundDarkBlur: 'rgba(0,0,0,0.8)',
    baseBackground: '#262b2a',
    baseBackground2: '#191c1c',
    baseBackground3: '#1f2323',
    baseBackground4: '#343c3b',
    baseBackground5: '#1c1f1f',
    baseBackground6: '#2f3636',
    baseMuted: '#a4a4a4',
    baseWhite: '#fff',
    beamBase: '#4d90f4',
    beamHighlight: '#1d72f1',
    borderBase: 'rgba(164,164,164,0.3)',
    borderMuted: 'rgba(217, 217, 217, 0.1)',
    brand: '#1bb3a4',
    brandGreen: '#1bb3a4',
    brandGreen2: '#1bb3a4',
    brandHotPink: '#cb0675',
    dark1: '#050D12',
    dark2: '#09161D',
    dark3: '#17242D',
    dark3alt: '#1f2a31',
    dark4: '#2B383F',
    dark4alt: '37484F',
    dark5: '#4F5E65',
    focus: '#1bb3a4',
    googleBase: '#ff3d00',
    googleHighlight: '#cc3100',
    gradientEnd: '#1bb3a3',
    gradientStart: '#09566d',
    grey: '#EFEFEF',
    greyMuted: '#585b5b',
    inactiveBackground: '#535559',
    light1: '#FFFFFF',
    light2: '#F5F8FA',
    light3: '#E3E8EB',
    light4: '#BDC2C4',
    light5: '#91979A',
    light6: '#D0D0D0',
    lightWhite: '#f5f5f5',
    microsoftBase: '#004185',
    microsoftHighlight: '#002852',
    purple: '#8736E0',
    red: '#F85640',
    teal: '#31c3a2',
    twitchBase: '#9146FF',
    twitchHighlight: '#6441a4',
    yellowDark: '#CA9D00',
    yellowLight: '#FFF9E5',
    translucentWhite: 'rgba(255,255,255,0.05)',

    /**
     * New Colors / Terminology
     */
    bg: '#1A242C',
    bodyBg: '#232E37',
    offWhite: '#EFEFEF',
    darkTeal: '#19B3A4',
    tooltipBlack: '#182027',
    sidebarBlack: '#161E25',

    // Brand Specific
    primary: '#1BB3A4',
    secondary: '#CB0675',
    secondaryDark: '#A3025C',
    tertiary: '#FFCC25',
    danger: '#f00',
  },

  space: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
  },

  zIndex: {
    premiumOnlyPage: 10,
    modal: 20,
  },

  mediaQueries,
  appBreakpoints,
};

const commonTheme = {
  actionButtons: defaultTheme.colors.teal,
  bgVideoBackground: defaultTheme.colors.dark1,
  icons: defaultTheme.colors.light5,
  info1: defaultTheme.colors.yellowLight,
  info2: defaultTheme.colors.yellowDark,
  newButtons: defaultTheme.colors.purple,
  warningButtons: defaultTheme.colors.red,
};

export const darkTheme = {
  bgMain: defaultTheme.colors.dark3,
  bgSections: defaultTheme.colors.dark2,
  bgSectionsAlt: defaultTheme.colors.dark4,
  bgSectionsAltHover: defaultTheme.colors.dark3alt,
  textDefaultButtons: defaultTheme.colors.dark5,
  textMuted: defaultTheme.colors.light5,
  textParagraph: defaultTheme.colors.light4,
  textTitles: defaultTheme.colors.light1,
};

export const lightTheme = {
  bgMain: defaultTheme.colors.light1,
  bgSections: defaultTheme.colors.light2,
  bgSectionsAlt: defaultTheme.colors.light2,
  bgSectionsAltHover: defaultTheme.colors.light3,
  textDefaultButtons: defaultTheme.colors.light3,
  textMuted: defaultTheme.colors.dark5,
  textParagraph: defaultTheme.colors.dark5,
  textTitles: defaultTheme.colors.dark2,
};

export const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    ...darkTheme,
    ...commonTheme,
  },
};

export const GlobalTheme = {
  global: {
    ...theme,
  },
};
