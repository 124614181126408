import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { Box, Paragraph, Text } from 'grommet';
import { CSSProperties } from 'react';
import { joinDomsGrammatically, joinStringsGrammatically } from '../../../../Util';
import { theme } from '../../../../Styled';
import { PlayerContext, withPlayerContext } from '../../PlayerContext';
import { BasicTrackArtistFragment } from '../../Queries/types/BasicTrackArtistFragment';
import { Link } from 'react-router-dom';

type PropsFromContext = Pick<PlayerContext, 'playToken' | 'changeSegment'>;

interface PublicProps {
  width: number;
}

type Props = PropsFromContext & PublicProps;

export class TrackInfoPresentation extends React.Component<Props> {
  public render() {
    if (!this.props.playToken) return null;
    const artists = joinStringsGrammatically(this.props.playToken.track.artists.map((artist: BasicTrackArtistFragment) => artist.name));
    const artistsDom = joinDomsGrammatically(this.props.playToken.track.artists.map((artist: BasicTrackArtistFragment) => this.renderArtist(artist)));
    const lineStyles: CSSProperties = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      lineHeight: '20px',
    };
    const padding = this.props.width < theme.appBreakpoints.large ? '10px' : '0px';
    return (
      <Box
        direction="column"
        justify="center"
        style={{ color: theme.colors.textTitles, paddingBottom: padding, lineHeight: 0 }}
        data-heapid="track-info"
        data-testid="track-info"
      >
        <Text style={{ ...lineStyles, fontSize: '16px' }} title={this.props.playToken.track.title} data-heapid="title" data-testid="title">
          {this.props.playToken.track.title}
        </Text>
        {artists && (
          <Text style={{ ...lineStyles, fontSize: '14px' }} title={artists} data-heapid="artist" data-testid="artist">
            {artistsDom}
          </Text>
        )}
        {this.props.playToken.track.album && (
          <Paragraph title={this.props.playToken.track.album.title} style={lineStyles} margin="0" size="small">
            <Link to={`/segment/${this.props.playToken.track.album.id}`} data-heapid="album" data-testid="album">
              {this.props.playToken.track.album.title}
            </Link>
          </Paragraph>
        )}
      </Box>
    );
  }

  private renderArtist(artist: BasicTrackArtistFragment) {
    return (
      <Link key={artist.id} data-id={artist.id} to={`/segment/${artist.id}`}>
        {artist.name}
      </Link>
    );
  }
}

function mapContextToProps(c: PlayerContext): PropsFromContext {
  return {
    changeSegment: c.changeSegment,
    playToken: c.playToken,
  };
}

export const TrackInfo = compose(withPlayerContext(mapContextToProps))(TrackInfoPresentation);
