import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { PlayerContext, withPlayerContext } from '@pretzel-aux/common/src/Core/Player/PlayerContext';
import { toUrl } from '@pretzel-aux/common/src/Util';
import { joinStringsGrammatically } from '@pretzel-aux/common/src/Util/Format';

type PropsFromPlayer = Pick<PlayerContext, 'playToken' | 'playing' | 'next' | 'previous' | 'togglePause'>;

type Props = PropsFromPlayer;

export class MediaSessionPresentation extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>): void {
    const { playToken, playing, next, previous, togglePause } = this.props;

    if ('mediaSession' in navigator && playToken) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: playToken.track.title,
        artist: joinStringsGrammatically(playToken.track.artists.map(artist => artist.name)),
        album: playToken.track.album?.title,
        artwork: [
          {
            src: toUrl(playToken.track.artworkGuid),
            sizes: '300x300',
            type: 'image/jpg',
          },
        ],
      });

      navigator.mediaSession.setActionHandler('play', togglePause);
      navigator.mediaSession.setActionHandler('pause', togglePause);
      navigator.mediaSession.setActionHandler('previoustrack', previous);
      navigator.mediaSession.setActionHandler('nexttrack', next);
    }
  }

  public render(): React.ReactDOM {
    return null;
  }
}

function mapPlayerToProps(c: PlayerContext): PropsFromPlayer {
  return {
    playToken: c.playToken,
    playing: c.playing,
    next: c.next,
    previous: c.previous,
    togglePause: c.togglePause,
  };
}

export const MediaSessionUpdater = compose(withPlayerContext(mapPlayerToProps))(MediaSessionPresentation);
