import React from 'react';
import { Icon, theme } from '../../../../Styled';
import { Tooltip } from '../../../Tooltip';
import { ForbiddenIcon } from '../../styled';
import { ViewportType } from '../../../../../../common/src/Util/responsive';
import styled from 'styled-components';
import { Modal } from '@pretzel-aux/common/src/Components/Modal';
import { PretzelUser, URLS } from '@pretzel-aux/common/src/Core/Platform';

interface Props {
  isPlaying: boolean;
  forbidden: boolean;
  freeTierRestricted: boolean;
  instrumentalRestricted: boolean;
  youtubeRestricted: boolean;
  dislikedContent: boolean;
  explicitRestricted: boolean;
  viewport: ViewportType;
  pretzelUser: PretzelUser;
  onClick: () => void;
}

const NoEntry = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  border: 1px solid ${theme.colors.brandHotPink};
  margin-left: -0.3125rem;

  :after {
    content: '';
    position: absolute;
    width: 1.95rem;
    border: 1px solid ${theme.colors.brandHotPink};
    transform: rotate(135deg);
    left: 0;
    top: 0.9375rem;
  }
`;

const UnlockModalContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;
  color: ${theme.colors.grey};
`;

export const PlayButton = ({
  isPlaying,
  forbidden,
  youtubeRestricted,
  freeTierRestricted,
  instrumentalRestricted,
  dislikedContent,
  explicitRestricted,
  viewport,
  pretzelUser,
  onClick,
}: Props) => {
  const [openUnlockModal, setOpenUnlockModal] = React.useState<boolean>(false);

  if (freeTierRestricted) {
    return (
      <React.Fragment>
        <NoEntry onClick={() => setOpenUnlockModal(true)}>
          <Icon size={48} name="Play" />
        </NoEntry>
        <Modal
          title="Want to unlock more features?"
          content={
            <UnlockModalContent>
              To unlock interactive mode and have the ability to play individual tracks, upgrade to Pretzel Premium
            </UnlockModalContent>
          }
          buttons={[
            {
              color: 'secondary',
              label: 'Upgrade to Premium',
              externalLink: `${URLS.RECURLY_PREMIUM_MONTHLY}/${pretzelUser.jwt.hash}?utm_source=pretzel&utm_medium=modal&utm_campaign=premium_upsell`,
            },
          ]}
          isOpen={openUnlockModal}
          setIsOpen={setOpenUnlockModal}
          testid="unlock-more-features-modal"
          variant="secondary"
          heapid="unlock-more-features-modal"
        />
      </React.Fragment>
    );
  }

  if (!forbidden) {
    return isPlaying ? (
      <Icon name="volume_3" className="track-playing-button" size={16} />
    ) : (
      <Tooltip text="Play" show={true} styles={`transform: translateY(-100%) translateX(4%);`}>
        <button
          type="button"
          className={`icon-button ${viewport}-track-play-button`}
          aria-label="Play"
          data-heapid="play-button"
          data-testid="track-list-play-pause-button"
          onClick={onClick}
        >
          <Icon size={48} name="Play" />
        </button>
      </Tooltip>
    );
  }

  if (dislikedContent) {
    return <ForbiddenIcon className="pretzel-icon-thumbs_down forbidden-icon" title="This track has has been disliked. " />;
  }

  if (instrumentalRestricted) {
    return (
      <ForbiddenIcon
        className="pretzel-icon-no-vocals forbidden-icon"
        title="This track has vocals, and you have the Instrumental Only filter turned on"
      />
    );
  }

  if (explicitRestricted) {
    return (
      <ForbiddenIcon
        style={{ color: '#a4a4a4' }}
        className="pretzel-icon-explicit forbidden-icon"
        title="This track is explicit, and you have the Allow Mature filter turned off"
      />
    );
  }

  if (youtubeRestricted) {
    return (
      <ForbiddenIcon
        className="pretzel-icon-youtube-safe forbidden-icon"
        title="This track is not safe for YouTube, and you have the YouTube Safe filter turned on"
      />
    );
  }

  return null;
};
