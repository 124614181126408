import * as React from "react";

function SvgFeatureOverlays(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M30.4 4.384H1.6c-.864 0-1.568.704-1.568 1.536v20.16c0 .832.704 1.536 1.568 1.536h28.8c.864 0 1.536-.704 1.536-1.536V5.92c.032-.832-.672-1.536-1.536-1.536zM1.6 18.336h10.528v4.48H1.6v-4.48zm28.8 7.744H1.6v-1.728h12.064v-7.584H1.6V5.92h28.8v20.16z" />
    </svg>
  );
}

export default SvgFeatureOverlays;
