import styled from 'styled-components';
import { theme } from '../../Styled';

export const StyledPageIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.45rem 0 ${theme.space.lg} 0;

  h1 {
    // Slowly add font family to headings globally
    font-family: ${theme.font.family};
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
`;
