import React, { useState } from 'react';
import { Icon } from '../../../../Styled';
import { Tooltip } from '../../../Tooltip';
import { AddToPlaylist } from '../../../../Core/Player/Components/PlayerControls/Components/AddToPlaylist/AddToPlaylist';
import { StyledPlaylistContainer } from '../../styled';
import { Props } from './types';
import { ControlItem } from '@pretzel-aux/common/src/Core/Player/Components/PlayerControls/Components/ControlItem/ControlItem';

export const Playlist = ({ track, showTooltip = true }: Props) => {
  const [showAddToPlaylist, setShowAddToPlaylist] = useState<boolean>(false);

  const handleAddToPlaylist = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowAddToPlaylist(true);
    event.stopPropagation();
  };

  return (
    <StyledPlaylistContainer>
      <Tooltip show={showTooltip} text="Add to playlist" styles={`transform: translateY(-100%) translateX(-23%); z-index: 100`}>
        <ControlItem isLoading={false} isDisabled={false} name="AddToPlaylist" onClick={handleAddToPlaylist} />
      </Tooltip>

      {showAddToPlaylist && (
        <div onMouseLeave={() => setShowAddToPlaylist(false)}>
          <AddToPlaylist track={track} hide={() => setShowAddToPlaylist(false)} />
        </div>
      )}
    </StyledPlaylistContainer>
  );
};
