import * as React from "react";

function Lederhosen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 100 100">
      <path d="M42.649,52.139c1.122,0,2.031-0.909,2.031-2.029c0-1.123-0.909-2.031-2.031-2.031c-1.121,0-2.029,0.909-2.029,2.031  C40.62,51.229,41.528,52.139,42.649,52.139z M72.883,44.473h-8.906V8h-8.555v9.891H41.636V8h-8.555v36.473h-8.909l-13.114,43.84  l31.696,4.495l5.774-12.738l5.773,12.738L86,88.313L72.883,44.473z M47.024,19.229c0.589,0,1.121,0.238,1.504,0.623  c0.387-0.384,0.919-0.623,1.508-0.623c1.175,0,2.129,0.955,2.129,2.129c0,0.432-0.13,0.833-0.351,1.168  c-0.236,0.312-3.013,2.952-3.286,2.952c-0.27,0-3.079-2.671-3.284-2.952c-0.22-0.335-0.35-0.736-0.35-1.168  C44.895,20.184,45.85,19.229,47.024,19.229z M41.636,26.818h13.786v17.655H41.636V26.818z M59.581,54.565H37.474v-8.993h22.107  V54.565z M54.406,52.139c1.121,0,2.029-0.909,2.029-2.029c0-1.123-0.908-2.031-2.029-2.031s-2.029,0.909-2.029,2.031  C52.377,51.229,53.285,52.139,54.406,52.139z"></path>
    </svg>
  );
}

export default Lederhosen;
