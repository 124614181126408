import * as React from "react";

function SvgMedium(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M10.656 7.52v20.96q0 .448-.224.736t-.64.32q-.32 0-.608-.128L.896 25.216q-.384-.16-.64-.576T0 23.808V3.456q0-.384.192-.608t.512-.256q.256 0 .768.256l9.152 4.576q.032.064.032.096zm1.152 1.792L21.344 24.8l-9.536-4.768V9.312zM32 9.632V28.48q0 .448-.256.704t-.672.288-.832-.224l-7.872-3.936zm-.064-2.144q0 .064-4.576 7.488t-5.344 8.704L15.04 12.384l5.792-9.408q.288-.512.928-.512.256 0 .448.096l9.664 4.832q.064.032.064.096z" />
    </svg>
  );
}

export default SvgMedium;
