import styled from 'styled-components/macro';
import { theme } from '../../../../../../Styled';

export const AudioOutputWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const AudioOutputTitle = styled.p`
  color: ${theme.colors.baseWhite};
  font-size: 14px;
  margin-bottom: 5px;
`;

export const AudioSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const SelectWrapper = styled.div`
  width: 98%;
`;

export const FeatureDisabledNotice = styled.div`
  margin-top: 5px;
  color: #a4a4a4;
  font-size: 14px;
`;

export const PremiumLink = styled.a`
  text-decoration: none;
  color: #1bb3a4;
  font-weight: 700;
  cursor: pointer;
`;
