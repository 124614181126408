import React, { useContext, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import platformContext, { PlatformContext, Tier } from '../../Core/Platform';
import { CurationsProps } from './types';
import { PlaylistCard } from '../../Components/PlaylistsList/Components/PlaylistCard';
import { Carousel } from '../../Components/Carousel';
import { Section } from '../../Components/Home/Components/Section';
import { TrendingTracks } from '../../Components/TrendingTracks';
import { UpgradeToPremiumModal } from '../../Components/UpgradeToPremiumModal';
import { Footer } from '../../Components/Footer';
import { FeaturedPlaylists } from './components/FeaturedPlaylists';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { curationsGuids } from './curationsGuids';
import { isUserEntitled } from '../../Util/Entitlements';
import PageWrapper from '../../Styled/Page';

const CurationsQuery = loader('./curations.graphql');

export const CurationsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { pretzelUser } = useContext<PlatformContext>(platformContext);
  const isProOrAdmin = isUserEntitled(pretzelUser.jwt.tier, [Tier.ZeroCpm, Tier.Pro, Tier.Admin]);

  const { loading, data } = useQuery<CurationsProps['data']>(CurationsQuery, {
    variables: { ...curationsGuids },
  });

  const handleNonPremiumClick = () => {
    setIsModalOpen(true);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <>
      <PageWrapper title="Curated playlists" description="Save time with our exquisitely crafted playlists, perfect for creators">
        <FeaturedPlaylists playlists={data.featuredPlaylists} {...(!isProOrAdmin && { handleNonPremiumClick })} />
        <TrendingTracks tracks={data.trendingTracks} {...(!isProOrAdmin && { handleNonPremiumClick })} />

        <Section title="Genres">
          <Carousel id="genres-playlists" numberOfSlides={data.genres?.length} slideSize="minmax(355px, 1fr)">
            {data.genres?.map(playlist => {
              return (
                <PlaylistCard
                  key={playlist.id}
                  id={playlist.id}
                  playlist={playlist}
                  size="355px"
                  widescreen
                  {...(!isProOrAdmin && { handleNonPremiumClick })}
                />
              );
            })}
          </Carousel>
        </Section>

        <Section title="Creator toolkit">
          <Carousel id="creator-toolkit-playlists" numberOfSlides={data.creatorToolkits?.length} showButtons={false} slideSize="minmax(355px, 1fr)">
            {data.creatorToolkits?.map((playlist, index) => {
              return (
                <PlaylistCard
                  id={playlist.id}
                  key={playlist.id}
                  playlist={playlist}
                  size="355px"
                  widescreen
                  {...(!isProOrAdmin && { handleNonPremiumClick })}
                />
              );
            })}
          </Carousel>
        </Section>

        <Footer />
      </PageWrapper>

      <UpgradeToPremiumModal
        displayCloseIcon={false}
        isOpen={isModalOpen}
        pro
        setIsOpen={setIsModalOpen}
        buttons={[
          {
            color: 'outline',
            onClick: () => {
              setIsModalOpen(false);
            },
            label: 'Cancel',
          },
        ]}
      />
    </>
  );
};
