import React, { useMemo, useState } from 'react';
import { flowRight as compose } from 'lodash';
import { graphql, withMutation } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { toast } from 'react-toastify';
import { Close, Plus } from '@pretzel-aux/assets/icons';
import { generateToastConfig } from '../../../../../../Styled/ToastContainer';
import { AddToPlaylistsListQuery, AddToPlaylistsListQuery_userPlaylists_edges } from './types/AddToPlaylistsListQuery';
import { Keyboard } from 'grommet';
import { MenuList } from '../../../../../../Styled/MenuList';
import { PlayerContext, withPlayerContext } from '../../../../PlayerContext';
import { BottomTail, PopupItem, StyledInputWrapper, StyledPlaylistName, Wrapper } from './styled';
import { Props, PropsFromContext, State, PublicProps } from './types';

const playlistsQuery = loader('./playlists.graphql');
const newPlaylistQuery = loader('./new-playlist.graphql');
const appendPlaylistQuery = loader('./append-playlist.graphql');

const AddToPlaylistPresentation = (props: Props) => {
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [newPlaylistName, setNewPlaylistName] = useState<string>('');
  const [playlistError, setPlaylistError] = useState<boolean>(false);
  const [playlists, setPlaylists] = useState(null);
  const [showNewPlaylist, setShowNewPlaylist] = useState<boolean>(false);

  const hasMore = props.data.userPlaylists && props.data.userPlaylists.pageInfo.hasNextPage;

  const handleNewPlaylist = () => {
    if (!newPlaylistName) {
      setPlaylistError(true);
      return;
    }

    if (props.playToken.track.id || props.track) {
      setPlaylistError(false);

      props
        .newPlaylist({
          variables: {
            attributes: {
              name: newPlaylistName,
              description: '',
              share: false,
              trackIds: props.track ? [props.track.id] : [props.playToken.track.id],
            },
          },
        })
        .then(() => {
          if (props.data && props.data.refetch) {
            props.data.refetch({ after: '' });
          }

          props.hide();

          toast(
            <span>
              Track added to new <StyledPlaylistName>'{newPlaylistName}'</StyledPlaylistName> playlist
            </span>,
            generateToastConfig('Playlist')
          );
        });
    }

    setShowNewPlaylist(false);
  };

  const handleExistingPlaylist = (event: React.MouseEvent<HTMLElement, MouseEvent>, playlist: AddToPlaylistsListQuery_userPlaylists_edges) => {
    event.stopPropagation();

    props
      .appendPlaylist({
        variables: {
          playlistId: playlist.node.id,
          trackId: props.track ? props.track.id : props.playToken.track.id,
        },
      })
      .then(() => {
        props.hide();
        toast(
          <span>
            Track added to <StyledPlaylistName>'{playlist.node.name}'</StyledPlaylistName> playlist
          </span>,
          generateToastConfig('Playlist')
        );
      });
  };

  const onMore = () => {
    const after = props.data.userPlaylists.pageInfo.endCursor || '';

    setLoadMore(true);

    props.data.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: AddToPlaylistsListQuery, { fetchMoreResult }) => {
        setLoadMore(false);
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          playlists: Object.assign({}, fetchMoreResult.playlists, {
            edges: [...previousQueryResult.userPlaylists.edges, ...fetchMoreResult.playlists.edges],
          }),
        };
      },
    });
  };

  useMemo(() => {
    if (!props.data.loading) {
      const playlists = [...props.data?.userPlaylists?.edges]?.sort((a, b) => a.node.name.localeCompare(b.node.name));

      setPlaylists(
        playlists.map((playlistEdge, index) => (
          <PopupItem
            justify="between"
            direction="row"
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => handleExistingPlaylist(event, playlistEdge)}
            key={playlistEdge.node.id}
            data-heapid="playlist"
            data-testid={`playlist-option-${index}`}
          >
            {playlistEdge.node.name}
          </PopupItem>
        ))
      );
    }
  }, [props.data.loading]);

  return (
    <Wrapper>
      <PopupItem justify="between" direction="row" data-heapid="new-playlist" data-testid="new-playlist-option">
        <Keyboard onEnter={handleNewPlaylist}>
          <StyledInputWrapper>
            <label htmlFor="new-playlist-input">New playlist name</label>
            <input autoFocus={true} id="new-playlist-input" maxLength={25} onChange={event => setNewPlaylistName(event.target.value)} type="text" />

            <Plus id="button-save-new-playlist" onClick={handleNewPlaylist} data-heapid="new-playlist-save" data-testid="new-playlist-save-button" />

            {playlistError && (
              <span>
                <Close />
                Type playlist name
              </span>
            )}
          </StyledInputWrapper>
        </Keyboard>
      </PopupItem>

      <MenuList title="Playlists" items={playlists} hasMore={hasMore} onMore={onMore} loading={loadMore} />

      <BottomTail />
    </Wrapper>
  );
};

function mapContextToProps(c: PlayerContext): PropsFromContext {
  return {
    playToken: c.playToken,
  };
}

// @ts-ignore
export const AddToPlaylist: React.ComponentClass<PublicProps> = compose(
  withPlayerContext(mapContextToProps),
  graphql(playlistsQuery, {
    options: {
      variables: {
        after: '',
      },
    },
  }),
  withMutation(newPlaylistQuery, {
    name: 'newPlaylist',
    options: {
      update: (cache, mutationResult) => {
        // TODO We could be more surgical and insert the newly added Playlist,
        // however this is good enough for the time being
        cache.evict({ fieldName: 'userPlaylists' });
      },
    },
  }),
  withMutation(appendPlaylistQuery, { name: 'appendPlaylist' })
  // @ts-ignore --- mutations don't seem to give the right types yet
)(AddToPlaylistPresentation);
