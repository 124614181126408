import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../Styled';

interface KeyWrapperProperties {
  wide?: boolean;
}

interface IWebKeyBinds {
  keyName: string;
  keyNameOther?: string;
  detail: string;
  wide?: boolean;
}

const KeyWrapper = styled.div<KeyWrapperProperties>`
  padding: 0.625rem;
  min-width: 45px;
  border: 1px solid ${theme.colors.baseBackground};
  color: ${theme.colors.baseBackground};
  text-align: center;
  display: inline-block;
  width: ${props => (props.wide ? '90px' : 'auto')};
  margin-right: 0.75rem;
  background: ${theme.colors.grey};
  border-radius: 0.5rem;
  font-family: 'proxima-nova';
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

const Notice = styled.div`
  color: ${theme.colors.baseWhite};
  margin: 2rem 0 1rem 0;
`;

const newWebKeyBinds = {
  PlayerControls: {
    Space: {
      keyName: 'Space',
      detail: 'Play/Pause',
      wide: true,
    },
    Previous: {
      keyName: '←',
      detail: 'Previous track',
    },
    Next: {
      keyName: '→',
      detail: 'Next track',
    },
    Like: {
      keyName: 'L',
      detail: 'Like track',
    },
    Blacklist: {
      keyName: 'B',
      detail: 'Blacklist track',
    },
  },
  VolumeControls: {
    Increase: {
      keyName: '+/=',
      detail: 'Increase volume',
    },
    Decrease: {
      keyName: '-',
      detail: 'Decrease volume',
    },
    SetVolume: {
      keyName: '1',
      keyNameOther: '0',
      detail: 'Set volume %',
      wide: true,
    },
  },
};

const StyledKeyBindGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 40px 40px 40px;
  grid-row-gap: 1rem;
  margin-top: 1rem;

  span {
    font-size: 0.75rem;
  }
`;

const WideKey = styled.div`
  grid-column: 1 / 3;
`;

export const LocalWindow = () => {
  return (
    <div>
      <Notice>Local window keybinds</Notice>
      <StyledKeyBindGrid>
        {Object.keys(newWebKeyBinds.PlayerControls).map((k: string) => {
          const webKey: IWebKeyBinds = newWebKeyBinds.PlayerControls[k];

          return webKey.wide ? (
            <WideKey key={webKey.keyName}>
              <KeyWrapper wide>{webKey.keyName}</KeyWrapper>
              <span>{webKey.detail}</span>
            </WideKey>
          ) : (
            <div key={webKey.keyName}>
              <KeyWrapper>{webKey.keyName}</KeyWrapper>
              <span>{webKey.detail}</span>
            </div>
          );
        })}
      </StyledKeyBindGrid>
      <Notice>Volume Controls</Notice>
      <StyledKeyBindGrid style={{ gridTemplateRows: '40px 40px' }}>
        {Object.keys(newWebKeyBinds.VolumeControls).map((k: string) => {
          const webKey: IWebKeyBinds = newWebKeyBinds.VolumeControls[k];

          return webKey.wide ? (
            <WideKey key={webKey.keyName}>
              <KeyWrapper>{webKey.keyName}</KeyWrapper>
              {webKey.keyNameOther && <KeyWrapper>{webKey.keyNameOther}</KeyWrapper>}
              <span>{webKey.detail}</span>
            </WideKey>
          ) : (
            <div key={webKey.keyName}>
              <KeyWrapper>{webKey.keyName}</KeyWrapper>
              <span>{webKey.detail}</span>
            </div>
          );
        })}
      </StyledKeyBindGrid>
      <Notice>These local keybinds are only active when the player controls are visible and focused</Notice>
    </div>
  );
};
