import { theme } from '../../Styled';
import styled from 'styled-components';

export const StyledGiftCardContainer = styled.div`
  position: fixed;
  width: 234px;
  height: 64px;
  top: 160px;
  right: -170px;
  background-color: ${theme.colors.brandGreen};
  color: ${theme.colors.brandGreen};
  box-shadow: 0px 4px 8px rgba(26, 36, 44, 0.5);
  border-radius: 4px 0px 0px 4px;
  white-space: nowrap;
  z-index: 10;
  transition: right 0.5s;
  -webkit-transition: right 0.5s;

  &:hover {
    right: 0px;
    cursor: pointer;
  }

  &.settings {
    right: -234px;
  }
`;

export const StyledGiftCardLink = styled.div`
  margin: 20px;
  font-weight: 700;
  font-size: 16px;

  span {
      margin-left 20px;
      position: fixed;
      padding-top: 3px;
      color: ${theme.colors.baseWhite};

      &:hover {
          border-bottom: 1px solid white;
      }
  }
`;
