import * as React from "react";

function SvgMedium1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path d="M2.48447 4.44688C2.50322 4.2625 2.43135 4.07812 2.29385 3.95312L0.884473 2.25312V2H5.26572L8.65322 9.42813L11.6313 2H15.8095V2.25312L14.6032 3.40937C14.5001 3.4875 14.447 3.61875 14.4688 3.74688V12.2469C14.447 12.375 14.5001 12.5063 14.6032 12.5844L15.7813 13.7406V13.9937H9.85322V13.7406L11.0751 12.5562C11.1938 12.4375 11.1938 12.4 11.1938 12.2188V5.35L7.79697 13.9719H7.3376L3.3876 5.35V11.1281C3.35322 11.3719 3.43447 11.6156 3.60635 11.7906L5.19385 13.7156V13.9688H0.693848V13.7188L2.28135 11.7906C2.4501 11.6156 2.52822 11.3688 2.48447 11.1281V4.44688Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" transform="translate(0.25)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgMedium1;
