import styled from 'styled-components';
import SnowWrapperImage from '../../../../assets/images/holidayBanner/snowWrapper.svg';
import SnowImage from '../../../../assets/images/holidayBanner/snowSmall.svg';
import { theme } from '../../Styled/theme';

export const StyledCountdown = styled.div`
  margin: 2rem 0 2rem 0;

  /* Overwriting package styles */
  .flip-countdown {
    font-family: ${theme.font.familySecondary};
    margin: 0;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
    background-color: ${theme.colors.bodyBg};
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top {
    color: ${theme.colors.baseWhite};
  }

  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-title {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: ${theme.space.sm};
  }

  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec,
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before {
    width: 64px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
    width: 100%;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top {
    border-bottom: none;
  }

  .flip-countdown-card {
    font-family: ${theme.font.familySecondary};
    font-weight: 700;
    height: 92px;
  }

  .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    font-size: 4rem;
    line-height: 91px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top {
    line-height: 91px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom,
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec.flip .card__back::before {
    height: 46px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom::after {
    margin-top: -46px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec.flip .card__back::before {
    border-radius: 4px 4px 0 0;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
    border-radius: 0 0 4px 4px;
  }

  .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    position: relative;

    :before {
      content: '';
      background-image: url(${SnowImage});
      background-repeat: no-repeat;
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 30px;
      z-index: 1;
    }
  }

  .flip-countdown.size-medium .flip-countdown-piece {
    margin: 0 ${theme.space.md} 0 0;

    :last-child {
      margin-right: 0;
    }

    @media all and (min-width: 1475px) {
      margin: 0 ${theme.space.lg} 0 0;
    }
  }
`;

export const StyledCountdownInner = styled.div`
  align-items: center;
  background-color: ${theme.colors.bg};
  border-radius: ${theme.borders.radius};
  color: ${theme.colors.baseWhite};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  justify-content: space-between;
  padding: 4rem ${theme.space.lg};
  position: relative;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  :before {
    content: '';
    background-image: url(${SnowWrapperImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 90px;

    ${theme.mediaQueries.large} {
      left: -2px;
      top: -30px;
      width: calc(100% + 10px);
    }
  }

  p {
    line-height: 1.5rem;
    margin-bottom: 0;
    text-align: center;
    @media all and (min-width: 1145px) {
      text-align: left;
    }
  }

  h2 {
    font-family: ${theme.font.familySecondary};
    font-size: 2.5rem;
    line-height: 50px;
    margin-bottom: ${theme.space.sm};
    text-align: center;
    font-weight: 700;
    @media all and (min-width: 1145px) {
      text-align: left;
    }
  }

  :hover {
    box-shadow: 0 0 40px -15px rgb(255 255 255 / 15%);
    transform: scale(1.025);
  }

  @media all and (min-width: 1145px) {
    flex-direction: row;
    padding: ${theme.space.md} ${theme.space.lg};
  }

  @media all and (min-width: 1550px) {
    height: 164px;
  }
`;

export const StyledImageWrapper = styled.div`
  display: none;
  position: relative;
  top: 3px;
  padding-right: 6rem;

  img {
    left: 0;
    max-width: 301px;
  }

  @media all and (min-width: 1550px) {
    display: block;
  }
`;

export const StyledTextWrapper = styled.div`
  flex: 1 1 auto;
`;

export const StyledCountdownWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  justify-content: flex-end;
  margin: 2rem 0 0 0;

  @media all and (min-width: 1145px) {
    flex-direction: row;
    height: 164px;
    margin: 0 0 0 2rem;
  }

  & > div {
    &:last-of-type {
      flex: 0 0 auto;
    }
  }
`;
