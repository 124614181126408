import { Icon } from '../../Styled';
import React from 'react';
import { StyledGiftCardContainer, StyledGiftCardLink } from './styled';
import { URLS } from '../../Core/Platform';

export const GiftCard = () => {
  const handleRedirectToGiftCards = () => {
    window.open(`${URLS.RECURLY_GIFT_CARD}`, '_blank');
  };

  return (
    <StyledGiftCardContainer id="giftcard" data-heapid="buy-giftcard" data-testid="buy-giftcard-button">
      <StyledGiftCardLink onClick={handleRedirectToGiftCards}>
        <Icon noFill={true} size={25} name="GiftBox2" />
        <span>Purchase Gift Cards</span>
      </StyledGiftCardLink>
    </StyledGiftCardContainer>
  );
};
