import * as React from "react";

function Shrink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path d="M23.384.615a1.251,1.251,0,0,0-1.768,0L18.2,4.027a.251.251,0,0,1-.354,0L16.525,2.7a1,1,0,0,0-1.707.707V8.181a1,1,0,0,0,1,1h4.773A1,1,0,0,0,21.3,7.474L19.972,6.148a.25.25,0,0,1,0-.353l3.412-3.412A1.251,1.251,0,0,0,23.384.615Z" />
      <path d="M.616,23.383a1.251,1.251,0,0,0,1.768,0L5.8,19.971a.251.251,0,0,1,.354,0L7.475,21.3a1,1,0,0,0,1.707-.707V15.817a1,1,0,0,0-1-1H3.409A1,1,0,0,0,2.7,16.524L4.028,17.85a.25.25,0,0,1,0,.353L.616,21.615A1.251,1.251,0,0,0,.616,23.383Z" />
      <path d="M14.818,20.59a1,1,0,0,0,1.707.707l1.326-1.326a.251.251,0,0,1,.354,0l3.411,3.412a1.25,1.25,0,0,0,1.768-1.768L19.972,18.2a.25.25,0,0,1,0-.353L21.3,16.524a1,1,0,0,0-.707-1.707H15.818a1,1,0,0,0-1,1Z" />
      <path d="M9.182,3.408a1,1,0,0,0-.618-.924A.99.99,0,0,0,7.475,2.7L6.149,4.027a.251.251,0,0,1-.354,0L2.384.615A1.25,1.25,0,0,0,.616,2.383L4.028,5.8a.25.25,0,0,1,0,.353L2.7,7.474a1,1,0,0,0,.707,1.707H8.182a1,1,0,0,0,1-1Z" />
    </svg>
  );
}

export default Shrink;
