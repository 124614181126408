import { Analytics, AnalyticsEventResourceType, AnalyticsEventType } from '../../Core/Analytics';
import { BasicTrackFragment } from '../../Core/Player/Queries/types/BasicTrackFragment';

export function sendAnalyticsEvent(type: AnalyticsEventType, track: BasicTrackFragment) {
  Analytics.captureEvent({
    type,
    source: 'tracklist',
    resource: {
      type: AnalyticsEventResourceType.Track,
      guid: track.id,
    },
  });
}
