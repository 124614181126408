import React, { FC } from 'react';
import FlipCountdown from '@rumess/react-flip-countdown';
import { flowRight as compose } from 'lodash';
import { StyledCountdown, StyledCountdownInner, StyledCountdownWrapper, StyledImageWrapper, StyledTextWrapper } from './styled';
import { CountdownProps } from './types';
import { Heading } from '../Heading';
import { PlatformContext, URLS, withPlatformContext } from '../../Core/Platform';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import useWindowSize from '../../../../common/src/Util/hooks/use-window-size';
import { screenSizes } from '../../Styled/theme';
import TagImage from '../../../../assets/images/holidayBanner/tag.svg';

export const CountdownFC: FC<CountdownProps> = ({ children, pretzelUser }) => {
  const { width } = useWindowSize();
  const endDate = '2021-12-31 23:59';

  return (
    <StyledCountdown>
      <ExternalLink href={`${URLS.RECURLY_PREMIUM_ANNUALLY}/${pretzelUser.jwt.hash}?subscription[coupon_code]=happy-holidays-y`} target="_blank">
        <StyledCountdownInner>
          <StyledTextWrapper>
            <Heading variant="H2">Happy Holidays Sale!</Heading>
            <p>
              We can't give you yummy gingerbread or figgy pudding early, but we CAN give you <strong>$50</strong> off an annual Premium subscription{' '}
              <strong>right now</strong>.
            </p>
          </StyledTextWrapper>
          <StyledCountdownWrapper>
            <StyledImageWrapper>
              <img src={TagImage} alt="Get discount" />
            </StyledImageWrapper>
            <FlipCountdown
              endAt={endDate}
              hideHour={width <= screenSizes.small}
              hideMinute={width <= screenSizes.small}
              hideYear
              hideMonth
              hideSecond
              dayTitle={width <= screenSizes.small ? 'Days remaining' : 'Days'}
              hourTitle="Hours"
              minuteTitle="Minutes"
              secondTitle="Seconds"
            />
          </StyledCountdownWrapper>
        </StyledCountdownInner>
      </ExternalLink>
    </StyledCountdown>
  );
};

function mapContextToProps(c: PlatformContext) {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export const Countdown = compose(withPlatformContext(mapContextToProps))(CountdownFC);
