import styled, { css } from 'styled-components';
import { theme } from '../../Styled';
import { Focus } from '../../Styled/Focus';

export const StyledButton = styled.button`
  border-radius: ${theme.space.lg};
  border: 1px solid;
  color: ${theme.colors.light1};
  cursor: pointer;
  font-family: ${theme.font.family};
  font-size: 1rem;
  font-weight: 600;
  padding: ${theme.space.sm} ${theme.space.md};
  height: 48px;
  box-shadow: 0px 4px 6px rgba(25, 23, 22, 0.1);

  ${theme.mediaQueries.medium} {
    padding: ${theme.space.sm} ${theme.space.lg};
  }

  ${({ color }) => {
    if (color === 'primary') {
      return css`
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};
      `;
    }

    if (color === 'secondary') {
      return css`
        background-color: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};

        &:hover,
        &:focus {
          background: linear-gradient(93.06deg, ${theme.colors.secondary} 0%, ${theme.colors.secondaryDark} 100%);
          border-color: ${theme.colors.secondaryDark};
        }
      `;
    }

    if (color === 'outline') {
      return css`
        background-color: transparent;
        border-color: ${theme.colors.light1};

        &:hover,
        &:focus {
          background: ${theme.colors.baseWhite};
          color: ${theme.colors.tooltipBlack};
        }
      `;
    }

    if (color === 'danger') {
      return css`
        background-color: ${theme.colors.danger};
        border-color: ${theme.colors.danger};
      `;
    }

    if (color === 'none') {
      return css`
        background-color: transparent;
        border-color: transparent;
      `;
    }

    return null;
  }}

  ${Focus};
`;
