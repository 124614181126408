import * as React from 'react';
import { Box, Paragraph } from 'grommet';
import { Icon, theme } from '@pretzel-aux/common/src/Styled';
import { RenderState, UserAgreementStorageKeys } from '../types';
import { Checkbox } from '@pretzel-aux/common/src/Components/Checkbox';
import { URLS } from '@pretzel-aux/common/src/Core/Platform';
import {
  StyledCheckboxContainer,
  StyledLabel,
  Splash,
  LoginWrapper,
  LoginLink,
  StyledLink,
  LoginButtonContainer,
  StyledParagraph,
  StyledLogo,
} from '../styled';
import { getAuthUrl, useCookie } from '@pretzel-aux/common/src/Util';

const IS_ELECTRON = /electron/i.test(navigator.userAgent);

export const SignInPage = () => {
  const [renderState, setRenderState] = React.useState(RenderState.SIGN_UP);
  const [agreedToUserAgreementDate, setAgreedToUserAgreementDate] = useCookie(
    UserAgreementStorageKeys.USER_LICENSE_AGREEMENT_TIME,
    false,
    value => value.toString(),
    value => value === 'true',
  );
  const [optedOutOfMarketing, setOptedOutOfMarketing] = useCookie(
    UserAgreementStorageKeys.OPTED_OUT_OF_MARKETING,
    true,
    value => value.toString(),
    value => value === 'true',
  );

  const getLink = (provider: string): string => {
    if (renderState === RenderState.SIGN_UP && !agreedToUserAgreementDate) {
      return '#';
    }

    return getAuthUrl(provider);
  };

  React.useEffect(() => {
    // @ts-ignore
    if (window.ga) {
      // @ts-ignore
      window.ga('set', 'page', '/sign-in');
      // @ts-ignore
      window.ga('send', 'pageview');
    }
  }, []);

  const getTrackingId = (name: string): string => {
    return renderState === RenderState.SIGN_UP ? `sign-up-${name}` : `login-${name}`;
  };

  return (
    <Splash>
      <LoginWrapper>
        <Box
          style={{
            color: theme.colors.baseWhite,
            flexShrink: 0,
            flexDirection: 'row',
            marginTop: 10,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <StyledLogo name="brand-text-horiz" size={60} />
        </Box>
        <Paragraph style={{ fontWeight: 'bold' }} textAlign="center" size="xlarge" color={theme.colors.baseWhite}>
          {renderState === RenderState.LOGIN ? 'Login to your Pretzel account' : 'Join the fun'}
        </Paragraph>
        <LoginButtonContainer>
          <LoginLink
            key={`${RenderState.SIGN_UP}_twitch`}
            type="twitch"
            href={getLink('twitch')}
            disabled={renderState === RenderState.SIGN_UP && !agreedToUserAgreementDate}
            data-heapid={getTrackingId('twitch')}
            data-testid={`${getTrackingId('twitch')}-button`}
          >
            <Icon name="twitch" size={20} />
            <span style={{ paddingLeft: 20 }}>
              {renderState === RenderState.LOGIN ? 'Login' : 'Sign up'} with Twitch
            </span>
          </LoginLink>
          {!IS_ELECTRON && (
            <LoginLink
              key={`${RenderState.SIGN_UP}_google`}
              type="google_oauth2"
              href={getLink('google_oauth2')}
              disabled={renderState === RenderState.SIGN_UP && !agreedToUserAgreementDate}
              data-heapid={getTrackingId('google')}
              data-testid={`${getTrackingId('google')}-button`}
            >
              <Icon name="google" size={20} />
              <span style={{ paddingLeft: 20 }}>
                {renderState === RenderState.LOGIN ? 'Login' : 'Sign up'} with Google
              </span>
            </LoginLink>
          )}
        </LoginButtonContainer>

        {renderState === RenderState.SIGN_UP && (
          <>
            <StyledCheckboxContainer>
              <StyledLabel>
                <Checkbox
                  checked={Boolean(agreedToUserAgreementDate)}
                  onChange={() => setAgreedToUserAgreementDate(!agreedToUserAgreementDate)}
                  data-heapid="license-agreement"
                  data-testid="license-agreement-checkbox"
                />
                <span>
                  I confirm that I have read and accept the{' '}
                  <a href={URLS.LICENSE_SERVICES_AGREEMENT} rel="noopener noreferrer" target="_blank">
                    License Services Agreement
                  </a>
                </span>
              </StyledLabel>
              <StyledLabel>
                <Checkbox
                  checked={Boolean(!optedOutOfMarketing)}
                  onChange={() => setOptedOutOfMarketing(!optedOutOfMarketing)}
                  data-heapid="marketing"
                  data-testid="marketing-checkbox"
                />
                <span>
                  I want to hear about new music, improving my stream, & personalized music recommendations. Sign me up!
                </span>
              </StyledLabel>
            </StyledCheckboxContainer>

            <StyledParagraph>
              Already have an account?&nbsp;
              <StyledLink onClick={() => setRenderState(RenderState.LOGIN)}>Log in here</StyledLink>
            </StyledParagraph>
          </>
        )}

        {renderState === RenderState.LOGIN && (
          <StyledParagraph>
            Don't have an account?&nbsp;
            <StyledLink onClick={() => setRenderState(RenderState.SIGN_UP)}>Sign up</StyledLink>
          </StyledParagraph>
        )}
      </LoginWrapper>
    </Splash>
  );
};
