import React, { FC } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { Carousel } from '../../../Carousel';
import { StationCard } from '../../../StationsList/Components/StationCard';
import { TopPlayedStationsProps } from './types';
import { Section } from '../Section';

const TopPlayedStationsQuery = loader('./topPlayedStations.graphql');

export const TopPlayedStationsFn: FC<TopPlayedStationsProps> = ({ data, ...rest }) => {
  return (
    <Section title="Top played stations" viewMoreLink="/stations">
      {!data.loading && data.segments && (
        <Carousel id="top-played-stations" numberOfSlides={data.segments.length}>
          {data?.segments.map(node => (
            <StationCard key={node.id} station={node} />
          ))}
        </Carousel>
      )}
    </Section>
  );
};

export const TopPlayedStations = compose(
  graphql(TopPlayedStationsQuery, {
    options: {
      variables: {
        guids: 'BjtN,Omtb,lgty,xOtL,Nmt2,NmtQ2,6etm,eXtz,7etO,aDtG,9mtb,YZtO,Abtv,pxt2,PZtm,Lmt4,bBtl,vxty',
      },
    },
  })
)(TopPlayedStationsFn);
