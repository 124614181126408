import React from 'react';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { Box } from 'grommet';
import { TrackList } from '../TrackList';
import { RecentlyPlayedQuery } from './types/RecentlyPlayedQuery';
import { Heading } from '../../../../common/src/Components/Heading';
import { BasicTrackFragment } from '../../Core/Player/Queries/types/BasicTrackFragment';
import { Footer } from '../Footer';
import PageWrapper from '../../Styled/Page';

const recentlyPlayedGql = loader('./recently-played.graphql');

interface GraphqlProps {
  data: QueryControls & RecentlyPlayedQuery;
}

type Props = GraphqlProps;

function RecentlyPlayedPresentation(props: Props) {
  const { data } = props;

  if (data.error) {
    throw data.error;
  }

  const tracks: BasicTrackFragment[] = data.recentlyPlayed || [];
  const hasData = tracks.length > 0;

  return (
    <PageWrapper title="Recently played">
      <TrackList
        title="Recently played"
        fit="content"
        tracks={tracks}
        hasMore={false}
        onMore={() => {}}
        loading={!hasData && data.loading}
        loadingMore={props.data.loading}
      />
      {!data.loading && <Footer />}
    </PageWrapper>
  );
}

export const RecentlyPlayed = compose(
  graphql(recentlyPlayedGql, {
    options: () => {
      return {
        fetchPolicy: 'cache-and-network',
      };
    },
  })
)(RecentlyPlayedPresentation);
