import * as React from "react";

function SvgPlaylistAlbumAlternate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M20 7a3 3 0 00-3-3H3a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3zm-2 14a1 1 0 01-1 1H3a1 1 0 01-1-1V7a1 1 0 011-1h14a1 1 0 011 1z" />
      <path d="M24 3a3 3 0 00-3-3H4.5a1 1 0 000 2H21a1 1 0 011 1v15a1 1 0 002 0z" />
      <path d="M15.5 10.375a2 2 0 00-2.4-1.959L8.132 9.534A2 2 0 006.5 11.5v3.761a.255.255 0 01-.081.185.251.251 0 01-.192.064A2.83 2.83 0 006 15.5a2.5 2.5 0 102.5 2.552V18l.035-6.309a.25.25 0 01.2-.242l4.465-1a.25.25 0 01.211.048.253.253 0 01.094.2v2.573a.255.255 0 01-.081.185.251.251 0 01-.192.064 2.83 2.83 0 00-.227-.01 2.5 2.5 0 102.5 2.552v-.052z" />
    </svg>
  );
}

export default SvgPlaylistAlbumAlternate;
