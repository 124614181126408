import * as React from "react";

function SvgListRemove(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M6 2.5h13.5a1 1 0 000-2H6a1 1 0 000 2zM6 8.5h13.5a1 1 0 000-2H6a1 1 0 000 2zM10 13.5a1 1 0 00-1-1H6a1 1 0 100 2h3a1 1 0 001-1zM2.5.5h-1a1 1 0 000 2h1a1 1 0 000-2zM2.5 6.5h-1a1 1 0 000 2h1a1 1 0 100-2zM2.5 12.5h-1a1 1 0 000 2h1a1 1 0 000-2zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.25 6.5a.75.75 0 01-.75.75h-5a.75.75 0 010-1.5h5a.75.75 0 01.75.75z" />
    </svg>
  );
}

export default SvgListRemove;
