import * as React from 'react';

const SvgCompactHome = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 35.5C30.3513 35.5 35.5 30.3513 35.5 24C35.5 17.6487 30.3513 12.5 24 12.5C17.6487 12.5 12.5 17.6487 12.5 24C12.5 30.3513 17.6487 35.5 24 35.5Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="7.51"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 31.5C28.1421 31.5 31.5 28.1421 31.5 24C31.5 19.8579 28.1421 16.5 24 16.5C19.8579 16.5 16.5 19.8579 16.5 24C16.5 28.1421 19.8579 31.5 24 31.5Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.94 20.7379C21.8508 20.6673 21.7274 20.6591 21.6297 20.7173C21.532 20.7755 21.4804 20.8879 21.5 20.9999V26.9999C21.4804 27.1119 21.532 27.2243 21.6297 27.2825C21.7274 27.3407 21.8508 27.3325 21.94 27.2619L27.529 24.2379C27.771 24.1069 27.771 23.8929 27.529 23.7619L21.94 20.7379Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCompactHome;
