import * as React from "react";

function SvgBrand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 29 32" {...props}>
      <path d="M28.32.064L12.8 8.704c-.128.064-.16.16-.16.32v7.232l3.584 2.336v-7.488c0-.128.064-.256.16-.32l8.32-4.64c.256-.128.544.064.544.32v13.792a3.713 3.713 0 01-2.688 3.776c-.992.288-2.08.192-3.008-.384l-8.32-5.28-.032-.032a7.193 7.193 0 00-6.208-.672 7.235 7.235 0 00-4.128 3.584C0 22.912-.192 24.832.288 26.656a7.284 7.284 0 003.552 4.448 7.234 7.234 0 003.52.928 7.11 7.11 0 002.144-.32 7.436 7.436 0 004.544-3.904l.128-.288-3.072-1.984-.352.768c-.448.928-1.312 1.664-2.304 1.984-.96.288-1.984.16-2.88-.32s-1.536-1.28-1.824-2.272c-.544-1.888.544-3.936 2.4-4.576.416-.16.832-.224 1.248-.224.672 0 1.344.192 1.952.544l8.256 5.248.032.032a7.55 7.55 0 003.84 1.056 7.65 7.65 0 002.112-.288c3.264-.96 5.344-4 5.28-7.232V.384c0-.32-.32-.48-.544-.32z" />
    </svg>
  );
}

export default SvgBrand;
