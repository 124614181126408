import { URI_BASE } from '../Core/GraphQL';

export const setUserAgreementPreferences = async (hasUserAgreedToLicense: boolean, hasUserOptedOutOfMarketing: boolean) => {
  return await fetch(`${URI_BASE}/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation ConsentUpdateMutation($attributes: ConsentAttributes!) {
        consentUpdate(attributes: $attributes) {
          user {
            consent {
              marketing
              legal
            }
          }
        }
      }`,
      variables: {
        attributes: {
          marketing: !hasUserOptedOutOfMarketing,
          legal: hasUserAgreedToLicense,
        },
      },
    }),
  });
};

export const addUserToHubSpotMarketing = async () => {
  return await fetch(`${process.env.REACT_APP_API_URL || 'https://api.pretzel.rocks'}/api/v1/account/marketing-opt-in`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
    },
  });
};
