import * as React from "react";

function SvgOnline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M8.672 14.144c1.056-1.088 2.784-1.088 3.872 0a2.723 2.723 0 010 3.84c-1.088 1.088-2.816 1.088-3.872 0s-1.056-2.784 0-3.84zM16 22.528c-.544 0-.928-.384-.928-1.056 0-.288.128-.544.256-.672a6.722 6.722 0 000-9.6 1.084 1.084 0 010-1.472c.416-.384.928-.384 1.344 0 3.456 3.456 3.456 8.928 0 12.416-.128.256-.416.384-.672.384zm4.128 4.128c-.512 0-.928-.384-.928-1.056 0-.256.128-.544.256-.672a12.574 12.574 0 000-17.856c-.384-.416-.384-1.088 0-1.472.416-.416.96-.416 1.344 0 5.728 5.728 5.728 15.072 0 20.8a.999.999 0 01-.672.256z" />
    </svg>
  );
}

export default SvgOnline;
