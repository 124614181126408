import * as React from 'react';
import { LoadingWrapper, StyledButton, Wrapper } from './Styles';
import { ClipLoader } from 'halogenium';
import { CSSProperties } from 'react';
import { Icon, theme } from '../../../../../../Styled';

interface PublicProps {
  active?: boolean | null;
  play?: boolean;
  style?: CSSProperties;
  size?: number;
  title?: string;
  name: string;
  isLoading: boolean;
  isDisabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  color?: string;
  onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  heapid?: string;
  testid?: string;
  wrapperStyle?: CSSProperties;
}

type Props = PublicProps;

export class ControlItem extends React.Component<Props> {
  public render() {
    return (
      <Wrapper style={this.props.wrapperStyle}>
        <StyledButton
          active={this.props.active}
          play={this.props.play}
          onClick={this.props.onClick}
          style={this.props.style}
          title={this.props.title}
          onMouseOver={this.props.onMouseOver}
          onMouseLeave={this.props.onMouseLeave}
          data-heapid={this.props.heapid}
          data-testid={this.props.testid ? this.props.testid : this.props.heapid ? this.props.heapid + '-button' : ''}
        >
          <Icon
            name={this.props.name}
            size={this.props.size}
            isLoading={this.props.isLoading}
            isDisabled={this.props.isDisabled}
            color={this.props.color}
            noFill={true}
          />
          {this.props.children}
        </StyledButton>
        {(this.props.isLoading || this.props.isDisabled) && (
          <LoadingWrapper className="loader-fade-in">
            {!this.props.play && !this.props.isDisabled && <ClipLoader color={theme.colors.brandGreen2} size="16px" />}
          </LoadingWrapper>
        )}
      </Wrapper>
    );
  }
}
