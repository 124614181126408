import { PlayTokenFragment } from '../../Core/Player/Queries/types/PlayTokenFragment';
import { BasicTrackFragment } from '../../Core/Player/Queries/types/BasicTrackFragment';
import { useMutation } from '@apollo/client';
import {
  SetTrackBlacklistedInput,
  SetTrackBlacklistedMutation,
  SetTrackLikedInput,
  SetTrackLikedMutation,
} from '../../Core/Player/Components/PlayerControls/schema';
import React from 'react';
import { sendAnalyticsEvent } from './Utils';
import { AnalyticsEventType } from '../../Core/Analytics';
import { DropdownBox, DropdownItem, TopTail } from './Styled';
import { theme } from '../';
import { Icon } from '../Icon';
import { loader } from 'graphql.macro';
import { Box } from 'grommet';

const likeTrackMutation = loader('../../Core/Player/Queries/set-track-liked.graphql');
const dislikeTrackMutation = loader('../../Core/Player/Queries/set-track-blacklisted.graphql');

export interface DropdownItemProps {
  track: BasicTrackFragment;
}
export interface DropdownItemConfig {
  id: string;
  render: (props: DropdownItemProps) => React.ReactNode;
  onClick: (props: DropdownItemProps) => void;
}

interface TrackDropdownProps {
  playToken: PlayTokenFragment | null;
  track: BasicTrackFragment;
  additionalItems: DropdownItemConfig[];
  next: () => void;
  playlistId?: string;
}

export function TrackDropdown(props: TrackDropdownProps) {
  const { track, next, playToken, additionalItems } = props;
  const [likeTrack] = useMutation<SetTrackLikedMutation, SetTrackLikedInput>(likeTrackMutation);
  const [dislikeTrack] = useMutation<SetTrackBlacklistedMutation, SetTrackBlacklistedInput>(dislikeTrackMutation);

  function generateHandler(handler: (props: DropdownItemProps) => void) {
    return function(event: React.MouseEvent<any>) {
      // @ts-ignore
      event.target.parentElement.blur();
      handler({ track });
    };
  }

  const extras = additionalItems || [];
  const items = [
    {
      id: 'like',
      render: ({ track }: DropdownItemProps) => {
        return (
          <>
            <span>Like</span>{' '}
            {track.self.liked && (
              <span>
                <Icon name="checkmark" size={12} />
              </span>
            )}
          </>
        );
      },
      onClick: ({ track }: DropdownItemProps) => {
        likeTrack({
          variables: {
            trackId: track.id,
            value: !track.self.liked,
          },
        });
        sendAnalyticsEvent(AnalyticsEventType.Like, track);
      },
    },
    {
      id: 'dislike',
      render: ({ track }: DropdownItemProps) => {
        return (
          <>
            <span>Dislike</span>
            {track.self.blacklisted && (
              <span>
                <Icon name="checkmark" size={12} />
              </span>
            )}
          </>
        );
      },
      onClick: ({ track }: DropdownItemProps) => {
        dislikeTrack({
          variables: {
            trackId: track.id,
            value: !track.self.blacklisted,
          },
        }).then(() => {
          if (playToken && playToken.track.id === track.id) {
            next();
          }
        });
        sendAnalyticsEvent(AnalyticsEventType.Dislike, track);
      },
    },
    ...extras,
  ];

  return (
    <DropdownBox>
      <TopTail />
      <Box background={theme.colors.bgSections} pad={{ vertical: '8px' }} style={{ fontStyle: 'normal' }}>
        {items.map(conf => (
          <DropdownItem justify="between" direction="row" onClick={generateHandler(conf.onClick)} key={conf.id}>
            {conf.render({ track })}
          </DropdownItem>
        ))}
      </Box>
    </DropdownBox>
  );
}
