import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { AlbumArt } from './Components/AlbumArt/AlbumArt';
import { TrackProgress } from './Components/TrackProgress/TrackProgress';
import { TrackInfo } from './Components/TrackInfo/TrackInfo';
import { PlayerControls } from './Components/PlayerControls/PlayerControls';
import { Box } from 'grommet';
import { ControlItem } from './Components/PlayerControls/Components/ControlItem/ControlItem';
import { LikeDislikeWrapper, VolumeWrapper, CompactPlayerHiddenControls } from './styles';
import Volume from './Components/Volume';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { ILikeAndDislikeProps, mapPlatformContextToProps, mapPlayerContextToProps, PlayerProps, PlayerState } from './shared/interfaces';
import {
  handleLike,
  handleMute,
  handleDislike,
  handleAddToPlaylistClick,
  blacklistTrackMutation,
  likeTrackMutation,
  handleVolumeHoverable,
  handlePlaylistUnhover,
} from './shared/utils';
import { withMutation } from '@apollo/client/react/hoc';
import { withPlatformContext } from '../Platform';
import { withPlayerContext } from './PlayerContext';
import { Settings } from './Components/PlayerControls/Components/Settings/Settings';
import { Tooltip } from '@pretzel-aux/common/src/Components/Tooltip';
import { CSSProperties } from 'styled-components';
import { theme } from '@pretzel-aux/common/src/Styled';
import { ActiveBar } from './Components/ActiveBar';
import { AddToPlaylist } from './Components/PlayerControls/Components/AddToPlaylist/AddToPlaylist';

interface IPlayerPropsExt extends PlayerProps {
  toggleCompactControls: () => void;
  contentRef: React.MutableRefObject<any>;
  height: string;
  openCompactControls: boolean;
  width: number;
}

export const LikeAndDislikeComponents = ({ loadingNext, playToken, likeTrack, sendAnalyticsEvent, dislikeTrack, next }: ILikeAndDislikeProps) => {
  const [state, setState] = React.useState({
    loadingLike: false,
    loadingDislike: false,
  });

  return (
    <React.Fragment>
      <Tooltip show text="Dislike Track" styles="left: -0.4rem;">
        <ControlItem
          isLoading={state.loadingDislike}
          isDisabled={loadingNext}
          active={playToken && playToken.track.self.blacklisted}
          name="ThumbsDown"
          onClick={e => handleDislike(e, playToken, setState, state, dislikeTrack, sendAnalyticsEvent, next)}
          heapid="dislike-track"
        />
      </Tooltip>

      <Tooltip show text="Like Track" styles="left: -0.4rem;">
        <ControlItem
          isLoading={state.loadingLike}
          isDisabled={loadingNext}
          active={playToken && playToken.track.self.liked}
          name="ThumbsUp"
          onClick={e => handleLike(e, playToken, setState, state, likeTrack, sendAnalyticsEvent)}
          heapid="like-track"
        />
      </Tooltip>
    </React.Fragment>
  );
};

const CommonAdditionalControls = ({
  pretzelUser,
  muted,
  audioVolume,
  setVolume,
  toggleMute,
  increaseVolume,
  decreaseVolume,
  loadingNext,
  playToken,
  likeTrack,
  sendAnalyticsEvent,
  dislikeTrack,
  next,
  width,
}: IPlayerPropsExt) => {
  const [state, setState] = React.useState({
    volumeBarVisible: false,
    showAddToPlaylist: false,
  });
  const isPremium = pretzelUser?.jwt.tier !== 'free';
  const upgradeText = 'Upgrade to premium to add tracks to playlists';
  const style = !isPremium ? { opacity: '0.5' } : {};

  return (
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        <Tooltip show text={!isPremium ? upgradeText : 'Add To Playlist'} styles={!isPremium ? 'left: -1.7rem;' : 'left: -0.6rem;'}>
          <ControlItem
            isLoading={false}
            isDisabled={!isPremium}
            name="AddToPlaylist"
            style={style as CSSProperties}
            size={48}
            onClick={() => {
              if (isPremium) {
                handleAddToPlaylistClick(setState, state);
              }
            }}
            heapid="add-to-playlist"
          />
        </Tooltip>
        {state.showAddToPlaylist && <AddToPlaylist hide={() => handlePlaylistUnhover(pretzelUser?.jwt.tier !== 'free', setState, state)} />}
      </div>

      <Settings horizontalPlayer />

      <VolumeWrapper
        onMouseEnter={e => handleVolumeHoverable(e, setState, state, increaseVolume, decreaseVolume)}
        onMouseLeave={e => handleVolumeHoverable(e, setState, state, increaseVolume, decreaseVolume)}
      >
        <Volume
          muted={muted}
          audioVolume={audioVolume}
          volumeBarVisible={state.volumeBarVisible}
          setVolume={setVolume}
          handleMute={e => handleMute(e, toggleMute)}
        />
      </VolumeWrapper>

      {width > theme.appBreakpoints.extraSmall && (
        <LikeAndDislikeComponents
          loadingNext={loadingNext}
          playToken={playToken}
          likeTrack={likeTrack}
          sendAnalyticsEvent={sendAnalyticsEvent}
          dislikeTrack={dislikeTrack}
          next={next}
        />
      )}
    </React.Fragment>
  );
};

export const HorizontalPlayerPresentation = (props: IPlayerPropsExt) => {
  const { playToken, toggleCompactControls, openCompactControls, contentRef, height } = props;
  const { width } = useWindowSize();

  const [openNowPlayingDrawer, setNowPlayingDrawer] = React.useState<boolean>(false);

  const setTrackBoxWidth = () => {
    if (width < theme.appBreakpoints.medium) return '43%';
    if (width >= theme.appBreakpoints.medium && width <= theme.appBreakpoints.large) {
      return '25%';
    }
    return '40%';
  };

  if (width >= theme.appBreakpoints.small && openNowPlayingDrawer) {
    setNowPlayingDrawer(false);
  }

  return (
    <React.Fragment>
      <Box
        fill
        direction="row"
        justify={width >= theme.appBreakpoints.medium ? 'stretch' : 'between'}
        style={{
          borderTop: theme.borders.style,
          padding: width > theme.appBreakpoints.extraSmall ? '0.75rem 0.75rem 0 0.75rem' : '',
        }}
        data-heapid="music-player"
      >
        {width > theme.appBreakpoints.extraSmall && (
          <Box width={setTrackBoxWidth()} height="76px" direction="row" flex={{ shrink: 0 }} style={{ maxWidth: '325px' }}>
            {width >= theme.appBreakpoints.extraSmall && width < theme.appBreakpoints.large && (
              <Box
                width="62px"
                height="62px"
                style={{ minWidth: '62px', marginRight: '0.5rem' }}
                data-heapid="track-artwork"
                data-testid="track-artwork"
              >
                <AlbumArt playToken={playToken} />
              </Box>
            )}

            {width >= theme.appBreakpoints.large && (
              <Box
                width="76px"
                height="76px"
                style={{ minWidth: '76px', marginRight: '0.5rem' }}
                data-heapid="track-artwork"
                data-testid="track-artwork"
              >
                <AlbumArt playToken={playToken} />
              </Box>
            )}
            <TrackInfo width={width} />
          </Box>
        )}

        <Box
          flex="grow"
          data-heapid="player-controls"
          data-testid="player-controls"
          style={width > theme.appBreakpoints.small ? { paddingTop: '7px' } : { paddingTop: '7px', alignItems: 'center' }}
        >
          <PlayerControls toggleCompactControls={toggleCompactControls} {...props} />
          {width >= theme.appBreakpoints.large && <TrackProgress />}
        </Box>

        {width >= theme.appBreakpoints.medium && (
          <Box direction="column" flex="shrink" style={{ paddingTop: '7px' }}>
            <Box direction="row">
              <CommonAdditionalControls {...props} width={width} />
            </Box>
            <Box direction="row" alignSelf="end">
              <ActiveBar segment={props.activeSegment} width={width} />
            </Box>
          </Box>
        )}
      </Box>

      {width < theme.appBreakpoints.medium && (
        <Box
          flex="grow"
          style={{ position: 'relative', backgroundColor: openCompactControls && theme.colors.bodyBg, height: openCompactControls ? '50px' : 0 }}
          data-heapid="music-player-extra-controls"
        >
          <CompactPlayerHiddenControls ref={contentRef} style={{ overflow: openCompactControls ? 'initial' : 'hidden' }}>
            <CommonAdditionalControls {...props} width={width} />
          </CompactPlayerHiddenControls>
          <ActiveBar segment={props.activeSegment} width={width} />
        </Box>
      )}

      {width < theme.appBreakpoints.large && (
        <Box
          flex="grow"
          pad={{ horizontal: '0.75rem', top: '0', bottom: '0' }}
          style={{
            height: width < theme.appBreakpoints.extraSmall ? '27px' : '44px',
            borderTop: theme.borders.style,
          }}
        >
          <TrackProgress width={width} />
        </Box>
      )}
    </React.Fragment>
  );
};

export const HorizontalPlayer = compose(
  withPlayerContext(mapPlayerContextToProps),
  withPlatformContext(mapPlatformContextToProps),
  withMutation(likeTrackMutation, { name: 'likeTrack' }),
  withMutation(blacklistTrackMutation, { name: 'dislikeTrack' })
  // @ts-ignore Just need to move to hooks for these so that typing isn't so fucked up.
)(HorizontalPlayerPresentation);
