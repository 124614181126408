import * as React from "react";

export default function Tags(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 500 500">
      <g>
        <g>
          <g>
            <path d="M277.9,111.4c2.1-2.1,4.3-4.3,6.4-6.4c-2.4,1-4.7,2-7.1,2.9c46.2-3.5,92.4-7,138.7-10.6c6.6-0.5,13.3-1,19.9-1.5     c-3.3-3.3-6.7-6.7-10-10c-2.9,46-5.8,92-8.6,138c-0.4,6.5-0.8,12.9-1.2,19.4c1-2.4,2-4.7,2.9-7.1c-18.8,18.8-37.5,37.5-56.3,56.3     c-29.9,29.9-59.8,59.8-89.8,89.8c-6.9,6.9-13.8,13.8-20.7,20.7c4.7,0,9.4,0,14.1,0c-7.3-7.3-14.6-14.6-21.9-21.9     c-9.1-9.1-23.3,5-14.1,14.1c7.3,7.3,14.6,14.6,21.9,21.9c3.8,3.8,10.3,3.8,14.1,0c14-14,28-28,42-42c26.3-26.3,52.7-52.7,79-79     c10.5-10.5,20.9-20.9,31.4-31.4c4.7-4.7,9.8-9.3,14.2-14.2c5-5.5,3.7-17.7,4.2-24.6c1-16.5,2.1-32.9,3.1-49.4     c1.9-29.6,4.5-59.3,5.6-88.9c0-0.6,0.1-1.1,0.1-1.7c0.3-5.1-4.8-10.4-10-10c-49.1,3.7-98.2,7.5-147.3,11.2     c-3.4,0.3-6.9,0.5-10.3,0.8c-6.7,0.6-10.1,5-14.5,9.5C254.7,106.3,268.8,120.5,277.9,111.4L277.9,111.4z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M230.3,380.9c-8.8,8.8-17.6,17.6-26.3,26.3c4.7,0,9.4,0,14.1,0c-16.6-16.6-33.2-33.2-49.9-49.9     c-26.3-26.3-52.7-52.7-79-79c-6.1-6.1-12.1-12.1-18.2-18.2c0,4.7,0,9.4,0,14.1c18.1-18.1,36.2-36.2,54.3-54.3     c28.6-28.6,57.2-57.2,85.9-85.9c6.6-6.6,13.3-13.3,19.9-19.9c-2.4,1-4.7,2-7.1,2.9c15.6-1,31.1-1.9,46.7-2.9     c-2.4-5.7-4.7-11.4-7.1-17.1c-17.9,17.9-35.7,35.7-53.6,53.6c-28.5,28.5-56.9,56.9-85.4,85.4c-6.5,6.5-13.1,13.1-19.6,19.6     c-3.8,3.8-3.8,10.3,0,14.1c14,14,28.1,28.1,42.1,42.1c22.5,22.5,45,45,67.5,67.5c5.2,5.2,10.3,10.3,15.5,15.5     c9.1,9.1,23.3-5,14.1-14.1c-14-14-28.1-28.1-42.1-42.1c-22.5-22.5-45-45-67.5-67.5c-5.2-5.2-10.3-10.3-15.5-15.5     c0,4.7,0,9.4,0,14.1c17.9-17.9,35.7-35.7,53.6-53.6c28.5-28.5,56.9-56.9,85.4-85.4c6.5-6.5,13.1-13.1,19.6-19.6     c5.8-5.8,1.8-17.6-7.1-17.1c-9.6,0.6-19.2,1.2-28.7,1.8c-7.2,0.4-19.5-0.9-25.2,4.2c-9.2,8.3-17.7,17.7-26.4,26.4     c-35.1,35.1-70.2,70.2-105.2,105.2c-9.4,9.4-18.9,18.9-28.3,28.3c-3.8,3.8-3.8,10.3,0,14.1c16.6,16.6,33.2,33.2,49.9,49.9     c26.3,26.3,52.7,52.7,79,79c6.1,6.1,12.1,12.1,18.2,18.2c3.8,3.8,10.3,3.8,14.1,0c8.8-8.8,17.6-17.6,26.3-26.3     C253.6,385.9,239.4,371.8,230.3,380.9z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M381.1,170c-8.1,7.7-20.8,8-28.9,0c-7.9-7.9-7.9-21,0-28.9c7.9-7.9,21-7.9,28.9,0C389.1,149.2,388.8,161.9,381.1,170     c-8.9,9.3,5.2,23.5,14.1,14.1c11.8-12.4,15.1-30.1,8-45.7c-6.8-15-23.5-24.8-40-23.1c-16.8,1.8-31.5,13.4-35.7,30.1     C323.3,162,330,179.2,344,189c15.9,11.2,37.5,8.2,51.2-4.8C404.5,175.2,390.4,161.1,381.1,170z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
