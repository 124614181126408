import * as React from 'react';

function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        fill={props.color}
        d="M12.704 20.128l8.64-4.448-8.64-4.512v8.96zM16 4.736q3.008 0 5.792.096t4.096.16l1.312.064.288.032q.288.032.416.064t.416.064.512.16.512.224.544.352.512.48q.128.096.288.32t.512 1.056.48 1.792q.128 1.152.224 2.432T32 14.08v3.136q.032 2.592-.32 5.184-.128.96-.448 1.76t-.576 1.12l-.256.288q-.224.256-.512.48t-.544.32-.512.224-.512.16-.416.064-.416.064-.288.032q-4.48.352-11.2.352-3.68-.064-6.432-.128t-3.552-.128l-.896-.096-.64-.064q-.64-.096-.96-.16t-.928-.384-.992-.736q-.128-.096-.288-.32T.8 24.192.32 22.4q-.128-1.152-.224-2.432T0 17.92v-3.136Q-.032 12.192.32 9.6q.128-.992.448-1.76t.576-1.12l.256-.288q.256-.288.512-.48t.544-.352.512-.224.512-.16.416-.064.416-.064.288-.032q4.48-.32 11.2-.32z"
      />
    </svg>
  );
}

export default SvgYoutube;
