import React, { FC } from 'react';
import { StyledCustomCheckbox, StyledInput, StyledLabel } from './styled';
import { InputType } from './types';

export const Checkbox: FC<InputType> = props => (
  <StyledLabel>
    <StyledInput type="checkbox" {...props} />
    <StyledCustomCheckbox />
  </StyledLabel>
);
