import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Affirmation } from '../Affirmation/Affirmation';
import { ConnectedSearchBox } from '../Search/Components/SearchBox';
import { ControlItem } from '@pretzel-aux/common/src/Core/Player/Components/PlayerControls/Components/ControlItem/ControlItem';
import { CurrentUserMenu } from '../CurrentUser/CurrentUserMenu';
import { data } from '../Segment/Components/SegmentHeader/tests/mock';
import { HeaderProps } from './types';
import { Icon, theme } from '@pretzel-aux/common/src/Styled';
import { Settings } from '../../Core/Player/Components/PlayerControls/Components/Settings/Settings';
import * as Styled from './styled';
import Drawer from '../Drawer/Drawer';
import SearchAnimation from '../Settings/Components/SearchAnimation/SearchAnimation';
import SettingsDrawer from '../Settings/Drawer/SettingsDrawer';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';

export const Header: FC<HeaderProps> = ({ children }) => {
  const history = useHistory();
  const { width } = useWindowSize();
  const [openSearchDropdown, setOpenSearchDropdown] = useState<boolean>(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState<boolean>(false);

  const setCompactModeStyling = () => {
    return width < theme.appBreakpoints.extraSmall ? '48px' : '64px';
  };

  return (
    <Styled.Header>
      <Styled.LogoWrapper>
        <Icon name="Logo" size={32} color="white" />
      </Styled.LogoWrapper>

      <Styled.ArrowsWrapper>
        <div onClick={history.goBack}>
          <Icon name="Arrow" size={48} />
        </div>
        <div onClick={history.goForward}>
          <Icon name="Arrow" size={48} />
        </div>
      </Styled.ArrowsWrapper>

      {width < theme.appBreakpoints.small && (
        <Styled.FilterWrapper>
          <div onClick={() => setOpenFiltersDrawer(!openFiltersDrawer)}>
            <Icon name="More" />
          </div>

          <Drawer
            open={openFiltersDrawer}
            style={{ position: 'fixed', left: 0, backgroundColor: theme.colors.bg, bottom: 0 }}
            height={`calc(100vh - ${setCompactModeStyling()})`}
            yOpen={0}
            yClosed={0}
          >
            <SettingsDrawer setOpenFiltersDrawer={setOpenFiltersDrawer} />
          </Drawer>
        </Styled.FilterWrapper>
      )}

      {width >= theme.appBreakpoints.small && (
        <React.Fragment>
          {width < theme.appBreakpoints.medium ? (
            <Styled.MobileSearch>
              <Styled.MobileSearchIcon onClick={() => setOpenSearchDropdown(!openSearchDropdown)}>
                <Icon name="Search" size={48} />
              </Styled.MobileSearchIcon>
              <SearchAnimation openSearchDropdown={openSearchDropdown}>
                <ConnectedSearchBox />
              </SearchAnimation>
            </Styled.MobileSearch>
          ) : (
            <ConnectedSearchBox />
          )}

          <Settings data={data} />
        </React.Fragment>
      )}

      {width >= theme.appBreakpoints.large && <Affirmation />}

      <CurrentUserMenu />
    </Styled.Header>
  );
};
