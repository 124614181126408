import styled from 'styled-components';
import { theme } from '../../../../Styled/theme';

export const StyledButton = styled.button`
  background: transparent;
  border: none;
  color: ${theme.colors.baseWhite};
  cursor: pointer;
`;

export const PopoverMenu = styled.div`
  background-color: ${theme.colors.baseBackground2};
  min-height: 60px;
  min-width: 510%;
  position: absolute;
  right: -0.5rem;
  top: 3.15rem;
  border-radius: 5px;
  z-index: 9;
  height: 60px;

  @media all and (min-width: 375px) {
    min-width: calc(510% + 10px);
  }

  @media all and (min-width: 475px) {
    min-width: calc(500% + 10px);
  }
`;

export const PopoverContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.bg};
  border-radius: 5px;
  height: 60px;

  .mobile-thumbs-up-container {
    padding-right: 0.8rem;
    color: ${theme.colors.baseWhite} !important;
  }

  .mobile-thumbs-down-container {
    color: ${theme.colors.baseWhite} !important;
  }

  .mobile-track-duration {
    padding-left: 1rem;
    color: ${theme.colors.baseWhite} !important;
  }

  .explicit-icon-mobile {
    margin-top: 0.22rem;
    padding-left: 0.2rem;
  }

  .instrumental-icon-mobile {
    margin-top: 0.3rem;
    margin-left: -0.6rem;
  }

  .mobile-playlist-container {
    margin-top: 0.45rem;
    margin-right: 0.8rem;
  }
`;

export const StyledIconContainer = styled.div``;

export const LeftIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RightIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 1.25rem;
`;
