import React, { FC } from 'react';
import { StyledContent, StyledTitle } from './styled';
import { UpgradeToPremiumModalProps } from './types';
import { flowRight as compose } from 'lodash';
import { withPlatformContext, PlatformContext, URLS } from '../../Core/Platform';
import { Modal } from '../Modal';
import { theme } from '../../Styled';

export const UpgradeToPremiumModalPresentation: FC<UpgradeToPremiumModalProps> = ({
  isOpen,
  setIsOpen,
  pretzelUser,
  openExternalLink,
  buttons = [],
  content,
  pro,
  ...rest
}) => {
  const onFindOutMoreClick = () => {
    openExternalLink(URLS.PRICING);
  };

  return (
    <Modal
      {...rest}
      variant={'secondary'}
      content={
        <StyledContent>
          This is only available to Pretzel {pro ? 'Pro' : 'Premium'} users. <br /> To access it and more, upgrade your account.
        </StyledContent>
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={<StyledTitle>Looks like you need an upgrade!</StyledTitle>}
      buttons={[
        ...buttons,
        {
          color: 'secondary',
          label: 'Find out more',
          onClick: onFindOutMoreClick,
        },
      ]}
      heapid="modal-upsell-premium"
      testid="modal-upsell-premium"
    />
  );
};

export const UpgradeToPremiumModal = compose(
  withPlatformContext(
    (c: PlatformContext, ownProps: UpgradeToPremiumModalProps): UpgradeToPremiumModalProps => ({
      isOpen: c.isUpgradeToPremiumModalOpen,
      setIsOpen: c.setIsUpgradeToPremiumModalOpen,
      pretzelUser: c.pretzelUser,
      openExternalLink: c.openExternalLink,
      ...ownProps,
    })
  )
)(UpgradeToPremiumModalPresentation);
