import { theme } from '../../../Styled/theme';
import styled from 'styled-components';

const BannedWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: ${theme.colors.baseWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SiteHeading = styled.p`
  font-size: 2rem;
`;

const SadIcon = styled.i.attrs({
  className: 'pretzel-icon-empty-playlist',
})`
  font-size: 8rem;
  color: ${theme.colors.brandGreen2};
`;

const LearnMore = styled.p`
  padding-top: 1.5rem;
`;

const ExternalLink = styled.a`
  color: ${theme.colors.brandGreen2};
  text-decoration: none;
  cursor: pointer;
`;

export { BannedWrapper, SiteHeading, SadIcon, LearnMore, ExternalLink };
