import * as React from 'react';

const SvgCompactPlaylists = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.6977 26.1818V13.0909C30.6977 12.8016 30.5883 12.5241 30.3935 12.3195C30.1987 12.1149 29.9345 12 29.659 12H13.0388C12.7633 12 12.4991 12.1149 12.3042 12.3195C12.1094 12.5241 12 12.8016 12 13.0909V30.5455C12 30.8348 12.1094 31.1123 12.3042 31.3168C12.4991 31.5214 12.7633 31.6364 13.0388 31.6364H23.4264"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3877 24.5455C22.3877 24.9795 22.5519 25.3957 22.8441 25.7026C23.1363 26.0095 23.5326 26.1819 23.9458 26.1819C24.3591 26.1819 24.7554 26.0095 25.0476 25.7026C25.3398 25.3957 25.504 24.9795 25.504 24.5455C25.504 24.1116 25.3398 23.6953 25.0476 23.3885C24.7554 23.0816 24.3591 22.9092 23.9458 22.9092C23.5326 22.9092 23.1363 23.0816 22.8441 23.3885C22.5519 23.6953 22.3877 24.1116 22.3877 24.5455V24.5455Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1162 25.6365C15.1162 26.0705 15.2804 26.4867 15.5726 26.7936C15.8648 27.1004 16.2611 27.2728 16.6744 27.2728C17.0876 27.2728 17.4839 27.1004 17.7761 26.7936C18.0683 26.4867 18.2325 26.0705 18.2325 25.6365C18.2325 25.2025 18.0683 24.7863 17.7761 24.4794C17.4839 24.1725 17.0876 24.0001 16.6744 24.0001C16.2611 24.0001 15.8648 24.1725 15.5726 24.4794C15.2804 24.7863 15.1162 25.2025 15.1162 25.6365Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2324 25.6363V19.368C18.2325 19.131 18.3061 18.9006 18.4419 18.7114C18.5778 18.5223 18.7686 18.3848 18.9855 18.3196L24.1793 16.7607C24.334 16.7142 24.4968 16.7061 24.6549 16.737C24.8131 16.7679 24.9622 16.837 25.0906 16.9387C25.219 17.0404 25.3232 17.1721 25.3949 17.3233C25.4666 17.4746 25.5038 17.6412 25.5038 17.8101V24.5454"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26.543 29.4546H35.8918" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.543 31.6365H35.8918" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.543 33.8181H35.8918" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.543 36H35.8918" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SvgCompactPlaylists;
