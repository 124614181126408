import * as React from "react";

function SvgSongRequest(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 39 32" {...props}>
      <path d="M38.208 23.36v-.352-.16c0-.16 0-.16-.16-.32v-.16L27.904 6.528c-.16-.32-.64-.48-.96-.48s-.832.16-.992.48L16 22.368v.16c0 .16 0 .16-.16.32V23.68c.16.16.16.32.32.32l9.952 5.568c.16.16.352.16.672.16s.32 0 .64-.16L37.376 24l.192-.16.16-.16v-.16c.32 0 .48 0 .48-.16zM26.944 9.472l6.208 9.952-5.728-3.264c-.32-.16-.8-.16-1.312 0l-5.696 3.264 6.528-9.952zm0 17.792l-7.52-4.096 7.52-4.384 7.52 4.384-7.52 4.096zm-15.36-5.376v-.16c0-.352-.16-.672-.32-.992L6.848 5.376h12.576V2.112H2.464l4.384 15.36h-.64c-2.944 0-5.216 2.272-5.216 5.216s2.272 5.216 5.216 5.216c2.944 0 5.216-2.272 5.216-5.216.16-.32.16-.48.16-.8z" />
    </svg>
  );
}

export default SvgSongRequest;
