import * as React from "react";

function SvgLock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M22.4 13.216h-.864v-2.24c0-3.072-2.432-5.536-5.536-5.536s-5.536 2.464-5.536 5.536v2.24H9.6c-.96 0-1.696.768-1.696 1.728v9.92c0 .96.736 1.696 1.696 1.696h12.8c.96 0 1.696-.736 1.696-1.696v-9.92c0-.96-.736-1.728-1.696-1.728zm-10.656-2.24c0-2.336 1.92-4.256 4.256-4.256s4.256 1.92 4.256 4.256v2.24h-8.512v-2.24zm11.072 13.888c0 .192-.192.416-.416.416H9.6c-.224 0-.416-.224-.416-.416v-9.92c0-.224.192-.448.416-.448h12.8c.224 0 .416.224.416.448v9.92z" />
    </svg>
  );
}

export default SvgLock;
