import styled from 'styled-components';
import { theme } from '../../../../Styled/theme';

const StyledConnectedContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  p {
    margin-bottom: 0;
  }
`;

const SocialDescriptionWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 1 100%;

  svg {
    margin-right: 1rem;
  }
`;

const ConnectBtnStyles = { height: '2.5rem', width: '136px', marginTop: '1.5rem' };

const DefaultAccountIcon = styled.div`
  &::before {
    background: ${theme.colors.primary};
    border-radius: 9rem;
    content: 'Default';
    display: block;
    font-weight: 700;
    margin-left: 1rem;
    padding: 0.5rem 1rem;
    text-align: center;
    width: min-content;
  }
`;

const StyledUsernameContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1 100%;
  margin-left: 0;
`;

export { StyledConnectedContent, StyledSocialWrapper, SocialDescriptionWrapper, ConnectBtnStyles, StyledUsernameContainer, DefaultAccountIcon };
