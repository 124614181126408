interface Cheer {
  color: string;
  animated: string;
  animatedLight?: string;
  static: string;
  staticLight?: string;
}

interface CheerLookup {
  [count: string]: Cheer;
}

const emptyCheer: Cheer = {
  color: '#979797',
  animated: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1/2.gif',
  static: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1/2.png',
};

const cheers: CheerLookup = {
  100: {
    color: '#9c3ee8',
    animated: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100/2.gif',
    animatedLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100/2.gif',
    static: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100/2.png',
    staticLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100/2.png',
  },
  1000: {
    color: '#1db2a5',
    animated: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/1000/2.gif',
    animatedLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/1000/2.gif',
    static: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/1000/2.png',
    staticLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/1000/2.png',
  },
  5000: {
    color: '#0099fe',
    animated: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/5000/2.gif',
    animatedLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/5000/2.gif',
    static: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/5000/2.png',
    staticLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/5000/2.png',
  },
  10000: {
    color: '#f43021',
    animated: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/10000/2.gif',
    animatedLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/10000/2.gif',
    static: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/10000/2.png',
    staticLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/10000/2.png',
  },
  100000: {
    color: '#f3a71a',
    animated: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/animated/100000/2.gif',
    animatedLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/animated/100000/2.gif',
    static: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/dark/static/100000/2.png',
    staticLight: 'https://d3aqoihi2n8ty8.cloudfront.net/actions/cheer/light/static/100000/2.png',
  },
};

export function getCheerForValue(value: number): Cheer {
  let bitValue = 100;
  if (value === 0) {
    return emptyCheer;
  }
  if (value >= 1000) {
    if (value < 5000) {
      bitValue = 1000;
    } else if (value < 10000) {
      bitValue = 5000;
    } else if (value < 100000) {
      bitValue = 10000;
    } else {
      bitValue = 100000;
    }
  }
  return cheers[bitValue];
}

// Users can't cheer 100,000 bits, so we remove it
export function getSpendableCheers() {
  const userLimited = Object.assign({}, cheers);
  delete userLimited[100000];
  return userLimited;
}
