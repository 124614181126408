import * as React from "react";

function SvgWindows(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 32" {...props}>
      <path d="M12.192 17.952V29.6L0 27.904v-9.952h12.192zm0-13.248v11.744H0V6.368zm17.536 13.248V32l-16.224-2.24V17.952h16.224zm0-15.68v14.176H13.504V4.512z" />
    </svg>
  );
}

export default SvgWindows;
