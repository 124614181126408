import * as React from 'react';
import { Box } from 'grommet';
import { theme } from './';

interface PublicProps {
  circle?: boolean;
}

export class PlaceholderMusicCard extends React.Component<PublicProps> {
  public render() {
    return (
      <Box margin="15px" width="100%" style={{ position: 'relative', cursor: 'default' }}>
        <Box
          height="100%"
          width="100%"
          style={{
            position: 'relative',
            cursor: 'pointer',
            backgroundColor: theme.colors.bgSectionsAlt,
            borderRadius: this.props.circle ? '50%' : '0',
            flexShrink: 0,
          }}
        />
        <Box direction="column" height="160px" width="160px" />
      </Box>
    );
  }
}
