import * as React from "react";

function SvgBrandText(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 49 32" {...props}>
      <path d="M0 32v-8.224c0-.32.256-.576.576-.576h3.296c4.192 0 4.192 6.528 0 6.528H2.24V32H0zm3.872-6.784c-.544-.032-1.088 0-1.6 0v2.528h1.6c1.216-.032 1.312-2.528 0-2.528zm11.424 6.464V32H12.8l-1.504-2.592h-1.184V32H7.84v-8.224c0-.32.256-.576.576-.576h3.296c3.456 0 4.096 4.192 1.856 5.696l1.728 2.784zm-3.584-6.464c-.544-.032-1.088 0-1.6 0v2.176h1.6c1.216 0 1.28-2.176 0-2.176zm9.728 0h-3.264v1.248h3.008v2.016h-3.008v1.536h3.36V32h-5.6v-8.224c0-.32.256-.576.576-.576h4.928v2.016zm10.656 4.768l3.04-5.952c.192-.384-.096-.832-.544-.832H22.88a.574.574 0 00-.576.576v1.408h2.176V32h2.24v-6.816H32L28.576 32h7.008v-2.016h-3.488zm10.048-4.768H38.88v1.248h3.008v2.016H38.88v1.536h3.36V32h-5.6v-8.224c0-.32.288-.576.576-.576h4.928v2.016zm3.456 4.768h3.168V32h-5.44v-8.8H45.6v6.784zM32.416.032l-8.992 4.992c-.064.032-.096.096-.096.192v4.16l2.08 1.344V6.4c0-.064.064-.128.096-.16l4.8-2.688c.16-.064.32.032.32.192v7.936c.032.992-.576 1.92-1.536 2.208-.608.16-1.216.096-1.76-.224l-4.768-3.072h-.032c-1.088-.672-2.368-.8-3.584-.384a4.158 4.158 0 00-2.368 2.048c-.512.96-.64 2.08-.352 3.136a4.306 4.306 0 002.048 2.56c.64.352 1.344.544 2.048.544.416 0 .8-.064 1.216-.192 1.152-.352 2.112-1.152 2.624-2.24l.096-.192-1.792-1.12-.192.416c-.256.544-.736.96-1.312 1.152a2.38 2.38 0 01-1.696-.16c-.512-.288-.864-.768-1.024-1.344-.32-1.088.288-2.272 1.376-2.624.224-.096.48-.128.704-.128.384 0 .8.096 1.12.32l4.8 3.04.032.032c.672.416 1.44.608 2.208.608.416 0 .8-.064 1.216-.16a4.28 4.28 0 003.04-4.192V.224c-.032-.192-.192-.288-.32-.192z" />
    </svg>
  );
}

export default SvgBrandText;
