export enum RenderState {
  LOGIN,
  SIGN_UP,
}

export interface LoginLink {
  type: string;
  disabled: boolean;
}

export enum UserAgreementStorageKeys {
  USER_LICENSE_AGREEMENT_TIME = 'pretzel_www_has_user_agreed_to_license',
  OPTED_OUT_OF_MARKETING = 'pretzel_www_has_user_opted_out_of_marketing',
}
