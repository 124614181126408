import * as React from "react";

function SvgCatalog(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 68 32" {...props}>
      <path d="M16 32c-3.552 0-6.88-1.12-9.984-3.328C2.88 25.984.672 21.984.224 17.792c-.448-4.224.672-8.448 3.328-11.808C6.208 2.656 10.016.672 14.208 0c4.224-.448 8.448.672 11.808 3.328.864.672 1.76 1.568 2.656 2.656l3.776 4.896-2.656 2.016L26.016 8c-.672-.672-1.344-1.568-2.016-2.016-2.656-1.984-5.984-2.88-9.344-2.656-3.328.448-6.432 2.24-8.448 4.896C4 10.88 3.104 14.208 3.552 17.568 4 20.896 5.568 24 8.224 25.984c5.568 4.224 13.568 3.36 17.792-1.984l6.88-8.896 2.656 2.016-6.88 8.864C25.568 30.016 20.896 32 16 32zm46.432-3.328c6.912-5.344 8.224-15.552 2.688-22.464-5.344-6.88-15.552-8.224-22.464-2.656-1.088.896-1.984 1.792-2.656 2.656l-6.88 8.672 2.432 2.24 6.88-8.672a10.619 10.619 0 012.24-2.24c5.536-4.224 13.536-3.328 17.76 2.24 4.224 5.536 3.36 13.536-2.208 17.76S46.656 29.568 42.432 24l-3.776-4.896L36 21.12l3.776 4.864a15.91 15.91 0 0012.672 6.24C56 32 59.552 30.88 62.432 28.672zM34.208 16" />
    </svg>
  );
}

export default SvgCatalog;
