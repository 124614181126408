import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { Box, Image, Text } from 'grommet';
import { ArtworkSize, minutesOrHours, toUrl } from '../../../Util';
import { PlayerContext, withPlayerContext } from '../../../Core/Player/PlayerContext';
import { MusicCard, theme } from '../../../Styled';
import { PlaylistsListQuery_playlists_edges_node } from '../types/PlaylistsListQuery';
import { Heading } from '../../Heading';
import { Logo } from '../../../../../assets/icons';
import { Placeholder } from './Styles';

type PropsFromPlayer = Pick<PlayerContext, 'playSegment' | 'activeSegment' | 'pause' | 'playing'>;

interface PublicProps {
  playlist: PlaylistsListQuery_playlists_edges_node;
  widescreen?: boolean;
  size?: string;
  handleNonPremiumClick?: () => void;
}

type Props = PublicProps & PropsFromPlayer;

export class PlaylistCardPresentation extends React.Component<Props> {
  public render() {
    const { playlist, activeSegment } = this.props;
    const isPlaying = activeSegment && activeSegment.id === playlist.id;

    /* Curation playlists with no art default to "d", user playlists default to "0.0" */
    const art =
      playlist.artworkGuid === 'd' || playlist.artworkGuid === '0.0' ? (
        <Placeholder>
          <Logo width={100} />
        </Placeholder>
      ) : (
        <Image
          src={toUrl(playlist.artworkGuid, this.props.widescreen ? ArtworkSize.LargeRect : ArtworkSize.Medium)}
          width="100%"
          style={
            this.props.widescreen && {
              objectFit: 'cover',
              height: '100%',
              width: '100%',
              borderRadius: '0.25rem',
            }
          }
        />
      );

    const text = (
      <Box margin={`${theme.space.md} 0 0 0`}>
        <Heading variant="H4">{playlist.name}</Heading>
        <Text size="xsmall">{playlist.description}</Text>
      </Box>
    );

    return (
      <MusicCard
        active={isPlaying}
        art={art}
        isPlaying={this.props.playing}
        linkTo={`/segment/${playlist.id}`}
        onClick={this.handleClick}
        text={text}
        meta={{
          hours: minutesOrHours(playlist.stats.duration),
          tracks: playlist.stats.totalCount,
        }}
        widescreen={this.props.widescreen}
        size={this.props.size || '180px'}
        isComingSoon={playlist.stats.totalCount < 15 && playlist.__typename === 'CuratedPlaylist'}
      />
    );
  }

  /**
   * Determines if the current playlist should play or pause
   */
  private handleClick = () => {
    if (this.props.handleNonPremiumClick) {
      this.props.handleNonPremiumClick();
      return;
    }

    if (this.props.playing && this.props.activeSegment.id === this.props.playlist.id) {
      this.props.pause();
    } else {
      this.props.playSegment({ id: this.props.playlist.id });
    }
  };
}

function mapContextToProps(c: PlayerContext): PropsFromPlayer {
  return {
    activeSegment: c.activeSegment,
    pause: c.pause,
    playing: c.playing,
    playSegment: c.playSegment,
  };
}

export const PlaylistCard = compose(withPlayerContext(mapContextToProps))(PlaylistCardPresentation);
