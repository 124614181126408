import * as React from 'react';
import { Props, State } from './interfaces';
import { BarWrapper, VolumeSlider, Wrapper } from './Styles';

const formatpct = (value: number) => `${Math.ceil(value)}%`;

export class VolumeControl extends React.Component<Props, State> {
  private volumeTimer?: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      volume: props.volume,
      volumeChanging: false,
      intermediateVolume: props.volume,
    };
  }
  public static getDerivedStateFromProps(props: Props, state: State) {
    return {
      ...state,
      volume: props.volume,
    };
  }

  public componentWillUnmount(): void {
    clearTimeout(this.volumeTimer);
  }

  public render() {
    // Having the volume slider removed mid drag produces errors and crashes the app. This is an issue with how
    // react-rangeslider responds to events. This ensures that we don't have a crash.
    const volumeVisible = this.props.volumeBarVisible || this.state.volumeChanging;

    return (
      <Wrapper orientation={this.props.orientation}>
        {/* Horizontal player */}
        {!this.props.orientation && (
          <BarWrapper orientation={this.props.orientation}>
            <VolumeSlider
              min={0}
              max={100}
              step={1}
              value={this.state.volume * 100}
              tooltip={this.props.tooltip}
              format={formatpct}
              orientation={this.props.orientation || 'horizontal'}
              color={this.props.color}
              onChange={this.handleSliderChange}
            />
          </BarWrapper>
        )}
      </Wrapper>
    );
  }

  private handleSliderChange = (value: number) => {
    this.props.setVolume(value / 100, true);
  };
}
