import * as React from 'react';

function SvgLadybug(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg id="Bold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>flying-insect-ladybug</title>
      <path d="M22.816,15.551l-.894-.3A10.173,10.173,0,0,0,22,14a10.061,10.061,0,0,0-.15-1.729l.966-.322a1,1,0,1,0-.632-1.9l-.876.292A10.007,10.007,0,0,0,19.676,7.6,2.133,2.133,0,0,1,21.5,6.5a1,1,0,0,0,0-2,4.022,4.022,0,0,0-3.266,1.688A9.955,9.955,0,0,0,16.5,5.071,4.48,4.48,0,0,0,15.8,2.6,9.643,9.643,0,0,0,16.4,1.447a1,1,0,0,0-1.79-.895l-.293.588a4.49,4.49,0,0,0-4.624,0L9.4.552a1,1,0,0,0-1.79.9A9.609,9.609,0,0,0,8.2,2.6a4.48,4.48,0,0,0-.7,2.475A9.955,9.955,0,0,0,5.766,6.187,4.022,4.022,0,0,0,2.5,4.5a1,1,0,0,0,0,2A2.147,2.147,0,0,1,4.324,7.6a10.007,10.007,0,0,0-1.632,2.745l-.876-.292a1,1,0,1,0-.632,1.9l.966.322A10.061,10.061,0,0,0,2,14a10.173,10.173,0,0,0,.078,1.254l-.894.3A1,1,0,0,0,1.5,17.5a3.334,3.334,0,0,0,1.029-.289,10,10,0,0,0,18.942,0A3.34,3.34,0,0,0,22.5,17.5a1,1,0,0,0,.316-1.948Zm-6.542,5.208A7.938,7.938,0,0,1,13,21.937V17.1a3.26,3.26,0,0,0,0-6.2V6.062a7.927,7.927,0,0,1,3.28,1.182,3,3,0,0,0,3.3,4.2,8.009,8.009,0,0,1,0,5.113A3.008,3.008,0,0,0,16.274,20.759Zm-8.55,0a3,3,0,0,0-3.305-4.2,8.009,8.009,0,0,1,0-5.113,3,3,0,0,0,3.3-4.2A7.939,7.939,0,0,1,11,6.062V10.9A3.26,3.26,0,0,0,11,17.1v4.835A7.948,7.948,0,0,1,7.724,20.758Z" />
    </svg>
  );
}

export default SvgLadybug;
