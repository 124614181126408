import * as React from 'react';

function SvgFilm(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>camera-studio</title>
      <path d="M23.506,12.99a1.176,1.176,0,0,0-1.121-.066l-2.731,1.137a.251.251,0,0,0-.154.231v5.416a.251.251,0,0,0,.154.231l2.73,1.137a1.154,1.154,0,0,0,1.122-.066A1.18,1.18,0,0,0,24,20V14A1.18,1.18,0,0,0,23.506,12.99Z" />
      <rect x="1" y="11.5" width="17" height="11" rx="1.5" ry="1.5" />
      <circle cx="4.5" cy="6" r="4.5" />
      <circle cx="13.5" cy="7.5" r="3" />
    </svg>
  );
}

export default SvgFilm;
