import * as React from 'react';

const TrashCan = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.3913 16.1739L31.5005 34.1311C31.3889 35.193 30.4938 35.9994 29.426 36H18.5739C17.5057 36 16.61 35.1934 16.4984 34.1311L14.6086 16.1739"
        stroke="#EFEFEF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 16.1739H36" stroke="#EFEFEF" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M19.3043 16.1739V13.0435C19.3043 12.4672 19.7715 12 20.3478 12H27.6521C28.2284 12 28.6956 12.4672 28.6956 13.0435V16.1739"
        stroke="#EFEFEF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M23.9999 20.8696V31.8261" stroke="#EFEFEF" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M28.6957 20.8696L28.174 31.8261" stroke="#EFEFEF" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M19.3043 20.8696L19.8261 31.8261" stroke="#EFEFEF" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default TrashCan;
