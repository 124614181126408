import { Icon } from '../../../../../../common/src/Styled';
import React from 'react';
import { NavigationButtonContainer, Badge } from './styles';
import { NavLink } from 'react-router-dom';

interface Props {
  location: string;
  iconName: string;
  badgeText?: string;
}

export const NavigationButton = ({ location, iconName, badgeText }: Props) => {
  const getTrackingId = () => {
    if (iconName.substring(0, 7) === 'Compact') {
      return iconName.replace('Compact', '').toLowerCase();
    }
    return iconName.toLowerCase();
  };
  return (
    <NavigationButtonContainer>
      <NavLink to={location} title={iconName.replace('Compact', '')} data-heapid={getTrackingId()} data-testid={`${getTrackingId()}-link`}>
        {badgeText && <Badge> {badgeText} </Badge>}
        <Icon name={iconName} />
      </NavLink>
    </NavigationButtonContainer>
  );
};
