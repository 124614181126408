import * as React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  labeltext?: string;
}

const Download = (props: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="download-icon" {...props}>
      <title id="download-icon">{props.labeltext ?? 'Download'}</title>
      <path
        d="M11.4932 10.594H12.4665C12.4665 10.594 15.3332 10.22 15.3332 7.40536C15.3338 6.53999 14.982 5.71169 14.3587 5.11133C13.7355 4.51098 12.8946 4.19036 12.0298 4.22336C11.1613 2.37996 9.15312 1.36044 7.15241 1.74719C5.1517 2.13393 3.66813 3.82842 3.54917 5.86269C2.84459 5.71452 2.11076 5.89178 1.55151 6.34523C0.992257 6.79868 0.667154 7.48004 0.666504 8.20002C0.666504 10.63 3.21717 10.592 3.21717 10.592H4.48784"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 14V7.33337" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 12.2607L8 14.2607L10 12.2607" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Download;
