import * as React from 'react';

function SvgVolume(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5965 21H14.0351C13.1921 21 12.5088 21.6716 12.5088 22.5V25.5C12.5088 26.3284 13.1921 27 14.0351 27H17.5965L23.8514 33.146C23.9968 33.2885 24.2153 33.3311 24.4051 33.2539C24.5949 33.1767 24.7189 32.9949 24.7193 32.793V15.207C24.7189 15.0051 24.5949 14.8233 24.4051 14.7461C24.2153 14.6689 23.9968 14.7114 23.8514 14.854L17.5965 21Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27.772 20.5C30.4959 22.447 30.4878 25.56 27.772 27.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SvgVolume;
