import * as React from "react";

function SvgHouseChimney2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <defs>
        <style>
          {
            ".house-chimney-2_svg__a{fill:none;stroke:#000;strokeLinecap:round;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <path
        className="house-chimney-2_svg__a"
        d="M3.5 13.5v9a1 1 0 001 1H9a.5.5 0 00.5-.5v-4a2.5 2.5 0 015 0v4a.5.5 0 00.5.5h4.5a1 1 0 001-1V14M.5 13L12 1.5 23.5 13M16 2.5h3a.5.5 0 01.5.5v3"
      />
    </svg>
  );
}

export default SvgHouseChimney2;
