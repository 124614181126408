import { Box } from 'grommet';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from './';
import { Square } from './Aspect';
import { Icon } from './Icon';
import platformContext, { PlatformContext } from '../Core/Platform';

export const Meta = styled.div`
  background: ${theme.colors.secondary};
  border-radius: 2px;
  font-size: 10px;
  height: 20px;
  left: ${theme.space.sm};
  line-height: 20px;
  padding: 0 ${theme.space.sm};
  position: absolute;
  top: ${theme.space.sm};
  z-index: 1;
`;

export const Cover = styled.div`
  background: radial-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  border-radius: ${({ circle }: { circle?: boolean }) => (circle ? '50%' : theme.card.borderRadius)};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const IconContainerStyles = `
  align-items: flex-end;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  right: 0;
  text-shadow: 0 0 7px ${theme.colors.brandGreen};
  top: 0;
`;

export const IconContainer = styled.div`
  ${IconContainerStyles}
`;

export const HoverShow = styled.div<{ isPlaying: boolean }>`
  ${IconContainerStyles}
  opacity: ${({ isPlaying }) => (isPlaying ? 1 : 0)};
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const StyledLink = styled(props => <Link {...props} />)`
  // Overriding global styles
  color: ${theme.colors.light1} !important;

  &:hover {
    color: ${theme.colors.primary} !important;
  }
`;

export const HoverIcon = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0.5rem;
  width: 40px;
  z-index: 1;
`;

type Props = {
  active?: boolean | null;
  art: React.ReactNode;
  circle?: boolean;
  isPlaying?: boolean;
  linkTo?: string;
  onClick: () => void;
  text: React.ReactNode;
  meta?: {
    hours: string;
    tracks: number;
  };
  size?: string;
  widescreen?: boolean;
  cardType?: CardType;
  cardName?: string;
  isLocked?: boolean;
  isComingSoon?: boolean;
};

export enum CardType {
  Artist = 'Artist',
  Album = 'Album',
  Label = 'Label',
  Station = 'Station',
}

export const MusicCard = ({
  active,
  art,
  cardName,
  cardType,
  circle,
  isComingSoon,
  isLocked,
  isPlaying,
  linkTo,
  meta,
  onClick,
  size,
  text,
  widescreen,
}: Props): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const history = useHistory();

  let textContents = text;

  const onClickRedirect = (): void => {
    if (isComingSoon) {
      return;
    }

    isLocked ? platformContextObj.setIsUpgradeToPremiumModalOpen(true) : history.push(linkTo);
  };

  const onClickPlay = (event: React.MouseEvent): void => {
    if (isComingSoon) {
      return;
    }

    event.stopPropagation();

    isLocked ? platformContextObj.setIsUpgradeToPremiumModalOpen(true) : onClick();
  };

  const getSafeType = (): string => {
    return cardType?.toLowerCase();
  };

  if (linkTo && !isComingSoon && !isLocked) {
    textContents = (
      <StyledLink to={linkTo} data-heapid={`${getSafeType()}-link`}>
        {textContents}
      </StyledLink>
    );
  }

  const getTrackingIdForSegment = (): string => {
    let trackingId = '';

    if (isLocked) {
      trackingId = 'locked-';
    } else {
      trackingId = 'playable-';
    }

    return (trackingId += getSafeType());
  };

  const trackingIdSegment = getTrackingIdForSegment();
  const trackingId = `${getSafeType()}-card`;

  return (
    <Box
      style={{
        borderRadius: theme.card.borderRadius,
        cursor: 'default',
        maxWidth: size || theme.card.size.normal,
        position: 'relative',
      }}
      data-heapid={trackingId}
      data-testid={trackingId}
    >
      {meta && meta.tracks > 0 && !isComingSoon && (
        <Meta>
          {meta.tracks} {meta.tracks === 1 ? 'track' : 'tracks'} | {meta.hours}
        </Meta>
      )}

      {isComingSoon && <Meta>Coming Soon</Meta>}

      <Square
        data-card-name={cardName}
        data-card-type={cardType}
        onClick={cardType === CardType.Station ? onClickPlay : onClickRedirect}
        pointer={!isComingSoon}
        ratio={widescreen ? 56.25 : undefined}
        data-heapid={trackingIdSegment}
        data-testid={trackingIdSegment}
      >
        {art}
        {isComingSoon ?? false ? (
          <IconContainer>
            <Cover circle={circle} />
          </IconContainer>
        ) : isLocked ?? false ? (
          <IconContainer data-heapid="lock-button">
            <Cover circle={circle} />
            <Icon color={theme.colors.textTitles} size={60} name="Lock" heapid="lock-button-icon" />
          </IconContainer>
        ) : (
          <HoverShow isPlaying={active}>
            <Cover circle={circle} />
            {isPlaying && active ? (
              <HoverIcon data-button-type="pause" onClick={onClickPlay} data-heapid="pause-button">
                <Icon color={theme.colors.textTitles} size={60} name="Pause2" heapid="pause-button-icon" />
              </HoverIcon>
            ) : (
              <HoverIcon data-button-type="play" onClick={onClickPlay} data-heapid="play-button">
                <Icon color={theme.colors.textTitles} size={60} name="Play" heapid="play-button-icon" />
              </HoverIcon>
            )}
          </HoverShow>
        )}
      </Square>
      <Box direction="column" flex="grow">
        {textContents}
      </Box>
    </Box>
  );
};
