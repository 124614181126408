import * as React from "react";

function SkipOnHover(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M0.624929 2.24976H0.937429C1.14452 2.24976 1.31243 2.41767 1.31243 2.62476V4.08179C2.6623 1.78248 5.16365 0.240857 8.02471 0.249795C12.2868 0.263138 15.7566 3.75004 15.7499 8.01214C15.7432 12.2867 12.276 15.7498 7.99993 15.7498C6.00224 15.7498 4.18112 14.9939 2.80687 13.7526C2.64709 13.6083 2.63955 13.3601 2.7918 13.2079L3.01271 12.987C3.15343 12.8462 3.37987 12.8396 3.52777 12.9728C4.71187 14.0385 6.27915 14.6873 7.99993 14.6873C11.6794 14.6873 14.6874 11.7082 14.6874 7.99976C14.6874 4.32026 11.7083 1.31226 7.99993 1.31226C5.41049 1.31226 3.1644 2.78342 2.0534 4.93726H3.62493C3.83202 4.93726 3.99993 5.10517 3.99993 5.31226V5.62476C3.99993 5.83186 3.83202 5.99976 3.62493 5.99976H0.624929C0.417836 5.99976 0.249929 5.83186 0.249929 5.62476V2.62476C0.249929 2.41767 0.417836 2.24976 0.624929 2.24976Z" fill="#19B3A4"/>
    </svg>
  );
}

export default SkipOnHover;


