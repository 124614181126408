import * as React from "react";

function SvgWaterDam(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.5 11.228a1 1 0 00-1 0l-4.109 2.36a.251.251 0 01-.375-.217v-1.263a1 1 0 00-1.49-.871l-4.155 2.339a.254.254 0 01-.249 0 .25.25 0 01-.122-.218v-1.207a1 1 0 00-1.545-.839L6.9 13.624a.248.248 0 01-.232.021.252.252 0 01-.149-.18L4.905 5.708a1 1 0 00-.979-.8H2.372a1 1 0 00-.99.861L.01 15.506c0 .024-.01.119-.01.144v7.26a1 1 0 001 1h22a1 1 0 001-1V12.093a1 1 0 00-.5-.865zM9.75 21.41h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm0-3.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm5.5 3.5h-2.5a.75.75 0 110-1.5h2.5a.75.75 0 010 1.5zm0-3.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm5.5 3.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm0-3.5h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zM2.765 3.334C5.129 1.859 7.67 4.7 10.881 4.7c1.693 0 3.546-.8 5.522-3.494a.5.5 0 00-.735-.669c-1.86 1.653-3.656 1.037-5.737.326C7.5.031 4.741-.911 2.1 2.611a.5.5 0 00.665.723z" />
    </svg>
  );
}

export default SvgWaterDam;
