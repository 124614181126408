import { Image, Text } from 'grommet';
import { flowRight as compose } from 'lodash';
import * as React from 'react';
import { Hit } from 'react-instantsearch-core';
import aa from 'search-insights';
import { Segment } from '../schema';
import { PretzelJwt, withPlatformContext } from '../../../Core/Platform';
import { PlayerContext, withPlayerContext } from '../../../Core/Player/PlayerContext';
import { CardType, MusicCard, theme } from '../../../Styled';
import { StationArt } from '../../../Styled/StationArt';
import { toUrl } from '../../../Util';
import { isUserEntitled } from '../../../Util/Entitlements';

interface PublicProps {
  hit: Hit<Segment>;
}

type PropsFromPlatform = {
  userTier: PretzelJwt['tier'];
};
type PropsFromPlayer = Pick<PlayerContext, 'playSegment'>;
type Props = PublicProps & PropsFromPlayer & PropsFromPlatform;

export class SegmentHitImplementation extends React.Component<Props> {
  public render() {
    let art, text, linkTo;

    const { hit, userTier } = this.props;
    linkTo = `/segment/${hit.guid}`;
    switch (hit.entity) {
      case 'Label':
        art = <Image src={toUrl(hit.artwork_guid)} width="100%" style={{ borderRadius: '50%' }} />;
        text = (
          <>
            <Text size="10px" color={theme.colors.textMuted} margin={{ top: '3px', bottom: '3px' }}>
              Label
            </Text>
            <br />
            <Text color={theme.colors.textTitles}>{hit.name}</Text>
            <Text size="xsmall">{hit.description}</Text>
          </>
        );
        break;
      case 'Artist':
        art = <Image src={toUrl(hit.artwork_guid)} width="100%" style={{ borderRadius: '50%' }} />;
        text = (
          <>
            <Text size="10px" color={theme.colors.textMuted} margin={{ top: '3px', bottom: '3px' }}>
              Artist
            </Text>
            <br />
            <Text color={theme.colors.textTitles}>{hit.name}</Text>
            <Text size="xsmall">{hit.description}</Text>
          </>
        );
        break;
      case 'Release':
        art = <Image src={toUrl(hit.artwork_guid)} width="100%" />;
        text = (
          <>
            <Text size="10px" color={theme.colors.textMuted} margin={{ top: '3px', bottom: '3px' }}>
              Album
            </Text>
            <br />
            <Text color={theme.colors.textTitles}>{hit.title}</Text>
            {/* <Text size="xsmall">{hit.artists}</Text> */}
          </>
        );
        break;
      case 'Station':
        linkTo = null;
        const isLiked = hit.guid === 'LIKED';
        art = <StationArt color1={hit.color1} color2={hit.color2} theIcon={hit.icon} />;
        text = (
          <>
            <Text size="10px" color={theme.colors.textMuted} margin={{ top: '3px', bottom: '3px' }}>
              Station
            </Text>
            <Text color={theme.colors.textTitles}>{hit.name}</Text>
            <Text size="xsmall">{hit.description}</Text>
          </>
        );
        break;
      default:
      // do nothing
    }

    return (
      <MusicCard
        art={art}
        text={text}
        onClick={this.handleClick}
        linkTo={linkTo}
        isLocked={!isUserEntitled(userTier, hit.entitlements)}
        cardType={(hit.entity === 'Release' ? 'album' : hit.entity?.toLowerCase()) as CardType}
      />
    );
  }

  private handleClick = () => {
    this.props.playSegment({ id: this.props.hit.guid });
    // @ts-ignore The __queryID and __position properties of hit are not listed in the types but they exist
    const { objectID, __queryID: queryID, __position: position } = this.props.hit;
    aa('click', { objectID, queryID, position });
  };
}

function mapContextToProps(c: PlayerContext): PropsFromPlayer {
  return {
    playSegment: c.playSegment,
  };
}
export const SegmentHit = compose(
  withPlayerContext(mapContextToProps),
  withPlatformContext(c => ({
    userTier: c.pretzelUser.jwt.tier,
  }))
)(SegmentHitImplementation);
