import styled from 'styled-components';
import React from 'react';

export interface AspectRatioProperties {
  ratio: number;
}
const AspectRatio = styled.div<AspectRatioProperties>`
  height: 0;
  padding-top: ${({ ratio }) => ratio || 100}%;
  position: relative;
`;

const AspectContents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface InteractiveProperties {
  pointer?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  ratio?: number;
}

export const Square: React.FunctionComponent<InteractiveProperties> = props => {
  return (
    <AspectRatio style={{ cursor: props.pointer ? 'pointer' : 'default' }} onClick={props.onClick} ratio={props.ratio || 100} {...props}>
      <AspectContents>{props.children}</AspectContents>
    </AspectRatio>
  );
};
