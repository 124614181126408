import * as React from "react";

function SvgCheckmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M25.344 8.8L14.528 27.744l-7.712-9.888 3.2-2.528 4 5.216 7.84-13.76L25.344 8.8z" />
    </svg>
  );
}

export default SvgCheckmark;
