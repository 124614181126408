import * as React from "react";

function SvgMoreCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M16 2.848C23.2 2.848 29.152 8.8 29.152 16S23.2 29.152 16 29.152 2.848 23.2 2.848 16 8.8 2.848 16 2.848m0-2.272C7.424.576.576 7.424.576 16S7.424 31.424 16 31.424 31.424 24.576 31.424 16 24.576.576 16 .576zm0 13.248c1.152 0 2.176 1.024 2.176 2.176S17.152 18.176 16 18.176 13.824 17.152 13.824 16s1.024-2.176 2.176-2.176zm7.2 0c1.152 0 2.176 1.024 2.176 2.176s-1.024 2.176-2.176 2.176-2.176-1.024-2.176-2.176 1.024-2.176 2.176-2.176zm-14.4 0c1.152 0 2.176 1.024 2.176 2.176S9.952 18.176 8.8 18.176 6.624 17.152 6.624 16s1.024-2.176 2.176-2.176z" />
    </svg>
  );
}

export default SvgMoreCircle;
