import styled from 'styled-components/macro';
import Slider from 'react-rangeslider';
import { theme } from '@pretzel-aux/common/src/Styled/theme';
import { PublicProps, BarWrapperProps } from './interfaces';

const setPosition = (orientation: string) => {
  if (orientation === 'vertical') {
    return `position: absolute;`;
  }
  return `position: initial;`;
};

const setHeight = (orientation: string) => {
  if (orientation === 'vertical') {
    return `height: 200px;`;
  }
  return `height: initial;`;
};

export const Wrapper = styled.div<BarWrapperProps>`
  ${(props: { orientation?: string }) => setPosition(props.orientation)}
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const BarWrapper = styled.div<BarWrapperProps>`
  ${(props: { orientation?: string }) => setPosition(props.orientation)}
  bottom: 15px;
  left: 0;
  right: 0;
  ${(props: { orientation?: string }) => setHeight(props.orientation)}
`;

export const VolumeSlider = styled(Slider)<PublicProps>`
  ${(props: { orientation?: string; color?: string }) => {
    if (props.orientation === 'horizontal') {
      return `
        background: ${theme.colors.dark5} !important;
        width: 5rem;
        height: 0.25rem;
        border-radius: 0.25rem;

        .rangeslider__fill {
          background-color: ${props.color};
          border-radius: 0.25rem;
          box-shadow: none;
          height: 100%;
          left: 0;
        }

        .rangeslider__handle {
          display: ${props.color !== theme.colors.offWhite ? 'inherit' : 'none'};
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 1rem;
          border: 7px solid #fff;
          transform: translateX(-5px) translateY(-0.35rem);
        }

        .rangeslider__handle-label {
          transform: translateX(-1rem) translateY(-0.625rem);
        }

        .rangeslider__handle-tooltip {
          transform: translateX(-25px) translateY(-2.25rem);
        }
      `;
    }
    return `
      left: -3px;
    `;
  }}
`;
