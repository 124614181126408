import * as React from 'react';

function SvgRotateBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <defs>
        <style>{'.a{fill:none;stroke:currentColor;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}'}</style>
      </defs>
      <polyline className="a" points="12.75 7.5 9.75 4.5 12.75 1.5" />
      <path className="a" d="M3.031,12.75c-.02.247-.031.5-.031.75a8.973,8.973,0,0,0,.284,2.25" />
      <path className="a" d="M5.106,7.715A8.968,8.968,0,0,0,3.877,9.621" />
      <path className="a" d="M5.128,19.312q.24.284.508.552a8.979,8.979,0,0,0,1.792,1.39" />
      <path className="a" d="M11.25,22.469c.247.02.5.031.75.031a9,9,0,0,0,0-18H9.75" />
    </svg>
  );
}

export default SvgRotateBack;
