import React from 'react';
import Select from 'react-select';
import { flowRight as compose } from 'lodash';
import { AudioOutput, SettingsContext, withSettingsContext } from '../../../../../Core/Settings/Settings';
import { customDropDownStyles } from '../../Audio/Components/CustomSelectStyles';

interface PropsFromContext {
  audioOutputList: AudioOutput[];
}
interface ComponentProps {
  onChange: (val: string) => void;
  value: string;
}

type IAudioOutputProps = PropsFromContext & ComponentProps;

interface ISelectOption {
  value: string;
  label: string;
}

const AudioOutputSelectorPresentation = ({ audioOutputList, value, onChange }: IAudioOutputProps) => {
  const options: ISelectOption[] = audioOutputList
    .map(output => ({
      label: output.label,
      value: output.device.deviceId,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

  const selected = options.find(option => option.value === value);

  return (
    <>
      <label>Audio output keybindings</label>
      <Select
        name="outputs"
        value={selected}
        options={options}
        onChange={val => onChange((val as any).value)}
        className="audioOutputs"
        placeholder="Default"
        styles={customDropDownStyles}
      />
    </>
  );
};

function mapContextToProps(c: SettingsContext): PropsFromContext {
  return {
    audioOutputList: c.audioOutputList,
  };
}

const AudioOutputKeybindSelector = compose(withSettingsContext(mapContextToProps))(AudioOutputSelectorPresentation);

export default AudioOutputKeybindSelector;
