import React, { FC } from 'react';
import { StyledInput, StyledInputContainer, StyledInputIcon } from './styled';
import { InputProps } from './types';

export const Input: FC<InputProps> = ({ icon, ...rest }) => (
  <StyledInputContainer>
    <StyledInput {...rest} />
    {icon && <StyledInputIcon>{icon}</StyledInputIcon>}
  </StyledInputContainer>
);
