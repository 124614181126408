import React from 'react';
import { PretzelRocksBase } from '../PretzelRocks/PretzelRocksBase';
import { Switch, Route, Redirect } from 'react-router';

// Pull in the PretzelRocks Platform Design System to get their base styles (and the font they use)
import 'platform-design-system/styles.css';
// Then override them
import '@pretzel-aux/assets/css/slobs-base.css';
import '@pretzel-aux/assets/css/pretzel-icons.css';
import '@pretzel-aux/assets/css/range-slider.css';
import { Grommet, Box, Paragraph } from 'grommet';
import { GlobalTheme, Icon, theme } from '@pretzel-aux/common/src/Styled';
import { BrowserRouter } from 'react-router-dom';
import ResponsivePage from "@pretzel-aux/common/src/Components/ResponsivePage/ResponsivePage";

interface State {
  unloaded: boolean;
  /** We could use this in future for theme preferences */
  themePreference?: "light" | "dark";
}

class App extends React.Component<{}, State> {
  state: State = {
    unloaded: false
  };

  public render() {
    if (this.state.unloaded) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState('', '', '/')
      return (
        <Grommet full plain className="night-theme content-wrapper">
          <Box fill justify="center" align="center">
            <Box style={{ color: theme.colors.brandGreen2 }} width="300px">
              <Icon name="brand-text" size={160} />
            </Box>
            <Box
              style={{ background: theme.colors.bgSections, borderRadius: '3px' }}
              width="400px"
              height="400px"
              align="center"
              justify="center"
              pad="50px"
            >
              <Paragraph textAlign="center" size="large">
                Please wait while we reload the app...
              </Paragraph>
            </Box>
          </Box>
        </Grommet>
      );
    }
    return (
      <Grommet
        className="night-theme content-wrapper slobs-embedded-app"
        full
        plain
        theme={GlobalTheme}
        style={{ overflow: 'hidden' }}
      >
        <BrowserRouter>
          <PretzelRocksBase unloadApp={this.unloadApp}>
            <Switch>
              {/* SignInPage is displayed by the `StreamlabsToPretzelAuthenticatedApolloProvider` class */}
              {/* If we hit this `login` route, we're already logged in, redirect to root */}
              <Redirect from="/login" to="/" />
              <Route path="*" component={ResponsivePage} />
            </Switch>
          </PretzelRocksBase>
        </BrowserRouter>
      </Grommet>
    );
  }
  private unloadApp = () => {
    this.setState({ unloaded: true });
    setTimeout(() => {
      this.setState({ unloaded: false });
    }, 3000);
  };
}

export default App;
