import * as React from "react";

function DiscoBall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path d="M5.75,14c0-.681.031-1.342.09-1.977a.25.25,0,0,0-.249-.273H2.5a.249.249,0,0,0-.242.189,8.489,8.489,0,0,0,0,4.122.25.25,0,0,0,.243.189H5.591a.25.25,0,0,0,.249-.273C5.781,15.342,5.75,14.681,5.75,14Z" />
      <path d="M13.647,11.974a.25.25,0,0,0-.249-.224H7.6a.25.25,0,0,0-.249.224,19.786,19.786,0,0,0,0,4.052.25.25,0,0,0,.249.224h5.8a.25.25,0,0,0,.249-.224A19.482,19.482,0,0,0,13.75,14,19.482,19.482,0,0,0,13.647,11.974Z" />
      <path d="M18.493,16.25a.25.25,0,0,0,.243-.189,8.489,8.489,0,0,0,0-4.122.248.248,0,0,0-.242-.189H15.41a.252.252,0,0,0-.185.082.248.248,0,0,0-.064.191c.059.635.089,1.3.089,1.977s-.03,1.342-.089,1.977a.249.249,0,0,0,.249.273Z" />
      <path d="M7.9,17.75a.219.219,0,0,0-.214.268c.606,2.61,1.754,4.232,2.817,4.232s2.2-1.606,2.809-4.192a.251.251,0,0,0-.244-.308Z" />
      <path d="M15.117,17.75a.25.25,0,0,0-.245.2,12.1,12.1,0,0,1-1.2,3.431.25.25,0,0,0,.322.349,8.554,8.554,0,0,0,3.924-3.608.251.251,0,0,0-.218-.372Z" />
      <path d="M6.129,17.95a.25.25,0,0,0-.245-.2H3.3a.25.25,0,0,0-.219.372A8.549,8.549,0,0,0,7.008,21.73a.25.25,0,0,0,.322-.349A12.1,12.1,0,0,1,6.129,17.95Z" />
      <path d="M5.884,10.25a.249.249,0,0,0,.245-.2A12.057,12.057,0,0,1,7.343,6.6a.25.25,0,0,0-.321-.35A8.547,8.547,0,0,0,3.074,9.879a.25.25,0,0,0,.218.371Z" />
      <path d="M14.872,10.05a.25.25,0,0,0,.245.2h2.592a.25.25,0,0,0,.218-.371,8.547,8.547,0,0,0-3.948-3.633.25.25,0,0,0-.321.35A12.057,12.057,0,0,1,14.872,10.05Z" />
      <path d="M11.576,6.275A.253.253,0,0,1,11.5,6.1V1a1,1,0,0,0-2,0V6.1a.252.252,0,0,1-.075.18A7.731,7.731,0,0,0,7.692,9.943a.251.251,0,0,0,.244.307h5.129a.251.251,0,0,0,.244-.307A7.731,7.731,0,0,0,11.576,6.275Z" />
      <path d="M18.75,6a.75.75,0,0,0,.75-.75v-.5a.25.25,0,0,1,.25-.25h.5a.75.75,0,0,0,0-1.5h-.5a.25.25,0,0,1-.25-.25v-.5a.75.75,0,0,0-1.5,0v.5a.25.25,0,0,1-.25.25h-.5a.75.75,0,0,0,0,1.5h.5a.25.25,0,0,1,.25.25v.5A.75.75,0,0,0,18.75,6Z" />
      <path d="M23.25,20.5h-1a.25.25,0,0,1-.25-.25v-1a.75.75,0,0,0-1.5,0v1a.25.25,0,0,1-.25.25h-1a.75.75,0,0,0,0,1.5h1a.25.25,0,0,1,.25.25v1a.75.75,0,0,0,1.5,0v-1a.25.25,0,0,1,.25-.25h1a.75.75,0,0,0,0-1.5Z" />
      <path d="M3.75,4h-.5A.25.25,0,0,1,3,3.75v-.5a.75.75,0,0,0-1.5,0v.5A.25.25,0,0,1,1.25,4H.75a.75.75,0,0,0,0,1.5h.5a.25.25,0,0,1,.25.25v.5a.75.75,0,0,0,1.5,0v-.5a.25.25,0,0,1,.25-.25h.5a.75.75,0,0,0,0-1.5Z" />
    </svg>
  );
}

export default DiscoBall;
