import { Box, Text } from 'grommet';
import React, { useContext } from 'react';
import { StationsListQuery_stations_edges_node } from '../types/StationsListQuery';
import { Heading } from '../../Heading';
import platformContext, { PlatformContext } from '../../../Core/Platform';
import playerContext, { PlayerContext } from '../../../Core/Player/PlayerContext';
import { MusicCard, theme, CardType } from '../../../Styled';
import { StationArt } from '../../../Styled/StationArt';
import { minutesOrHours } from '../../../Util';
import { isUserEntitled } from '../../../Util/Entitlements';

type Props = {
  station: StationsListQuery_stations_edges_node;
  size?: string;
};

export const StationCard = ({ station, size }: Props): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const playerContextObj = useContext<PlayerContext>(playerContext);
  const isPlaying = playerContextObj.activeSegment && playerContextObj.activeSegment.id === station.id;
  const isLiked = station.id === 'LIKED';

  const handleClick = (): void => {
    if (playerContextObj.playing && playerContextObj.activeSegment.id === station.id) {
      playerContextObj.pause();
    } else {
      playerContextObj.playSegment({ id: station.id });
    }
  };

  const art = <StationArt color1={station.color1} color2={station.color2} theIcon={station.icon} />;
  const text = (
    <Box margin={`${theme.space.md} 0 0 0`}>
      <Heading variant="H4">{station.name}</Heading>
      <Text size="xsmall">{station.description}</Text>
    </Box>
  );

  return (
    <MusicCard
      active={isPlaying}
      art={art}
      isPlaying={playerContextObj.playing}
      onClick={handleClick}
      text={text}
      meta={{
        hours: minutesOrHours(station.stats.duration),
        tracks: station.stats.totalCount,
      }}
      cardType={CardType.Station}
      cardName={station.name}
      isLocked={!isUserEntitled(platformContextObj.pretzelUser.jwt.tier, station.entitlements)}
      size={size}
    />
  );
};
