import * as React from "react";

function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-labelledby="play-icon"
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id="play-icon">Play</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4371 12.7894C16.6222 12.7894 16.8036 12.8408 16.9612 12.9378L27.0532 19.1482C27.5235 19.4376 27.6702 20.0536 27.3807 20.524C27.2987 20.6573 27.1865 20.7695 27.0532 20.8515L16.9612 27.0619C16.4909 27.3514 15.8749 27.2047 15.5855 26.7344C15.4885 26.5768 15.4371 26.3954 15.4371 26.2103V13.7894C15.4371 13.2371 15.8848 12.7894 16.4371 12.7894Z"
        fill="#EFEFEF"
      />
    </svg>
  );
}

export default SvgPlay;
