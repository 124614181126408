import React, { useState } from 'react';
import { TableMore, TableMoreActive } from '@pretzel-aux/assets/icons';
import InstrumentalIcon from '../../../../../../assets/icons/Instrumental';
import ExplicitIcon from '../../../../../../assets/icons/Explicit';
import { PopoverMenu, PopoverContainer, StyledButton, StyledIconContainer, LeftIconContainer, RightIconContainer } from './styled';
import { formatTime } from '../../../../Util';
import { Icon, theme } from '../../../../Styled';
import { BasicTrackFragment } from '../../../../../../common/src/Core/Player/Queries/types/BasicTrackFragment';
import { PretzelUser } from '../../../../../../common/src/Core/Platform';
import { Playlist } from '../Playlist';
import { ControlItem } from '@pretzel-aux/common/src/Core/Player/Components/PlayerControls/Components/ControlItem/ControlItem';
import { Download } from '../Download';

interface Props {
  track: BasicTrackFragment;
  handleDislikeTrackClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: BasicTrackFragment) => void;
  handleLikeTrackClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: BasicTrackFragment) => void;
  pretzelUser: PretzelUser;
}

export const MobileMoreInfo = ({ track, handleDislikeTrackClick, handleLikeTrackClick, pretzelUser }: Props) => {
  const [isPopoverVisible, togglePopoverVisibility] = useState(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    togglePopoverVisibility(state => !state);
  };

  return (
    <>
      <div className={isPopoverVisible ? 'mobile-more popover-visible' : 'mobile-more'}>
        {isPopoverVisible && (
          <PopoverMenu>
            <PopoverContainer>
              <LeftIconContainer>
                <StyledIconContainer className="mobile-track-duration">{formatTime(track.duration)}</StyledIconContainer>
                <StyledIconContainer
                  className={track.explicit ? ' explicit-icon explicit-icon-mobile' : 'explicit-icon explicit-icon-mobile not-explicit'}
                >
                  <ExplicitIcon labeltext={track.explicit ? 'Explicit' : 'Not Explicit'} />
                </StyledIconContainer>
                <StyledIconContainer
                  className={
                    track.instrumental ? 'instrumental-icon instrumental-icon-mobile' : 'instrumental-icon instrumental-icon-mobile not-instrumental'
                  }
                >
                  <InstrumentalIcon labeltext={track.instrumental ? 'Instrumental' : 'This track contains vocals'} />
                </StyledIconContainer>
              </LeftIconContainer>

              <RightIconContainer>
                {pretzelUser?.jwt.tier !== 'free' ? <Playlist showTooltip={false} track={track} /> : null}
                <ControlItem
                  isLoading={false}
                  isDisabled={false}
                  active={track.self.liked}
                  name="ThumbsUp"
                  onClick={event => handleLikeTrackClick(event, track)}
                />
                <ControlItem
                  isLoading={false}
                  isDisabled={false}
                  active={track.self.blacklisted}
                  name="ThumbsDown"
                  onClick={event => handleDislikeTrackClick(event, track)}
                />
                <Download track={track} />
              </RightIconContainer>
            </PopoverContainer>
          </PopoverMenu>
        )}
        <StyledButton arial-label="More track information" className="mobile-more-button" onClick={handleMenuClick}>
          {isPopoverVisible ? <TableMoreActive /> : <TableMore />}
        </StyledButton>
      </div>
    </>
  );
};
