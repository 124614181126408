import { theme } from '@pretzel-aux/common/src/Styled/theme';
import styled from 'styled-components/macro';

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  justify-content: space-between;
  @media (max-width: 470px), (max-height: 470px) {
    width: 100%;
    height: 100%;
  }
  @media (width: 304px), (height: 467px) {
    height: auto;
  }
`;

const CompactPlayerHiddenControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DesktopPlayerControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VolumeWrapper = styled.div`
  position: relative;
  padding-right: 10px;

  &:hover .rangeslider__handle {
    transform: translateX(-5px) translateY(-9px);
  }
`;

const LikeDislikeWrapper = styled.div`
  display: contents;

  svg {
    path:nth-of-type(1) {
      display: none;
    }
  }
  ${theme.mediaQueries.medium} {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    div:nth-of-type(1) {
      order: 2;
      top: -1rem;
    }
    div:nth-of-type(2) {
      top: 1.5rem;
    }
  }
`;

export { PlayerWrapper, VolumeWrapper, CompactPlayerHiddenControls, DesktopPlayerControls, LikeDislikeWrapper };
