import { theme } from '@pretzel-aux/common/src/Styled';
import React, { ReactNode, useRef, useState } from 'react';
import { Heading } from '../Heading';
import { AccordionContainer, AccordionContent, AccordionToggle, Divider } from './styled';

interface IChevronProps {
  open: boolean;
  fill?: string;
}

const Chevron = ({ open, fill = 'white' }: IChevronProps) => {
  return (
    <svg
      height="16px"
      width="16px"
      viewBox="0 0 266 438"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: 'transform 0.6s ease',
        transform: open ? 'rotate(270deg)' : 'rotate(90deg)',
        marginLeft: '1rem',
      }}
    >
      <path
        d="m258.476 235.971-194.344 194.343c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901l154.021-154.746-154.021-154.745c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0l194.343 194.343c9.373 9.372 9.373 24.568.001 33.941z"
        fill={fill}
      />
    </svg>
  );
};

interface IProps {
  title: string;
  content: ReactNode | string;
  showDivider?: boolean;
  openAccordion?: boolean;
}

const Accordion = ({ title, content, showDivider = true, openAccordion = false }: IProps) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setOpen(!open);
    if (contentRef && contentRef.current && contentRef.current['scrollHeight']) {
      setHeight(open ? '0px' : `${contentRef.current['scrollHeight']}px`);
    }
  };

  React.useEffect(() => {
    if (openAccordion) {
      toggleAccordion();
    }
  }, [openAccordion]);

  return (
    <AccordionContainer>
      <AccordionToggle onClick={() => toggleAccordion()}>
        <Heading variant="H3" style={{ margin: 0, fontWeight: 'bold', color: open ? theme.colors.brandGreen : 'inherit' }}>
          {title}
        </Heading>
        <Chevron open={open} fill={open ? theme.colors.brandGreen : 'white'} />
      </AccordionToggle>
      <AccordionContent ref={contentRef} style={{ maxHeight: `${height}` }}>
        {content}
      </AccordionContent>
      {showDivider && <Divider />}
    </AccordionContainer>
  );
};
export default Accordion;
