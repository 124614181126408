import React, { FC } from 'react';
import { StyledTooltip } from './styled';
import { TooltipProps, EPlacement } from './types';

/**
 * @author Andrew Cochrane
 * "styles" is added to the tooltip styles itself as there may be
 * a scenario wherein the tooltip needs shifted over or styling changed
 * over the default
 *
 * e.g. styles={`transform: translateY(-100%) translateX(-25%);`} will shift the tooltip
 * when the content is larger than just a few words
 */
export const Tooltip: FC<TooltipProps> = ({ children, text, show, color, fontColor, placement = EPlacement.TOP, styles }) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      {show && (
        <div
          style={{ display: 'inline-block', position: 'relative' }}
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(!showTooltip)}
        >
          {showTooltip && (
            <StyledTooltip text={text} placement={placement} color={color} fontColor={fontColor} show={show} styles={styles}>
              {text}
            </StyledTooltip>
          )}
          {children}
        </div>
      )}
      {!show && children}
    </React.Fragment>
  );
};
