import * as React from 'react';

const SvgCompactLabels = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.5314 28.2503C33.6255 28.349 33.7001 28.4662 33.751 28.5952C33.802 28.7241 33.8282 28.8624 33.8282 29.0021C33.8282 29.1417 33.802 29.28 33.751 29.409C33.7001 29.538 33.6255 29.6552 33.5314 29.7538L27.8805 35.6884C27.7866 35.7872 27.675 35.8656 27.5521 35.9191C27.4293 35.9725 27.2976 36.0001 27.1647 36.0001C27.0317 36.0001 26.9 35.9725 26.7772 35.9191C26.6544 35.8656 26.5428 35.7872 26.4488 35.6884L13.7153 22.3146C13.2871 21.8133 13.0347 21.1733 13 20.5008V15.1878C13 14.906 13.1066 14.6357 13.2963 14.4365C13.4861 14.2372 13.7435 14.1252 14.0118 14.1252H19.0708C19.7112 14.1617 20.3205 14.4268 20.7979 14.8765L33.5314 28.2503Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0354 12H22.2792C22.8157 11.9998 23.3303 12.2234 23.7099 12.6216L35.555 25.0624C35.6491 25.1611 35.7237 25.2783 35.7746 25.4073C35.8256 25.5363 35.8518 25.6746 35.8518 25.8142C35.8518 25.9539 35.8256 26.0921 35.7746 26.2211C35.7237 26.3501 35.6491 26.4673 35.555 26.566L35.2586 26.8773"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0354 18.9068C16.0354 19.1161 16.0747 19.3233 16.1509 19.5167C16.2272 19.7101 16.339 19.8858 16.4799 20.0338C16.6209 20.1818 16.7882 20.2992 16.9723 20.3793C17.1564 20.4594 17.3538 20.5006 17.5531 20.5006C17.7524 20.5006 17.9498 20.4594 18.1339 20.3793C18.318 20.2992 18.4853 20.1818 18.6263 20.0338C18.7672 19.8858 18.879 19.7101 18.9553 19.5167C19.0315 19.3233 19.0708 19.1161 19.0708 18.9068C19.0708 18.6974 19.0315 18.4902 18.9553 18.2968C18.879 18.1034 18.7672 17.9277 18.6263 17.7797C18.4853 17.6317 18.318 17.5143 18.1339 17.4342C17.9498 17.3541 17.7524 17.3129 17.5531 17.3129C17.3538 17.3129 17.1564 17.3541 16.9723 17.4342C16.7882 17.5143 16.6209 17.6317 16.4799 17.7797C16.339 17.9277 16.2272 18.1034 16.1509 18.2968C16.0747 18.4902 16.0354 18.6974 16.0354 18.9068V18.9068Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCompactLabels;
