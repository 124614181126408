import * as React from 'react';

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path
        fill={props.color}
        d="M17.216 16l7.712-7.744c.288-.256.288-.8 0-1.184-.256-.288-.8-.288-1.184 0L16 14.784 8.256 7.072c-.256-.288-.8-.288-1.184 0s-.256.928 0 1.184L14.816 16l-7.744 7.744c-.256.256-.256.8 0 1.184.256.256.8.256 1.184 0L16 17.184l7.744 7.744c.256.256.8.256 1.184 0s.288-.8 0-1.184L17.216 16z"
      />
    </svg>
  );
}

export default SvgClose;
