import React, { ReactElement } from 'react';
import { BannedWrapper, ExternalLink, LearnMore, SadIcon, SiteHeading } from './styles';

const Banned = (): ReactElement => {
  return (
    <BannedWrapper>
      {/* LoginTitleBar + macOS + electron */}
      <div>
        <SiteHeading>Oh no!</SiteHeading>
      </div>
      <div>
        <SadIcon />
      </div>
      <div>Looks like you have been banned!</div>
      <div>
        <LearnMore>
          To learn more, click{' '}
          <ExternalLink href="https://prtzl.to/OEsVe" target="_blank">
            here
          </ExternalLink>
        </LearnMore>
      </div>
    </BannedWrapper>
  );
};

export default Banned;
