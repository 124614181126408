import styled from 'styled-components';
import { theme } from '../../../../Styled';

export const StyledTrendingTrackCard = styled.div`
  align-items: center;
  background-color: ${theme.colors.bg};
  border-radius: ${theme.borders.radius};
  border: 1px solid ${theme.colors.translucentWhite};
  color: ${theme.colors.light1};
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  padding: 1.5rem;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
  margin-right: ${theme.space.md};
  width: 40px;
`;

export const StyledTrackDetailsContainer = styled.div`
  width: 100%;
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;

  strong {
    margin-bottom: 0.25rem;
  }

  .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    top: 0.5rem;
    right: 0.1rem;

    :focus-visible {
      outline: rgba(59, 153, 252, 0.7) auto 5px;
      outline-color: rgba(59, 153, 252, 0.7);
      outline-style: auto;
      outline-width: 0.313rem;
    }
  }

  .download-disabled {
    cursor: not-allowed;

    svg {
      path {
        stroke: ${theme.colors.dark5};
      }
    }
  }
`;

export const StyledArtists = styled.div`
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${theme.mediaQueries.large} {
    max-width: 300px;
  }
`;
