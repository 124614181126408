import * as React from "react";

function SvgMoney(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M11.424 27.264c-.256 0-.544-.064-.736-.288a.966.966 0 010-1.376L25.6 10.688a.915.915 0 011.376 0c.416.416.416.992 0 1.408l-14.88 14.88c-.192.224-.416.288-.672.288zm2.752 1.792L28.992 14.24c.416-.416.416-1.024 0-1.408-.384-.416-.992-.416-1.408 0L12.768 27.648c-.416.416-.416 1.024 0 1.408.192.192.448.288.736.288s.448-.096.672-.288zm2.048 2.08l14.848-14.848a.966.966 0 000-1.376c-.416-.416-.992-.416-1.408 0L14.848 29.664c-.416.416-.416.992 0 1.408.192.192.448.256.704.256s.48 0 .672-.192zm-.512-14.976c1.472-1.472 1.312-4.064-.352-5.728-1.664-1.696-4.256-1.824-5.728-.352s-1.344 4.096.32 5.76c.928.928 2.08 1.312 3.2 1.312a3.02 3.02 0 002.56-.992zm-1.76-4.352c.896.896 1.088 2.208.352 2.944s-2.016.608-2.944-.32c-.864-.864-1.056-2.208-.32-2.944.32-.32.704-.48 1.12-.48.608 0 1.28.288 1.792.8zm10.976-1.792L10.016 24.928c-.192.192-.448.256-.736.256s-.512-.064-.736-.256l-7.68-7.776a.966.966 0 010-1.376L15.712.864a.966.966 0 011.376 0l3.104 3.072 4.672 4.672c.448.416.448 1.088.064 1.408zM14.912 4.544c.928.48 2.112.48 3.072 0l-1.536-1.536-1.536 1.536zM3.008 16.448l1.536 1.536c.48-.96.48-2.144 0-3.072l-1.536 1.536zm7.872 4.8c-.928-.48-2.112-.48-3.072 0l1.536 1.536 1.536-1.536zm8.896-14.912l-.32-.32c-.96.608-1.952.928-3.008.928-1.088 0-2.08-.32-3.008-.928L6.016 13.44a5.364 5.364 0 010 5.984l.32.352c1.824-1.216 4.224-1.216 6.016 0l7.424-7.424c-1.216-1.792-1.216-4.192 0-6.016zm3.008 3.008l-1.536-1.536c-.48.928-.48 2.144 0 3.072l1.536-1.536z" />
    </svg>
  );
}

export default SvgMoney;
