import styled from 'styled-components';
import { theme } from '../../Styled';

export const StatisticsContainer = styled.section`
  border-radius: ${theme.borders.radius};
  margin: 4rem auto ${theme.space.lg} auto;
`;

export const StatisticsContent = styled.div`
  align-items: center;
  background-color: ${theme.colors.bg};
  border-radius: ${theme.borders.radius};
  color: ${theme.colors.baseWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1;
  padding: ${theme.space.lg};
  text-align: center;
  margin: ${theme.space.lg} 0;

  ${theme.mediaQueries.medium} {
    flex-direction: row;
    text-align: left;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: ${theme.space.md};
  }
`;

export const StatisticsGrid = styled.div`
  align-items: center;
  display: grid;
  grid-gap: ${theme.space.lg};
  grid-template-columns: 1fr;
  margin-top: ${theme.space.lg};
  margin-left: ${theme.space.lg};

  ${theme.mediaQueries.medium} {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0;
  }

  ${theme.mediaQueries.extraLarge} {
    grid-auto-flow: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;

    ${theme.mediaQueries.extraLarge} {
      &:not(:last-child):before {
        background-color: ${theme.colors.translucentWhite};
        content: '';
        height: 100%;
        position: absolute;
        right: -1rem;
        top: 0;
        width: 2px;
      }
    }

    > strong {
      font-size: 2.5rem;
      margin-bottom: ${theme.space.md};
    }
  }
`;
