import styled from 'styled-components/macro';
import { theme } from '../../../Styled';

export const LeftNavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; //Firefox

  ul {
    list-style-type: none;
    margin-top: ${theme.space.md};
    padding-left: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.bgSections};
  }
`;

export const FlexEndContainer = styled.ul`
  margin-top: auto !important;
`;
