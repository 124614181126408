import React from 'react';
import { SegmentType, PublicProps as TrackListProps } from '../types';
import { ViewportType } from '../../../../../common/src/Util/responsive';

export const generateColumns = (
  type: SegmentType,
  shareUrl: string,
  removeTrackFromPlaylist: TrackListProps['removeTrackFromPlaylist'],
  viewport: ViewportType
) => {
  // It isn't ideal having such long conditionals for config but the alternate is lots of nested ternaries.
  // We have three different configs we return based on the viewport type.
  if (viewport === ViewportType.Tablet) {
    return React.useMemo(
      () => [
        {
          Header: ' ',
          columns: [
            {
              Header: ' ',
              accessor: 'playButton',
              width: 25,
              maxWidth: 25,
            },
            {
              Header: '',
              accessor: 'trackDetails',
              minWidth: 160,
              maxWidth: 400,
            },
            {
              Header: ' ',
              accessor: 'explicit',
              width: 20,
              maxWidth: 48,
            },
            {
              Header: ' ',
              accessor: 'instrumental',
              width: 48,
              maxWidth: 48,
            },
            {
              Header: '',
              accessor: 'trackLength',
              minWidth: 45,
              width: 45,
              maxWidth: 45,
            },
            {
              ...(type === 'Playlist'
                ? {
                    Header: ` `,
                    accessor: removeTrackFromPlaylist ? 'removeTrack' : '',
                    minWidth: 35,
                    width: 35,
                    maxWidth: 35,
                  }
                : {
                    Header: ' ',
                    accessor: 'playlist',
                    minWidth: 35,
                    width: 35,
                    maxWidth: 35,
                  }),
            },
            {
              Header: ' ',
              accessor: 'thumbsUp',
              minWidth: 35,
              width: 35,
              maxWidth: 35,
            },
            {
              Header: ' ',
              accessor: 'thumbsDown',
              minWidth: 35,
              width: 35,
              maxWidth: 35,
            },
            {
              Header: ' ',
              accessor: 'download',
              minWidth: 35,
              width: 35,
              maxWidth: 35,
            },
            {
              Header: ' ',
              accessor: shareUrl ? 'copy' : 'no-copy',
              minWidth: shareUrl ? 35 : 0,
              width: shareUrl ? 35 : 0,
              maxWidth: shareUrl ? 35 : 0,
            },
          ],
        },
      ],
      [removeTrackFromPlaylist, viewport]
    );
  }

  if (viewport === ViewportType.Mobile) {
    return React.useMemo(
      () => [
        {
          Header: ' ',
          columns: [
            {
              Header: ' ',
              accessor: 'playButton',
              width: 25,
              maxWidth: 25,
            },
            {
              Header: '',
              accessor: 'trackDetails',
              minWidth: 130,
              maxWidth: 400,
            },
            {
              Header: '',
              accessor: 'mobileMore',
              minWidth: 18,
              maxWidth: 50,
            },
          ],
        },
      ],
      [removeTrackFromPlaylist, viewport]
    );
  }

  // Default to Desktop config for tables
  return React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: ' ',
            accessor: 'playButton',
            width: 25,
            maxWidth: 25,
          },
          {
            Header: 'Track Name',
            accessor: 'trackName',
            minWidth: 160,
            maxWidth: 400,
          },
          {
            Header: ' ',
            accessor: 'explicit',
            width: 20,
            maxWidth: 48,
          },
          {
            Header: ' ',
            accessor: 'instrumental',
            width: 48,
            maxWidth: 48,
          },
          {
            /* Custom table columns for artist and playlist page */
            ...(type === 'Artist'
              ? {
                  Header: `Album Name`,
                  accessor: 'albumName',
                  minWidth: 200,
                  maxWidth: 400,
                }
              : {
                  Header: `Artist Name`,
                  accessor: 'artistName',
                  minWidth: 200,
                  maxWidth: 400,
                }),
          },
          {
            Header: 'Time',
            accessor: 'trackLength',
            minWidth: 45,
            width: 45,
            maxWidth: 45,
          },
          {
            ...(type === 'Playlist'
              ? {
                  Header: ` `,
                  accessor: removeTrackFromPlaylist ? 'removeTrack' : '',
                  minWidth: 35,
                  width: 35,
                  maxWidth: 35,
                }
              : {
                  Header: ' ',
                  accessor: 'playlist',
                  minWidth: 35,
                  width: 35,
                  maxWidth: 35,
                }),
          },
          {
            Header: ' ',
            accessor: 'thumbsUp',
            minWidth: 35,
            width: 35,
            maxWidth: 35,
          },
          {
            Header: ' ',
            accessor: 'thumbsDown',
            minWidth: 35,
            width: 35,
            maxWidth: 35,
          },
          {
            Header: ' ',
            accessor: 'download',
            minWidth: 35,
            width: 35,
            maxWidth: 35,
          },
          {
            Header: ' ',
            accessor: shareUrl ? 'copy' : 'no-copy',
            minWidth: shareUrl ? 35 : 0,
            width: shareUrl ? 35 : 0,
            maxWidth: shareUrl ? 35 : 0,
          },
        ],
      },
    ],
    [removeTrackFromPlaylist, viewport]
  );
};
