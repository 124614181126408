import * as React from "react";

function SvgSpotify(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 27 32" {...props}>
      <path d="M20.128 21.6q0-.576-.544-.896-3.424-2.048-7.968-2.048-2.368 0-5.12.608-.768.16-.768.928 0 .352.256.608t.64.256q.064 0 .64-.16 2.368-.48 4.352-.48 4.032 0 7.072 1.856.352.192.608.192.32 0 .576-.256t.256-.608zm1.728-3.84q0-.704-.64-1.088-4.224-2.528-9.792-2.528-2.72 0-5.408.768-.864.224-.864 1.152 0 .448.32.736t.768.32q.128 0 .64-.128 2.208-.608 4.512-.608 4.96 0 8.704 2.208.416.256.672.256.448 0 .768-.32t.32-.768zm1.92-4.416q0-.832-.736-1.248-2.24-1.312-5.216-1.984t-6.112-.672q-3.648 0-6.528.832-.384.128-.672.48t-.288.864q0 .544.384.928t.896.352q.224 0 .736-.128 2.368-.672 5.472-.672 2.848 0 5.536.608T21.76 14.4q.384.224.736.224.512 0 .896-.352t.384-.928zM27.424 16q0 3.744-1.824 6.88t-4.992 4.992-6.88 1.856-6.912-1.856-4.96-4.992T0 16t1.856-6.88 4.96-4.992 6.912-1.856 6.88 1.856T25.6 9.12 27.424 16z" />
    </svg>
  );
}

export default SvgSpotify;
