import styled, { keyframes } from 'styled-components';
import SwirlImage from '@pretzel-aux/assets/images/swirl.png';
import { theme } from '../../Styled';

/**
 * Used for when the Modal fades in / out
 */
const ModalAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-30%);
  }

  to {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const StyledModalContainer = styled.div`
  background-color: ${theme.colors.backgroundDarkBlur};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${theme.zIndex.modal};

  h2 {
    text-align: center;
  }
`;

export const StyledModal = styled.div<{ variant?: string }>`
  animation: ${ModalAnimation} 0.85s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: ${theme.colors.bg};
  border-radius: ${theme.borders.radius};
  border-top: 8px solid ${({ variant }) => theme.colors[variant]};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
  color: ${theme.colors.light1};
  font-size: 1rem;
  line-height: 1.5rem;
  left: 50%;
  max-width: 550px;
  padding: ${theme.space.lg};
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;

  :before {
    background-image: url(${SwirlImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.75;
    pointer-events: none;
    position: absolute;
    width: 100%;
  }
`;

export const StyledModalTitle = styled.h2<{ variant?: string }>`
  ${({ variant }) =>
    variant === 'primary'
      ? `
    color: ${theme.colors.primary} !important;
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.75rem;
    margin-bottom: 0;
  `
      : `
    font-family: Sora;
    font-size: 2.5rem;
    line-height: 3.125rem;
    text-align: center;
    color: ${theme.colors.light1};
    max-width: 365px;
  `}
`;

export const StyledModalSubTitle = styled.h4`
  color: ${theme.colors.light1};
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0 0 ${theme.space.lg};
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.space.md};
  margin-top: ${theme.space.lg};
`;

export const StyledModalCloseIcon = styled.div`
  align-items: center;
  background-color: ${theme.colors.light1};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: -24px;
  top: -32px;
  width: 48px;

  &::before,
  &::after {
    background-color: #000;
    content: '';
    height: 24px;
    position: absolute;
    left: 23px;
    top: 13px;
    width: 3px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
