import { loader } from 'graphql.macro';
import { AnalyticsEventType } from '../../Analytics';
import { toast } from 'react-toastify';
import { generateToastConfig } from '../../../Styled/ToastContainer';
import { PlayTokenFragment } from '../Queries/types/PlayTokenFragment';

export const likeTrackMutation = loader('../Queries/set-track-liked.graphql');
export const blacklistTrackMutation = loader('../Queries/set-track-blacklisted.graphql');

const mouseWheelListenerAttached: Array<any> = [];

export const handleLike = (
  event: React.MouseEvent<HTMLButtonElement>,
  playToken: PlayTokenFragment | null,
  setState: (state: any) => void,
  state: any,
  likeTrack: (track: any) => Promise<any>,
  sendAnalyticsEvent: (type: AnalyticsEventType, playToken?: PlayTokenFragment | null, source?: string) => void
) => {
  // @ts-ignore
  event.target.parentElement.blur();

  if (playToken) {
    setState({ ...state, loadingLike: true });
    likeTrack({
      variables: {
        trackId: playToken.track.id,
        value: !playToken.track.self.liked,
      },
    })
      .then(() => {
        if (playToken.track.self.liked) {
          // If track is already liked, we are removing the like
          toast('Like removed from track', generateToastConfig('ThumbsUp'));
        } else {
          toast('Liked track', generateToastConfig('ThumbsUp'));
        }
        setState({ ...state, loadingLike: false });
      })
      .catch(() => setState({ ...state, loadingLike: false }));
    sendAnalyticsEvent(AnalyticsEventType.Like, playToken, 'playerControls');
  }
};

export const handleDislike = (
  event: React.MouseEvent<HTMLButtonElement>,
  playToken: PlayTokenFragment | null,
  setState: (state: any) => void,
  state: any,
  dislikeTrack: (track: any) => Promise<any>,
  sendAnalyticsEvent: (type: AnalyticsEventType, playToken?: PlayTokenFragment | null, source?: string) => void,
  next: () => void
) => {
  // @ts-ignore
  event.target.parentElement.blur();
  if (playToken) {
    setState({ ...state, loadingDislike: true });
    dislikeTrack({
      variables: {
        trackId: playToken.track.id,
        value: !playToken.track.self.blacklisted,
      },
    })
      .then(() => {
        setState({ ...state, loadingDislike: false });

        if (playToken.track.self.blacklisted) {
          toast('Dislike removed from track', generateToastConfig('ThumbsDown'));
        } else {
          toast('Track disliked', generateToastConfig('ThumbsDown'));
        }

        next();
      })
      .catch(() => setState({ ...state, loadingDislike: false }));
    sendAnalyticsEvent(AnalyticsEventType.Dislike, playToken, 'playerControls');
  }
};

export const handleBlacklist = (
  event: React.MouseEvent<HTMLButtonElement>,
  playToken: PlayTokenFragment | null,
  setState: (state: any) => void,
  state: any,
  blacklistTrack: (track: any) => Promise<any>,
  sendAnalyticsEvent: (type: AnalyticsEventType, playToken?: PlayTokenFragment | null, source?: string) => void,
  next: () => void
) => {
  // @ts-ignore
  event.target.parentElement.blur();

  if (playToken) {
    setState({ ...state, loadingBlacklist: true });
    blacklistTrack({
      variables: {
        trackId: playToken.track.id,
        value: !playToken.track.self.blacklisted,
      },
    })
      .then(() => {
        setState({ ...state, loadingBlacklist: false });
        next();
      })
      .catch(() => setState({ ...state, loadingBlacklist: false }));
    sendAnalyticsEvent(AnalyticsEventType.Dislike, playToken, 'playerControls');
  }
};

export const handleTogglePause = (event: React.MouseEvent<HTMLElement>, togglePause: () => void) => {
  // @ts-ignore
  event.target.parentElement.blur();
  togglePause();
};

export const handleNext = (event: React.MouseEvent<HTMLElement>, next: () => void) => {
  // @ts-ignore
  event.target.parentElement.blur();
  next();
};

export const handlePrevious = (event: React.MouseEvent<HTMLElement>, previous: () => void) => {
  // @ts-ignore
  event.target.parentElement.blur();
  previous();
};

export const handleMute = (event: React.MouseEvent<HTMLElement>, toggleMute: () => void) => {
  // @ts-ignore
  event.target.parentElement.blur();
  toggleMute();
};

export const handleScroll = (event: WheelEvent, increaseVolume: () => void, decreaseVolume: () => void) => {
  const direction = event.deltaY;
  if (direction) {
    if (direction < 0) {
      increaseVolume();
    } else if (direction > 0) {
      decreaseVolume();
    }
  }
};

export const handleVolumeHoverable = (
  e: React.MouseEvent<HTMLElement>,
  setState: (state: any) => void,
  state: any,
  increaseVolume: () => void,
  decreaseVolume: () => void
) => {
  if (state.volumeBarVisible) {
    removeWheelEvents();
  } else {
    if (mouseWheelListenerAttached.length === 0) {
      const arg = {
        listener: (f: any) => handleScroll(f, increaseVolume, decreaseVolume),
        options: { passive: true },
      };
      document.addEventListener('wheel', arg.listener, arg.options);
      mouseWheelListenerAttached.push(arg);
    }
  }
  setState({ ...state, volumeBarVisible: !state.volumeBarVisible });
};

export const removeWheelEvents = () => {
  mouseWheelListenerAttached.forEach(l => document.removeEventListener('wheel', l.listener, l.options));
  mouseWheelListenerAttached.shift();
};

export const handleAddToPlaylistClick = (setState: (state: any) => void, state: any, show?: boolean) => {
  setState({ ...state, showAddToPlaylist: show ? show : !state.showAddToPlaylist });
};

export const handlePlaylistUnhover = (premium: boolean, setState: (state: any) => void, state: any) => {
  if (premium) {
    setState({ ...state, showAddToPlaylist: false });
  }
};
