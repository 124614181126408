import * as React from "react";

function SvgBandcamp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M19.104 21.024l5.472-10.048h-11.68L7.424 21.024h11.68zM32 16q0 3.264-1.28 6.208t-3.392 5.12-5.12 3.392T16 32t-6.208-1.28-5.12-3.392-3.392-5.12T0 16t1.28-6.208 3.392-5.12 5.12-3.392T16 0t6.208 1.28 5.12 3.392 3.392 5.12T32 16z" />
    </svg>
  );
}

export default SvgBandcamp;
