import * as React from 'react';
import { appBreakpoints } from '../Styled/theme';
import MediaQuery, { useMediaQuery } from 'react-responsive';

interface Props {
  children: React.ReactNode;
}

const compactBreakHeight = 600;
const compactBreakWidth = 768;

export const isCompact = () =>
  useMediaQuery({
    query: `(max-width: ${compactBreakWidth}px), (max-height: ${compactBreakHeight}px)`,
  });

export function CompactOnly(props: Props) {
  return <MediaQuery query={`(max-width: ${compactBreakWidth}px), (max-height: ${compactBreakHeight}px)`}>{props.children}</MediaQuery>;
}

export function LibraryOnly(props: Props) {
  return <MediaQuery query={`(min-width: ${compactBreakWidth + 1}px) and (min-height: ${compactBreakHeight + 1}px)`}>{props.children}</MediaQuery>;
}

export enum ViewportType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export function returnViewportType(width: number) {
  if (width <= appBreakpoints.small) {
    return ViewportType.Mobile;
  }

  if (width <= appBreakpoints.medium) {
    return ViewportType.Tablet;
  }

  return ViewportType.Desktop;
}
