import * as React from 'react';
import { theme } from '@pretzel-aux/common/src/Styled';

function SkipForward(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg
      onMouseEnter={() => setFill(theme.colors.brandGreen)}
      onMouseLeave={() => setFill(theme.colors.offWhite)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="no-hover-effect"
    >
      <path d="M35.5 20.992L32.5 25.492L29 21.492" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M23 33.5C17.7533 33.5 13.5 29.2467 13.5 24C13.5 18.7533 17.7533 14.5 23 14.5C28.2467 14.5 32.5 18.7533 32.5 24V25.494"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7092 27H21.3732V21.664H20.7892L19.4532 23.04L19.8532 23.448L20.7092 22.544V27ZM22.5241 26.24C22.9241 26.768 23.5001 27.096 24.4041 27.096C25.4761 27.096 26.3001 26.424 26.3001 25.336C26.3001 24.24 25.4921 23.632 24.6041 23.632C24.0681 23.632 23.6121 23.856 23.3561 24.112V22.256H25.9561V21.664H22.6921V24.544L23.1801 24.712C23.5401 24.36 23.9401 24.208 24.4121 24.208C25.1321 24.208 25.6361 24.656 25.6361 25.36C25.6361 26.008 25.1321 26.504 24.4041 26.504C23.7481 26.504 23.2841 26.248 22.9321 25.8L22.5241 26.24Z"
        fill={fill}
      />
    </svg>
  );
}

export default SkipForward;
