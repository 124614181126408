import * as React from "react";

function SvgSoundcloud(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M1.088 21.504q0 .128-.32.128-.192 0-.32-.128L0 19.264l.448-2.304q.128-.128.32-.128.32 0 .32.128l.512 2.304zm3.264 1.216q0 .256-.384.256t-.384-.256L3.2 19.264l.384-5.312q0-.256.384-.256t.384.256l.448 5.312zm3.264-.064q0 .32-.448.32t-.448-.256l-.32-3.456.32-7.104q0-.32.448-.32t.448.32L8 19.264zm3.264 0q0 .32-.512.32t-.512-.32L9.6 19.264l.256-7.168q0-.32.512-.32t.512.32l.32 7.168zm3.264-.064q0 .384-.576.384-.512 0-.576-.384l-.192-3.328.192-8.192q.128-.448.576-.448.576 0 .576.448l.256 8.192zm2.304.384q-.448 0-.448-.448V9.856q0-.32.384-.448 1.152-.448 2.688-.448 2.816 0 4.896 1.856t2.336 4.544q.768-.32 1.6-.32 1.664 0 2.88 1.184T32 19.008q0 1.664-1.216 2.848t-2.88 1.184z" />
    </svg>
  );
}

export default SvgSoundcloud;
