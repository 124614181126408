export enum ValueType {
  number = 'number',
  dropdown = 'dropdown',
  audio = 'audio',
}

export type Option = {
  label: string;
  value: string;
  note?: string;
};

export interface KeybindConfiguration {
  selectedAction?: string;
  value?: string | number;
  accelerator?: string;
  error: boolean;
}

export type KeyAction = {
  label: string;
  value: string;
  isValue: boolean;
  valueType?: ValueType;
  valueTitle?: string;
  minValue?: number;
  maxValue?: number;
  pattern?: string;
  defaultValue?: string | number;
  valueUnit?: string;
  valueUnitPlural?: string;
  valueModifier?: number;
  dropdown?: Option[];
};

export const keybindOptions: KeyAction[] = [
  {
    label: 'Play/pause track',
    value: 'togglePause',
    isValue: false,
  },
  {
    label: 'Pause after current',
    value: 'pauseAfterCurrent',
    isValue: false,
  },
  {
    label: 'Next track',
    value: 'next',
    isValue: false,
  },
  {
    label: 'Restart track',
    value: 'prev',
    isValue: false,
  },
  {
    label: 'Like track',
    value: 'like',
    isValue: false,
  },
  {
    label: 'Blacklist track',
    value: 'blacklist',
    isValue: false,
  },
  {
    label: 'Toggle mute',
    value: 'toggleMute',
    isValue: false,
  },
  {
    label: 'Increase volume',
    value: 'increaseVolume',
    isValue: false,
  },
  {
    label: 'Decrease volume',
    value: 'decreaseVolume',
    isValue: false,
  },
  {
    label: 'Set volume',
    value: 'setVolume',
    isValue: true,
    valueType: ValueType.number,
    valueTitle: 'Volume',
    minValue: 0,
    maxValue: 100,
    pattern: '[0-9]{1,3}',
    defaultValue: 0.5,
    valueUnit: '%',
    valueUnitPlural: '%',
    valueModifier: 100,
  },
  {
    label: 'Toggle setting',
    value: 'toggleSetting',
    isValue: true,
    valueType: ValueType.dropdown,
    defaultValue: 0,
    dropdown: [
      {
        label: 'Allow Mature',
        value: 'allowExplicit',
      },
      {
        label: 'Instrumental only',
        value: 'instrumentalOnly',
      },
      {
        label: 'YouTube Safe',
        value: 'youtubeSafe',
      },
      {
        label: 'Announce in chat',
        value: 'notifyInChat',
        note: `Disabling what's playing chat announcements requires Pretzel Premium.`,
      },
    ],
  },
  // TODO We need to implement this in Hotkeys.tsx if we want to use it
  // {
  //   label: 'Toggle Statusbar',
  //   value: 'toggleStatusBar',
  //   isValue: false,
  // },
  {
    label: 'Set output',
    value: 'setOutput',
    isValue: true,
    valueType: ValueType.audio,
    defaultValue: 'default',
  },
];

export function formatForDisplay(action: KeyAction, value: number) {
  if (!action || !action.isValue) {
    return undefined;
  }
  if (action.valueType === ValueType.number && action.valueModifier) {
    // @ts-ignore
    return value * action.valueModifier;
  }
  return value;
}

export function formatForStore(action: KeyAction, value: number) {
  if (!action || !action.isValue) {
    return undefined;
  }
  if (!action || !action.isValue) {
    return undefined;
  }
  if (action.valueType === ValueType.number && action.valueModifier) {
    // @ts-ignore
    return value / action.valueModifier;
  }
  return value;
}
