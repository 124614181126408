import * as React from "react";

function SvgMobileListen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M13.696 24.192c-.704 0-1.152-.448-1.152-1.12 0-.352.128-.576.352-.8l6.208-6.208-6.208-6.24c-.448-.448-.576-1.12-.128-1.568s1.152-.576 1.6-.128l.096.128 7.008 7.008c.48.448.48 1.12 0 1.568l-7.008 7.04c-.224.224-.544.32-.768.32zm2.368 7.008C7.68 31.2.8 24.416.8 15.936S7.584.672 16.064.672s15.264 6.784 15.264 15.264C31.2 24.416 24.416 31.2 16.064 31.2zm0-28.16C8.832 3.04 3.04 8.832 3.04 16.064s5.792 12.992 13.024 12.992c7.104 0 12.992-5.76 12.992-12.896-.096-7.328-5.888-13.12-12.992-13.12z" />
    </svg>
  );
}

export default SvgMobileListen;
