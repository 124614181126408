import { useMutation } from '@apollo/client';
import cancelPremium from '@pretzel-aux/common/src/Api/cancel-premium';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';
import { StyledHeading } from '@pretzel-aux/common/src/Components/Carousel/styled';
import platformContext, { PlatformContext, URLS } from '@pretzel-aux/common/src/Core/Platform';
import { loader } from 'graphql.macro';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { PlanHeading, PlanWrapper, StyledSubscriptionContent, PlanHeadingAdditionalStyles } from './Styles';
import { Modal } from '../../../Modal';
import { RefreshAccount } from '../../../../Core/Settings/Queries/types/RefreshAccount';
import { Crown, Hand } from '../../../../../../assets/icons';

const RefreshAccountQuery = loader('../../../../Core/Settings/Queries/refresh-jwt.graphql');

type MessageProps = {
  amount: string;
  hasData: boolean;
  nextDate: string;
  premium: boolean;
  provider: string;
  status: string;
  toDate: string;
};

const parseAmount = (amount: number): string => {
  return `\$${(amount / 100).toFixed(2)}`;
};

const parseDate = (date: string): string => {
  return moment(date).format('L');
};

const Message = ({ amount, hasData, nextDate, premium, provider, status, toDate }: MessageProps): JSX.Element => {
  let subText = <></>;

  if (hasData) {
    if (status === 'canceled') {
      subText = (
        <div>
          Thanks for your support! Your subscription is valid until <strong>{toDate}</strong> and will not automatically renew.
        </div>
      );
    } else {
      if (provider === 'streamlabs') {
        subText = (
          <div>
            Thanks for your support! Your subscription will automatically renew on <strong>{nextDate}</strong>
          </div>
        );
      } else if (provider === 'humble') {
        subText = (
          <div>
            Thanks for your support through Humble Bundle!
            <br />
            <br />
            Your subscription is valid until <strong>{toDate}</strong> and will not automatically renew.
          </div>
        );
      } else {
        if (amount) {
          subText = (
            <div>
              Thanks for your support! Your subscription will automatically renew on <strong>{nextDate}</strong> and you'll be charged{' '}
              <strong>{amount}</strong>
            </div>
          );
        } else {
          subText = (
            <div>
              Thanks for your support! Your subscription will automatically renew on <strong>{nextDate}</strong>
            </div>
          );
        }
      }
    }
  } else if (premium) {
    subText = (
      <div>
        Thanks for your support! Your subscription will automatically renew on <strong>NEVER</strong> and you'll be charged{' '}
        <strong>Space Magic</strong>
      </div>
    );
  }

  return subText;
};

const Subscriptions = (): JSX.Element => {
  // this will need updated to check the user tier for pro when that goes live
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const [refreshAccount] = useMutation<RefreshAccount, {}>(RefreshAccountQuery);
  const isPremium = platformContextObj.pretzelUser?.jwt.tier !== 'free';
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  const cancelPremiumPlan = async () => {
    if (!(await cancelPremium('premium'))) {
      toast('There was an error cancelling your subscription.');
      return;
    }

    setDisplayModal(false);

    const result = await refreshAccount();
    const newToken = result?.data?.refreshAccount?.token || '';

    platformContextObj.saveData('pretzel_app_token', newToken);
    platformContextObj.unloadApp();
  };

  return (
    <>
      <StyledSubscriptionContent>
        {isPremium ? (
          <PlanWrapper>
            <PlanHeading>
              <Crown />
              <StyledHeading variant="H2" style={PlanHeadingAdditionalStyles}>
                Pretzel Premium
              </StyledHeading>
            </PlanHeading>
            <Message
              amount={
                platformContextObj.pretzelUserDetails?.subscription && platformContextObj.pretzelUserDetails?.subscription.next_billing_period_amount
                  ? parseAmount(platformContextObj.pretzelUserDetails?.subscription.next_billing_period_amount)
                  : ''
              }
              hasData={!!platformContextObj.pretzelUserDetails?.subscription}
              nextDate={parseDate(platformContextObj.pretzelUserDetails?.subscription?.next_billing_date)}
              premium={isPremium}
              provider={platformContextObj.pretzelUserDetails?.subscription?.provider}
              status={platformContextObj.pretzelUserDetails?.subscription?.state}
              toDate={parseDate(platformContextObj.pretzelUserDetails?.subscription?.paid_through_date)}
            />
            <StyledButton color="outline" style={{ marginTop: '1.5rem', width: '85%' }} onClick={() => window.open(`${URLS.SETTINGS}/subscription`)}>
              Manage Account
            </StyledButton>
          </PlanWrapper>
        ) : (
          <PlanWrapper>
            <PlanHeading>
              <Hand />
              <StyledHeading variant="H2" style={PlanHeadingAdditionalStyles}>
                Pretzel Free
              </StyledHeading>
            </PlanHeading>
            <p>Listen on web or desktop in standard quality with mandatory chat attribution</p>
          </PlanWrapper>
        )}
      </StyledSubscriptionContent>
      <Modal
        buttons={[
          { color: 'outline', label: 'Cancel', onClick: () => setDisplayModal(false) },
          { color: 'primary', label: 'Confirm', onClick: async () => cancelPremiumPlan() },
        ]}
        content={<p>Are you sure you wish to cancel your Pretzel Premium subscription?</p>}
        heapid={'cancellation-modal'}
        isOpen={displayModal}
        testid={'cancellation-modal'}
        setIsOpen={setDisplayModal}
        title={'Whoa there!'}
        variant="primary"
      />
    </>
  );
};

export default Subscriptions;
