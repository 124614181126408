import * as React from "react";

function SvgYoutubeSafe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M27.84 16.416V14.08c0-2.016-.224-3.936-.224-4.064 0-.192-.32-1.92-1.152-2.752-1.088-1.088-2.144-1.184-2.688-1.184h-.096c-3.424-.224-8.416-.224-8.544-.224 0 0-5.12 0-8.512.224H6.4c-.544 0-1.696.096-2.656 1.184-.864.832-1.088 2.56-1.184 2.752 0 .128-.224 2.048-.224 4.064V16c0 2.016.224 3.936.224 4.064 0 .192.32 1.92 1.184 2.752.96.96 2.112 1.088 2.752 1.184h.32c1.92.224 8 .224 8.32.224h3.84c.96 2.432 3.2 4.032 5.888 4.032 3.52 0 6.272-2.88 6.272-6.272-.096-2.368-1.376-4.48-3.296-5.568zM6.944 22.72h-.32c-.544-.096-1.408-.224-1.92-.736-.448-.448-.768-1.504-.768-2.048 0 0-.192-1.92-.192-3.84v-1.792c0-1.92.192-3.84.192-3.84.128-.544.32-1.6.768-2.048.64-.832 1.28-.96 1.792-.96h.128c3.296-.192 8.416-.192 8.416-.192s5.12 0 8.416.192h.128c.416 0 1.152.128 1.92.864.416.416.736 1.504.736 2.016 0 0 .224 1.92.224 3.84V16c-.544-.224-1.184-.224-1.824-.224-3.52 0-6.304 2.88-6.304 6.304 0 .32 0 .64.128.864H15.04s-6.304-.128-8.096-.224zm17.792 4.256c-2.24 0-4.256-1.472-4.8-3.616-.096-.416-.192-.864-.192-1.28 0-2.784 2.24-5.024 4.992-5.024s5.024 2.24 5.024 5.024-2.24 4.896-5.024 4.896zm-.64-3.712l-1.6-2.144-1.056.864 2.784 3.52 3.84-5.568-1.088-.736-2.88 4.064zM11.84 10.24v8.96l8.64-4.48-8.64-4.48zm1.376 2.24l4.48 2.336-4.48 2.24V12.48z" />
    </svg>
  );
}

export default SvgYoutubeSafe;
