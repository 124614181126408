import { theme } from '../../Styled';
import styled from 'styled-components';

export const StyledToggleLabel = styled.label`
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  height: 14px;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 28px;

  &::before {
    background-color: ${theme.colors.light1};
    border: 1px solid transparent;
    border-radius: 50%;
    content: '';
    height: 10px;
    position: absolute;
    left: 1px;
    top: 1px;
    width: 10px;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  + label {
    cursor: pointer;
  }
`;

export const StyledToggleContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  margin-right: ${theme.space.md};
  position: relative;
  text-align: center;
  width: 80px;

  &:hover ${StyledToggleLabel} {
    &::before {
      transform: translateX(calc(50% + 2px));
    }
  }
  &.first {
    margin-left: ${theme.space.md};
  }
`;

export const StyledToggle = styled.input`
  cursor: pointer;
  height: 14px;
  margin-right: 10px;
  opacity: 0;
  pointer-events: none;
  width: 28px;
  z-index: 1;

  &:checked + ${StyledToggleLabel} {
    background: ${theme.colors.primary};

    &::before {
      background-color: ${theme.colors.light1};
      border-color: ${theme.colors.light1};
      transform: translateX(calc(100% + 3px));
    }

    + label {
      color: ${theme.colors.primary};
    }
  }
`;
