import * as React from 'react';

function AddToPlaylist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 14.5H30.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 19.5H30.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 24.5H22.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5 29.5H22.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 14.5H13.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 19.5H13.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 24.5H13.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 29.5H13.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 35.5C33.2614 35.5 35.5 33.2614 35.5 30.5C35.5 27.7386 33.2614 25.5 30.5 25.5C27.7386 25.5 25.5 27.7386 25.5 30.5C25.5 33.2614 27.7386 35.5 30.5 35.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M33 30.5H28" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.5 33V28" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default AddToPlaylist;
