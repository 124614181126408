import axios from 'axios';
import { IIdentities } from '@pretzel-aux/common/src/@types/Connected';

export const getIdentities = async (): Promise<IIdentities> => {
  const options = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
    },
  };

  return axios
    .get(`https://api.pretzel.rocks/api/v1/account/identities`, options)
    .then(({ data }) => data)
    .catch(e => e);
};

export default getIdentities;
