export enum ArtworkSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',

  SmallRect = 'small_lrect',
  MediumRect = 'medium_lrect',
  LargeRect = 'large_lrect',
}
export function toUrl(artworkGuid: string, size: ArtworkSize = ArtworkSize.Medium) {
  return `https://img.pretzel.rocks/artwork/${artworkGuid}/${size}.jpg`;
}

export function urlFromSuffix(artworkSuffix: string) {
  return `https://img.pretzel.rocks/artwork/${artworkSuffix}`;
}
