import * as React from "react";

function SvgFeatureWp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 35 32" {...props}>
      <path d="M14.88 16.768c1.504 0 2.656 1.152 2.656 2.656s-1.152 2.624-2.656 2.624-2.624-1.152-2.624-2.624 1.152-2.656 2.624-2.656zm7.424 0c1.472 0 2.624 1.152 2.624 2.656s-1.152 2.624-2.624 2.624-2.656-1.152-2.656-2.624 1.152-2.656 2.656-2.656zm1.056 2.72l-2.464-8.8h-5.664l2.368 8.384-1.632.416-2.976-10.432h9.152l2.784 10.016-1.568.416zm-4.768 12.416c-6.816 0-12.928-4.352-15.136-10.752l1.568-.576c1.984 5.76 7.392 9.696 13.568 9.696h.096c3.84 0 7.392-1.568 10.112-4.288s4.192-6.336 4.096-10.112c0-7.904-6.496-14.24-14.304-14.24h-.096c-7.872 0-14.304 6.432-14.208 14.336H2.624C2.56 7.168 9.696 0 18.496-.096h.096c8.8 0 15.968 7.168 15.968 15.968 0 4.288-1.664 8.32-4.608 11.36a16.1 16.1 0 01-11.36 4.672c.096 0 0 0 0 0zM3.552 17.44L0 12.736l1.312-.96 2.4 3.104L6.336 12l1.248 1.152-4.032 4.288z" />
    </svg>
  );
}

export default SvgFeatureWp;
