import React, { FC } from 'react';
import { StyledFeaturedHeading, StyledH1, StyledH2, StyledH3, StyledH4, StyledH5 } from './styled';
import { HeadingProps } from './types';

export const Heading: FC<HeadingProps> = ({ children, variant, ...rest }) => {
  const renderHeading = () => {
    switch (variant) {
      case 'H1':
        return <StyledH1 {...rest}>{children}</StyledH1>;

      case 'H2':
        return <StyledH2 {...rest}>{children}</StyledH2>;

      case 'H3':
        return <StyledH3 {...rest}>{children}</StyledH3>;

      case 'H4':
        return <StyledH4 {...rest}>{children}</StyledH4>;

      case 'H5':
        return <StyledH5 {...rest}>{children}</StyledH5>;

      case 'Featured':
        return <StyledFeaturedHeading {...rest}>{children}</StyledFeaturedHeading>;

      default:
        console.error('Incorrect type used. Defaulting to H3.');
        return <StyledH3 {...rest}>{children}</StyledH3>;
    }
  };

  return renderHeading();
};
