import * as React from "react";

function SvgMinimize(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M24.416 16.8h-16.8c-.416 0-.8-.544-.672-.928 0-.416.384-.672.672-.672h16.928c.384 0 .8.544.672.928-.16.416-.416.672-.8.672z" />
    </svg>
  );
}

export default SvgMinimize;
