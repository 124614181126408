import React, { FC } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { Carousel } from '../../../Carousel';
import { TrendingLabelsProps } from './types';
import { LabelCard } from '../../../LabelsList/Components/LabelCard';
import { Section } from '../Section';

const TrendingLabelsQuery = loader('./trendingLabels.graphql');

export const TrendingLabelsFn: FC<TrendingLabelsProps> = ({ data, children }) => {
  return (
    <Section title="Trending labels" viewMoreLink="/labels">
      {!data.loading && data.segments && (
        <Carousel id="trending-labels" numberOfSlides={data.segments.length}>
          {data.segments.map(node => (
            <LabelCard key={node.id} label={node} />
          ))}
        </Carousel>
      )}
    </Section>
  );
};

export const TrendingLabels = compose(
  graphql(TrendingLabelsQuery, {
    options: {
      variables: {
        guids: 'Vns9g8,WJsZaE,G3sAnQ,e5sm7m,MXsXmy,nXsb,J4sJ,9ksWEX,lOs3VB,66sl9z,ZVsGo2,66slQo',
      },
    },
  })
)(TrendingLabelsFn);
