import * as React from "react";

function SvgTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 32" {...props}>
      <path d="M28.928 7.296q-1.184 1.728-2.88 2.976v.736q0 2.336-.672 4.64t-2.048 4.448-3.296 3.744-4.608 2.624-5.792.96Q4.8 27.424.8 24.832q.608.064 1.376.064 4.032 0 7.168-2.464-1.888-.032-3.36-1.152t-2.048-2.848q.608.096 1.088.096.768 0 1.536-.192-2.016-.416-3.328-1.984t-1.312-3.68v-.064q1.216.672 2.624.736-1.184-.8-1.888-2.048t-.704-2.752q0-1.568.8-2.912Q4.928 8.288 8 9.888t6.656 1.76q-.16-.672-.16-1.312 0-2.4 1.696-4.064t4.064-1.696q2.528 0 4.224 1.824 1.952-.384 3.68-1.408-.672 2.048-2.56 3.2 1.664-.192 3.328-.896z" />
    </svg>
  );
}

export default SvgTwitter;
