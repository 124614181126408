import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { ControlItem } from './Components/ControlItem/ControlItem';
import { Wrapper } from './styles';
import { Tooltip } from '@pretzel-aux/common/src/Components/Tooltip';
import { ILikeAndDislikeProps, mapPlatformContextToProps, mapPlayerContextToProps, PlayerProps } from '../../shared/interfaces';
import { handleNext, handlePrevious, handleTogglePause } from '../../shared/utils';
import { withPlatformContext } from '@pretzel-aux/common/src/Core/Platform';
import { withPlayerContext } from '../../PlayerContext';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { theme } from '@pretzel-aux/common/src/Styled';
import { CSSProperties } from 'styled-components';
import { LikeAndDislikeComponents } from '../../HorizontalPlayer';

interface ICommonPlayerControls extends PlayerProps {
  toggleCompactControls: () => void;
}

type TypeCommonPlayerControls = ICommonPlayerControls & ILikeAndDislikeProps;

const CommonPlayerControls = ({
  pretzelUser,
  loadingNext,
  next,
  previous,
  playing,
  togglePause,
  skipBack,
  skipForward,
  toggleCompactControls,
  likeTrack,
  dislikeTrack,
  playToken,
  sendAnalyticsEvent,
}: TypeCommonPlayerControls) => {
  const { width } = useWindowSize();
  const isPremium = pretzelUser?.jwt.tier !== 'free';
  const upgradeText = 'Upgrade to premium to use interactive mode';

  return (
    <React.Fragment>
      {width > theme.appBreakpoints.small && (
        <Tooltip show text={!isPremium ? upgradeText : 'Skip back'} styles={!isPremium ? 'left: -1.65rem;' : ''}>
          <ControlItem
            name="SkipBack"
            isLoading={loadingNext}
            isDisabled={!isPremium}
            onClick={() => skipBack(15)}
            wrapperStyle={{ width: '48px', height: '48px' } as CSSProperties}
            heapid="skip-back"
          />
        </Tooltip>
      )}
      <Tooltip show text="Replay track" styles="left: -0.4rem;">
        <ControlItem name="Previous" isLoading={loadingNext} isDisabled={false} onClick={e => handlePrevious(e, previous)} heapid="replay-track" />
      </Tooltip>

      <ControlItem
        name={playing ? 'Pause' : 'PlayerPlay'}
        isLoading={loadingNext}
        play
        size={48}
        isDisabled={false}
        style={{ width: '60px' }}
        wrapperStyle={{ width: '60px', height: '48px' }}
        onClick={e => handleTogglePause(e, togglePause)}
        heapid="play-button"
        testid={playing ? 'pause-button' : 'play-button'}
      />

      <Tooltip show text="Next track" styles="left: -0.2rem;">
        <ControlItem name="Next" isLoading={loadingNext} isDisabled={false} onClick={e => handleNext(e, next)} heapid="next-track" />
      </Tooltip>

      {width >= theme.appBreakpoints.small && (
        <Tooltip show text={!isPremium ? upgradeText : 'Skip forward'} styles={!isPremium ? 'left: -1.65rem;' : 'left: -0.4rem;'}>
          <ControlItem name="SkipForward" isLoading={loadingNext} isDisabled={!isPremium} onClick={() => skipForward(15)} />
        </Tooltip>
      )}

      {width < theme.appBreakpoints.extraSmall && (
        <LikeAndDislikeComponents
          loadingNext={loadingNext}
          playToken={playToken}
          likeTrack={likeTrack}
          sendAnalyticsEvent={sendAnalyticsEvent}
          dislikeTrack={dislikeTrack}
          next={next}
        />
      )}

      {width < theme.appBreakpoints.medium && (
        <Tooltip show text="More" styles="left: 0.4rem;">
          <ControlItem name="More" isLoading={false} isDisabled={false} onClick={toggleCompactControls} />
        </Tooltip>
      )}
    </React.Fragment>
  );
};

const PlayerControlsPresentation = (props: ICommonPlayerControls): React.ReactElement => {
  return (
    <Wrapper>
      <CommonPlayerControls {...props} />
    </Wrapper>
  );
};

export const PlayerControls = compose(
  withPlayerContext(mapPlayerContextToProps),
  withPlatformContext(mapPlatformContextToProps)
  // @ts-ignore Just need to move to hooks for these so that typing isn't so fucked up.
)(PlayerControlsPresentation);
