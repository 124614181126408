import React, { FC } from 'react';
import { StyledArtists, StyledButtonContainer, StyledFlex, StyledTrackDetailsContainer, StyledTrendingTrackCard } from './styled';
import { Download } from '../../../../Components/TrackList/components/Download';
import { TrendingTrackCardProps } from './types';
import { PlayButton } from '../../../PlayButton';
import { formatTime } from '../../../../Util';

export const TrendingTrackCard: FC<TrendingTrackCardProps> = ({ track, children, handleNonPremiumClick }) => {
  return (
    <StyledTrendingTrackCard>
      <StyledButtonContainer>
        <PlayButton idToPlay={track.id} isTrack handleNonPremiumClick={handleNonPremiumClick} />
      </StyledButtonContainer>

      <StyledTrackDetailsContainer>
        <StyledFlex>
          <strong>{track.title}</strong>
          <span>{formatTime(track.duration)}</span>
        </StyledFlex>

        <StyledFlex>
          <StyledArtists>{track.artists[0].name}</StyledArtists>
          <Download track={track as any} heapid="download-track" curations curationsHandleNonPremiumClick={handleNonPremiumClick} />
        </StyledFlex>
      </StyledTrackDetailsContainer>
    </StyledTrendingTrackCard>
  );
};
