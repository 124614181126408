import * as React from 'react';
import { Component } from 'react';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { RetryLink } from '@apollo/client/link/retry';

export const URI_BASE = ((): string => {
  if (process.env.REACT_APP_URI) {
    return process.env.REACT_APP_URI;
  } else if (process.env.REACT_APP_CONTEXT === 'production') {
    return 'https://gql.pretzel.rocks';
  }
  return 'https://gql-staging.pretzel.rocks';
})();

export interface PublicProperties {
  jwt: string | null;
}

interface State {
  jwt: string | null;
  client: ApolloClient<any> | null;
}

type Props = PublicProperties;

export class AuthenticatedApolloProvider extends Component<Props, State> {
  state: State = {
    jwt: null,
    client: null,
  };

  // @ts-ignore
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.jwt && props.jwt !== state.jwt) {
      const client = new ApolloClient({
        link: ApolloLink.from([
          new RetryLink(),
          onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
              graphQLErrors.forEach(err => {
                const { message, locations, path, originalError } = err;
                const properError = new Error(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} Original Error: ${originalError}`
                );
                console.error(properError.message);
                Sentry.captureException(properError);
              });
            }
            if (networkError) {
              console.error(`[Network error]: ${networkError}`);
              Sentry.captureException(networkError);
            }
          }),
          new HttpLink({
            uri: `${URI_BASE}/graphql`,
            headers: {
              Authorization: `Bearer ${props.jwt}`,
            },
          }),
        ]),
        cache: new InMemoryCache({
          // TODO: Generate this automatically
          // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
          possibleTypes: {
            Node: ['Album', 'Artist', 'Label', 'Mix', 'PlayToken', 'Playlist', 'Station', 'User'],
            PlayTokenSourceDetailsUnion: ['SegmentSourceDetails', 'SongRequestSourceDetails'],
            SegmentInterface: ['Album', 'Artist', 'Label', 'Mix', 'Playlist', 'Station'],
          },
        }),
      });
      return {
        jwt: props.jwt,
        client,
      };
    } else {
      return state;
    }
  }

  render() {
    if (this.state.client) {
      return <ApolloProvider client={this.state.client}>{this.props.children}</ApolloProvider>;
    }
    return null;
  }
}
