import { theme } from '@pretzel-aux/common/src/Styled';
import * as React from 'react';

function SvgPlayerPlay(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg
      onMouseEnter={() => setFill(theme.colors.brandGreen)}
      onMouseLeave={() => setFill(theme.colors.offWhite)}
      width="60"
      height="48"
      viewBox="0 0 60 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="no-hover-effect"
    >
      <circle cx="30" cy="24" r="19.5" stroke={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4371 16.7893C26.6222 16.7893 26.8036 16.8407 26.9612 16.9376L37.0532 23.1481C37.5235 23.4375 37.6702 24.0535 37.3807 24.5238C37.2987 24.6572 37.1865 24.7694 37.0532 24.8514L26.9612 31.0618C26.4909 31.3513 25.8749 31.2046 25.5855 30.7343C25.4885 30.5767 25.4371 30.3952 25.4371 30.2102V17.7893C25.4371 17.237 25.8848 16.7893 26.4371 16.7893Z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgPlayerPlay;
