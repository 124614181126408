import { theme } from '@pretzel-aux/common/src/Styled';
import { AudioOutputSelector } from '@pretzel-aux/common/src/Components/Settings/Sections/Audio/Components/AudioOutputSelector/AudioOutputSelector';
import { AudioQualitySelector } from '@pretzel-aux/common/src/Components/Settings/Sections/Audio/Components/AudioQualitySelector/AudioQualitySelector';
import playerContext, { PlayerContext } from '@pretzel-aux/common/src/Core/Player/PlayerContext';
import settingsContext, { DownloadFileType, SettingsContext } from '@pretzel-aux/common/src/Core/Settings/Settings';
import React, { useContext } from 'react';
import Select from 'react-select';
import { StyledAudioContent } from './Styled';
import { StyledCheck, StyledCheckbox, StyledCheckboxWrapper } from '../../Styles';
import { customDropDownStyles } from '../../../Settings/Sections/Audio/Components/CustomSelectStyles';
import { AudioOutputTitle, AudioSelectorWrapper, SelectWrapper } from '../../../Settings/Sections/Audio/Components/AudioQualitySelector/Styles';
import platformContext, { PlatformContext, Tier } from '@pretzel-aux/common/src/Core/Platform';
import { isUserEntitled } from '@pretzel-aux/common/src/Util/Entitlements';

const options = Object.keys(DownloadFileType).map(key => {
  return { label: key, value: DownloadFileType[key] as string };
});

export const AudioControls = (): JSX.Element => {
  const { onFiltersChange } = useContext<PlayerContext>(playerContext);
  const { pretzelUser } = useContext<PlatformContext>(platformContext);
  const isPro = isUserEntitled(pretzelUser.jwt.tier, [Tier.ZeroCpm, Tier.Pro, Tier.Admin]);

  const {
    advanceOnFilterChange,
    allowExplicit,
    downloadFileType,
    instrumentalOnly,
    setAdvanceOnFilterChange,
    setAllowExplicit,
    setDownloadFileType,
    setInstrumentalOnly,
    setYoutubeSafe,
    youtubeSafe,
  } = useContext<SettingsContext>(settingsContext);
  const handleToggleYoutube = (): void => {
    setYoutubeSafe(!youtubeSafe);
    onFiltersChange();
  };

  const filters = [
    {
      text: 'Advance to next track when filters are changed',
      checked: advanceOnFilterChange,
      func: (value: boolean): void => {
        setAdvanceOnFilterChange(value);
      },
      margin: '2rem 0 0 0',
    },
    {
      text: 'Instrumental only',
      checked: instrumentalOnly,
      func: (value: boolean): void => {
        setInstrumentalOnly(value);
        onFiltersChange();
      },
      margin: '1rem 0 1rem 0',
    },
    {
      text: 'Allow mature',
      checked: allowExplicit,
      func: (value: boolean): void => {
        setAllowExplicit(value);
        onFiltersChange();
      },
      margin: '0 0 1rem 0',
    },
    {
      text: 'YouTube safe mode',
      checked: youtubeSafe,
      func: handleToggleYoutube,
      margin: '0',
    },
  ];

  return (
    <StyledAudioContent>
      <AudioQualitySelector />
      <AudioOutputSelector />
      {filters.map(f => {
        const { text, checked, func, margin } = f;
        return (
          <StyledCheckboxWrapper key={text} style={{ margin, fontSize: '0.85rem' }}>
            <StyledCheckbox onClick={() => func(!checked)} style={{ backgroundColor: checked ? theme.colors.brandGreen : theme.colors.sidebarBlack }}>
              {checked && <StyledCheck />}
            </StyledCheckbox>
            {text}
          </StyledCheckboxWrapper>
        );
      })}
      {isPro && (
        <>
          <AudioOutputTitle style={{ paddingTop: '30px' }}>Download file type</AudioOutputTitle>
          <AudioSelectorWrapper>
            <SelectWrapper className="no-drag">
              <Select
                isClearable={false}
                isSearchable={false}
                name="download-file-type"
                onChange={(newValue: { label: string; value: string }) => setDownloadFileType(newValue.value as DownloadFileType)}
                options={options}
                styles={customDropDownStyles}
                value={options.find(option => option.value === downloadFileType)}
              />
            </SelectWrapper>
          </AudioSelectorWrapper>
        </>
      )}
    </StyledAudioContent>
  );
};
