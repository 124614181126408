import * as React from "react";

function Crown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14 18.833C14.8284 18.833 15.5 18.1614 15.5 17.333C15.5 16.5046 14.8284 15.833 14 15.833C13.1716 15.833 12.5 16.5046 12.5 17.333C12.5 18.1614 13.1716 18.833 14 18.833Z" stroke="#D9C54A" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34 18.917C34.8284 18.917 35.5 18.2454 35.5 17.417C35.5 16.5886 34.8284 15.917 34 15.917C33.1716 15.917 32.5 16.5886 32.5 17.417C32.5 18.2454 33.1716 18.917 34 18.917Z" stroke="#D9C54A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 33H32" stroke="#D9C54A" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0001 30L13.9531 18.828C13.9531 18.828 15.0001 24 19.0001 24C23.0001 24 24.0001 17 24.0001 17C24.0001 17 25.0001 24 29.0001 24C33.0001 24 34.0471 18.907 34.0471 18.907L32.0001 30H16.0001Z" stroke="#D9C54A" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 17C25.1046 17 26 16.1046 26 15C26 13.8954 25.1046 13 24 13C22.8954 13 22 13.8954 22 15C22 16.1046 22.8954 17 24 17Z" stroke="#D9C54A" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="24" cy="24" r="23.5" stroke="#D9C54A"/>
    </svg>
  );
}

export default Crown;
