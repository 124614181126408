import React, { useContext } from 'react';
import { LeftNavigationContainer, FlexEndContainer } from './styles';
import { NavigationButton } from './components/NavigationButton';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { theme } from '@pretzel-aux/common/src/Styled';

export const LeftNav = () => {
  const { width } = useWindowSize();

  return (
    <LeftNavigationContainer style={width < theme.appBreakpoints.extraSmall ? { width: '48px' } : { width: '64px' }}>
      <ul style={width < theme.appBreakpoints.extraSmall ? { marginTop: theme.space.sm, marginBottom: 0 } : {}}>
        <NavigationButton location="/home" iconName={width < theme.appBreakpoints.extraSmall ? 'CompactHome' : 'Home'} />
        <NavigationButton location="/stations" iconName={width < theme.appBreakpoints.extraSmall ? 'CompactStations' : 'Stations'} />
        {width > theme.appBreakpoints.extraSmall && <NavigationButton location="/curations" iconName="Curations" badgeText="PRO" />}
        <NavigationButton location="/artists" iconName={width < theme.appBreakpoints.extraSmall ? 'CompactArtists' : 'Artists'} />
        <NavigationButton location="/albums" iconName={width < theme.appBreakpoints.extraSmall ? 'CompactAlbums' : 'Albums'} />
        <NavigationButton location="/labels" iconName={width < theme.appBreakpoints.extraSmall ? 'CompactLabels' : 'Labels'} />
        <NavigationButton location="/playlists" iconName={width < theme.appBreakpoints.extraSmall ? 'CompactPlaylists' : 'Playlists'} />
      </ul>
      {width > theme.appBreakpoints.extraSmall && (
        <FlexEndContainer>
          <NavigationButton location="/recent" iconName="Recent" />
          <NavigationButton location="/likes" iconName="Like" />
          <NavigationButton location="/dislikes" iconName="Dislike" />
        </FlexEndContainer>
      )}
    </LeftNavigationContainer>
  );
};
