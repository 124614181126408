import React, { useContext } from 'react';
import { OutputFileSelector } from './OutputFileSelector';
import { OutputFormatter } from './OutputFormatter';
import { SectionWrapper } from './Styles';
import { StyledCheck, StyledCheckbox, StyledCheckboxWrapper } from '../../../CurrentUser/Styles';
import { PICK_FILE, PickFileParams } from '../../../../Core/IPC/IPCRequest';
import platformContext, { PlatformContext } from '../../../../Core/Platform';
import settingsContext, { SettingsContext } from '../../../../Core/Settings/Settings';
import { theme } from '../../../../Styled';

export const FileOutput = (): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const settingsContextObj = useContext<SettingsContext>(settingsContext);

  const openTrackInfoDialog = async () => {
    console.log('Open Track Info Dialog');
    const params: PickFileParams = {
      type: PICK_FILE,
      title: 'Set Now Playing label file',
      message: 'Set Now Playing label file',
      filters: [{ name: 'Text', extensions: ['txt'] }],
      defaultPath: settingsContextObj.writeTrackInfoFile,
    };
    const newPath = await platformContextObj.sendIPC<string>(PICK_FILE, { params });
    settingsContextObj.setWriteTrackInfoFile(newPath);
  };

  const clearTrackInfoFile = () => {
    settingsContextObj.setWriteTrackInfoFile('');
  };

  const openCoverDialog = async () => {
    const params: PickFileParams = {
      type: PICK_FILE,
      title: 'Set Album Cover file',
      message: 'Set Album Cover file',
      filters: [{ name: 'Image', extensions: ['jpg'] }],
      defaultPath: settingsContextObj.writeCoverFile,
    };
    const newPath = await platformContextObj.sendIPC<string>(PICK_FILE, { params });
    settingsContextObj.setWriteCoverFile(newPath);
  };

  const clearCoverFile = () => {
    settingsContextObj.setWriteCoverFile('');
  };

  const openTrackJsonDialog = async () => {
    console.log('Open Track Json Dialog');
    const params: PickFileParams = {
      type: PICK_FILE,
      title: 'Set Now Playing Json file',
      message: 'Set Now Playing JSON file',
      filters: [{ name: 'JSON', extensions: ['json'] }],
      defaultPath: settingsContextObj.writeTrackJsonFile,
    };
    const newPath = await platformContextObj.sendIPC<string>(PICK_FILE, { params });
    settingsContextObj.setWriteTrackJsonFile(newPath);
  };

  const clearTrackJsonFile = () => {
    settingsContextObj.setWriteTrackJsonFile('');
  };

  return platformContextObj.features.writeToFile ? (
    <>
      <SectionWrapper>
        <StyledCheckboxWrapper className="styled-checkbox-wrapper">
          <StyledCheckbox
            onClick={() => settingsContextObj.setWriteTrackInfoToFile(!settingsContextObj.writeTrackInfoToFile)}
            style={{ backgroundColor: settingsContextObj.writeTrackInfoToFile ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
          >
            {settingsContextObj.writeTrackInfoToFile && <StyledCheck />}
          </StyledCheckbox>
          Write Track Info to file
        </StyledCheckboxWrapper>
        <OutputFileSelector
          title="Track Info file"
          disabled={!settingsContextObj.writeTrackInfoToFile}
          filePath={settingsContextObj.writeTrackInfoFile}
          clearSavePath={clearTrackInfoFile}
          openSaveDialog={openTrackInfoDialog}
        />
        <OutputFormatter
          title="Now Playing Output format"
          formatString={settingsContextObj.writeToFileFormat}
          onChange={settingsContextObj.setWriteToFileFormat}
          disabled={!settingsContextObj.writeTrackInfoToFile}
        />
      </SectionWrapper>
      <SectionWrapper>
        <StyledCheckboxWrapper className="styled-checkbox-wrapper">
          <StyledCheckbox
            onClick={() => settingsContextObj.setWriteToFilePaused(!settingsContextObj.writeToFilePaused)}
            style={{ backgroundColor: settingsContextObj.writeToFilePaused ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
          >
            {settingsContextObj.writeToFilePaused && <StyledCheck />}
          </StyledCheckbox>
          Alternate output when paused
        </StyledCheckboxWrapper>
        <OutputFormatter
          title="Paused Output format"
          disabled={!settingsContextObj.writeToFilePaused}
          formatString={settingsContextObj.writeToFilePausedFormat}
          onChange={settingsContextObj.setWriteToFilePausedFormat}
        />
      </SectionWrapper>
      <SectionWrapper>
        <StyledCheckboxWrapper className="styled-checkbox-wrapper">
          <StyledCheckbox
            onClick={() => settingsContextObj.setWriteCoverToFile(!settingsContextObj.writeCoverToFile)}
            style={{ backgroundColor: settingsContextObj.writeCoverToFile ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
          >
            {settingsContextObj.writeCoverToFile && <StyledCheck />}
          </StyledCheckbox>
          Save Album Cover to file
        </StyledCheckboxWrapper>
        <OutputFileSelector
          title="Cover file"
          disabled={!settingsContextObj.writeCoverToFile}
          filePath={settingsContextObj.writeCoverFile}
          clearSavePath={clearCoverFile}
          openSaveDialog={openCoverDialog}
        />
      </SectionWrapper>
      <SectionWrapper>
        <StyledCheckboxWrapper className="styled-checkbox-wrapper">
          <StyledCheckbox
            onClick={e => settingsContextObj.setWriteTrackJsonToFile(!settingsContextObj.writeTrackJsonToFile)}
            style={{ backgroundColor: settingsContextObj.writeTrackJsonToFile ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
          >
            {settingsContextObj.writeTrackJsonToFile && <StyledCheck />}
          </StyledCheckbox>
          Save Now Playing JSON Data to file
        </StyledCheckboxWrapper>
        <OutputFileSelector
          title="Now Playing JSON file"
          disabled={!settingsContextObj.writeTrackJsonToFile}
          filePath={settingsContextObj.writeTrackJsonFile}
          clearSavePath={clearTrackJsonFile}
          openSaveDialog={openTrackJsonDialog}
        />
      </SectionWrapper>
    </>
  ) : null;
};
