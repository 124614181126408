import * as React from 'react';

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.9168 26.8336C24.1845 26.8336 26.8336 24.1845 26.8336 20.9168C26.8336 17.649 24.1845 15 20.9168 15C17.649 15 15 17.649 15 20.9168C15 24.1845 17.649 26.8336 20.9168 26.8336Z"
        strokeWidth="1.5"
      />
      <path d="M28.6745 29.0992L28.6858 29.0673M28.6858 29.0673C28.6858 29.0287 28.6711 28.9901 28.6417 28.9607L25.3816 25.7006C25.3522 25.6712 25.3136 25.6565 25.275 25.6565C25.2364 25.6565 25.1978 25.6712 25.1683 25.7006C25.1389 25.7301 25.1242 25.7687 25.1242 25.8073C25.1242 25.8459 25.1389 25.8845 25.1683 25.9139L28.4284 29.174C28.5889 29.1558 28.6858 29.1261 28.6858 29.0673Z" />
    </svg>
  );
}

export default SvgSearch;
