import * as React from 'react';

const TableMoreActive = () => (
  <svg width="16" height="40" viewBox="0 0 16 40" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="table-more-button-active">
    <title id="table-more-button-acitve">Close More Information</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 15.6667C8.92048 15.6667 9.66668 14.9205 9.66668 14C9.66668 13.0795 8.92048 12.3333 8.00001 12.3333C7.07954 12.3333 6.33334 13.0795 6.33334 14C6.33334 14.9205 7.07954 15.6667 8.00001 15.6667Z"
      fill="#1BB3A4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 21.6667C8.92048 21.6667 9.66668 20.9205 9.66668 20C9.66668 19.0795 8.92048 18.3333 8.00001 18.3333C7.07954 18.3333 6.33334 19.0795 6.33334 20C6.33334 20.9205 7.07954 21.6667 8.00001 21.6667Z"
      fill="#1BB3A4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00001 27.6667C8.92048 27.6667 9.66668 26.9205 9.66668 26C9.66668 25.0795 8.92048 24.3333 8.00001 24.3333C7.07954 24.3333 6.33334 25.0795 6.33334 26C6.33334 26.9205 7.07954 27.6667 8.00001 27.6667Z"
      fill="#1BB3A4"
    />
  </svg>
);

export default TableMoreActive;
