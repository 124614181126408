import * as React from 'react';

function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.9" y="0.65" width="14.7" height="14.7" rx="3.35" stroke="#FFF" strokeWidth="1.3" />
      <path
        d="M8.25 4C6.04087 4 4.25 5.79087 4.25 8C4.25 10.2091 6.04087 12 8.25 12C10.4591 12 12.25 10.2091 12.25 8C12.25 5.79087 10.4591 4 8.25 4ZM8.25 10.5965C6.81601 10.5965 5.6535 9.43399 5.6535 8C5.6535 6.56601 6.81601 5.4035 8.25 5.4035C9.68399 5.4035 10.8465 6.56601 10.8465 8C10.8465 9.43399 9.68399 10.5965 8.25 10.5965Z"
        fill="#FFF"
      />
      <path
        d="M13.55 3.74999C13.55 4.1642 13.2143 4.5 12.8001 4.5C12.3858 4.5 12.05 4.1642 12.05 3.74999C12.05 3.33577 12.3858 3 12.8001 3C13.2143 3 13.55 3.33577 13.55 3.74999Z"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgInstagram;
