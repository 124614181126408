import { StyledCheck, StyledCheckbox, StyledCheckboxWrapper } from '@pretzel-aux/common/src/Components/CurrentUser/Styles';
import { LocalWindow } from '@pretzel-aux/common/src/Components/Settings/Sections/Keybinds/LocalWindow';
import { GlobalWindow } from '@pretzel-aux/common/src/Components/Settings/Sections/Keybinds/GlobalWindow';
import settingsContext, { SettingsContext } from '@pretzel-aux/common/src/Core/Settings/Settings';
import React, { useContext } from 'react';
import { StyledKeybindsWrapper } from './Styles';
import { theme } from '../../../../Styled';

const Keybinds = (): JSX.Element => {
  const settingsContextObj = useContext<SettingsContext>(settingsContext);

  return (
    <StyledKeybindsWrapper>
      <StyledCheckboxWrapper>
        <StyledCheckbox
          onClick={e => settingsContextObj.setBindMediaHotkeys(!settingsContextObj.bindMediaHotkeys)}
          style={{ backgroundColor: settingsContextObj.bindMediaHotkeys ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
        >
          {settingsContextObj.bindMediaHotkeys && <StyledCheck />}
        </StyledCheckbox>
        Use media keys for player controls
      </StyledCheckboxWrapper>
      <GlobalWindow />
      <LocalWindow />
    </StyledKeybindsWrapper>
  );
};

export default Keybinds;
