import * as React from 'react';
import { Box, Text } from 'grommet';
import { theme } from '@pretzel-aux/common/src/Styled';
import { ActiveSegmentFragment } from '../Queries/types/ActiveSegmentFragment';
import { Link } from 'react-router-dom';

interface PublicProps {
  segment: ActiveSegmentFragment | null;
  width?: number;
  isCompact?: boolean;
}

type Props = PublicProps;

export class ActiveBar extends React.Component<Props> {
  public render() {
    if (this.props.segment) {
      const { name, __typename: segmentType } = this.props.segment;
      const style = this.props.width < theme.appBreakpoints.large && !this.props.isCompact ? { display: 'none' } : {};

      return (
        <Box direction="row" style={style}>
          <Text color={theme.colors.textTitles} size="xsmall" weight="bold" data-heapid="active-segment-type">
            {segmentType}
          </Text>
          <Text color={theme.colors.textTitles} size="xsmall">
            :
          </Text>
          &nbsp;
          {this.props.segment.__typename != 'Station' ? (
            <Link to={`/segment/${this.props.segment.id}`} style={{ transform: 'translateY(-1px)' }}>
              <Text color={theme.colors.textTitles} size="xsmall" data-heapid="active-segment">
                {name}
              </Text>
            </Link>
          ) : (
            <Text color={theme.colors.textTitles} size="xsmall" data-heapid="active-segment">
              {name}
            </Text>
          )}
        </Box>
      );
    }
    return null;
  }
}
