import React, { FC } from 'react';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { StyledButton } from './styled';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, externalLink, ...rest }) => {
  return externalLink ? (
    <ExternalLink tabIndex={1} href={externalLink}>
      <StyledButton {...rest}>{children}</StyledButton>
    </ExternalLink>
  ) : (
    <StyledButton tabIndex={1} {...rest}>
      {children}
    </StyledButton>
  );
};
