import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Modal } from '../Modal';
import { StyledOption } from './styled';
import { IOption, ISelectOption, IYouTubeChannel, IYouTubeChannelSelectModalProps, IYouTubeChannelSelectModalState, Step } from './types';
import { STORAGE_KEYS } from '../../Core/Platform';
import { SetYouTubeChannelVariables, SetYouTubeChannel_youtubeChannelUpdate } from '../../Core/Settings/Queries/types/SetYouTubeChannel';
import Steps from './steps';

const setYouTubeChannelMutationFile = loader('../../Core/Settings/Queries/set-youtube-channel.graphql');

const mapToSelectOptions = (options: IOption[]): ISelectOption[] => {
  return options.map((option: IOption) => {
    return {
      ...option,
      label: (
        <StyledOption>
          <img alt={`${option.channelName}-channel-logo`} src={option.channelImageUrl} />
          {option.channelName}
        </StyledOption>
      ),
      value: option.channelId,
    };
  });
};

const YouTubeChannelSelectModal = ({ getYouTubeChannels, onClose, setOpen, setYouTubeChannel }: IYouTubeChannelSelectModalProps): JSX.Element => {
  const [youtubeChannelEntry, setYoutubeChannelEntry] = useState<string>();
  const [selectedChannelOption, setSelectedChannelOption] = useState<IOption>();
  const [youtubeSearchErrorCode, setYoutubeSearchErrorCode] = useState<number>();
  const [channelValidity, setChannelValidity] = useState<boolean>(false);

  const [state, setState] = useState<IYouTubeChannelSelectModalState>({
    step: Step.Search,
    options: [],
    selectedOption: undefined,
  });

  const [setYouTubeChannelMutation] = useMutation<SetYouTubeChannel_youtubeChannelUpdate, SetYouTubeChannelVariables>(setYouTubeChannelMutationFile);

  const defaultGetYouTubeChannels = async (searchTerm: string): Promise<IOption[] | Error> => {
    let baseUrl: string;

    if (process.env.REACT_APP_CONTEXT === 'development') baseUrl = process.env.REACT_APP_USER_METADATA_SERVICE_BASE_URL;
    else if (process.env.REACT_APP_CONTEXT === 'production') baseUrl = 'https://api.songtradr.net';
    else baseUrl = 'https://api.staging-2.songtradr.dev';

    const response = await fetch(`${baseUrl}/user-metadata/accounts/youtube/v3/search?channelName=${searchTerm}`, {
      method: 'GET',
    });

    if (response.ok) {
      const channels = (await response.json()) as Array<IYouTubeChannel>;
      const filteredItems = channels.map(
        (item: IYouTubeChannel): IOption => {
          return {
            channelId: item.snippet.channelId,
            channelImageUrl: item.snippet.thumbnails.default.url,
            channelName: item.snippet.channelTitle,
          };
        }
      );

      return filteredItems;
    }
    setYoutubeSearchErrorCode(response.status);
    return Error(`YouTube channel search error - Status: ${response.status}, Message: ${response.statusText}.`);
  };

  const deleteOption = () => {
    setYoutubeChannelEntry('');
    setSelectedChannelOption(null);
    setState({
      ...state,
      options: [],
      selectedOption: undefined,
    });
  };

  const defaultSetYouTubeChannel = async (id: string, name: string): Promise<void | Error> => {
    const response = await setYouTubeChannelMutation({
      variables: {
        attributes: {
          id,
          name,
        },
      },
    });

    if (response.data?.errors?.length > 0) {
      return Error(response.data.errors.join(', '));
    }
    return;
  };

  const onSearch = async (): Promise<ISelectOption[]> => {
    if (!channelValidity) return [];
    const implementation = getYouTubeChannels ?? defaultGetYouTubeChannels;
    const response = await implementation(youtubeChannelEntry);

    if (!Array.isArray(response)) {
      console.debug('Get YouTube channels error(s).', response);

      if (youtubeSearchErrorCode && youtubeSearchErrorCode === 500) {
        setState({
          ...state,
          step: Step.YoutubeServerError,
        });

        if (!window.sessionStorage.getItem(STORAGE_KEYS.YOUTUBE_MODAL_RETRY_DATE)) {
          const endOfDay = new Date();
          endOfDay.setUTCHours(23, 59, 59, 999);
          window.sessionStorage.setItem(STORAGE_KEYS.YOUTUBE_MODAL_RETRY_DATE, `${endOfDay}`);
        }
      }
      return [];
    }
    setState({
      ...state,
      options: response,
    });

    return mapToSelectOptions(response);
  };

  const onSubmit = async (): Promise<void> => {
    setState({
      ...state,
      step: Step.Loading,
    });

    const implementation = setYouTubeChannel ?? defaultSetYouTubeChannel;
    const error = await implementation(state.selectedOption.channelId, state.selectedOption.channelName);

    if (error) {
      console.debug('Set YouTube channel error(s).', error);

      setState({
        ...state,
        step: Step.Error,
      });
    } else {
      setState({
        ...state,
        step: Step.Complete,
      });
      window.sessionStorage.removeItem(STORAGE_KEYS.YOUTUBE_MODAL_RETRY_DATE);
    }
  };

  const selectedContent = Steps({
    state,
    setState,
    modal: { getYouTubeChannels, onClose, setOpen, setYouTubeChannel },
    onSubmit,
    selectedChannelOption,
    setSelectedChannelOption,
    youtubeChannelEntry,
    setYoutubeChannelEntry,
    channelValidity,
    setChannelValidity,
    deleteOption,
    onSearch,
  });

  return (
    <Modal
      buttons={selectedContent.buttons}
      content={selectedContent.content}
      displayCloseIcon={false}
      footerContent={selectedContent.footerContent}
      isOpen
      setIsOpen={() => setOpen(false)}
      title={selectedContent.title}
      heapid="modal-youtube"
      testid="modal-youtube"
      variant="secondary"
    />
  );
};

export default YouTubeChannelSelectModal;
