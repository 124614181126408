import * as React from 'react';
import { Time } from './Styles';
import { flowRight as compose } from 'lodash';
import { Box } from 'grommet';
import { formatTime } from '../../../../Util';
import { PlayerContext, withPlayerContext } from '../../PlayerContext';
import { PlatformContext, withPlatformContext } from '../../../../Core/Platform';
import { theme } from '@pretzel-aux/common/src/Styled';

interface PropsFromPlayerContext {
  currentTime: number;
  duration: number | null;
  remainingTime: number | null;
  seekTo: (seconds: number) => void;
}

interface TrackProgressProps {
  width: number;
}

type PropsFromPlatform = Pick<PlatformContext, 'pretzelUser'>;

interface State {
  showRemaining: boolean;
}

type Props = PropsFromPlayerContext & PropsFromPlatform & TrackProgressProps;

export class TrackProgressPresentation extends React.Component<Props, State> {
  state: State = {
    showRemaining: false,
  };

  public render() {
    return (
      <Box
        height="25px"
        direction="column"
        style={this.props.width < theme.appBreakpoints.extraSmall ? { paddingTop: '3px' } : { paddingTop: '18px' }}
      >
        <Box direction="row" justify="between" align="center">
          <Time data-testid="track-time-elapsed">{formatTime(this.props.currentTime)}</Time>
          <Box margin={{ horizontal: '6px', top: '9px' }} flex="grow">
            {/* This is styled in slobs-base.css */}
            <progress
              value={this.props.currentTime}
              max={this.props.duration || undefined}
              onClick={this.props.pretzelUser.jwt.tier !== 'free' ? this.handleScrub : undefined}
              style={{ width: '100%' }}
              title={this.props.pretzelUser.jwt.tier !== 'free' ? undefined : 'Seeking Disabled for free tier'}
              data-testid="track-progress-bar"
            />
          </Box>
          {this.state.showRemaining ? (
            <Time onClick={this.handleToggleShowRemaining} data-testid="track-time-remaining">
              -{formatTime(this.props.remainingTime || 0)}
            </Time>
          ) : (
            <Time onClick={this.handleToggleShowRemaining} data-testid="track-time-total">
              {formatTime(this.props.duration || 0)}
            </Time>
          )}
        </Box>
        <Box direction="row" justify="between" />
      </Box>
    );
  }

  private skip = (to: number) => {
    if (this.props.currentTime + to <= 0) {
      this.props.seekTo(0);
    } else {
      this.props.seekTo(this.props.currentTime + to);
    }
  };

  private handleScrub = (event: React.MouseEvent<HTMLProgressElement>) => {
    if (!this.props.duration) return;
    const percent = event.nativeEvent.offsetX / event.currentTarget.offsetWidth;
    this.props.seekTo(percent * this.props.duration);
  };

  private handleToggleShowRemaining = () => {
    this.setState((state: State) => ({
      showRemaining: !state.showRemaining,
    }));
  };
}

function mapContextToProps(c: PlayerContext): PropsFromPlayerContext {
  return {
    currentTime: c.currentTime,
    duration: c.duration,
    remainingTime: c.remainingTime,
    seekTo: c.seekTo,
  };
}

function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export const TrackProgress = compose(withPlatformContext(mapPlatformToProps), withPlayerContext(mapContextToProps))(TrackProgressPresentation);
