import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { ApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { Analytics, AnalyticsEventResourceType, AnalyticsEventType } from '../Analytics';
import ReactModal from 'react-modal';
import { buildMutationInput, Mutation } from '../GraphQL';
import { AudioQuality, SettingsContext, withSettingsContext } from '../Settings/Settings';
import { createContext, toUrl } from '../../Util';
import { theme } from '../../Styled';
import { graphql, QueryControls, withApollo, withMutation } from '@apollo/client/react/hoc';
import { CurrentPlayTokenQuery } from './Queries/types/CurrentPlayTokenQuery';
import { SetActiveSegment, SetActiveSegmentVariables } from './Queries/types/SetActiveSegment';
import { ActiveSegmentFragment } from './Queries/types/ActiveSegmentFragment';
import { PlayTokenFragment } from './Queries/types/PlayTokenFragment';
import { BasicTrackFragment } from './Queries/types/BasicTrackFragment';
import { NotifyPlayToken, NotifyPlayTokenVariables } from './Queries/types/NotifyPlayToken';
import { NextPlayToken } from './Queries/types/NextPlayToken';
import { RecentlyPlayedQuery } from '../../Components/RecentlyPlayed/types/RecentlyPlayedQuery';
import { UpsellLink } from '../../Components/UpsellLink/UpsellLink';
import { addBreadcrumb } from '../../Util';
import { PlayerMode } from '../../Core/Analytics';
import { Modal } from '../../Components/Modal';
import { Button } from '../../Components/Button';

const nextPlayTokenQuery = loader('./Queries/next-play-token.graphql');
const currentPlayTokenQuery = loader('./Queries/current-play-token.graphql');
const notifyPlayTokenMutation = loader('./Queries/notify-play-token.graphql');
const setActiveSegmentMutation = loader('./Queries/set-active-segment.graphql');
const recentlyPlayedGql = loader('../../Components/RecentlyPlayed/recently-played.graphql');

const nextDebounceMs = 400;

interface GrqphQLProps {
  data: QueryControls & CurrentPlayTokenQuery;
  setActiveSegment: Mutation<SetActiveSegmentVariables, SetActiveSegment>;
}

interface PropsFromContext {
  advanceOnFilterChange: boolean;
  audioQuality: AudioQuality;
  audioVolume: number;
  audioOutputDevice: string;
  setAudioVolume: (value: number) => void;
  notifyDelay: number;
}

interface AudioOutput {
  id: string;
}

export interface PlayerContext {
  activeSegment: ActiveSegmentFragment | null;
  audioErrors: number;
  audioVolume: number;
  autoplayOnLoad: boolean;
  autoplayOverride: boolean;
  currentTime: number;
  duration: number;
  error: boolean;
  loadingNext: boolean;
  muted: boolean;
  output: AudioOutput | null;
  playerMode: PlayerMode;
  playing: boolean;
  playToken: PlayTokenFragment | null;
  remainingTime: number;
  scheduledPause: false;

  // Actions / inputs
  playSegment: (segment: SetActiveSegmentVariables) => void;
  changeSegment: (id: string) => void;
  playTrack: (track: BasicTrackFragment) => void;
  seekTo: (seconds: number) => void;
  next: () => void;
  previous: () => void;
  togglePause: () => void;
  pause: () => void;
  schedulePause: (scheduledPause: boolean) => void;
  increaseVolume: () => void;
  decreaseVolume: () => void;
  setVolume: (vol: number, instant?: boolean, faded?: boolean, visible?: boolean) => void;
  toggleMute: () => void;
  sendAnalyticsEvent: (type: AnalyticsEventType, playToken?: PlayTokenFragment | null, source?: string) => void;
  showModal: (el: React.ReactNode, showButtons: boolean) => void;
  closeModal: () => void;
  onFiltersChange: () => void;
  setPlayerMode: (playerMode: PlayerMode) => void;
  skipBack: (seconds: number) => void;
  skipForward: (seconds: number) => void;

  // Actually private
  modalOpen: boolean;
  modalMessage: React.ReactNode;
  showModalButton: boolean;
}

export interface PromiseParts {
  resolve: () => void;
  reject: () => void;
}

export const {
  context: playerContext,
  withContext: withPlayerContext,
  context: { Provider: BasePlayerProvider },
  context: { Consumer: PlayerConsumer },
} = createContext<PlayerContext>('VerticalPlayer', {
  playToken: null,
  activeSegment: null,
  loadingNext: false,
  playing: false,
  muted: false,
  currentTime: 0,
  remainingTime: 0,
  duration: 0,
  audioErrors: 0,
  scheduledPause: false,
  audioVolume: 0,
  output: null,
  autoplayOnLoad: false,
  autoplayOverride: false,
  error: false,
  playerMode: PlayerMode.Init,

  playSegment: () => {},
  changeSegment: () => {},
  playTrack: () => {},
  seekTo: () => {},
  next: () => {},
  previous: () => {},
  togglePause: () => {},
  pause: () => {},
  schedulePause: () => {},
  increaseVolume: () => {},
  decreaseVolume: () => {},
  setVolume: (volume: number) => {},
  toggleMute: () => {},
  sendAnalyticsEvent: () => {},
  showModal: () => {},
  closeModal: () => {},
  onFiltersChange: () => {},
  setPlayerMode: (playerMode: PlayerMode) => {},
  skipBack: () => {},
  skipForward: () => {},

  modalOpen: false,
  modalMessage: '',
  showModalButton: true,
});

export default playerContext;

interface PublicProps {
  client: ApolloClient<any>;
}

type Props = PublicProps & GrqphQLProps & PropsFromContext;

const MediaErrors: { [key: string]: string } = {
  [MediaError.MEDIA_ERR_ABORTED.toString()]: 'Aborted',
  [MediaError.MEDIA_ERR_DECODE.toString()]: 'Decode',
  [MediaError.MEDIA_ERR_NETWORK.toString()]: 'Network',
  [MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED.toString()]: 'Source Not Supported',
  unknown: 'unknown',
};

const MODAL_STYLES = {
  overlay: {
    zIndex: 6,
    backgroundColor: `rgba(0, 0, 0, 0.8)`,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: theme.colors.bgSections,
    color: theme.colors.textParagraph,
    borderColor: theme.colors.bgSections,
  },
};

class PlayerProviderImplementation extends React.Component<Props, PlayerContext> {
  state: PlayerContext = {
    playToken: null,
    activeSegment: null,
    loadingNext: false,
    playing: false,
    muted: false,
    currentTime: 0,
    remainingTime: 0,
    duration: 0,
    audioErrors: 0,
    scheduledPause: false,
    audioVolume: 0,
    output: null,
    autoplayOnLoad: false,
    autoplayOverride: false,
    error: false,
    playerMode: PlayerMode.Init,

    // We wrap because state is initialized before the rest of the class.
    playSegment: (segment: SetActiveSegmentVariables) => this.playSegment(segment),
    changeSegment: (id: string) => this.changeSegment(id),
    playTrack: (track: BasicTrackFragment) => this.playTrack(track),
    seekTo: (seconds: number) => this.seekTo(seconds),
    next: () => {
      addBreadcrumb(`CN:${Math.floor(performance.now())}`);
      this.next({ source: 'playerControls' }).catch(() => {});
    },
    previous: () => this.previous({ source: 'playerControls' }),
    togglePause: () => this.togglePause(),
    pause: () => this.pause(),
    schedulePause: (scheduledPause: boolean) => this.schedulePause(scheduledPause),
    increaseVolume: () => this.increaseVolume(),
    decreaseVolume: () => this.decreaseVolume(),
    setVolume: (volume: number) => this.setVolume(volume),
    toggleMute: () => this.toggleMute(),
    onFiltersChange: () => {
      if (this.props.advanceOnFilterChange) {
        addBreadcrumb('Filter Next');
        this.next({ source: 'filterChange' });
      }
    },
    skipBack: (seconds: number) => this.skipBack(seconds),
    skipForward: (seconds: number) => this.skipForward(seconds),

    sendAnalyticsEvent: (type: AnalyticsEventType, playToken?: PlayTokenFragment | null, source?: string) =>
      this.sendAnalyticsEvent(type, playToken, source),

    showModal: (el, showButtons) => {
      this.setState({
        modalOpen: true,
        modalMessage: el,
        showModalButton: showButtons,
      });
    },
    closeModal: () => this.closeModal(),
    setPlayerMode: (playerMode: PlayerMode) => {
      console.debug(`Player Mode: ${playerMode}`);
      this.state.playerMode = playerMode;
    },

    // Actually private
    modalOpen: false,
    modalMessage: '',
    showModalButton: true,
  };

  public static getDerivedStateFromProps(props: Props, state: PlayerContext) {
    const playToken = props.data && props.data.currentUser && props.data.currentUser.currentToken;
    const activeSegment = props.data && props.data.currentUser && props.data.currentUser.activeSegment;
    if (playToken !== state.playToken) {
      console.debug('GetDerivedStateFromProps', playToken);
      return {
        ...state,
        activeSegment,
        playToken,
      };
    } else if (activeSegment !== state.activeSegment) {
      return {
        ...state,
        activeSegment,
      };
    }
    return null;
  }

  private audio: HTMLAudioElement = new Audio();
  private imageCache: HTMLImageElement = new Image();
  private activePromises: PromiseParts[] = [];
  private needsNotify: boolean = false;
  private needsAnalytics: boolean = false;

  // Timers/Timeouts
  private audioFadeTimer?: number;
  private loadTimeout?: number;
  private restartTrackTimer?: number;
  private playedTrackUpdate?: number;
  private nowPlayingUpdate?: number;
  private notifyTimer?: number;
  private heartbeatTimer?: number;
  private lastNextTime: number = 0;

  constructor(props: Props) {
    super(props);
    this.audio.preload = 'auto';
    this.audio.crossOrigin = 'anonymous';
    this.heartbeatTimer = setInterval(this.heartbeat, 5 * 60 * 1000);
  }

  public async componentDidMount() {
    this.audio.addEventListener('timeupdate', this.updateTime);
    this.audio.addEventListener('error', this.handleAudioError);
    this.audio.addEventListener('play', this.handlePlay);
    this.audio.addEventListener('ended', this.handleEnded, false);
    this.audio.addEventListener('loadedmetadata', this.handleMetadata);
    this.setVolume(this.props.audioVolume, true);

    this.setAudioSink();
    if (this.state.playToken) {
      this.load(this.state.playToken);
    }
  }

  public componentWillUnmount(): void {
    delete this.audio.src;
    this.audio.load();
    clearTimeout(this.audioFadeTimer);
    clearTimeout(this.loadTimeout);
    clearTimeout(this.restartTrackTimer);
    clearTimeout(this.playedTrackUpdate);
    clearTimeout(this.nowPlayingUpdate);
    clearTimeout(this.notifyTimer);
    clearTimeout(this.heartbeatTimer);
    this.audio.removeEventListener('timeupdate', this.updateTime);
    this.audio.removeEventListener('error', this.handleAudioError);
    this.audio.removeEventListener('play', this.handlePlay);
    this.audio.removeEventListener('ended', this.handleEnded, false);
    this.audio.removeEventListener('loadedmetadata', this.handleMetadata);
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<PlayerContext>): void {
    // TODO: Make sure audio output is set correctly
    if (
      (!prevState.playToken && this.state.playToken) ||
      (prevState.playToken && this.state.playToken && prevState.playToken.id !== this.state.playToken.id)
    ) {
      if (this.state.playToken) {
        this.load(this.state.playToken);
      }
    }
    if (prevProps.audioOutputDevice !== this.props.audioOutputDevice) {
      this.setAudioSink();
    }
  }

  public render() {
    if (this.props.data.error) {
      console.error('VerticalPlayer Context', this.props.data.error);
      Sentry.captureException(this.props.data.error);
    }

    return (
      <BasePlayerProvider value={this.state}>
        {this.props.children}
        <Modal
          buttons={[
            {
              color: 'outline',
              label: 'Close',
              onClick: () =>
                this.setState({
                  modalOpen: false,
                }),
            },
          ]}
          content={<div>{this.state.modalMessage}</div>}
          isOpen={this.state.modalOpen}
          title="Something went wrong"
          onClose={() =>
            this.setState({
              modalOpen: false,
            })
          }
          setIsOpen={() =>
            this.setState({
              modalOpen: false,
            })
          }
          heapid="modal-something-went-wrong"
          testid="modal-something-went-wrong"
        />
      </BasePlayerProvider>
    );
  }

  /************************ PRIVATES ***********************/

  private displayError(message: React.ReactNode) {
    this.setState({
      modalOpen: true,
      modalMessage: message,
    });
  }

  private closeModal = () => {
    this.setState({
      modalOpen: false,
      modalMessage: '',
      showModalButton: true,
    });
  };

  private playSegment(segment: SetActiveSegmentVariables) {
    if (this.state.activeSegment && this.state.activeSegment.id === segment.id) {
      if (!this.state.playing) {
        this.togglePause();
      }
    } else {
      console.debug('Playing SegmentView', segment);
      this.props
        .setActiveSegment(buildMutationInput(segment))
        .then(() => {
          addBreadcrumb('Segment Next');
          this.next();
        })
        .then(() => !this.state.playing && this.togglePause())
        .catch(() => console.error("Couldn't play segment"));
    }
  }

  private changeSegment(id: string) {
    if (this.state.activeSegment && this.state.activeSegment.id === id) return;
    this.props.setActiveSegment(buildMutationInput({ id }));
  }

  private playTrack(track: BasicTrackFragment) {
    if (this.state.playToken && this.state.playToken.track.id === track.id) return;
    console.debug('Playing Track', track);
    addBreadcrumb('Track Next');
    this.next({}, track.id)
      .then(() => !this.state.playing && this.togglePause())
      .catch(() => console.error("Couldn't play track"));
  }

  private updateTime = () => {
    if (!isNaN(this.audio.duration)) {
      const duration = Math.round(this.audio.duration);
      const currentTime = Math.round(this.audio.currentTime);
      const remainingTime = duration - currentTime;
      if (duration !== this.state.duration) {
        this.setState({ duration });
      }
      this.setState({ currentTime, remainingTime });
    }
  };

  private handleAudioError = () => {
    // We get an error event, but it's useless. the error object on this.audio is slightly more helpful.
    console.warn('Audio error received. MediaError Code:', this.audio.error.code);
    clearTimeout(this.playedTrackUpdate);
    Sentry.withScope(scope => {
      if (this.props.data.currentUser) {
        scope.setExtra('currentUser', this.props.data.currentUser.id);
      }
      scope.setExtra('url', this.audio.src);
      const code = this.audio.error ? this.audio.error.code.toString() : 'unknown';
      scope.setExtra('MediaErrorCode', code);
      const description = MediaErrors[code];
      Sentry.captureMessage(`Audio Playback Error - ${description}`, Severity.Error);
    });
    if (this.state.audioErrors < 5) {
      const delay = (this.state.audioErrors || 1) * 2 * 1000;
      this.setState({ audioErrors: this.state.audioErrors + 1 });
      setTimeout(() => {
        addBreadcrumb(`EN:${Math.floor(performance.now())} AE:${this.state.audioErrors}`);
        this.next();
      }, delay);
    }
  };

  sendAnalyticsEvent = (type: AnalyticsEventType, playToken?: PlayTokenFragment | null, source: string = 'player') => {
    if (!playToken) {
      playToken = this.state.playToken;
    }
    if (playToken) {
      Analytics.captureEvent({
        type,
        source,
        resource: {
          type: AnalyticsEventResourceType.Track,
          guid: playToken.track.id,
          token: playToken.id,
          source: {
            guid: playToken.source.guid || '',
            type: playToken.source.kind.toString().toLowerCase(),
            name: playToken.source.name || '',
          },
        },
        state: this.state.playing ? 'playing' : 'stopped',
        volume: this.state.audioVolume,
        playerMode: this.state.playerMode,
        playerResolution: {
          height: window.innerHeight,
          width: window.innerWidth,
        },
      });
    }
  };

  sendNotify = (token: string) => {
    console.debug('Notifying for token', token);
    return this.props.client.mutate<NotifyPlayToken, NotifyPlayTokenVariables>({
      mutation: notifyPlayTokenMutation,
      variables: { guid: token },
    });
  };

  handlePlay = () => {
    const playedToken = this.state.playToken;
    if (this.needsNotify) {
      clearTimeout(this.notifyTimer);
      this.notifyTimer = setTimeout(() => {
        if (playedToken && playedToken.id === (this.state.playToken && this.state.playToken.id) && this.state.playing) {
          this.sendNotify(playedToken.id);
          this.needsNotify = false;
        }
      }, this.props.notifyDelay * 1000);
    }
    if (this.needsAnalytics) {
      clearTimeout(this.playedTrackUpdate);
      this.playedTrackUpdate = setTimeout(() => {
        if (playedToken && playedToken.id === (this.state.playToken && this.state.playToken.id)) {
          this.sendAnalyticsEvent(AnalyticsEventType.Play, playedToken);
          this.needsAnalytics = false;
        }
      }, 5000);
    }
  };

  private handleEnded = () => {
    this.sendAnalyticsEvent(AnalyticsEventType.End, this.state.playToken);
    delete this.audio.src;
    this.audio.load();

    if (false) {
      //this.state.needsRefresh && !this.state.scheduledPause) {
      /* TODO: Implement App Refresh
      console.debug('PretzelPlayer: Executing app refresh');
      window.localStorage.setItem('pretzel_app_forcePlay', true);
      window.localStorage.setItem('pretzel_app_affirmation_skip', true);
      console.debug(`PretzelPlayer: forcePlay set to ${window.localStorage.getItem('pretzel_app_forcePlay')}`);
      if (this.props.browserOnline) {
        setTimeout(() => window.location.reload(true), 500);
      } else {
        window.removeEventListener('online', this.handleBrowserOnline);
        window.addEventListener('online', this.handleBrowserOnline);
      }
      */
    } else {
      if (this.state.scheduledPause) {
        this.setState({ playing: false });
        this.schedulePause(false);
      }
      addBreadcrumb('Ended Next');
      this.next({ ended: true }).catch(() => {});
    }
  };

  private handleMetadata = () => {
    this.updateTime();
    this.setState({ audioErrors: 0 });
  };

  private getNext(trackId?: string): Promise<PlayTokenFragment> {
    this.setState({ loadingNext: true });
    console.debug('Fetching next playtoken');
    // @ts-ignore
    return this.props.client
      .mutate<NextPlayToken>({
        mutation: nextPlayTokenQuery,
        variables: { trackId },
        update: (cache, mutationResult) => {
          let cacheData: RecentlyPlayedQuery;
          try {
            cacheData = cache.readQuery<RecentlyPlayedQuery>({
              query: recentlyPlayedGql,
            });
          } catch (e) {
            return;
          }
          const recentlyPlayed = [mutationResult.data.nextPlayToken.user.currentToken.track, ...cacheData.recentlyPlayed];
          cache.writeQuery({
            query: recentlyPlayedGql,
            data: { recentlyPlayed },
          });
        },
      })
      .then(result => {
        console.debug('Next Playtoken Finished Loading', result);
        if (result.data && result.data.nextPlayToken && result.data.nextPlayToken.errors && result.data.nextPlayToken.errors.length) {
          const error = result.data.nextPlayToken.errors[0];

          if (error.match(/premium/i)) {
            this.displayError(
              <div
                style={{
                  marginTop: theme.space.md,
                }}
              >
                <p>
                  Couldn't find any free songs to play. Level up to a Premium account on{' '}
                  <UpsellLink
                    style={{
                      color: theme.colors.textTitles,
                    }}
                  >
                    the Pretzel website
                  </UpsellLink>
                  .
                </p>
                <p>Try adjusting your filters, or choosing another Station, Artist or Album.</p>
              </div>
            );
          } else if (error.match(/at this time/)) {
            throw new Error('Unable to generate play token: Too Many Requests: ' + error);
          } else {
            this.displayError(
              <div
                style={{
                  marginTop: theme.space.md,
                }}
              >
                <p>Couldn't find any songs to play. Try adjusting your filters, or choosing another Station, Artist or Album.</p>
              </div>
            );
          }

          this.state.togglePause();
          this.setState({ playing: false });
          throw new Error('Unable to generate play token: ' + error);
        }
        return result;
      });
  }

  // @ts-ignore Ignored while unimplemented
  private getPrev(currentPlayToken: PlayToken): Promise<PlayToken> {
    // TODO: Implement
    // this.setState({ loadingNext: true });
    // return this.props.client.mutate<NextPlayTokenMutation>({ mutation: nextPlayTokenQuery });
  }

  private schedulePause(scheduledPause: boolean) {
    // TODO Implement again?
    //this.setState({ scheduledPause });
  }

  private pause = () => {
    this.smoothPause();
    this.setState({ playing: false });
  };

  private togglePause = () => {
    if (this.state.playing) {
      this.smoothPause();
    } else {
      this.smoothResume();
      this.handlePlay();
    }
    this.setState({ playing: !this.state.playing });
  };

  private seekTo = (seconds: number) => {
    if (isNaN(seconds) || seconds < 0 || seconds > this.audio.duration) return;
    this.audio.currentTime = seconds;
  };

  private skipBack = (seconds: number) => {
    if (this.audio.currentTime - seconds >= 0) {
      this.audio.currentTime -= seconds;
    } else {
      this.audio.currentTime = 0;
    }
  };

  private skipForward = (seconds: number) => {
    this.audio.currentTime += seconds;
  };

  private next = (event?: { ended?: boolean; boot?: boolean; fail?: boolean; source?: string }, trackId?: string): Promise<unknown> => {
    const currentTime = performance.now();
    const elapsedMs = currentTime - this.lastNextTime;
    if (elapsedMs < nextDebounceMs) {
      console.debug('Ignoring next call since elapsed ms', elapsedMs, 'is less than debounce', nextDebounceMs);
      return Promise.resolve();
    }
    this.lastNextTime = currentTime;

    console.debug('Advancing to Next', event, trackId);
    clearTimeout(this.restartTrackTimer);
    clearTimeout(this.playedTrackUpdate);
    clearTimeout(this.nowPlayingUpdate);
    clearTimeout(this.notifyTimer);

    if (!event || (!event.ended && !event.boot && !event.fail)) {
      this.sendAnalyticsEvent(AnalyticsEventType.Skip, this.state.playToken, 'playerControls');
    }

    delete this.audio.src;
    this.audio.preload = 'none';
    this.audio.load();
    /*
     if (event && event.boot && window.localStorage.getItem('pretzel_app_refreshPaused')) {
     console.debug('PretzelPlayer: Recovering from paused refresh, reloading current track');
     return this.getCurrent().then((data) => {
     if (data && data.audio_urls) {
     if (data.active_segment && this.props.activeData && this.props.activeData.resource && data.active_segment !== this.props.activeData.resource.guid)  {
     this.props.getActive();
     }
     this.load(data);
     }
     });
     } else {
     */
    return this.getNext(trackId).catch(e => {
      console.error(e);
      this.setState({ loadingNext: false });
      throw e;
    });
    // This seemed to be checking for some occasional errors that occurred in the api, don't need it right now.
    // if (data && data.audio_urls) {
    // Loading happens in componentDidUpdate() now
    //this.load(result.user.currentToken);
    /*
       } else {
       if (this.state.audioErrors < 5) {
       this.setState({audioErrors: this.state.audioErrors + 1});
       setTimeout(() => {
       this.next({fail: true});
       }, ((this.state.audioErrors || 1) * 2) * 1000);
       } else {
       this.props.applyPlaying(false);
       }
       }
       });
       */
    /*
     }
     */
  };

  // @ts-ignore
  private previous = (event: { source: string }) => {
    clearTimeout(this.restartTrackTimer);

    if (this.state.currentTime) {
      //} > 8) {
      this.restartTrackTimer = setTimeout(() => {
        this.sendAnalyticsEvent(AnalyticsEventType.Restart, this.state.playToken, event.source);
      }, 8 * 1000);
      this.audio.currentTime = 0;
    } else {
      // clearTimeout(this.playedTrackUpdate);
      // clearTimeout(this.nowPlayingUpdate);
      // clearTimeout(this.notifyTimer);
      // this.sendAnalyticsEvent(AnalyticsEventType.Previous, this.state.playToken, 'playerControls');
      //
      // delete this.audio.src;
      // this.audio.preload = 'none';
      // this.audio.load();
      // if (this.state.playToken) {
      //   return this.getPrev(this.state.playToken).then((token: PlayToken) => {
      //     if (token && token.track.self.cfUrls) {
      //       this.setState({ playToken: token });
      //     } else {
      //       this.setState({ playing: false });
      //     }
      //   });
      // } else {
      //   console.warn('Pretzel attempted to play previous, when no token loaded.');
      // }
    }
  };

  private load = (playToken: PlayTokenFragment) => {
    console.debug('PlayerContext Loading: ', playToken);
    /*
     // I think this is for if a user upgrades to premium while playing... we don't have to worry about that
     if ((this.props.premium && Object.keys(track.audio_urls).length === 1) || (!this.props.premium && Object.keys(track.audio_urls).length > 1)) {
     this.props.updateUserData();
     }
     */
    clearTimeout(this.playedTrackUpdate);

    if (playToken.track.artworkGuid) {
      delete this.imageCache.src;
      this.imageCache.src = toUrl(playToken.track.artworkGuid);
    }

    this.audio.preload = 'auto';
    const trackUrl = playToken.track.self.cfUrls[this.props.audioQuality] || playToken.track.self.cfUrls.normal;
    this.audio.src = trackUrl;

    const forcePlay = window.localStorage.getItem('pretzel_app_forcePlay');
    const refreshPaused = window.localStorage.getItem('pretzel_app_refreshPaused');
    const { playing, autoplayOnLoad, autoplayOverride } = this.state;

    console.debug('-- PretzelPlayer -- ');
    console.debug(
      `playing: ${playing}, autoplayOnLoad: ${autoplayOnLoad}, forcePlay: ${forcePlay}, refreshPaused: ${refreshPaused}, result: ${(playing ||
        autoplayOnLoad ||
        forcePlay) &&
        !refreshPaused}`
    );

    if (refreshPaused) {
      console.debug('PretzelPlayer: Reloaded into paused state intentionally');
      try {
        //this.audio.currentTime = window.localStorage.getItem('pretzel_app_refresh_time');
      } catch (e) {
        this.audio.currentTime = 0;
      }
      window.localStorage.removeItem('pretzel_app_refreshPaused');
      window.localStorage.removeItem('pretzel_app_refresh_time');
    } else if (playing || autoplayOnLoad || forcePlay) {
      console.debug('PretzelPlayer: Loaded track and about to play');
      if (forcePlay) {
        window.localStorage.removeItem('pretzel_app_forcePlay');
        console.debug('PretzelPlayer: Removed the forcePlay tracker');
      }
      if (!forcePlay && !playing && autoplayOverride) {
        // overrides the autoplay event when a param is passed through
      } else {
        this.audio.addEventListener('canplaythrough', this.handleCanPlayThrough);
        this.setState({ playing: true });
      }
    } else {
      console.debug('PretzelPlayer: Loaded into paused state by default');
    }

    this.needsNotify = true;
    this.needsAnalytics = true;
    this.setState({
      currentTime: 0,
      loadingNext: false,
    });
  };

  private handleCanPlayThrough = () => {
    try {
      this.audio.removeEventListener('canplaythrough', this.handleCanPlayThrough);
      if (this.state.playing) {
        this.smoothResume();
      }
    } catch (e) {
      // no-op, we are firing a next track while the player is being closed
    }
  };

  smoothPause = () => {
    new Promise((resolve, reject) => {
      this.activePromises.push({ resolve, reject });
      this.fadeVolume(0, false, resolve);
    })
      .then(() => {
        this.audio.pause();
      })
      .catch(e => {});
  };

  private smoothResume = () => {
    if (!this.state.playing) {
      this.audio.volume = 0;
    }
    try {
      this.handlePlayPromise(this.audio.play());
    } catch (e) {
      this.handlePlayPromise(this.audio.play());
    }
    this.fadeVolume(this.state.audioVolume, false);
  };

  /***************************** VOLUME *******************************/

  private setVolume = (vol: number, instant: boolean = false, faded: boolean = false, visible: boolean = true) => {
    let audioVolume = Math.round(vol * 100) / 100;
    audioVolume = Math.min(Math.max(audioVolume, 0), 1);

    if (!instant) {
      if (Math.abs(audioVolume - this.audio.volume) > 0.06) {
        clearInterval(this.audioFadeTimer);
        this.fadeVolume(audioVolume);
      } else {
        if (!faded) {
          clearInterval(this.audioFadeTimer);
        }
      }
    }
    this.audio.volume = audioVolume;
    if (visible) {
      if (this.activePromises.length > 0) {
        this.activePromises.shift()!.resolve();
      }
      // the player is the source-of-truth for volume
      this.setState({ audioVolume });
      // but we save it to settings so that it's there the next time we open up.
      this.props.setAudioVolume(audioVolume);
    }
  };

  private fadeVolume = (targetVolume: number, visible: boolean = true, resolve?: () => void) => {
    clearInterval(this.audioFadeTimer);
    const lengthCondition = resolve ? 1 : 0;
    if (this.activePromises.length > lengthCondition) {
      this.activePromises.shift()!.reject();
    }
    if (this.audio.volume === targetVolume) {
      if (resolve) {
        resolve();
      }
      return;
    }
    if (!this.state.playing && visible) {
      this.setVolume(targetVolume, true);
      if (resolve) {
        resolve();
      }
      return;
    }
    let volumeModifer: VolumeModifier;
    if (this.audio.volume < targetVolume) {
      volumeModifer = this.increaseVolume;
    } else {
      volumeModifer = this.decreaseVolume;
    }
    const diff = Math.abs(this.audio.volume - targetVolume);
    const steps = diff / 0.02;
    const timeOnRange = Math.min(diff * 100 * 25, 1000);
    const repeatTime = timeOnRange / steps;
    this.audioFadeTimer = setInterval(() => this.applyVolumeFade(volumeModifer, targetVolume, visible, resolve), repeatTime);
  };

  private applyVolumeFade = (volumeModifer: VolumeModifier, targetVolume: number, visible: boolean, resolve?: () => void) => {
    volumeModifer(false, true, visible);
    if (this.audio.volume !== targetVolume) {
      if (Math.abs(this.audio.volume - targetVolume) < 0.03) {
        clearInterval(this.audioFadeTimer);
        this.setVolume(targetVolume, true, true, visible);
        if (resolve) {
          resolve();
        }
      }
    } else {
      clearInterval(this.audioFadeTimer);
      if (resolve) {
        resolve();
      }
    }
  };

  increaseVolume = (instant = true, faded = false, visible = true) => {
    if (this.audio) {
      if (!this.state.playing && this.state.audioVolume !== this.audio.volume && visible) {
        this.audio.volume = this.state.audioVolume;
      }
      this.setVolume(this.audio.volume + 0.02, instant, faded, visible);
    }
  };

  decreaseVolume = (instant = true, faded = false, visible = true) => {
    if (this.audio) {
      if (!this.state.playing && this.state.audioVolume !== this.audio.volume && visible) {
        this.audio.volume = this.state.audioVolume;
      }
      this.setVolume(this.audio.volume - 0.02, instant, faded, visible);
    }
  };

  private toggleMute = () => {
    const muted = !this.state.muted;
    this.audio.muted = muted;
    this.setState({ muted });
  };

  private handlePlayPromise(p: Promise<void>) {
    p.catch(err => {
      console.warn('Playing audio failed:', err.message, err);
    });
  }

  private setAudioSink() {
    // @ts-ignore TODO: Add TS for `setSinkId`
    if (this.audio && this.audio.setSinkId && this.props.audioOutputDevice) {
      try {
        console.debug(`PretzelPlayer: Setting audio sink to ${this.props.audioOutputDevice}`);
        // @ts-ignore
        this.audio.setSinkId(this.props.audioOutputDevice).catch(e => {
          console.warn(`PretzelPlayer: Audio sink promise error - ${e.message}`);
        });
      } catch (e) {
        console.warn(`PretzelPlayer: Audio sink error - ${e.message}`);
      }
    }
  }

  private heartbeat = () => {
    this.sendAnalyticsEvent(AnalyticsEventType.Heartbeat, this.state.playToken, 'app');
  };
}

type VolumeModifier = (instant: boolean, faded: boolean, visible: boolean) => void;

function mapContextToProps(c: SettingsContext): PropsFromContext {
  return {
    advanceOnFilterChange: c.advanceOnFilterChange,
    audioQuality: c.audioQuality,
    audioVolume: c.audioVolume,
    audioOutputDevice: c.audioOutputDevice,
    setAudioVolume: c.setAudioVolume,
    notifyDelay: c.notifyDelay,
  };
}

export const PlayerProvider = compose(
  withSettingsContext(mapContextToProps),
  withApollo,
  graphql(currentPlayTokenQuery),
  withMutation(setActiveSegmentMutation, { name: 'setActiveSegment' })
  // @ts-ignore Move to hooks, this is screwy because withMutation seems to wipe out the types that are being composed.
)(PlayerProviderImplementation);
