import * as React from "react";

function SvgTrophy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M26.24 1.792V0H5.376v1.792H0v1.184c.062 2.448.574 4.76 1.455 6.879l-.047-.127c1.472 3.871 4.792 6.735 8.852 7.539l.076.013a9.26 9.26 0 001.391 1.394l.017.014v2.592h2.176v5.344h-2.176v2.208h-2.4V32h12.928v-3.168h-2.4v-2.208h-1.984V21.28h2.176v-2.592a9.26 9.26 0 001.394-1.391l.014-.017a11.985 11.985 0 008.901-7.47l.027-.082c.834-1.992 1.346-4.304 1.407-6.728V1.792zM3.776 8.928A17.856 17.856 0 012.6 4.244l-.008-.084H5.76c.299 3.665 1.224 7.045 2.668 10.133l-.076-.181a9.883 9.883 0 01-4.553-5.117l-.023-.067zm24.256-.192a11.175 11.175 0 01-4.527 5.348l-.049.028c1.463-2.9 2.399-6.295 2.589-9.889l.003-.063h3.168a11.9 11.9 0 01-1.215 4.646l.031-.07z" />
    </svg>
  );
}

export default SvgTrophy;
