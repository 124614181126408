import { useEffect } from 'react';
import { flowRight as compose } from 'lodash';
import { PretzelUserDetails, withPlatformContext } from '../Platform';

declare var stonlyTrack: any;

interface IProps {
  userDetails: PretzelUserDetails['user'];
  userSubscription: PretzelUserDetails['subscription'];
}

const doesStonlyExist = () => typeof stonlyTrack !== 'undefined' && stonlyTrack;

if (doesStonlyExist() && process.env.REACT_APP_STONLY_ID) {
  stonlyTrack('init', process.env.REACT_APP_STONLY_ID);
}

const Stonly = ({ userDetails, userSubscription }: IProps): null => {
  useEffect(() => {
    if (!doesStonlyExist() || !userDetails) {
      return;
    }

    const args = {
      email: userDetails.email,
      userguid: userDetails.guid,
      plantype: userDetails.tier,
      createdatdate: userDetails.created_at,
      longhash: userDetails.hash,
    };

    if (userSubscription) {
      args['firstbillingdate'] = new Date(userSubscription.first_billing_date).toISOString();
    }

    stonlyTrack('identify', userDetails.guid, args);
  }, [userDetails, userSubscription]);

  return null;
};

export default compose(
  withPlatformContext(c => ({
    userDetails: c.pretzelUserDetails?.user,
    userSubscription: c.pretzelUserDetails?.subscription,
  }))
)(Stonly);
