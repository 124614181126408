import * as React from 'react';
import { ApolloClient } from '@apollo/client';
import { graphql, QueryControls, withApollo, withMutation } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { buildMutationInput, Mutation } from '../GraphQL';
import { createContext } from '../../Util';
import { PlatformContext, withPlatformContext } from '../Platform';
import { getBoolean, getInt, getNumber, getString, getValueOrDefault, setBoolean, setInt, setNumber, setString } from './LocalStore';
import { KeybindConfiguration } from '../../Components/Settings/Sections/Keybinds/KeyAction';

import { GetSettingsQuery } from './Queries/types/GetSettingsQuery';
import { SetSettings, SetSettingsVariables } from './Queries/types/SetSettings';
import { Settings } from './Queries/types/Settings';
import ABTesting from '../ABTesting';

const GetSettingsQ = loader('./Queries/get-settings.graphql');
const SetSettingsQ = loader('./Queries/set-settings.graphql');

export enum AudioQuality {
  'Auto' = 'auto',
  'VeryHigh' = 'veryHigh',
  'High' = 'high',
  'Normal' = 'normal',
  'Low' = 'low',
}

export enum DownloadFileType {
  MP3 = 'download_mp3',
  WAV = 'download_wav',
}

function getAudioQuality(key: string): AudioQuality | null {
  const value = getString(key);
  switch (value) {
    case AudioQuality.Auto:
      return AudioQuality.Auto;
    case AudioQuality.Normal:
      return AudioQuality.Normal;
    case AudioQuality.High:
      return AudioQuality.High;
    case AudioQuality.VeryHigh:
      return AudioQuality.VeryHigh;
    case AudioQuality.Low:
      return AudioQuality.Low;
  }
  return null;
}

export enum SettingsKeys {
  /** General **/
  DailyAffirmations = 'dailyAffirmations',
  AdvanceOnFilterChange = 'advanceOnFilterChange',
  DownloadFileType = 'downloadFileType',

  /** Filters **/
  InstrumentalOnly = 'instrumentalOnly',
  AllowExplicit = 'allowExplicit',
  YoutubeSafe = 'youtubeSafe',

  /** Chat Notification **/
  NotifyInChat = 'notifyInChat',
  NotifyOnlyWhenLive = 'notifyOnlyWhenLive',
  NotifyDelay = 'notifyDelay',

  /** Audio **/
  AudioOutputDevice = 'audioOutputDevice',
  AudioQuality = 'audioQuality',
  AudioVolume = 'audioVolume',

  /** Hot Keys **/
  BindMediaHotkeys = 'bindMediaHotkeys',
  GlobalHotKeyConfiguration = 'globalHotKeyConfiguration',

  /** File Output **/
  WriteTrackInfoToFile = 'writeTrackInfoToFile',
  WriteTrackInfoFile = 'writeTrackInfoFile',
  WriteTrackJsonToFile = 'writeTrackJsonToFile',
  WriteTrackJsonFile = 'writeTrackJsonFile',
  WriteToFileFormat = 'writeToFileFormat',
  WriteToFilePaused = 'writeToFilePaused',
  WriteToFilePausedFormat = 'writeToFilePausedFormat',
  WriteCoverToFile = 'writeCoverToFile',
  WriteCoverFile = 'writeCoverFile',

  /** Song Requests **/
  SongRequestNotifyOnRequest = 'songRequestNotifyOnRequest',
  SongRequestNotifyOnRequestAlert = 'songRequestNotifyOnRequestAlert',
  SongRequestNotifyOnRequestVolume = 'songRequestNotifyOnRequestVolume',
  SongRequestNotifyOnBoost = 'songRequestNotifyOnBoost',
  SongRequestNotifyOnBoostAlert = 'songRequestNotifyOnBoostAlert',
  SongRequestNotifyOnBoostVolume = 'songRequestNotifyOnBoostVolume',
  SongRequestQueueVisible = 'songRequestQueueVisible',
}

export interface SettingsData {
  /** General **/
  [SettingsKeys.DailyAffirmations]: boolean;
  [SettingsKeys.AdvanceOnFilterChange]: boolean;
  [SettingsKeys.DownloadFileType]: string;

  /** Filters **/
  [SettingsKeys.AllowExplicit]: boolean;
  [SettingsKeys.InstrumentalOnly]: boolean;
  [SettingsKeys.YoutubeSafe]: boolean;

  /** Chat Notification **/
  [SettingsKeys.NotifyDelay]: number;
  [SettingsKeys.NotifyInChat]: boolean;
  [SettingsKeys.NotifyOnlyWhenLive]: boolean;

  /** Audio **/
  [SettingsKeys.AudioOutputDevice]: string;
  [SettingsKeys.AudioQuality]: AudioQuality;
  [SettingsKeys.AudioVolume]: number;

  /** Hot Keys **/
  [SettingsKeys.BindMediaHotkeys]: boolean;
  [SettingsKeys.GlobalHotKeyConfiguration]: KeybindConfiguration[];

  /** File Output **/
  [SettingsKeys.WriteTrackInfoToFile]: boolean;
  [SettingsKeys.WriteTrackInfoFile]: string;
  [SettingsKeys.WriteTrackJsonToFile]: boolean;
  [SettingsKeys.WriteTrackJsonFile]: string;
  [SettingsKeys.WriteToFileFormat]: string;
  [SettingsKeys.WriteToFilePaused]: boolean;
  [SettingsKeys.WriteToFilePausedFormat]: string;
  [SettingsKeys.WriteCoverToFile]: boolean;
  [SettingsKeys.WriteCoverFile]: string;

  /** Song Requests **/
  [SettingsKeys.SongRequestNotifyOnRequest]: boolean;
  [SettingsKeys.SongRequestNotifyOnRequestAlert]: string;
  [SettingsKeys.SongRequestNotifyOnRequestVolume]: number;
  [SettingsKeys.SongRequestNotifyOnBoost]: boolean;
  [SettingsKeys.SongRequestNotifyOnBoostAlert]: string;
  [SettingsKeys.SongRequestNotifyOnBoostVolume]: number;
  [SettingsKeys.SongRequestQueueVisible]: boolean;
}

export interface SystemData {
  audioOutputList: AudioOutput[];
}

export interface SettingsSetters {
  /** General **/
  setDailyAffirmations: (value: boolean) => void;
  setAdvanceOnFilterChange: (value: boolean) => void;
  setDownloadFileType: (value: DownloadFileType) => void;

  /** Filters **/
  setAllowExplicit: (value: boolean) => void;
  setInstrumentalOnly: (value: boolean) => void;
  setYoutubeSafe: (value: boolean) => void;

  /** Chat Notification **/
  setNotifyDelay: (value: number) => void;
  setNotifyInChat: (value: boolean) => void;
  setNotifyOnlyWhenLive: (value: boolean) => void;

  /** Audio **/
  refreshAudioOutputList: () => void;
  setAudioOutputDevice: (value: string) => void;
  setAudioQuality: (value: AudioQuality) => void;
  setAudioVolume: (value: number) => void;

  /** Hot Keys **/
  setBindMediaHotkeys: (value: boolean) => void;
  setGlobalHotKeys: (value: KeybindConfiguration[]) => void;

  /** File Output **/
  setWriteTrackInfoToFile: (value: boolean) => void;
  setWriteTrackInfoFile: (value: string) => void;
  setWriteTrackJsonToFile: (value: boolean) => void;
  setWriteTrackJsonFile: (value: string) => void;
  setWriteToFileFormat: (value: string) => void;
  setWriteToFilePaused: (value: boolean) => void;
  setWriteToFilePausedFormat: (value: string) => void;
  setWriteCoverToFile: (value: boolean) => void;
  setWriteCoverFile: (value: string) => void;

  /** Song Requests **/
  setSongRequestNotifyOnRequest: (value: boolean) => void;
  setSongRequestNotifyOnRequestAlert: (value: string) => void;
  setSongRequestNotifyOnRequestVolume: (value: number) => void;
  setSongRequestNotifyOnBoost: (value: boolean) => void;
  setSongRequestNotifyOnBoostAlert: (value: string) => void;
  setSongRequestNotifyOnBoostVolume: (value: number) => void;
  setSongRequestQueueVisible: (value: boolean) => void;
}

export type SettingsContext = SettingsData & SettingsSetters & SystemData;

export interface AudioOutput {
  label: string;
  device: MediaDeviceInfo;
}

function getAudioOutputList(): Promise<AudioOutput[]> {
  try {
    return navigator.mediaDevices.enumerateDevices().then(devices => {
      return devices
        .filter(device => device.kind === 'audiooutput')
        .map((device, index) => {
          console.log(device);
          return {
            label: device.label || `Device ${index + 1}`,
            device,
          };
        });
    });
  } catch (e) {
    console.warn('Error enumerating audio devices');
  }
  return Promise.resolve([]);
}

export const {
  context: settingsContext,
  withContext: withSettingsContext,
  context: { Provider: SettingsContextProvider },
} = createContext<SettingsContext>('Settings');

export default settingsContext;

interface PublicProps {
  client: ApolloClient<any>;
}

type PlatformContextProps = Pick<PlatformContext, 'loadData' | 'saveData'>;

interface GraphQLProps {
  data: QueryControls & GetSettingsQuery;
  setSettings: Mutation<SetSettingsVariables, SetSettings>;
}

type Props = PublicProps & GraphQLProps & PlatformContextProps;

export class SettingsProviderImplementation extends React.Component<Props, SettingsContext> {
  private getPlatformSetter = <K extends keyof SettingsData>(storeKey: SettingsKeys) => {
    return (value: SettingsContext[K]) => {
      if (typeof value === 'string') {
        this.props.saveData(storeKey, value);
      }
      this.setState({ [storeKey]: value } as Pick<SettingsContext, K>);
    };
  };

  private storeGlobalHotKeys = (configuration: KeybindConfiguration[]) => {
    const encoded = JSON.stringify(configuration);
    this.props.saveData(SettingsKeys.GlobalHotKeyConfiguration, encoded);
    this.setState({ [SettingsKeys.GlobalHotKeyConfiguration]: configuration } as Pick<SettingsContext, SettingsKeys.GlobalHotKeyConfiguration>);
  };

  private getLocalSetter = <K extends keyof SettingsData>(storeKey: SettingsKeys) => {
    return (value: SettingsContext[K]) => {
      if (typeof value === 'string') {
        setString(storeKey, value);
      } else if (typeof value === 'number') {
        setNumber(storeKey, value);
      } else if (typeof value === 'boolean') {
        setBoolean(storeKey, value);
      }
      this.setState({ [storeKey]: value } as Pick<SettingsContext, K>);
    };
  };

  private getServerSetter = <K extends keyof SettingsData>(storeKey: SettingsKeys) => {
    return (value: SettingsContext[K]) => {
      this.mutateSetting({ [storeKey]: value });
    };
  };

  state = {
    /** General **/
    dailyAffirmations: getValueOrDefault(getBoolean, SettingsKeys.DailyAffirmations, true),
    setDailyAffirmations: this.getServerSetter(SettingsKeys.DailyAffirmations),
    advanceOnFilterChange: getValueOrDefault(getBoolean, SettingsKeys.AdvanceOnFilterChange, false),
    setAdvanceOnFilterChange: this.getServerSetter(SettingsKeys.AdvanceOnFilterChange),
    downloadFileType: getValueOrDefault(getString, SettingsKeys.DownloadFileType, DownloadFileType.MP3),
    setDownloadFileType: this.getServerSetter(SettingsKeys.DownloadFileType),

    /** Filters **/
    allowExplicit: getValueOrDefault(getBoolean, SettingsKeys.AllowExplicit, false),
    setAllowExplicit: this.getServerSetter(SettingsKeys.AllowExplicit),
    instrumentalOnly: getValueOrDefault(getBoolean, SettingsKeys.InstrumentalOnly, false),
    setInstrumentalOnly: this.getServerSetter(SettingsKeys.InstrumentalOnly),
    youtubeSafe: getValueOrDefault(getBoolean, SettingsKeys.YoutubeSafe, false),
    setYoutubeSafe: this.getServerSetter(SettingsKeys.YoutubeSafe),

    /** Chat Notification **/
    notifyDelay: getValueOrDefault(getInt, SettingsKeys.NotifyDelay, 10),
    setNotifyDelay: this.getServerSetter(SettingsKeys.NotifyDelay),
    notifyInChat: getValueOrDefault(getBoolean, SettingsKeys.NotifyInChat, false),
    setNotifyInChat: this.getServerSetter(SettingsKeys.NotifyInChat),
    notifyOnlyWhenLive: getValueOrDefault(getBoolean, SettingsKeys.NotifyOnlyWhenLive, false),
    setNotifyOnlyWhenLive: this.getServerSetter(SettingsKeys.NotifyOnlyWhenLive),

    /** Audio **/
    audioOutputList: [] as AudioOutput[],
    refreshAudioOutputList: () => this.refreshAudioOutputList(),
    audioOutputDevice: getValueOrDefault(getString, SettingsKeys.AudioOutputDevice, 'default'),
    setAudioOutputDevice: this.getPlatformSetter(SettingsKeys.AudioOutputDevice),
    audioQuality: getValueOrDefault(getAudioQuality, SettingsKeys.AudioQuality, AudioQuality.Auto),
    setAudioQuality: (value: AudioQuality) => this.setAudioQuality(value),
    audioVolume: getValueOrDefault(getNumber, SettingsKeys.AudioVolume, 0.3),
    setAudioVolume: this.getLocalSetter(SettingsKeys.AudioVolume),

    /** Hot Keys **/
    bindMediaHotkeys: getValueOrDefault(getBoolean, SettingsKeys.BindMediaHotkeys, true),
    setBindMediaHotkeys: this.getLocalSetter(SettingsKeys.BindMediaHotkeys),
    globalHotKeyConfiguration: [] as KeybindConfiguration[],
    setGlobalHotKeys: this.storeGlobalHotKeys,

    /** File Output **/
    writeTrackInfoToFile: getValueOrDefault(getBoolean, SettingsKeys.WriteTrackInfoToFile, false),
    setWriteTrackInfoToFile: this.getLocalSetter(SettingsKeys.WriteTrackInfoToFile),
    writeTrackInfoFile: getValueOrDefault(getString, SettingsKeys.WriteTrackInfoFile, ''),
    setWriteTrackInfoFile: this.getLocalSetter(SettingsKeys.WriteTrackInfoFile),
    writeTrackJsonToFile: getValueOrDefault(getBoolean, SettingsKeys.WriteTrackJsonToFile, false),
    setWriteTrackJsonToFile: this.getLocalSetter(SettingsKeys.WriteTrackJsonToFile),
    writeTrackJsonFile: getValueOrDefault(getString, SettingsKeys.WriteTrackJsonFile, ''),
    setWriteTrackJsonFile: this.getLocalSetter(SettingsKeys.WriteTrackJsonFile),
    writeToFileFormat: getValueOrDefault(getString, SettingsKeys.WriteToFileFormat, '{title} - {artist}'),
    setWriteToFileFormat: this.getLocalSetter(SettingsKeys.WriteToFileFormat),
    writeToFilePaused: getValueOrDefault(getBoolean, SettingsKeys.WriteToFilePaused, false),
    setWriteToFilePaused: this.getLocalSetter(SettingsKeys.WriteToFilePaused),
    writeToFilePausedFormat: getValueOrDefault(getString, SettingsKeys.WriteToFilePausedFormat, ''),
    setWriteToFilePausedFormat: this.getLocalSetter(SettingsKeys.WriteToFilePausedFormat),
    writeCoverToFile: getValueOrDefault(getBoolean, SettingsKeys.WriteCoverToFile, false),
    setWriteCoverToFile: this.getLocalSetter(SettingsKeys.WriteCoverToFile),
    writeCoverFile: getValueOrDefault(getString, SettingsKeys.WriteCoverFile, ''),
    setWriteCoverFile: this.getLocalSetter(SettingsKeys.WriteCoverFile),

    /** Song Requests **/
    songRequestNotifyOnRequest: getValueOrDefault(getBoolean, SettingsKeys.SongRequestNotifyOnRequest, true),
    setSongRequestNotifyOnRequest: this.getLocalSetter(SettingsKeys.SongRequestNotifyOnRequest),
    songRequestNotifyOnRequestAlert: getValueOrDefault(getString, SettingsKeys.SongRequestNotifyOnRequestAlert, 'default'),
    setSongRequestNotifyOnRequestAlert: this.getLocalSetter(SettingsKeys.SongRequestNotifyOnRequestAlert),
    songRequestNotifyOnRequestVolume: getValueOrDefault(getNumber, SettingsKeys.SongRequestNotifyOnRequestVolume, 1),
    setSongRequestNotifyOnRequestVolume: this.getLocalSetter(SettingsKeys.SongRequestNotifyOnRequestVolume),
    songRequestNotifyOnBoost: getValueOrDefault(getBoolean, SettingsKeys.SongRequestNotifyOnBoost, true),
    setSongRequestNotifyOnBoost: this.getLocalSetter(SettingsKeys.SongRequestNotifyOnBoost),
    songRequestNotifyOnBoostAlert: getValueOrDefault(getString, SettingsKeys.SongRequestNotifyOnBoostAlert, 'default'),
    setSongRequestNotifyOnBoostAlert: this.getLocalSetter(SettingsKeys.SongRequestNotifyOnBoostAlert),
    songRequestNotifyOnBoostVolume: getValueOrDefault(getNumber, SettingsKeys.SongRequestNotifyOnBoostVolume, 1),
    setSongRequestNotifyOnBoostVolume: this.getLocalSetter(SettingsKeys.SongRequestNotifyOnBoostVolume),
    songRequestQueueVisible: getValueOrDefault(getBoolean, SettingsKeys.SongRequestQueueVisible, true),
    setSongRequestQueueVisible: this.getLocalSetter(SettingsKeys.SongRequestQueueVisible),
  };
  ABTesting: ABTesting;

  constructor(props: Props) {
    super(props);
    getAudioOutputList().then(audioOutputList => {
      this.setState({
        audioOutputList,
      });
    });
    this.getSettingsFromPlatform();
    this.ABTesting = new ABTesting();
  }

  // Incoming GQL always overrides any local cache we have
  static getDerivedStateFromProps(props: Props, state: SettingsContext): SettingsContext | null {
    if (!props.data.currentUser) return null;
    return {
      ...state,
      ...props.data.currentUser.settings,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<SettingsContext>, snapshot?: any): void {
    if (prevState.allowExplicit !== this.state.allowExplicit) {
      setBoolean(SettingsKeys.AllowExplicit, this.state.allowExplicit);
    }
    if (prevState.dailyAffirmations !== this.state.dailyAffirmations) {
      setBoolean(SettingsKeys.DailyAffirmations, this.state.dailyAffirmations);
    }
    if (prevState.instrumentalOnly !== this.state.instrumentalOnly) {
      setBoolean(SettingsKeys.InstrumentalOnly, this.state.instrumentalOnly);
    }
    if (prevState.notifyDelay !== this.state.notifyDelay) {
      setInt(SettingsKeys.NotifyDelay, this.state.notifyDelay);
    }
    if (prevState.notifyInChat !== this.state.notifyInChat) {
      setBoolean(SettingsKeys.NotifyInChat, this.state.notifyInChat);
    }
    if (prevState.notifyOnlyWhenLive !== this.state.notifyOnlyWhenLive) {
      setBoolean(SettingsKeys.NotifyOnlyWhenLive, this.state.notifyOnlyWhenLive);
    }
    if (prevState.youtubeSafe !== this.state.youtubeSafe) {
      setBoolean(SettingsKeys.YoutubeSafe, this.state.youtubeSafe);
    }
    if (prevState.downloadFileType !== this.state.downloadFileType) {
      setString(SettingsKeys.DownloadFileType, this.state.downloadFileType);
    }
  }

  public render() {
    return <SettingsContextProvider value={this.state}>{this.props.children}</SettingsContextProvider>;
  }

  /** Local-Only setters **/
  private setAudioQuality(value: AudioQuality) {
    setString(SettingsKeys.AudioQuality, value);
    this.setState({ [SettingsKeys.AudioQuality]: value });
  }

  private refreshAudioOutputList() {
    getAudioOutputList().then(audioOutputList => {
      this.setState({ audioOutputList });
    });
  }

  /** GQL Setters **/

  private mutateSetting(settings: Partial<Settings>) {
    this.props.setSettings(
      buildMutationInput({
        attributes: {
          ...settings,
        },
      })
    );
  }

  private async getSettingsFromPlatform() {
    try {
      const audioOutputDevice = await this.props.loadData(SettingsKeys.AudioOutputDevice);
      const globalHotkeyConfigurationString = await this.props.loadData(SettingsKeys.GlobalHotKeyConfiguration);
      let globalHotkeyConfig: KeybindConfiguration[] = [];
      try {
        globalHotkeyConfig = JSON.parse(globalHotkeyConfigurationString || 'false') || [];
      } catch (e) {
        console.error('Failed to load global hotkeys. Data:', globalHotkeyConfigurationString, 'Error:', e);
      }
      this.setState({
        [SettingsKeys.AudioOutputDevice]: audioOutputDevice,
        [SettingsKeys.GlobalHotKeyConfiguration]: globalHotkeyConfig,
      });
    } catch (e) {
      console.log('Could not load settings from platform:', e);
    }
  }
}

function mapContextToProps(c: PlatformContext): PlatformContextProps {
  return {
    loadData: c.loadData,
    saveData: c.saveData,
  };
}

export const SettingsProvider = compose(
  withApollo,
  withPlatformContext(mapContextToProps),
  graphql<{}, GetSettingsQuery>(GetSettingsQ),
  withMutation(SetSettingsQ, { name: 'setSettings' })
)(SettingsProviderImplementation);
