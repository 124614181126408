import { Box, Image } from 'grommet';
import React, { useContext } from 'react';
import { ArtistsListQuery_artists_edges_node } from '../types/ArtistsListQuery';
import { Heading } from '../../Heading';
import platformContext, { PlatformContext } from '../../../Core/Platform';
import playerContext, { PlayerContext } from '../../../Core/Player/PlayerContext';
import { CardType, MusicCard, theme } from '../../../Styled';
import { ArtworkSize, minutesOrHours, toUrl } from '../../../Util';
import { isUserEntitled } from '../../../Util/Entitlements';

type Props = {
  artist: ArtistsListQuery_artists_edges_node;
  artworkSize?: ArtworkSize;
};

export const ArtistCard = ({ artist, artworkSize }: Props): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const playerContextObj = useContext<PlayerContext>(playerContext);
  const isPlaying = playerContextObj.activeSegment && playerContextObj.activeSegment.id === artist.id;

  const handleClick = (): void => {
    if (playerContextObj.playing && playerContextObj.activeSegment.id === artist.id) {
      playerContextObj.pause();
    } else {
      playerContextObj.playSegment({ id: artist.id });
    }
  };

  const art = (
    <Image
      fit="cover"
      src={toUrl(artist.artworkGuid, artworkSize ?? ArtworkSize.Medium)}
      style={{ borderRadius: theme.card.borderRadius }}
      width="100%"
    />
  );

  const text = (
    <Box margin={`${theme.space.md} 0 0 0`}>
      <Heading variant="H4">{artist.name}</Heading>
    </Box>
  );

  return (
    <MusicCard
      art={art}
      text={text}
      active={isPlaying}
      isPlaying={playerContextObj.playing}
      linkTo={`/segment/${artist.id}`}
      onClick={handleClick}
      meta={{
        tracks: artist.stats.totalCount,
        hours: minutesOrHours(artist.stats.duration),
      }}
      cardType={CardType.Artist}
      cardName={artist.name}
      isLocked={!isUserEntitled(platformContextObj.pretzelUser.jwt.tier, artist.entitlements)}
      size="100%"
    />
  );
};
