import * as React from 'react';
import { Button } from '../Components/Button';

interface ButtonProperties {
  onMore: () => void;
  loading: boolean;
}

export const LoadMoreButton = (props: ButtonProperties) => (
  <Button color="primary" disabled={props.loading} id="button-load-more" onClick={props.onMore}>
    {props.loading ? 'Loading...' : 'Load More'}
  </Button>
);
