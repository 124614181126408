import * as React from "react";

function SvgChristmasSnowflake(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M22.519 14.5a1 1 0 00-1.361-.39l-1.54.854a.5.5 0 01-.492 0l-1.876-1.087a.5.5 0 01-.25-.433v-2.888a.5.5 0 01.25-.433l1.876-1.083a.5.5 0 01.492 0l1.54.854a1 1 0 10.971-1.749l-.351-.194a.25.25 0 010-.435l.252-.146a1 1 0 10-1-1.732l-.252.145a.25.25 0 01-.375-.22l.007-.4a1 1 0 00-.982-1.017 1.025 1.025 0 00-1.018.983l-.03 1.76a.5.5 0 01-.25.424l-1.852 1.062a.5.5 0 01-.514-.008L13.236 6.8A.5.5 0 0113 6.371V4.348a.5.5 0 01.243-.428l1.509-.906A1 1 0 0013.723 1.3l-.344.206A.25.25 0 0113 1.291V1a1 1 0 00-2 0v.291a.25.25 0 01-.379.214l-.344-.205a1 1 0 00-1.029 1.714l1.509.906a.5.5 0 01.243.428v2.023a.5.5 0 01-.236.425L8.236 8.367a.5.5 0 01-.514.008L5.874 7.308a.5.5 0 01-.25-.424l-.03-1.76a1 1 0 10-2 .034l.007.4a.25.25 0 01-.375.22l-.252-.145a1 1 0 10-1 1.732l.252.146a.25.25 0 010 .435l-.351.194a1 1 0 10.967 1.75l1.54-.854a.5.5 0 01.492 0l1.876 1.087a.5.5 0 01.25.433v2.888a.5.5 0 01-.25.433L4.874 14.96a.5.5 0 01-.492 0l-1.54-.854a1 1 0 10-.971 1.749l.351.194a.25.25 0 010 .435l-.252.146a1 1 0 101 1.732l.252-.145a.25.25 0 01.375.22l-.007.4a1 1 0 00.982 1.017h.018a1 1 0 001-.983l.03-1.76a.5.5 0 01.25-.424l1.848-1.067a.5.5 0 01.514.008l2.532 1.572a.5.5 0 01.236.425v2.023a.5.5 0 01-.243.428l-1.509.906a1 1 0 101.029 1.718l.344-.206a.25.25 0 01.379.214V23a1 1 0 002 0v-.291a.25.25 0 01.379-.214l.344.206a1 1 0 101.029-1.715l-1.509-.906a.5.5 0 01-.243-.428v-2.023a.5.5 0 01.236-.425l2.528-1.571a.5.5 0 01.514-.008l1.848 1.067a.5.5 0 01.25.424l.03 1.76a1 1 0 001 .983h.018a1 1 0 00.982-1.017l-.007-.4a.25.25 0 01.375-.22l.252.145a1 1 0 101-1.732l-.252-.146a.25.25 0 010-.435l.351-.194a1 1 0 00.394-1.36zm-10.255.954a.5.5 0 01-.528 0l-2.5-1.554A.5.5 0 019 13.476v-2.952a.5.5 0 01.236-.425l2.5-1.553a.5.5 0 01.528 0l2.5 1.553a.5.5 0 01.236.425v2.952a.5.5 0 01-.236.425z" />
    </svg>
  );
}

export default SvgChristmasSnowflake;
