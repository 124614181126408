import * as React from 'react';

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 4.9998C10 4.58558 9.66421 4.2498 9.25 4.2498L5.75019 4.2498L5.75019 0.75C5.75019 0.335787 5.4144 5.27567e-08 5.00019 0C4.58598 -5.27567e-08 4.25019 0.335786 4.25019 0.75L4.25019 4.2498L0.75 4.2498C0.335787 4.2498 2.11027e-07 4.58559 0 4.9998C2.11027e-07 5.41401 0.335787 5.7498 0.75 5.7498L4.25019 5.7498L4.25019 9.25C4.25019 9.66421 4.58598 10 5.00019 10C5.4144 10 5.75019 9.66421 5.75019 9.25V5.7498L9.25 5.7498C9.66421 5.7498 10 5.41401 10 4.9998Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgPlus;
