import axios from 'axios';

export const cancelPremium = async (plan: string): Promise<boolean> => {
  const options = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
    },
  };

  try {
    await axios.post(`https://api.pretzel.rocks/api/v1/${plan}/cancel`, { credentials: 'include' }, options);
    return true;
  } catch (error) {
    return false;
  }
};

export default cancelPremium;
