import * as React from "react";

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M9.408 25.312c0-.352.16-.736.352-.896l9.76-8.512-9.76-8.32c-.352-.352-.544-1.088-.192-1.632s1.088-.544 1.632-.16c0 0 .192 0 .192.16L22.24 15.36c.544.352.544 1.28 0 1.632L11.392 26.4c-.544.352-1.28.352-1.632-.192-.192-.352-.192-.704-.352-.896z" />
    </svg>
  );
}

export default SvgArrowRight;
