import * as React from "react";

function SvgHeadphonesPlug(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5.82 15.655a.252.252 0 00-.354 0l-3.107 3.11a3 3 0 000 4.241 3.071 3.071 0 004.243 0l3.181-3.182a.5.5 0 11.708.707l-1.683 1.683a1 1 0 101.414 1.414l1.678-1.682a2.5 2.5 0 10-3.536-3.535l-3.177 3.182a1.022 1.022 0 01-1.414 0 1 1 0 010-1.413l3.108-3.11a.25.25 0 000-.353zM22.5 1.284A1 1 0 0021.333.1l-1.045.2a.5.5 0 00-.26.137L17.862 2.6a.263.263 0 01-.078.053l-1.655.709a.25.25 0 00-.079.4l2.751 2.8a.25.25 0 00.408-.077l.722-1.685a.263.263 0 01.053-.078l2.166-2.168a.5.5 0 00.135-.25zM15.138 4.265a.251.251 0 00-.355 0l-2.122 2.12a.249.249 0 01-.261.058 1.541 1.541 0 00-.5-.087 1.492 1.492 0 00-1.061.44l-3.094 3.1a1.507 1.507 0 00-.158 1.935.251.251 0 01-.025.325l-1.211 1.202a1 1 0 000 1.415l1.414 1.414a1 1 0 001.414 0l1.2-1.2a.249.249 0 01.329-.021 1.5 1.5 0 001.981-.125l3.093-3.093a1.5 1.5 0 00.352-1.564.252.252 0 01.059-.261l2.1-2.1a.25.25 0 000-.352z" />
    </svg>
  );
}

export default SvgHeadphonesPlug;
