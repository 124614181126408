import * as React from "react";

function SvgLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M28.992 15.904c0 2.848-2.208 5.056-5.024 5.056H20.48s-.128 0-.128-.16 0-.16.128-.16c.96-.32 1.92-1.088 2.528-2.048h1.12c1.568 0 2.656-1.248 2.656-2.688 0-1.408-1.248-2.656-2.656-2.656h-8.032c-1.6 0-2.688 1.248-2.688 2.656v.64c0 .16-.16.32-.32.32H11.2c-.16 0-.16 0-.32-.16v-.16-.64c0-2.816 2.208-5.024 5.056-5.024h8.032c2.688 0 5.024 2.208 5.024 5.024zm-25.984.16c0-2.816 2.208-5.024 5.024-5.024h3.488s.16 0 .16.16 0 .16-.16.16c-.96.288-1.888 1.088-2.528 1.888h-.96c-1.568 0-2.656 1.248-2.656 2.656 0 1.44 1.248 2.688 2.656 2.688h8.064c1.568 0 2.656-1.248 2.656-2.688v-.448-.16c0-.16.16-.32.32-.32h1.888c.16 0 .16 0 .32.16v.768c0 2.848-2.208 5.056-5.056 5.056H8.032c-2.816.16-5.024-2.208-5.024-4.896z" />
    </svg>
  );
}

export default SvgLink;
