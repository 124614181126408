import React from 'react';
import styled from 'styled-components';
import { StyledButton } from '../../../../../Components/Button/styled';

const Wrapper = styled.div`
  margin-top: 1.5rem;
`;

interface Props {
  addAKeybind: (e: React.MouseEvent) => void;
}

const AddKeybindButton = (props: Props) => (
  <Wrapper>
    <StyledButton color="outline" onClick={props.addAKeybind}>
      Add a keybind
    </StyledButton>
  </Wrapper>
);

export default AddKeybindButton;
