import styled from 'styled-components';
import { theme } from '../../../../Styled';

export const SectionWrapper = styled.div`
  margin-top: 2rem;

  &:first-of-type {
    margin-top: 1.5rem;
  }

  * {
    font-family: ${theme.font.familySecondary} !important;
  }

  .styled-checkbox-wrapper {
    margin-bottom: 2rem;
  }
`;
