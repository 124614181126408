import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

const SettingsDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const DrawerContent = styled.div`
  margin: 1rem ${theme.space.lg} 10rem ${theme.space.lg};

  h2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 40px;
    font-family: ${theme.font.familySecondary};

    :first-of-type {
      border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
      padding-bottom: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: 2.25rem;
    }
  }

  h3 {
    font-family: Sora;
    font-weight: bold;
    line-height: 1.5rem;
    cursor: pointer;

    :first-of-type {
      margin-top: 2rem;
    }

    :nth-of-type(even) {
      margin: 1.5rem 0;
    }
  }
`;

export { SettingsDrawerWrapper, DrawerContent };
