import * as React from "react";

function SvgLabelsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 22 7" {...props}>
      <path d="M4.129 3.98a.202.202 0 01-.048-.34 2.024 2.024 0 00.706-2.23A1.998 1.998 0 003.417.08 2.038 2.038 0 00.812 2.032c.001.63.296 1.223.798 1.603a.202.202 0 01-.045.342A2.835 2.835 0 000 6.495a.412.412 0 00.404.42h4.883a.412.412 0 00.407-.418v-.002A2.835 2.835 0 004.129 3.98zM12.161 3.98a.202.202 0 01-.048-.34 2.024 2.024 0 00.706-2.23A1.998 1.998 0 0011.45.08a2.038 2.038 0 00-2.603 1.952c0 .63.295 1.223.797 1.603a.2.2 0 01-.045.342 2.835 2.835 0 00-1.566 2.518.412.412 0 00.407.42h4.88a.412.412 0 00.407-.418v-.002a2.835 2.835 0 00-1.565-2.515zM20.193 3.98a.202.202 0 01-.048-.34 2.024 2.024 0 00.706-2.23 1.998 1.998 0 00-1.37-1.33 2.038 2.038 0 00-2.603 1.952c0 .63.296 1.223.797 1.603a.201.201 0 01-.045.342 2.835 2.835 0 00-1.565 2.518.412.412 0 00.406.42h4.88a.412.412 0 00.407-.418v-.002a2.835 2.835 0 00-1.565-2.515z" />
    </svg>
  );
}

export default SvgLabelsIcon;
