import * as React from 'react';
import {
  PermissionBody,
  PermissionButton,
  PermissionButtons,
  PermissionContent,
  PermissionOverlay,
  PermissionText,
  PermissionWrapper,
} from './Styles';

interface PublicProps {
  onClick: () => void;
}

type Props = PublicProps;

export class AudioPermissionsAlertPresentation extends React.Component<Props> {
  public render() {
    return (
      <PermissionOverlay>
        <PermissionWrapper>
          <PermissionContent>
            <PermissionBody>
              <PermissionText>
                In order for Pretzel to list your audio outputs we need your permission. After pressing OK, the app will prompt you for microphone
                access. Press "Allow" for Pretzel to get your audio outputs.
              </PermissionText>
              <PermissionText>
                Pretzel has no intent on using your microphone, however we need your permission to access your audio outputs.
              </PermissionText>
            </PermissionBody>
            <PermissionButtons>
              <PermissionButton onClick={this.props.onClick}>OK</PermissionButton>
            </PermissionButtons>
          </PermissionContent>
        </PermissionWrapper>
      </PermissionOverlay>
    );
  }
}

export const AudioPermissionsAlert = AudioPermissionsAlertPresentation;
