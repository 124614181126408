import * as React from 'react';

function SvgVolume2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.011 21H15.511C14.6826 21 14.011 21.6716 14.011 22.5V25.5C14.011 26.3284 14.6826 27 15.511 27H19.011L25.158 33.146C25.301 33.2885 25.5156 33.3311 25.7022 33.2539C25.8887 33.1767 26.0105 32.9949 26.011 32.793V15.207C26.0105 15.0051 25.8887 14.8233 25.7022 14.7461C25.5156 14.6689 25.301 14.7115 25.158 14.854L19.011 21Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.011 18C32.8865 19.4248 33.9879 21.6447 33.9879 24C33.9879 26.3553 32.8865 28.5752 31.011 30"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M29.011 20.5C31.689 22.447 31.68 25.56 29.011 27.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SvgVolume2;
