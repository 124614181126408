import * as React from "react";

function SvgChatAnnouncement(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M23.264 4.8c-1.408-.224-2.784.416-3.424 1.696l-.096.128v.096c-1.088 1.056-8.768 2.784-11.424 3.296-1.056.224-1.92.448-2.336.544-.768.224-1.184.864-1.184 1.504v4.48c0 .736.544 1.376 1.184 1.472.416.128 1.28.32 2.336.544l3.936 7.68c.224.64.864 1.056 1.504 1.056h1.504c.512 0 1.056-.192 1.28-.64s.416-.96.32-1.472l-1.408-4.928c3.2.864 4.064 1.504 4.288 1.728l.096.192c.64 1.184 1.92 1.824 3.424 1.728 1.92-.224 1.92-1.92 1.92-3.104V8c-.128-1.28-.128-2.976-1.92-3.2zm-7.68 20.704c0 .096 0 .192-.128.32 0 0-.096.096-.32.096h-1.28c-.096 0-.192-.096-.32-.32l-3.52-6.72c.96.224 2.368.544 3.968.96l1.6 5.664zm-.864-6.848l-1.184-.192c-2.112-.544-3.712-.864-4.352-.96l-.224-.128h-.32c-1.056-.192-1.92-.416-2.336-.512-.128 0-.224-.128-.224-.224v-4.48c0-.096.096-.224.224-.224.416-.096 1.28-.32 2.336-.512 5.536-1.184 9.28-2.144 11.104-3.104v12.16c-.864-.64-2.464-1.184-5.024-1.824zm9.056 2.048c0 1.376-.096 1.696-.736 1.792-.864.128-1.6-.192-2.016-.832V6.944c.32-.64 1.152-.96 2.016-.864.64.096.736.32.736 1.824v12.8z" />
    </svg>
  );
}

export default SvgChatAnnouncement;
