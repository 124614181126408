import React, { useState } from 'react';
import { Configure, Index } from 'react-instantsearch-dom';
import { SegmentHit } from './Components/SegmentHit';
import { SearchMusicList } from './Components/SearchMusicList';
import { SearchList } from './Components/SearchList';
import './style.css';
import { Footer } from '../Footer';
import PageWrapper from '../../Styled/Page';

function SearchImplementation() {
  const [areTracksLoading, setTracksLoading] = useState<boolean>(false);

  return (
    <PageWrapper title="Search">
      <Index indexName="prod_segments">
        <Configure hitsPerPage={6} clickAnalytics={true} />
        <SearchList hitComponent={SegmentHit} areTracksLoading={areTracksLoading} />
      </Index>
      <br />

      <Index indexName="prod_tracks">
        <Configure hitsPerPage={15} clickAnalytics={true} />
        <SearchMusicList setTracksLoading={setTracksLoading} />
      </Index>
      <Footer />
    </PageWrapper>
  );
}

export const Search = SearchImplementation;
