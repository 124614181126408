import * as React from "react";

function SvgPartyMusicDanceWoman(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.5 3.721a1 1 0 00-.122-1.409L21.2.484a1 1 0 00-1.638.654L19.3 3.48a.251.251 0 01-.259.222A2.072 2.072 0 1021 6.144a12.962 12.962 0 00.292-2.462.249.249 0 01.409-.163l.386.324a1 1 0 001.413-.122zM22.053 11.7a1 1 0 00-.591-1.284l-2.007-.743a1 1 0 00-1.32 1.169l.422 1.773a.251.251 0 01-.12.275 1.66 1.66 0 00-.6.577 1.687 1.687 0 001.439 2.572 1.627 1.627 0 00.389-.046 1.69 1.69 0 001.252-2.032c-.136-.6-.25-1.1-.343-1.5a.144.144 0 01.045-.143.148.148 0 01.148-.028.994.994 0 001.286-.59zM5.872 17.354a.25.25 0 01.231.324l-1.271 4.149a1.5 1.5 0 001.015 1.862 1.525 1.525 0 00.424.061 1.5 1.5 0 001.439-1.077l1.249-4.209.393-1.337a.249.249 0 01.187-.174.252.252 0 01.242.081l.448.52a2.189 2.189 0 01.475 1.9l-.4 1.975a1.5 1.5 0 001.139 1.79 1.469 1.469 0 00.327.036 1.5 1.5 0 001.463-1.174l.6-2.7a4.82 4.82 0 00-.621-3.847.252.252 0 01.108-.38c.4-.16.9-.425.966-.7a1 1 0 00-.268-.946l-4.3-3.8a.251.251 0 01.121-.434c.3-.052 1.047-.175 3.227-.539a1.5 1.5 0 00-.244-2.98 1.525 1.525 0 00-.25.021l-3.239.541a10.242 10.242 0 00-5.556 2.836l-3.061 3.03a1.5 1.5 0 102.11 2.132l2.209-2.187a.25.25 0 01.418.241l-1 3.79a.924.924 0 00.335.915 2.127 2.127 0 001.084.28zM6.271 4.24a2.5 2.5 0 10-2.5 2.5 2.458 2.458 0 00.806-.14 2.5 2.5 0 001.694-2.36z" />
    </svg>
  );
}

export default SvgPartyMusicDanceWoman;
