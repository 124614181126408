import * as React from "react";

function SvgTracklistPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M7.52 4.832l16.96 11.296L7.264 27.424V4.832z" />
    </svg>
  );
}

export default SvgTracklistPlay;
