import axios from 'axios';

export const checkUsername = async (username: string): Promise<{ available: boolean }> => {
  const body = JSON.stringify({ username });
  const options = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('pretzel_app_token')}`,
    },
  };

  return axios
    .post(`https://api.pretzel.rocks/api/v1/account/username/check`, body, options)
    .then(({ data }) => data)
    .catch(e => e);
};

export default checkUsername;
