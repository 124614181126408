import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { PlaylistCard } from './Components/PlaylistCard';
import { MusicList, theme } from '../../Styled';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { PlaylistsListQuery } from './types/PlaylistsListQuery';
import { UserPlaylistsListQuery } from './types/UserPlaylistsListQuery';
import { Heading, Text } from 'grommet';
import { ListAdd } from '../../../../assets/icons';
import { PlatformContext, withPlatformContext } from '../../Core/Platform';
import { PlaylistUpsell } from './Components/PlaylistUpsell';
import useWindowSize from '@pretzel-aux/common/src/Util/hooks/use-window-size';
import { LoadingSpinner } from '../LoadingSpinner';

const playlistsQuery = loader('./playlists.graphql');
const userPlaylistsQuery = loader('./userPlaylists.graphql');

type PropsFromPlatform = Pick<PlatformContext, 'pretzelUser'>;

interface GraphqlProps {
  shared: QueryControls & PlaylistsListQuery;
  user: QueryControls & UserPlaylistsListQuery;
}

type Props = GraphqlProps & PropsFromPlatform;

interface State {
  loadingMorePlaylists: boolean;
  loadingMoreUserPlaylists: boolean;
}

const PlaylistsListPresentation = ({ user, shared, pretzelUser }: Props) => {
  const [state, setState] = React.useState<State>({
    loadingMorePlaylists: false,
    loadingMoreUserPlaylists: false,
  });
  const { width } = useWindowSize();

  const setTileSize = () => {
    if (width >= theme.appBreakpoints.small && width < theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.small);
    }
    if (width >= theme.appBreakpoints.medium) {
      return parseInt(theme.card.size.normal);
    }
    return parseInt(theme.card.size.large);
  };

  const setPlaylistCardSize = () => {
    if (width <= theme.appBreakpoints.medium) {
      return theme.card.size.featured;
    }
    return parseInt(theme.card.size.large);
  };

  const userPlaylists = user.loading
    ? null
    : user.userPlaylists.edges.map(playlistEdge => (
        <PlaylistCard key={playlistEdge.node.id} playlist={playlistEdge.node} size={setPlaylistCardSize()} />
      ));
  const hasMoreUserPlaylists = user.userPlaylists && user.userPlaylists.pageInfo.hasNextPage;

  const playlists = shared.loading
    ? null
    : shared.playlists.edges.map(playlistEdge => (
        <PlaylistCard key={playlistEdge.node.id} playlist={playlistEdge.node} size={setPlaylistCardSize()} />
      ));
  const hasMorePlaylists = shared.playlists && shared.playlists.pageInfo.hasNextPage;

  const onMoreUserPlaylists = () => {
    const after = user.userPlaylists.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'playlist',
      currentLength: user.userPlaylists.edges.length,
      after,
    });
    setState({ ...state, loadingMoreUserPlaylists: true });

    user.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: UserPlaylistsListQuery, { fetchMoreResult }) => {
        setState({ ...state, loadingMorePlaylists: false });
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          userPlaylists: Object.assign({}, fetchMoreResult.userPlaylists, {
            edges: [...previousQueryResult.userPlaylists.edges, ...fetchMoreResult.userPlaylists.edges],
          }),
        };
      },
    });
  };

  const onMore = () => {
    const after = shared.playlists.pageInfo.endCursor || '';
    console.debug('Loading More', {
      type: 'playlist',
      currentLength: shared.playlists.edges.length,
      after,
    });
    setState({ ...state, loadingMorePlaylists: true });
    shared.fetchMore({
      variables: { after },
      updateQuery: (previousQueryResult: PlaylistsListQuery, { fetchMoreResult }) => {
        setState({ ...state, loadingMorePlaylists: false });
        if (!fetchMoreResult) {
          return previousQueryResult;
        }
        return {
          playlists: Object.assign({}, fetchMoreResult.playlists, {
            edges: [...previousQueryResult.playlists.edges, ...fetchMoreResult.playlists.edges],
          }),
        };
      },
    });
  };

  if (user.loading || shared.loading) {
    return <LoadingSpinner />;
  }

  if (pretzelUser && !pretzelUser.premium) {
    return <PlaylistUpsell />;
  }

  return (
    <>
      {user.userPlaylists.edges.length ? (
        <MusicList
          title="Your playlists"
          items={userPlaylists}
          hasMore={hasMoreUserPlaylists}
          onMore={onMoreUserPlaylists}
          loading={state.loadingMoreUserPlaylists}
          tileSize={setTileSize()}
        />
      ) : (
        <div style={{ padding: 30 }}>
          <Heading level="2">Your Playlists</Heading>
          <Text>
            It's empty here! Create your first playlist by clicking the <ListAdd style={{ height: 16, color: theme.colors.brandGreen2 }} /> icon while
            listening to a song that hits just right.
          </Text>
        </div>
      )}
    </>
  );
};

function mapPlatformToProps(c: PlatformContext): PropsFromPlatform {
  return {
    pretzelUser: c.pretzelUser,
  };
}

export const PlaylistsList = compose(
  graphql(playlistsQuery, {
    name: 'shared',
    options: {
      variables: {
        after: '',
      },
    },
  }),
  graphql(userPlaylistsQuery, {
    name: 'user',
    options: {
      variables: {
        after: '',
      },
    },
  }),
  withPlatformContext(mapPlatformToProps)
  // @ts-ignore
)(PlaylistsListPresentation);
