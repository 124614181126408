import { emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import experiments from './data';

let instance: ABTesting = null;

export default class ABTesting {
  private isDebugOn: boolean = false;

  constructor(enableDebug = false) {
    if (!instance) {
      instance = this;
    } else {
      return instance;
    }

    experiments.forEach(experiment => {
      emitter.defineVariants(experiment.name, experiment.variants, experiment.variantWeights ?? []);
    });

    emitter.addWinListener(instance.sendWin);

    instance.isDebugOn = enableDebug && process.env.REACT_APP_CONTEXT !== 'production';

    if (instance.isDebugOn) {
      experimentDebugger.enable();
    }

    return instance;
  }

  registerWin(experimentWin: string) {
    emitter.emitWin(experimentWin);
  }

  private sendWin(experimentName: string, variantName: string) {
    if (instance.isDebugOn) {
      console.log(`Variant ${variantName} of experiment ${experimentName} was clicked`);
    }
  }
}
