const DEFAULT_RETRY_LIMIT = 10;
const DEFAULT_INITIAL_DELAY = 200;
const DEFAULT_SUCCESS_STATUSES = [200];

interface RetryOptions {
  limit?: number;
  initialDelay?: number;
  successStatuses?: number[];
}

export const fetchRetry = function(url: string, requestData: RequestInit, options: RetryOptions = {}) {
  const retriesLimit = options.limit || DEFAULT_RETRY_LIMIT;
  const retryInitialDelay = options.initialDelay || DEFAULT_INITIAL_DELAY;
  const considerSuccessfulOn = options.successStatuses || DEFAULT_SUCCESS_STATUSES;

  let retriesCount = 0;

  if (considerSuccessfulOn && !(considerSuccessfulOn instanceof Array)) {
    throw new Error('considerSuccessfulOn property expects an array');
  }

  return new Promise(function(resolve, reject) {
    const wrappedFetch = function(n: number) {
      fetch(url, requestData)
        .then(function(response) {
          if (considerSuccessfulOn.indexOf(response.status) !== -1) {
            resolve(response);
          } else {
            if (n > 0) {
              retry();
            } else {
              reject(response);
            }
          }
        })
        .catch(function(error) {
          if (n > 0) {
            retry();
          } else {
            reject(error);
          }
        });
    };

    function retry() {
      retriesCount = retriesCount + 1;
      setTimeout(function() {
        wrappedFetch(retriesLimit - retriesCount);
      }, 2 ^ (retriesCount * retryInitialDelay));
    }

    wrappedFetch(retriesLimit - retriesCount);
  });
};
