import styled from 'styled-components';

export const StyledDownloadFileTypeSelectModal = styled.div`
  h2 {
    align-self: flex-start;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;

  label {
    display: flex !important;
    align-items: center;
    margin: 0 1.5rem 0 0;

    &:last-of-type {
      margin-right: 0;
    }

    > span {
      margin-right: 0.75rem;
    }
  }

  ~ div {
    justify-content: flex-start;
  }
`;

export const OptionsWrapperLabel = styled.label`
  display: block;
  margin-top: 2rem;
`;
