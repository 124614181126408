import React, { FC, useState } from 'react';
import { StyledToggle, StyledToggleContainer, StyledToggleLabel } from './styled';
import { ToggleProps } from './types';

export const Toggle: FC<ToggleProps> = ({ label, onChange, value, selected, className }) => {
  const [isSelected, setIsSelected] = useState<boolean>(selected || false);
  return (
    <StyledToggleContainer className={className} onClick={onChange}>
      <StyledToggle type="checkbox" id={label} value={value} checked={selected} readOnly />
      <StyledToggleLabel htmlFor={label} />
      <label htmlFor={label}>{label}</label>
    </StyledToggleContainer>
  );
};
