import { theme } from '../../Styled';
import styled from 'styled-components';
import { InputProps } from './types';

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<InputProps>`
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: ${theme.space.lg};
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: ${theme.colors.light1};
  height: 40px;
  outline: none;
  padding: ${props => (props.icon ? `0 ${theme.space.lg} 0 ${theme.space.md}` : `0 ${theme.space.md}`)};

  &::placeholder {
    color: #d8d8d8;
  }

  &:focus {
    border-color: ${theme.colors.primary};
  }
`;

export const StyledInputIcon = styled.span`
  position: absolute;
  right: ${theme.space.md};
  top: 50%;
  transform: translateY(calc(-50% + 1px));

  svg {
    stroke: ${theme.colors.baseWhite};
  }
`;
