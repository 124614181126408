import * as React from "react";

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 21 32" {...props}>
      <path d="M7.968 27.2q-1.088 0-1.792-.896l-5.76-7.552q-.512-.768-.384-1.664t.832-1.472 1.632-.448T4 16.064l3.776 4.928 9.472-15.168q.512-.768 1.376-.96t1.696.256q.768.512.96 1.376t-.256 1.696l-11.2 17.92q-.64 1.024-1.792 1.024z" />
    </svg>
  );
}

export default SvgCheck;
