import * as React from 'react';
import { theme } from '@pretzel-aux/common/src/Styled';

function SkipBack(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg
      onMouseEnter={() => setFill(theme.colors.brandGreen)}
      onMouseLeave={() => setFill(theme.colors.offWhite)}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="no-hover-effect"
    >
      <path d="M12.5 20.992L15.5 25.492L19 21.492" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M25 33.5C30.2467 33.5 34.5 29.2467 34.5 24C34.5 18.7533 30.2467 14.5 25 14.5C19.7533 14.5 15.5 18.7533 15.5 24V25.494"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7092 27H23.3732V21.664H22.7892L21.4532 23.04L21.8532 23.448L22.7092 22.544V27ZM24.5241 26.24C24.9241 26.768 25.5001 27.096 26.4041 27.096C27.4761 27.096 28.3001 26.424 28.3001 25.336C28.3001 24.24 27.4921 23.632 26.6041 23.632C26.0681 23.632 25.6121 23.856 25.3561 24.112V22.256H27.9561V21.664H24.6921V24.544L25.1801 24.712C25.5401 24.36 25.9401 24.208 26.4121 24.208C27.1321 24.208 27.6361 24.656 27.6361 25.36C27.6361 26.008 27.1321 26.504 26.4041 26.504C25.7481 26.504 25.2841 26.248 24.9321 25.8L24.5241 26.24Z"
        fill={fill}
      />
    </svg>
  );
}

export default SkipBack;
