import styled from 'styled-components';
import { theme } from '../../Styled/theme';
import { InputType } from './types';

const height = '25px';
const width = '25px';

export const StyledCustomCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${height};
  width: ${width};
  background-color: ${theme.colors.bg};
  border: 0.1rem solid ${theme.colors.brandGreen};
  min-width: ${width};

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${theme.colors.baseWhite};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const StyledInput = styled.input<InputType>`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${StyledCustomCheckbox} {
    background-color: ${theme.colors.brandGreen};

    &:after {
      display: block;
    }
  }
`;

export const StyledLabel = styled.span`
  display: inline-block;
  position: relative;
  height: ${height};
  width: ${width};
  cursor: pointer;
  user-select: none;
  min-width: ${width};
`;
