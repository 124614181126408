import { theme } from '../../../../Styled';
import styled from 'styled-components';
import { Heading } from '../../../Heading';

export const StyledSection = styled.section`
  margin-bottom: 1.5rem;
`;

export const StyledSectionHeader = styled.div`
  align-items: flex-end;
  display: flex;
  font-size: 1.15rem;
  margin-bottom: ${theme.space.lg};
`;

export const StyledHeading = styled(Heading)`
  margin-right: 3.5rem;
  ${theme.mediaQueries.medium} {
    margin-right: 1.5rem;
  }
`;
