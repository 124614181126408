import * as React from "react";

function SvgFeatureBot(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M28.16 32H3.776V13.184H28.16V32zM5.184 30.592h21.568v-16H5.184v16zm0-3.872H.32v-9.312h4.864v9.312zm-3.456-1.408h2.048V18.88H1.728v6.432zm29.888 1.408h-4.864v-9.312h4.864v9.312zm-3.456-1.408h2.048V18.88H28.16v6.432zm-15.52-7.328c1.056 0 1.984.896 1.984 1.952s-.928 1.984-1.984 1.984-1.952-.928-1.952-1.984.896-1.952 1.952-1.952zm6.656 0c1.056 0 1.952.896 1.952 1.952s-.896 1.984-1.952 1.984-1.984-.928-1.984-1.984.896-1.952 1.984-1.952zm-8.256 6.272h9.856v1.536H11.04v-1.536zM22.464 4.64c0-2.528-2.912-4.64-6.496-4.64S9.472 2.112 9.472 4.64c0 2.112 1.984 3.904 4.736 4.448.48.704 1.12 1.344 1.824 1.92.064-.64.224-1.216.576-1.76 3.232-.224 5.856-2.208 5.856-4.608z" />
    </svg>
  );
}

export default SvgFeatureBot;
