import * as React from 'react';
import { Box } from 'grommet';
import { theme } from './';

export class PlaceholderLink extends React.Component {
  public render() {
    return (
      <Box width="200px" style={{ position: 'relative', cursor: 'default' }}>
        <Box
          height="15px"
          margin={{ bottom: '10px', top: '3px' }}
          width="200px"
          style={{
            position: 'relative',
            cursor: 'pointer',
            backgroundColor: theme.colors.textMuted,
            flexShrink: 0,
          }}
        />
      </Box>
    );
  }
}
