import { theme } from '@pretzel-aux/common/src/Styled';
import { Icon } from '@pretzel-aux/common/src/Styled/Icon';
import styled from 'styled-components';
import backgroundImage from '@pretzel-aux/assets/images/background.jpg';
import { Box, Paragraph } from 'grommet';
import { LoginLink as ILoginLink } from './types';
import { Button } from '@pretzel-aux/common/src/Components/Button';

const disabledStyles = `
    opacity: .5;
    pointer-events: none;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.space.md};

  & > span {
    display: inline-block;
    margin-right: ${theme.space.md};
    font-size: 0.95rem;

    a {
      color: ${theme.colors.brandGreen};
    }
  }
`;

export const StyledCheckboxContainer = styled.div`
  text-align: left;
  margin-top: ${theme.space.lg};
`;

export const Splash = styled(Box)`
  width: 100%;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  align: center;
`;

export const LoginWrapper = styled(Box)`
  width: 100vw;
  margin: auto;
  max-width: 625px;
  box-sizing: border-box;
  padding-top: ${theme.space.md};
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  background: ${theme.colors.backgroundDarkBlur};
  @media (max-width: 470px) {
    max-width: 470px;
    height: 100vh;
  }
  text-align: center;
  align: center;
  justify: center;
  border-radius: ${theme.borders.radius};
`;

export const LoginLink = styled.a<ILoginLink>`
  font-size: 17px;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  max-width: 250px;
  text-align: left;
  margin-bottom: 0;
  cursor: pointer;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
  white-space: nowrap;
  &:hover,
  &:focus,
  &:visited {
    color: ${theme.colors.baseWhite} !important;
    text-decoration: none;
    outline: none;
    outline-offset: 0;
  }
  color: ${theme.colors.baseWhite} !important;
  transition: opacity 0.25s ease-in-out;
  background-color: ${({ type }) => {
    let color;
    switch (type) {
      case 'twitch':
        color = theme.colors.twitchBase;
        break;
      case 'google_oauth2':
        color = theme.colors.googleBase;
        break;
      case 'pretzel':
        color = theme.colors.brandGreen2;
        break;
      default:
        break;
    }
    return color;
  }};
  &:hover {
    background-color: ${({ type }) => {
    let color;
    switch (type) {
      case 'twitch':
        color = theme.colors.twitchHighlight;
        break;
      case 'google_oauth2':
        color = theme.colors.googleHighlight;
        break;
      case 'pretzel':
        color = theme.colors.brandGreen;
        break;
      default:
        break;
    }
    return color;
  }};
  }

  ${props => props.disabled && disabledStyles};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-bottom: ${theme.space.md};
`;

export const StyledButton = styled(Button)`
  height: auto;
  padding: 0.2rem ${theme.space.lg};

  ${props => props.disabled && disabledStyles};
`;

export const StyledLink = styled.a`
  font-weight: bold;
  color: ${theme.colors.baseWhite};
`;

export const LoginButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  a {
    margin-top: 1rem;

    &:first-of-type {
      margin: 0;
    }
  }

  ${theme.mediaQueries.large} {
    flex-direction: row;
    justify-content: space-between;

    a {
      margin: 0;
    }
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin: ${theme.space.lg} 0 0 0;

  ${theme.mediaQueries.large} {
    text-align: left;
  }
`;

export const StyledLogo = styled(Icon)`
  &,
  &:before {
    width: auto !important;
  }
`;
