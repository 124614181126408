import * as React from 'react';
import { Heading, Text, Anchor } from 'grommet';
import { UpsellLink } from '../../UpsellLink/UpsellLink';

export function PlaylistUpsell() {
  return (
    <div style={{ padding: 30 }}>
      <Heading level="2">Unlock the Power of Playlists</Heading>
      <Text>
        Level up to a Premium account on <UpsellLink>the Pretzel website</UpsellLink>.
      </Text>
    </div>
  );
}
