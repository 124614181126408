import * as React from "react";

function SvgInstrumentMaracas1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M10.02 9.691a.246.246 0 01-.059-.26 5 5 0 00-1.175-5.217 5.719 5.719 0 00-.179-.17.25.25 0 00-.416.209l.22 2.361a1.248 1.248 0 01-1.572 1.319l-1.511-.412a.25.25 0 00-.307.307l.412 1.509a1.251 1.251 0 01-1.207 1.58h-.1l-2.368-.222a.25.25 0 00-.208.417c.054.06.111.12.17.178a5 5 0 005.216 1.175.246.246 0 01.26.059l.744.743a.25.25 0 00.353 0l2.475-2.475a.25.25 0 000-.353zM15.335 20.664a2 2 0 102.829-2.828l-2.1-2.1a.249.249 0 00-.353 0l-2.475 2.474a.252.252 0 000 .354z" />
      <path d="M3.343 7.379a1.25 1.25 0 011.535-1.535l1.658.456a.25.25 0 00.314-.265L6.576 3.1a.249.249 0 00-.192-.22 5 5 0 00-6 6 .25.25 0 00.22.193l2.931.274a.254.254 0 00.215-.088.249.249 0 00.05-.223zM15.919 13.409a.248.248 0 00.071-.212.252.252 0 00-.127-.184 6.031 6.031 0 01-2.374-2.377.251.251 0 00-.4-.057l-7.253 7.257a2 2 0 102.829 2.828zM22.285 4.214a5 5 0 00-4.668-1.334.25.25 0 00-.193.22l-.274 2.931a.25.25 0 00.315.265l1.658-.452a1.249 1.249 0 011.533 1.537L20.2 9.036a.252.252 0 00.049.227.254.254 0 00.215.088l2.936-.274a.25.25 0 00.22-.193 5 5 0 00-1.335-4.67z" />
      <path d="M19.887 10.912h-.109a1.252 1.252 0 01-1.211-1.579l.413-1.51a.251.251 0 00-.307-.307l-1.511.412a1.248 1.248 0 01-1.573-1.315l.221-2.364a.251.251 0 00-.417-.209c-.061.055-.12.112-.179.17a5 5 0 107.071 7.072 3.57 3.57 0 00.17-.179.249.249 0 00-.208-.416z" />
    </svg>
  );
}

export default SvgInstrumentMaracas1;
