import { useMutation, useQuery } from '@apollo/client';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@pretzel-aux/common/src/Components/Accordion/Accordion';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';
import platformContext, { PlatformContext, URLS } from '@pretzel-aux/common/src/Core/Platform';
import { getBuildVersion } from '@pretzel-aux/common/src/Util';
import { loader } from 'graphql.macro';
import React, { useContext, useState } from 'react';
import { AudioControls } from './Components/AudioControls/AudioControls';
import Connected from './Components/Connected/Connected';
import Keybinds from './Components/Keybinds/Keybinds';
import { Profile } from './Components/Profile/Profile';
import Subscriptions from './Components/Subscriptions/Subscriptions';
import { WhatsPlaying } from './Components/WhatsPlaying/WhatsPlaying';
import { ButtonBox, StyledSidebarFooter, StyledSidebarFooterBuildVersion, StyledSidebarFooterButtonWrapper, StyledSidebarFooterUser } from './Styles';
import { CurrentUser_CurrentUserQuery } from './types/CurrentUser_CurrentUserQuery';
import Sidebar from '../Sidebar/Sidebar';
import { Avatar, AvatarDefault, theme } from '../../Styled';
import { CLEAR_ACCOUNT_DATA } from '../../Core/IPC/IPCRequest';
import { RefreshAccount } from '../../Core/Settings/Queries/types/RefreshAccount';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { Extras } from '../Settings/Sections/Extras/Extras';
import { FileOutput } from '../Settings/Sections/FileOutput/FileOutput';

const currentUserGqlQuery = loader('./currentUser.graphql');
const refreshAccountGqlQuery = loader('../../Core/Settings/Queries/refresh-jwt.graphql');

export const CurrentUserMenu = (): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const response = useQuery<CurrentUser_CurrentUserQuery>(currentUserGqlQuery);
  const [refreshAccount] = useMutation<RefreshAccount, {}>(refreshAccountGqlQuery);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const handleUserClicked = (): void => {
    // This should probably be handled in the app state somewhere rather than the DOM being updated directly but kicking the can down the road for now.
    const element = document.getElementById('giftcard');

    if (element) {
      element.classList.add('settings');
    }

    setOpenSidebar(!openSidebar);
  };

  const handleLogout = (): void => {
    platformContextObj.sendIPC(CLEAR_ACCOUNT_DATA, { params: { type: CLEAR_ACCOUNT_DATA } }).catch(() => {});
    platformContextObj.saveData('pretzel_app_token', '');
    platformContextObj.unloadApp();
  };

  const handleRefresh = async (): Promise<void> => {
    const result = await refreshAccount();
    const newToken = result?.data?.refreshAccount?.token || '';
    platformContextObj.saveData('pretzel_app_token', newToken);
    platformContextObj.unloadApp();
  };

  return response?.data?.currentUser ? (
    <React.Fragment>
      <ButtonBox align="center" direction="row" focusIndicator={false} height="100%" justify="end" onClick={handleUserClicked}>
        {response.data.currentUser.avatar ? (
          <Avatar size={40} src={response.data.currentUser.avatar} />
        ) : (
          <AvatarDefault size={40}>
            <FontAwesomeIcon icon={faUser} />
          </AvatarDefault>
        )}
      </ButtonBox>
      <Sidebar open={openSidebar} setOpenSidebar={setOpenSidebar}>
        <Accordion title="Profile" content={<Profile />} showDivider />
        <Accordion title="Subscriptions" content={<Subscriptions />} showDivider />
        <Accordion title="Connected" content={<Connected userData={response} />} showDivider />
        <Accordion title="Audio" content={<AudioControls />} showDivider />
        <Accordion title="What's playing" content={<WhatsPlaying />} showDivider />
        {platformContextObj.features.writeToFile && <Accordion title="File output" content={<FileOutput />} showDivider />}
        <Accordion title="Keybinds" content={<Keybinds />} showDivider />
        <StyledSidebarFooter>
          <StyledSidebarFooterUser>
            Logged in as{' '}
            <span>
              {platformContextObj.pretzelUser.name} ({platformContextObj.pretzelUser?.jwt.guid})
            </span>
          </StyledSidebarFooterUser>
          <StyledSidebarFooterButtonWrapper>
            <StyledButton color="outline" onClick={() => handleRefresh()}>
              Refresh
            </StyledButton>
            <StyledButton color="secondary" onClick={() => handleLogout()}>
              Logout
            </StyledButton>
          </StyledSidebarFooterButtonWrapper>
          <StyledSidebarFooterBuildVersion>{getBuildVersion()}</StyledSidebarFooterBuildVersion>
          <ExternalLink href={URLS.CLIENT_OPEN_SOURCE_LICENSES}>Open Source Licenses &amp; Copyright Notices</ExternalLink>
          <Extras />
        </StyledSidebarFooter>
      </Sidebar>
    </React.Fragment>
  ) : null;
};
