import * as React from 'react';

function Notifications(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3043 17.5645V26.5958C19.3043 29.4487 20.9218 32.0549 23.4782 33.3211" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.913 15.0434C33.913 14.6872 33.7313 14.3556 33.431 14.1639C33.1307 13.9722 32.7534 13.947 32.4303 14.097C28.4285 15.9533 24.4758 17.6312 16.1739 17.6688H15.1304C13.4015 17.6688 12 19.0704 12 20.7992V22.8862C12 24.6151 13.4015 26.0166 15.1304 26.0166H16.1739C24.4758 26.0542 28.4285 27.7321 32.4303 29.5885C32.7534 29.7384 33.1307 29.7132 33.431 29.5215C33.7313 29.3299 33.913 28.9983 33.913 28.642V15.0434Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9131 19.7557H34.4348C35.2993 19.7557 36 20.4565 36 21.3209V22.3644C36 23.2288 35.2993 23.9296 34.4348 23.9296H33.9131"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Notifications;
