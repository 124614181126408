import * as React from "react";

function SvgFeatureSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M8.8 24.832h-.256C3.68 24.672-.16 20.576 0 15.712s4.288-8.704 9.12-8.544h14.336c2.336.096 4.512 1.088 6.208 2.752 1.568 1.76 2.4 3.936 2.336 6.368a8.815 8.815 0 01-9.12 8.544H9.056 8.8zm.096-16c-3.872 0-7.04 3.104-7.2 6.944-.192 3.936 3.008 7.2 6.944 7.392h14.304c3.936.064 7.296-3.04 7.392-6.976.064-3.936-3.04-7.2-6.976-7.36H9.056h-.16zm.224 2.784c2.432 0 4.448 1.984 4.448 4.416S11.552 20.48 9.12 20.48s-4.416-2.016-4.416-4.448 2.016-4.416 4.416-4.416z" />
    </svg>
  );
}

export default SvgFeatureSettings;
