import React, { Fragment, useState } from 'react';
import { OptionsWrapper, OptionsWrapperLabel, StyledDownloadFileTypeSelectModal } from './styled';
import { Props } from './types';
import { Checkbox } from '../Checkbox';
import { Modal } from '../Modal';
import { DownloadFileType } from '../../Core/Settings/Settings';

export const DownloadFileTypeSelectModal = ({ setOpen, onSubmit }: Props): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<DownloadFileType>(null);

  const content = (
    <Fragment>
      <div>
        Looks like you're downloading your first track (Congrats!). So we don't have to ask you everytime, you can select your preferred file format
        for downloads now. If you ever need to change this, simply go to Settings &gt; Audio.
      </div>
      <OptionsWrapperLabel>Preferred format</OptionsWrapperLabel>
      <OptionsWrapper>
        {Object.keys(DownloadFileType).map(key => {
          return (
            <label key={key}>
              <Checkbox
                checked={selectedValue === DownloadFileType[key]}
                onChange={() => {
                  setSelectedValue(DownloadFileType[key]);
                }}
                data-heapid={`modal-download-file-type-${key}`}
                data-testid={`modal-download-file-type-${key}`}
              />{' '}
              {key}
            </label>
          );
        })}
      </OptionsWrapper>
    </Fragment>
  );

  return (
    <StyledDownloadFileTypeSelectModal>
      <Modal
        buttons={[
          {
            color: 'outline',
            label: 'Cancel',
            onClick: () => setOpen(false),
          },
          {
            color: 'secondary',
            disabled: !selectedValue,
            label: 'Download',
            onClick: () => onSubmit(selectedValue),
          },
        ]}
        content={content}
        displayCloseIcon={false}
        heapid="modal-download-file-type"
        isOpen
        testid="modal-download-file-type"
        title="Download track"
        variant="secondary"
      />
    </StyledDownloadFileTypeSelectModal>
  );
};
