import loadingSpinner from '@pretzel-aux/assets/images/loading.gif';
import React from 'react';
import { LoadingSpinnerContainer } from './styles';
import { LoadingSpinnerProps } from './types';

export const LoadingSpinner = ({ variant = 'fixed' }: LoadingSpinnerProps): JSX.Element => {
  return (
    <LoadingSpinnerContainer variant={variant}>
      <img src={loadingSpinner} alt="loading" height={160} width={160} />
    </LoadingSpinnerContainer>
  );
};
