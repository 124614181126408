import { loader } from 'graphql.macro';
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { StyledButton } from '@pretzel-aux/common/src/Components/Button/styled';
import platformContext, { PlatformContext, Tier } from '@pretzel-aux/common/src/Core/Platform';
import { TestNotification } from '@pretzel-aux/common/src/Core/Settings/Queries/types/TestNotification';
import settingsContext, { SettingsContext } from '@pretzel-aux/common/src/Core/Settings/Settings';
import { theme } from '@pretzel-aux/common/src/Styled';
import { isUserEntitled } from '@pretzel-aux/common/src/Util/Entitlements';
import { StyledWhatsPlayingContent, StyledPlusAndMinus } from './Styles';
import { StyledCheck, StyledCheckbox, StyledCheckboxWrapper } from '../../Styles';

const testNotificationGqlQuery = loader('../../../../Core/Settings/Queries/test-notification.graphql');

export const WhatsPlaying = (): JSX.Element => {
  const platformContextObj = useContext<PlatformContext>(platformContext);
  const settingsContextObj = useContext<SettingsContext>(settingsContext);
  const [testNotification] = useMutation<TestNotification, {}>(testNotificationGqlQuery);

  const sendTestMessage = async (): Promise<void> => {
    const response = await testNotification();
    const errors = response?.data?.testNotification?.errors || [];
    console.log('Got errors?', errors);
  };

  const handleSetNotifyDelay = (value: number) => {
    if (value > 0 && value <= 30) {
      settingsContextObj.setNotifyDelay(value);
    }
  };

  const isFree = isUserEntitled(platformContextObj.pretzelUser.jwt.tier, [Tier.Free]);

  return (
    <StyledWhatsPlayingContent>
      {!isFree && (
        <StyledCheckboxWrapper style={{ marginBottom: '1.875rem' }}>
          <StyledCheckbox
            onClick={e => settingsContextObj.setNotifyInChat(!settingsContextObj.notifyInChat)}
            style={{ backgroundColor: settingsContextObj.notifyInChat ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
          >
            {settingsContextObj.notifyInChat && <StyledCheck />}
          </StyledCheckbox>
          Announce what’s playing in chat
        </StyledCheckboxWrapper>
      )}
      <p style={{ marginBottom: '0.25rem' }}>What’s playing notification delay</p>
      <StyledPlusAndMinus>
        <button type="button" onClick={() => handleSetNotifyDelay(settingsContextObj.notifyDelay - 1)}>
          -
        </button>
        <div>
          <span>
            {settingsContextObj.notifyDelay} {settingsContextObj.notifyDelay === 1 ? 'second' : 'seconds'}
          </span>
        </div>
        <button type="button" onClick={() => handleSetNotifyDelay(settingsContextObj.notifyDelay + 1)}>
          +
        </button>
      </StyledPlusAndMinus>
      <StyledButton color="outline" style={{ height: '40px', marginTop: '2rem', width: '70%' }} onClick={() => sendTestMessage()}>
        Send test chat message
      </StyledButton>
    </StyledWhatsPlayingContent>
  );
};
