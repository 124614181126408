import * as React from "react";

function SvgRefresh(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M16 4c-5.12 0-9.376 3.168-11.136 7.616l1.856.768c1.472-3.744 4.992-6.4 9.28-6.4 3.232 0 6.144 1.6 7.936 4H20V12h7.008V4.992h-2.016v3.104A11.821 11.821 0 0016 4zm9.28 15.616c-1.44 3.744-4.992 6.368-9.28 6.368-3.264 0-6.144-1.6-7.968-4H12V20H4.992v7.008h2.016v-3.104C9.184 26.368 12.384 28 16 28c5.12 0 9.376-3.168 11.136-7.616l-1.856-.768z" />
    </svg>
  );
}

export default SvgRefresh;
