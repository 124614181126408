import React, { FC } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { loader } from 'graphql.macro';
import { ArtistCard } from '../../../ArtistsList/Components/ArtistCard';
import { Carousel } from '../../../Carousel';
import { PopularArtistsProps } from './types';
import { Section } from '../Section';

const PopularArtistsQuery = loader('./popularArtists.graphql');

const PopularArtistsFn: FC<PopularArtistsProps> = ({ data }) => {
  return (
    <Section title="Popular artists" viewMoreLink="/artists">
      {!data.loading && data.segments && (
        <Carousel id="popular-artists" numberOfSlides={data.segments.length}>
          {data.segments?.map(node => (
            <ArtistCard key={node.id} artist={node} />
          ))}
        </Carousel>
      )}
    </Section>
  );
};

export const PopularArtists = compose(
  graphql(PopularArtistsQuery, {
    options: {
      variables: {
        guids: 'xVTK4oe,8nTgWg,mPT9e1Z,oPTalwJ,M5T4db,DKTXAeB,N8Tx,oPTadl6,vVTJgP,P3TgX8,WjTOWD,wwTVlY,9bT7kd',
      },
    },
  })
)(PopularArtistsFn);
