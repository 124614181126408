import React from 'react';
import { SegmentQuery_segment } from '../../../../types/SegmentQuery';

export const data = {
  __typename: 'Artist',
  name: 'Logistics',
  stats: {
    totalCount: 6,
    duration: 300,
  },
} as SegmentQuery_segment;

export const art = <img />;
export const handlePlay = () => {};
export const handleDelete = () => {};
export const shareUrl = 'https://pretzel.rocks';
export const subHeaderText = 'This is the sub header text';
