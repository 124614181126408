import styled from 'styled-components';
import { theme } from './theme';

export interface AvatarProperties {
  size: number;
}

export const Avatar = styled('img')<AvatarProperties>`
  border-radius: 50%;
  height: ${({ size }) => size}px;
  object-fit: cover;
  width: ${({ size }) => size}px;
`;

export const AvatarDefault = styled.div<{ size: number }>`
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${theme.colors.light1};
  display: flex;
  height: ${({ size }) => size}px;
  justify-content: center;
  width: ${({ size }) => size}px;
`;
