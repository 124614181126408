import styled, { css } from 'styled-components';
import { theme } from '../../../../Styled';

export const StyledSegmentHeader = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  position: relative;
`;

export const SegmentHeaderImageContainer = styled.div`
  width: 180px;
  height: 180px;
`;

export const SegmentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.875rem;
`;

export const TypeNameContainer = styled.h4`
  margin-top: 0.313rem;
  margin-bottom: 0;
  color: ${theme.colors.primary};
  font-weight: normal;
  text-transform: capitalize;
`;

export const NameContainer = styled.div`
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  h1 {
    padding-right: 0.625rem;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const DurationContainer = styled.div``;

export const ButtonsContainer = styled.div`
  margin-top: auto;
  font-weight: bold;
  left: 0;
  bottom: -1.25rem;

  ${theme.mediaQueries.small} {
    position: inherit;
  }

  button {
    font-size: 0.875rem !important;
  }

  button:nth-of-type(1) {
    margin-bottom: ${theme.space.sm};

    ${theme.mediaQueries.small} {
      margin-bottom:: inherit;
    }
  }

  .share-tracks-button {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
  }
`;

export const buttonStyles = {
  minWidth: '160px',
  height: '40px',
  marginRight: '0.625rem',
};
