export interface IConnections {
  makeDefault?: (id: string) => void;
  connect?: (url: string) => void;
  sync?: (url: string) => void;
}

export interface IConnectionBoxProps extends IConnections {
  provider: string;
  isDefault?: boolean;
  data: any;
  text: string;
  info: any;
  connectString: string;
  canSync?: boolean;
  canDefault?: boolean;
}

export interface IIdentity {
  avatar: string;
  id: string;
  provider: string;
  uid: string;
  url: string;
  username: string;
}

export interface IIdentities {
  default: string;
  identities: Array<IIdentity>;
}

export interface INotification {
  message: string;
  error: boolean;
}

export enum IdentityProviderType {
  Discord = 'discord',
  Google = 'google_oauth2',
  Twitch = 'twitch',
}
