import * as React from 'react';

const SvgCompactStations = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 19.7666H36V36.2045H12V19.7666Z" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.0869 27.9814C14.0869 29.5787 14.6911 31.1107 15.7666 32.2402C16.8422 33.3697 18.3009 34.0042 19.8219 34.0042C21.3429 34.0042 22.8016 33.3697 23.8771 32.2402C24.9526 31.1107 25.5568 29.5787 25.5568 27.9814C25.5568 26.384 24.9526 24.8521 23.8771 23.7225C22.8016 22.593 21.3429 21.9585 19.8219 21.9585C18.3009 21.9585 16.8422 22.593 15.7666 23.7225C14.6911 24.8521 14.0869 26.384 14.0869 27.9814V27.9814Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8261 27.7075C19.8777 27.7075 19.9281 27.7236 19.971 27.7537C20.0139 27.7838 20.0473 27.8266 20.0671 27.8766C20.0868 27.9267 20.092 27.9818 20.0819 28.0349C20.0718 28.0881 20.047 28.1369 20.0105 28.1752C19.974 28.2135 19.9276 28.2396 19.8769 28.2502C19.8263 28.2608 19.7739 28.2553 19.7262 28.2346C19.6786 28.2139 19.6378 28.1787 19.6091 28.1337C19.5805 28.0886 19.5652 28.0357 19.5652 27.9815C19.5652 27.9088 19.5927 27.8391 19.6416 27.7878C19.6905 27.7364 19.7569 27.7075 19.8261 27.7075Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.1843 24.6357H20.4584" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.4584 31.3271H19.1843" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.7437 26.8877L17.3812 25.7283" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.8993 29.074L22.2617 30.2334" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.3812 30.2334L16.7437 29.074" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.2617 25.7283L22.8993 26.8877" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.8696 19.7669L12 11" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.8695 32.9075H28.6956" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.8695 30.7158H28.6956" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.8695 28.5239H28.6956" stroke="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M29.2173 24.1406C29.2173 24.5766 29.3822 24.9947 29.6757 25.303C29.9693 25.6112 30.3674 25.7844 30.7825 25.7844C31.1976 25.7844 31.5957 25.6112 31.8893 25.303C32.1828 24.9947 32.3477 24.5766 32.3477 24.1406C32.3477 23.7047 32.1828 23.2866 31.8893 22.9783C31.5957 22.67 31.1976 22.4968 30.7825 22.4968C30.3674 22.4968 29.9693 22.67 29.6757 22.9783C29.3822 23.2866 29.2173 23.7047 29.2173 24.1406V24.1406Z"
        stroke="#EFEFEF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCompactStations;
