export const customDropDownStyles = {
  // @ts-ignore
  control: (provided, _) => ({
    ...provided,
    background: '#2B383F',
    backgroundColor: '#2B383F',
    borderColor: '#2B383F',
    color: '#BDC2C4',
    boxShadow: 'unset !important',
    outline: 'none !important',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  // @ts-ignore
  singleValue: (provided, _) => ({
    ...provided,
    color: '#BDC2C4',
  }),
  // @ts-ignore
  menu: (provided, _) => ({
    ...provided,
    background: '#2B383F',
    backgroundColor: '#2B383F',
    color: '#BDC2C4',
  }),
  // @ts-ignore
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#31C3A2' : isFocused ? '#45D7B6' : 'transparent',
    color: isSelected || isFocused ? '#FFFFFF' : '#BDC2C4',
    ':active': {
      backgroundColor: isSelected ? '#45D7B6' : '#31C3A2',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  }),
};
