import React, { useCallback } from 'react';
import InstrumentalIcon from '../../../../../assets/icons/Instrumental';
import ExplicitIcon from '../../../../../assets/icons/Explicit';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { loader } from 'graphql.macro';
import {
  SetTrackBlacklistedInput,
  SetTrackBlacklistedMutation,
  SetTrackLikedInput,
  SetTrackLikedMutation,
} from '../../../Core/Player/Components/PlayerControls/schema';
import { AnalyticsEventType } from '../../../Core/Analytics';
import { CopyToClipboard, formatTime, joinStringsGrammatically } from '../../../Util';
import { BasicTrackFragment } from '../../../Core/Player/Queries/types/BasicTrackFragment';
import { ITrack } from '../types';
import { Tooltip } from '../../Tooltip';
import { TrackListProps } from '../types';
import { ViewportType } from '../../../../../common/src/Util/responsive';
import { sendAnalyticsEvent } from '../../../Styled/TrackList/Utils';
import { Playlist } from '../components/Playlist';
import { PlayButton } from '../components/PlayButton';
import { MobileMoreInfo } from '../components/MobileMoreInfo';
import { RemoveFromPlaylistToastConfig, generateToastConfig } from '../../../Styled/ToastContainer';
import { debounce } from 'lodash';
import { ControlItem } from '@pretzel-aux/common/src/Core/Player/Components/PlayerControls/Components/ControlItem/ControlItem';
import { Download } from '../components/Download';

const likeTrackMutation = loader('../../../Core/Player/Queries/set-track-liked.graphql');
const dislikeTrackMutation = loader('../../../Core/Player/Queries/set-track-blacklisted.graphql');

export const generateData = (props: TrackListProps, viewport: ViewportType) => {
  const {
    allowExplicit,
    clickNotification,
    instrumentalOnly,
    next,
    playToken,
    playTrack,
    pretzelUser,
    removeTrackFromPlaylist,
    shareUrl,
    tracks,
    type,
    youtubeSafe,
  } = props;

  const [likeTrack] = useMutation<SetTrackLikedMutation, SetTrackLikedInput>(likeTrackMutation);
  const [dislikeTrack] = useMutation<SetTrackBlacklistedMutation, SetTrackBlacklistedInput>(dislikeTrackMutation);

  const handleLikeTrackClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: BasicTrackFragment) => {
    // By default, a click anywhere on a track row will play a track.
    // We want to stop bubbling on icon clicks so users can interact with tracks without them playing immediately.                                                                                                                                                                                                                                                                                                                                                                                      d
    event.stopPropagation();
    debouncedLike(track);
  };

  const handleDislikeTrackClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: BasicTrackFragment) => {
    event.stopPropagation();
    debouncedDislike(track);
  };

  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: BasicTrackFragment) => {
    event.stopPropagation();
    debouncedCopy(track);
  };

  const debouncedLike = useCallback(
    debounce((track: BasicTrackFragment) => {
      likeTrack({
        variables: {
          trackId: track.id,
          value: !track.self.liked,
        },
      });

      if (track.self.liked) {
        // If track is already liked, we are removing the like
        toast('Like removed from track', generateToastConfig('ThumbsUp'));
      } else {
        toast('Liked track', generateToastConfig('ThumbsUp'));
      }

      sendAnalyticsEvent(AnalyticsEventType.Like, track);
    }, 500),
    []
  );

  const debouncedDislike = useCallback(
    debounce((track: BasicTrackFragment) => {
      dislikeTrack({
        variables: {
          trackId: track.id,
          value: !track.self.blacklisted,
        },
      }).then(() => {
        if (playToken && playToken.track.id === track.id) {
          next();
        }
      });

      if (track.self.blacklisted) {
        // If track is already disliked, we are removing the dislike
        toast('Dislike removed from track', generateToastConfig('ThumbsDown'));
      } else {
        toast('Disliked track', generateToastConfig('ThumbsDown'));
      }

      sendAnalyticsEvent(AnalyticsEventType.Dislike, track);
    }, 500),
    []
  );

  const debouncedCopy = useCallback(
    debounce((track: BasicTrackFragment) => {
      CopyToClipboard(`${shareUrl}/${track.id}`);
      toast('Track link copied', generateToastConfig('Copy'));
    }, 500),
    []
  );

  const handleRemoveFromPlaylist = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, track: BasicTrackFragment) => {
    event.stopPropagation();
    removeTrackFromPlaylist(track);
    toast('Track removed from playlist', RemoveFromPlaylistToastConfig);
  };

  const handlePlayTrack = (track: BasicTrackFragment, forbidden: boolean): void => {
    if (forbidden) {
      return;
    }

    playTrack(track);

    if (clickNotification) {
      clickNotification(track);
    }
  };

  return React.useMemo(() => {
    const tableData: ITrack[] = tracks.map((track: BasicTrackFragment, index) => {
      const isPlaying = playToken && playToken.track.id === track.id;
      const explicitForbidden: boolean = !!track.explicit && !allowExplicit;
      const youtubeForbidden: boolean = !track.youtubeSafe && youtubeSafe;
      const instrumentalForbidden: boolean = !track.instrumental && instrumentalOnly;
      const isPremium = pretzelUser.jwt.tier !== 'free';
      const forbidden: boolean = explicitForbidden || youtubeForbidden || instrumentalForbidden || track.self.blacklisted;

      return {
        track,
        forbidden,
        playButton: (
          <PlayButton
            isPlaying={isPlaying}
            forbidden={forbidden}
            freeTierRestricted={!isPremium}
            instrumentalRestricted={instrumentalForbidden}
            youtubeRestricted={youtubeForbidden}
            dislikedContent={track.self.blacklisted}
            explicitRestricted={explicitForbidden}
            viewport={viewport}
            pretzelUser={pretzelUser}
            onClick={() => handlePlayTrack(track, forbidden)}
          />
        ),
        trackName: <div className="track-row-info">{track.title}</div>,
        trackDetails: (
          <div>
            <div className={`${viewport}-track-row-info`}>{track.title}</div>
            <div className={`${viewport}-track-row-info`}>
              {type === 'Artist' ? track.album.title : joinStringsGrammatically(track.artists.map(a => a.name))}
            </div>
          </div>
        ),
        explicit: (
          <Tooltip
            text={track.explicit ? 'Explicit' : 'Not Explicit'}
            show
            styles={track.explicit ? `transform: translateY(-100%) translateX(0%);` : `transform: translateY(-100%) translateX(-17%);`}
          >
            <div className={track.explicit ? ' explicit-icon' : 'explicit-icon not-explicit'}>
              <ExplicitIcon labeltext={track.explicit ? 'Explicit' : 'Not Explicit'} />
            </div>
          </Tooltip>
        ),
        instrumental: (
          <Tooltip
            text={track.instrumental ? 'Instrumental' : 'This track contains vocals'}
            show
            styles={track.instrumental ? `transform: translateY(-100%) translateX(-20%);` : `transform: translateY(-100%) translateX(-31%);`}
          >
            <div className={track.instrumental ? 'instrumental-icon' : 'instrumental-icon not-instrumental'}>
              <InstrumentalIcon labeltext={track.instrumental ? 'Instrumental' : 'This track contains vocals'} />
            </div>
          </Tooltip>
        ),
        artistName: <div className="track-row-info">{joinStringsGrammatically(track.artists.map(a => a.name))}</div>,
        albumName: <div className="track-row-info">{track.album.title}</div>,
        trackLength: <div className="track-row-info">{formatTime(track.duration)}</div>,
        removeTrack: (
          <Tooltip text="Remove from playlist" show styles={`transform: translateY(-100%) translateX(-28%);`}>
            <ControlItem
              isLoading={false}
              isDisabled={false}
              name="TrashCan"
              onClick={event => handleRemoveFromPlaylist(event, track)}
              data-heapid="remove-from-playlist"
              data-testid={`track-list-remove-from-playlist-button-${index}`}
            />
          </Tooltip>
        ),
        playlist: isPremium ? <Playlist showTooltip track={track} /> : null,
        download: <Download track={track} heapid="download-track" testid={`track-list-download-track-button-${index}`} />,
        thumbsUp: (
          <Tooltip text={track.self.liked ? 'Liked' : 'Like'} show styles={`transform: translateY(-100%) translateX(15%);`}>
            <ControlItem
              isLoading={false}
              isDisabled={false}
              active={track.self.liked}
              name="ThumbsUp"
              onClick={event => handleLikeTrackClick(event, track)}
              data-heapid="like-track"
              data-testid={`track-list-like-track-button-${index}`}
            />
          </Tooltip>
        ),
        thumbsDown: (
          <Tooltip text={track.self.blacklisted ? 'Disliked' : 'Dislike'} show styles={`transform: translateY(-100%) translateX(-4%);`}>
            <ControlItem
              isLoading={false}
              isDisabled={false}
              active={track.self.blacklisted}
              name="ThumbsDown"
              onClick={event => handleDislikeTrackClick(event, track)}
              data-heapid="dislike-track"
              data-testid={`track-list-dislike-track-button-${index}`}
            />
          </Tooltip>
        ),
        mobileMore: (
          <MobileMoreInfo
            track={track}
            handleDislikeTrackClick={handleDislikeTrackClick}
            handleLikeTrackClick={handleLikeTrackClick}
            pretzelUser={pretzelUser}
          />
        ),
        copy: (
          <Tooltip text="Share track" show styles={`transform: translateY(-100%) translateX(-17%);`}>
            <ControlItem
              isLoading={false}
              isDisabled={false}
              name="Copy"
              onClick={event => handleCopyClick(event, track)}
              data-heapid="share-track"
              data-testid={`track-list-share-track-button-${index}`}
            />
          </Tooltip>
        ),
      };
    });

    return tableData;
  }, [tracks, allowExplicit, youtubeSafe, instrumentalOnly, playToken?.track?.id]);
};
