import * as React from "react";

export default function SvgDholak(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M5,1.5A2.5,2.5,0,0,0,7.5,4h9A2.5,2.5,0,0,0,19,1.5,1.5,1.5,0,0,0,17.5,0H6.5A1.5,1.5,0,0,0,5,1.5Z" />
      <path d="M9.667,19a.5.5,0,0,0-.462.308L8.116,21.922A1.5,1.5,0,0,0,9.5,24h5a1.5,1.5,0,0,0,1.385-2.077L14.8,19.308A.5.5,0,0,0,14.333,19Z" />
      <path d="M7.585,5.334A.251.251,0,0,0,7.349,5H6.5a.5.5,0,0,0-.5.48c0,.134-.043,1.222.054,2.713a.25.25,0,0,0,.485.068Z" />
      <path d="M17.461,8.261a.25.25,0,0,0,.485-.068c.1-1.491.059-2.579.054-2.713A.5.5,0,0,0,17.5,5h-.849a.251.251,0,0,0-.236.334Z" />
      <path d="M15.7,7.778a.25.25,0,0,0-.468-.007l-2.528,6.5a.75.75,0,0,1-1.4,0l-2.528-6.5a.249.249,0,0,0-.468.006l-1.62,4.536a.252.252,0,0,0-.008.144A10.055,10.055,0,0,0,8.76,16.839.5.5,0,0,0,9.128,17h5.744a.5.5,0,0,0,.368-.161,10.043,10.043,0,0,0,2.082-4.382.245.245,0,0,0-.007-.144Z" />
      <path d="M11.767,11.331a.25.25,0,0,0,.466,0l2.33-5.99A.251.251,0,0,0,14.33,5H9.67a.251.251,0,0,0-.233.341Z" />
    </svg>
  );
}
