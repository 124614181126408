import * as React from 'react';
import { theme } from '@pretzel-aux/common/src/Styled';

function Copy(props: React.SVGProps<SVGSVGElement>) {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg
      onMouseEnter={() => setFill(theme.colors.brandGreen)}
      onMouseLeave={() => setFill(theme.colors.offWhite)}
      viewBox="0 0 32 32"
      fill={fill}
      height="28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="copy-icon"
      className="no-hover-effect"
    >
      <title id="copy-icon">Copy</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6332 18.203L17.9446 12.8916C18.3374 12.4988 18.9743 12.4987 19.3672 12.8916C19.76 13.2844 19.76 13.9213 19.3672 14.3141L14.0557 19.6256C13.6629 20.0184 13.026 20.0184 12.6332 19.6256C12.2403 19.2328 12.2403 18.5959 12.6332 18.203Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9446 12.8916L12.6332 18.203C12.2403 18.5959 12.2403 19.2328 12.6332 19.6256C13.026 20.0184 13.6629 20.0184 14.0557 19.6256L19.3672 14.3141C19.76 13.9213 19.76 13.2844 19.3672 12.8916C18.9743 12.4987 18.3374 12.4988 17.9446 12.8916Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1554 8.84524C21.1824 6.87228 17.9836 6.87228 16.0106 8.84524L12.3463 12.5096C11.9948 12.8611 11.4249 12.8611 11.0735 12.5096C10.722 12.1582 10.722 11.5883 11.0735 11.2368L14.7379 7.57245C17.4138 4.89654 21.7523 4.89654 24.4282 7.57244C27.1041 10.2483 27.1041 14.5868 24.4282 17.2627C24.0767 17.6142 23.5068 17.6142 23.1554 17.2627C22.8039 16.9113 22.8039 16.3414 23.1554 15.99C25.1283 14.017 25.1283 10.8182 23.1554 8.84524ZM21.4822 18.9359C21.8337 19.2874 21.8337 19.8572 21.4822 20.2087L17.2632 24.4277C14.5873 27.1036 10.2488 27.1036 7.57287 24.4277C4.89697 21.7518 4.89697 17.4133 7.57287 14.7374C7.92435 14.386 8.49419 14.386 8.84567 14.7374C9.19714 15.0889 9.19714 15.6587 8.84567 16.0102C6.87271 17.9832 6.87271 21.182 8.84567 23.1549C10.8186 25.1279 14.0174 25.1279 15.9904 23.1549L20.2094 18.9359C20.5609 18.5844 21.1307 18.5844 21.4822 18.9359Z"
      />
    </svg>
  );
}

export default Copy;
