import * as React from "react";

function SvgArtist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 40 32" {...props}>
      <path d="M36.736 15.552c-.128-8-7.552-14.496-16.704-14.496S3.488 7.552 3.328 15.552C2.016 16.16.8 17.152.8 18.56v7.84c0 1.984 2.624 3.328 4.352 3.52.672.608 1.472 1.024 2.4 1.024 1.952 0 3.552-1.6 3.552-3.552v-9.76c0-1.92-1.6-3.552-3.552-3.552-.864 0-1.6.352-2.208.832.608-6.624 6.944-11.84 14.688-11.84 7.776 0 14.112 5.216 14.656 11.84-.608-.48-1.408-.832-2.208-.832-1.952 0-3.552 1.632-3.552 3.552v9.76c0 1.952 1.6 3.552 3.552 3.552.928 0 1.792-.416 2.4-1.024 1.728-.192 4.352-1.472 4.352-3.52v-7.84c.064-1.312-1.152-2.4-2.496-3.008zM2.88 26.4v-7.84c0-.32.544-.8 1.248-1.12v10.144C3.424 27.2 2.88 26.72 2.88 26.4zm6.336-8.768v9.76c0 .864-.672 1.536-1.536 1.536s-1.536-.672-1.536-1.536v-9.76c0-.864.672-1.536 1.536-1.536s1.536.672 1.536 1.536zm21.728 9.76v-9.76c0-.864.672-1.536 1.536-1.536s1.536.672 1.536 1.536v9.76c0 .864-.672 1.536-1.536 1.536s-1.536-.736-1.536-1.536zm6.336-.992c0 .32-.544.8-1.28 1.12v-9.888-.192c.672.32 1.28.8 1.28 1.12v7.84zm-12.16-3.904l-4.288 1.888 1.216-3.488c.128-.384 0-.8-.256-1.056-.352-.256-.736-.32-1.088-.128l-6.528 3.136c-.48.256-.736.864-.48 1.344s.864.736 1.344.448l4.32-2.048-1.184 3.328c-.128.384 0 .8.256 1.056.224.224.416.288.672.288.128 0 .256 0 .416-.064l6.4-2.816c.544-.192.736-.8.544-1.344s-.8-.736-1.344-.544z" />
    </svg>
  );
}

export default SvgArtist;
