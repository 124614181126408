import { useCallback, useEffect, useState } from 'react';

interface Props {
  reference: React.MutableRefObject<HTMLDivElement>;
}

const useDragToScroll = ({ reference }: Props) => {
  const [isDragged, setIsDragged] = useState(false);
  let isBeingDragged = false;
  let leftPos = 0;
  let momentumID = 0;
  let veloicty = 0;
  let xPos = 0;

  const handleScroll = useCallback(() => {
    const { current } = reference;

    const determineDragVelocity = () => {
      current.scrollLeft += veloicty;
      veloicty *= 0.5;

      if (Math.abs(veloicty) > 0.5) {
        momentumID = requestAnimationFrame(determineDragVelocity);
      }
    };

    const stopTrackingDrag = () => {
      cancelAnimationFrame(momentumID);
    };

    const startTrackingDrag = () => {
      stopTrackingDrag();
      momentumID = requestAnimationFrame(determineDragVelocity);
    };

    current.addEventListener('mousedown', e => {
      isBeingDragged = true;
      xPos = e.pageX - current.offsetLeft;
      leftPos = current.scrollLeft;
      stopTrackingDrag();
    });

    current.addEventListener('mouseleave', () => {
      isBeingDragged = false;
    });

    current.addEventListener('mouseup', () => {
      isBeingDragged = false;
      startTrackingDrag();
      setTimeout(() => setIsDragged(false), 0);
    });

    current.addEventListener('mousemove', e => {
      e.preventDefault();

      if (!isBeingDragged) return;

      const x = e.pageX - current.offsetLeft;
      const prevScrollLeft = current.scrollLeft;

      current.scrollLeft = leftPos - (x - xPos) * 2;
      veloicty = current.scrollLeft - prevScrollLeft;

      if (current.scrollLeft - prevScrollLeft && !isDragged) {
        setIsDragged(true);
      }
    });

    current.addEventListener('wheel', e => {
      stopTrackingDrag();
    });
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  return { isDragged };
};

export default useDragToScroll;
