import * as React from "react";

function SvgYogaMeditate1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <circle cx={12} cy={3} r={3} />
      <path d="M19.768 18.527a1.5 1.5 0 00-1.914-.915l-3.7 1.306a.25.25 0 000 .471l2.135.754a2.481 2.481 0 01.923.577.5.5 0 00.518.116l1.12-.4a1.5 1.5 0 00.918-1.909zM15.458 24a1.485 1.485 0 01-.5-.086L5.117 20.44a1.5 1.5 0 011-2.828l9.843 3.474a1.5 1.5 0 01-.5 2.914zM11.352 18.391a2 2 0 001.326 0l2.595-.916a.334.334 0 00.223-.314.273.273 0 00-.244-.272 2.5 2.5 0 01-.456-4.881l.379-.132a.5.5 0 00.335-.471v-.48a.424.424 0 01.424-.423 1.08 1.08 0 01.311 2.115l-1.162.349a1.5 1.5 0 00.862 2.873l1.162-.349a4.08 4.08 0 00-1.172-7.99h-7.87a4.08 4.08 0 00-1.173 7.988l1.162.349a1.5 1.5 0 00.862-2.873l-1.162-.349a1.08 1.08 0 01.311-2.115.424.424 0 01.424.423v.48a.5.5 0 00.335.471l.379.132a2.5 2.5 0 01-.456 4.881.273.273 0 00-.244.272.334.334 0 00.223.314z" />
    </svg>
  );
}

export default SvgYogaMeditate1;
