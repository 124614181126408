import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { PlatformContext, Tier, withPlatformContext } from '@pretzel-aux/common/src/Core/Platform';
import { Box } from 'grommet';
import { Button } from '../../../Button';
import { isUserEntitled } from '@pretzel-aux/common/src/Util/Entitlements';
import { StyledCheck, StyledCheckbox, StyledCheckboxWrapper } from '@pretzel-aux/common/src/Components/CurrentUser/Styles';
import { theme } from '@pretzel-aux/common/src/Styled';
import { StyledHeading } from '@pretzel-aux/common/src/Components/Home/Components/Section/styled';

type PropsFromPlatformContext = Pick<PlatformContext, 'setPretzelUser' | 'pretzelUser'>;

type Props = PropsFromPlatformContext;

type State = {
  userTier: string;
};

const ZeroCpmUser = () => {
  return (
    <StyledHeading variant="H3" style={{ alignSelf: 'center' }}>
      <strong>Hello, friend of Pretzel &lt;3</strong>
    </StyledHeading>
  );
};

export class ExtrasPresentation extends React.Component<Props, State> {
  state = {
    userTier: Tier.Free,
  };

  setTier(userTier: string) {
    this.setState({ userTier: userTier });
  }

  public render() {
    const isAdmin = isUserEntitled(this.props.pretzelUser.jwt.tier, [Tier.Admin]);
    const isZeroCpm = isUserEntitled(this.props.pretzelUser.jwt.tier, [Tier.ZeroCpm]);
    const tiers = Object.values(Tier);

    return (
      <Box width="medium" style={{ overflow: 'hidden', marginTop: '50px' }}>
        {isZeroCpm && <ZeroCpmUser />}

        {isAdmin && (
          <>
            <StyledHeading variant="H3">
              <strong>Admin extras:</strong>
            </StyledHeading>

            {tiers.map(tier => {
              if (tier !== Tier.Admin) {
                return (
                  <StyledCheckboxWrapper key={tier} style={{ margin: '1rem 0', fontSize: '0.85rem' }}>
                    <StyledCheckbox
                      onClick={e => this.setTier(tier)}
                      style={{ backgroundColor: this.state.userTier === tier ? theme.colors.brandGreen : theme.colors.sidebarBlack }}
                    >
                      {this.state.userTier === tier && <StyledCheck />}
                    </StyledCheckbox>
                    Switch to&nbsp;<strong>{tier}</strong>
                  </StyledCheckboxWrapper>
                );
              }
              return null;
            })}
            <Button color="secondary" id="button-temporary-downgrade" onClick={this.forceBasic} style={{ marginTop: '16px' }}>
              Temporary Switch
            </Button>
          </>
        )}
      </Box>
    );
  }

  private forceBasic = () => {
    let user = this.props.pretzelUser;

    if (this.state.userTier === 'free') {
      user.premium = false;
    } else {
      user.premium = true;
    }

    user.jwt.tier = this.state.userTier;
    this.props.setPretzelUser(user);
  };
}

function mapContextToProps(c: PlatformContext): PropsFromPlatformContext {
  return {
    pretzelUser: c.pretzelUser,
    setPretzelUser: c.setPretzelUser,
  };
}

export const Extras = compose(withPlatformContext<PropsFromPlatformContext>(mapContextToProps))(ExtrasPresentation);
