import React from 'react';
import fallbackArt from '@pretzel-aux/assets/images/default_artwork.png';
import { Image } from 'grommet';
import { toUrl } from '../../../../Util';
import { PlayTokenFragment } from '../../Queries/types/PlayTokenFragment';

interface PublicProps {
  playToken: PlayTokenFragment | null;
}

type Props = PublicProps;

export class AlbumArt extends React.Component<Props> {
  public render() {
    const artworkSource = (this.props.playToken && toUrl(this.props.playToken.track.artworkGuid)) || fallbackArt;
    return <Image fit="cover" src={artworkSource} style={{ borderRadius: '2px' }} />;
  }
}
