import * as React from 'react';
import { Box } from 'grommet';
import { Icon, LibraryIcons } from '../../Styled';

export const DevIconsPage = () => {
  const rows = Object.keys(LibraryIcons).map(name => {
    const ThisIcon = LibraryIcons[name];
    return (
      <tr>
        <td>{name}</td>
        <td>
          <Icon name={name} size={18} />
        </td>
        <td>
          <Icon name={name} size={32} />
        </td>
        <td>
          <Icon name={name} size={64} />
        </td>
        <td>
          <Icon name={name} size={120} />
        </td>
      </tr>
    );
  });
  rows.unshift(
    <thead>
      <tr>
        <th>Name</th>
        <th>18</th>
        <th>32</th>
        <th>64</th>
        <th>120</th>
      </tr>
    </thead>
  );
  return (
    <Box>
      <table>{rows}</table>
    </Box>
  );
};
