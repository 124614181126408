import * as React from 'react';
import 'instantsearch.css/themes/reset.css';
import { Route, Switch } from 'react-router-dom';
import { Box } from 'grommet';
import { flowRight as compose } from 'lodash';
import { SettingsContext, SettingsKeys, withSettingsContext } from '../../Core/Settings/Settings';
import { PlatformContext, withPlatformContext } from '../../Core/Platform';
import { DevIconsPage } from './DevIconsPage';

type PropsFromSettings = Pick<SettingsContext, SettingsKeys.SongRequestQueueVisible>;

interface PropsFromContext {
  unloadApp: () => void;
}

type Props = PropsFromContext & PropsFromSettings;

export class DevPagePresentation extends React.Component<Props> {
  public render() {
    return (
      <Box height="100%" overflow="auto">
        <Box>
          <a href="/__dev/icons">Icons</a>
        </Box>
        <Switch>
          <Route path="/__dev/icons" component={DevIconsPage} />
        </Switch>
      </Box>
    );
  }

  private maybeReload = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.altKey) {
      this.props.unloadApp();
    }
  };
}

function mapContextToProps(c: PlatformContext): PropsFromContext {
  return {
    unloadApp: c.unloadApp,
  };
}

function mapSettingsToProps(c: SettingsContext): PropsFromSettings {
  return {
    songRequestQueueVisible: c.songRequestQueueVisible,
  };
}

export const DevPage = compose(withSettingsContext(mapSettingsToProps), withPlatformContext(mapContextToProps))(DevPagePresentation);
