import styled from 'styled-components/macro';

export interface TileListProperties {
  gutter?: number; // 30
  tileWidth?: number; // 160
  rowGap?: number;
}

export const TileList = styled.div<TileListProperties>`
  display: grid;
  grid-column-gap: ${({ gutter }) => (gutter ? gutter : 30)}px;
  grid-row-gap: ${({ rowGap }) => (rowGap ? rowGap : 30)}px;
  grid-template-columns: repeat(auto-fit, minmax(${({ tileWidth }) => (tileWidth ? tileWidth : 180)}px, 1fr));
`;

interface TileRowProperties {
  rows?: number; // 1
}

type Props = TileRowProperties & TileListProperties;

// @ts-ignore For some reason TS marks this as a circular dependency. it's not.
export const TileRow = styled(TileList)<Props>`
  grid-template-rows: repeat(${({ rows }) => (rows ? rows : 1)}, 1fr);
  grid-auto-rows: 0;
  grid-row-gap: 0;
  overflow-y: hidden;
`;
