import styled from 'styled-components/macro';
import { theme } from '../../../../../../Styled';
import { Button } from '../../../../../../Components/Button';

interface SettingsProps {
  isMusicPlayer?: boolean;
}

export const Wrapper = styled.div`
  height: 74px;
  width: 100%;
  ${'' /* font-size: 12px; */}
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 12px;
  background: ${theme.colors.baseBackground};
  z-index: 7;
`;

export const Handle = styled.div`
  width: 100%;
  height: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
`;

export const OptionWrapper = styled.div`
  margin: 0 2.5px;
  cursor: pointer;

  ${({ disabled }: { disabled: boolean }) =>
    disabled
      ? `
    opacity: 0.6;
    pointer-events: none;
  `
      : ''}
`;

export const OptionIcon = styled.i`
  color: ${({ active }: { active: boolean }) => (active ? theme.colors.brandGreen2 : theme.colors.baseMuted)};
  font-size: 32px;
  height: 32px;
  cursor: pointer;
  &:hover {
    color: ${({ active }: { active: boolean }) => (active ? theme.colors.brandGreen2 : theme.colors.baseWhite)};
  }
`;

export const SettingsContainer = styled.div<SettingsProps>`
  display: flex;
  ${(props: { isMusicPlayer?: boolean }) => {
    return `
      flex-direction: ${props.isMusicPlayer ? 'row' : 'column'};
      padding: 0;

      ${theme.mediaQueries.small} {
        flex-direction: row;
      }
    `;
  }}

  .drawer-settings {
    flex-direction: row;
    width: 100%;
    margin-right: 0;
    align-items: center;

    :first-of-type {
      margin-top: 2.25rem;
    }
    :nth-of-type(even) {
      margin: 1.5rem 0;
    }

    input {
      width: initial;
      margin-right: 0;
    }

    label:nth-of-type(1) {
      position: initial;
      :before {
        width: 12px;
        height: 12px;
        left: -1px;
        top: 0;
      }
    }

    label:nth-of-type(2) {
      font-family: Sora;
      font-style: normal;
      font-weight: bold;
      ${theme.headings.h3};
      line-height: 1.5rem;
    }
  }
`;

export const UpgradeButton = styled(Button)`
  display: none;
  padding: 0.2rem 0.75rem;
  height: 2rem;

  ${theme.mediaQueries.large} {
    display: block;
  }
`;
