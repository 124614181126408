import * as React from "react";

function SvgAdvertisingMegaphone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M23.854,14.293l-2.163-3.561a.25.25,0,0,1-.035-.149l.334-4.154a1,1,0,0,0-.76-1.052L17.182,4.39a.254.254,0,0,1-.131-.081l-2.7-3.174A1,1,0,0,0,13.069.929L9.509,3.092a.248.248,0,0,1-.15.035L5.205,2.793a1.025,1.025,0,0,0-1.052.76L3.069,8a1,1,0,0,0,1.944.474l.831-3.407a.25.25,0,0,1,.263-.189l3.5.281a1.024,1.024,0,0,0,.6-.143l3-1.82a.249.249,0,0,1,.32.052L15.79,5.914a1,1,0,0,0,.524.323l3.407.831a.25.25,0,0,1,.19.263l-.281,3.5a1,1,0,0,0,.142.6l1.821,3a.252.252,0,0,1-.052.32L18.87,17.014a1,1,0,0,0-.323.525l-.831,3.406a.25.25,0,0,1-.263.19l-3.495-.281a1,1,0,0,0-.16,1.994l4.556.366c.028,0,.054,0,.082,0a1,1,0,0,0,.97-.762l.988-4.049a.248.248,0,0,1,.081-.131l3.173-2.7A1,1,0,0,0,23.854,14.293Z" />
      <path d="M.114,19.226a.5.5,0,0,0-.031.6l1.008,1.518a.5.5,0,0,0,.559.2h0l2.257-.675a.249.249,0,0,1,.28.1l.6.91A2.87,2.87,0,0,0,9.9,19.365a.249.249,0,0,1,.182-.324l4.294-.964a.249.249,0,0,1,.263.1l.078.117a.752.752,0,0,0,.626.335.743.743,0,0,0,.414-.125.751.751,0,0,0,.21-1.04L9.588,7.857a.75.75,0,1,0-1.25.83l.071.106a.249.249,0,0,1-.015.3ZM7.95,21.433a1.375,1.375,0,0,1-1.906-.385l-.268-.4a.251.251,0,0,1,.137-.378L8.146,19.6a.251.251,0,0,1,.3.139A1.375,1.375,0,0,1,7.95,21.433Z" />
    </svg>
  );
}

export default SvgAdvertisingMegaphone;
