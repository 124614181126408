import { Box } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../Styled';
import { StyledInput } from '../../../CurrentUser/Styles';

interface Disableable {
  disabled?: boolean;
}

interface OutputFileSelectorProperties {
  disabled: boolean;
  title: string;
  filePath: string;
  openSaveDialog: () => void;
  clearSavePath: () => void;
}

const FileButton = styled.button<Disableable>`
  background: ${theme.colors.bodyBg};
  border: ${theme.borders.style};
  border-radius: 0.25rem;
  color: ${props => (props.disabled ? theme.colors.baseMuted : theme.colors.baseWhite)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 1.5rem;
  height: 40px;
  margin-top: 0.5rem;

  &:hover {
    color: ${props => (props.disabled ? theme.colors.baseMuted : theme.colors.brandGreen2)};
  }

  &:last-of-type {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &:first-of-type {
    border-left: none !important;
    border-radius: 0;
    border-right: none !important;
  }
`;

const FileInput = styled.input`
  // This is hacky and needs revisited.
  ${StyledInput}
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  color: ${props => (props.disabled ? theme.colors.baseMuted : theme.colors.baseWhite)} !important;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 40px !important;
  margin: 0;
  padding: 0.6875rem 1rem !important;
`;

const Icon = styled.i<Disableable>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const InputWrapper = styled.div`
  margin-top: 0.5rem;
  width: 98%;
`;

const Label = styled.label<{ disabled: boolean }>`
  color: ${props => (props.disabled ? theme.colors.baseMuted : theme.colors.baseWhite)};
`;

const Wrapper = styled.div`
  margin-top: 1rem;
`;

export const OutputFileSelector = (props: OutputFileSelectorProperties) => (
  <Wrapper>
    <Label disabled={props.disabled}>{props.title}</Label>
    <Box direction="row">
      <InputWrapper>
        <FileInput
          disabled={props.disabled}
          type="text"
          readOnly
          value={props.filePath || ''}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => {
            props.openSaveDialog();
          }}
        />
      </InputWrapper>
      <FileButton disabled={props.disabled} onClick={props.openSaveDialog} title="Edit">
        <Icon disabled={props.disabled} className="pretzel-icon-create_new" />
      </FileButton>
      <FileButton disabled={props.disabled} onClick={props.clearSavePath} title="Clear">
        <Icon disabled={props.disabled} className="pretzel-icon-close" />
      </FileButton>
    </Box>
  </Wrapper>
);
