import * as React from "react";

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 116.3 129" {...props}>
      <path
        className="logo_svg__st0"
        d="M114.1.2L51.6 35.1c-.5.3-.7.7-.7 1.3v29.2l14.5 9.3V44.8c0-.5.3-1 .7-1.3l33.5-18.7c1-.5 2.2.2 2.2 1.3v55.6c.3 6.8-4 13.2-10.8 15.2-4.1 1.2-8.5.7-12.2-1.5L45.3 74l-.1-.1c-7.6-4.6-16.7-5.6-25.1-2.7C12.9 73.7 7 78.8 3.5 85.6 0 92.4-.8 100.1 1.2 107.5c2.2 7.7 7.3 14.1 14.3 17.9 4.4 2.4 9.3 3.7 14.2 3.7 2.9 0 5.7-.4 8.6-1.3 8-2.4 14.7-8.1 18.3-15.7l.5-1.2-12.4-7.9-1.3 3c-1.9 3.8-5.3 6.7-9.3 8-3.9 1.2-8.1.7-11.7-1.2-3.6-2-6.2-5.2-7.3-9.2-2.2-7.6 2.2-15.9 9.7-18.5 1.6-.6 3.3-.8 5-.8 2.7 0 5.4.7 7.8 2.2L71 107.6l.1.1c4.7 2.9 10.1 4.3 15.5 4.3 2.9 0 5.7-.4 8.5-1.2 13.1-3.9 21.5-16.1 21.2-29.1V1.5c0-1.2-1.2-1.9-2.2-1.3z"
      />
    </svg>
  );
}

export default SvgLogo;
