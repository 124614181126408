import * as React from 'react';
import { graphql, QueryControls } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import { SongRequestDisplay } from './Components/SongRequestDisplay';
import { getSongRequesterListHeight, SongRequesterList } from './Components/SongRequesterList';
import { ExternalLink } from '../ExternalLink/ExternalLink';
import { theme } from '../../Styled';
import { PlayerContext, withPlayerContext } from '../../Core/Player/PlayerContext';
import { PlatformContext, withPlatformContext } from '../../Core/Platform';
import { SongRequestQueueQuery } from './types/SongRequestQueueQuery';
import { PlayTokenFragment } from '../../Core/Player/Queries/types/PlayTokenFragment';
import isSongRequest from '../../Core/Player/Queries/isSongRequest';
import { CurrentPlayTokenQuery_currentUser_currentToken_source_details_SongRequestSourceDetails } from '../../Core/Player/Queries/types/CurrentPlayTokenQuery';

const songRequestsQueueQuery = loader('./song-requests.graphql');

interface PublicProps {}

const EXTENSION_LINK = 'https://www.twitch.tv/ext/lym9op247qx2dplz60iu0quhhwrjwt';
const SR_HELP_LINK = 'https://pretzel.zendesk.com/hc/en-us/sections/360002371314-Twitch-Extension';

interface GraphqlProps {
  data: QueryControls & SongRequestQueueQuery;
}

interface PropsFromPlayer {
  playToken: PlayTokenFragment | null;
}

type PropsFromContext = Pick<PlatformContext, 'openExternalLink'>;

type Props = PublicProps & PropsFromPlayer & PropsFromContext & GraphqlProps;

interface State {
  showCurrentRequest: boolean;
}

const TransitioningBox = styled(Box)`
  transition: flex-basis 300ms ease-in-out;
`;

class SongRequestsPresentation extends React.Component<Props, State> {
  state = {
    showCurrentRequest: false,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    return {
      showCurrentRequest: props.playToken && isSongRequest(props.playToken.source.details),
    };
  }

  public render() {
    if (this.props.data.loading) {
      return <Box fill />;
    }
    return (
      <Box fill overflow="hidden">
        <TransitioningBox
          background={{ color: theme.colors.bgSectionsAlt }}
          pad={{ horizontal: '16px', bottom: '16px' }}
          flex={{ shrink: 1, grow: 1 }}
        >
          <Box background={{ color: theme.colors.bgMain }} fill style={{ overflow: 'auto' }}>
            {this.renderQueueSpace()}
          </Box>
        </TransitioningBox>
        {this.renderCurrentSongRequest()}
      </Box>
    );
  }

  private renderQueueSpace() {
    if (this.props.data.currentUser.songRequestsEnabled) {
      if (this.props.data.currentUser.songRequestQueue.length !== 0) {
        return this.renderQueue();
      }
      return this.renderSomethingAboutQueue();
    }
    return this.renderUpsell();
  }

  private renderQueue() {
    return (
      <Box style={{ minHeight: 'min-content' }}>
        {this.props.data.currentUser.songRequestQueue.map(request => (
          <SongRequestDisplay request={request} key={request.track.id} />
        ))}
      </Box>
    );
  }

  private renderSomethingAboutQueue() {
    return (
      <Box style={{ minHeight: 'min-content' }} flex="grow" justify="center">
        <Text size="xsmall" color={theme.colors.textMuted} textAlign="center">
          There's nothing in your queue!
          <br />
          <ExternalLink href={SR_HELP_LINK}>Need help?</ExternalLink>
        </Text>
      </Box>
    );
  }

  private openExtensionLink = () => {
    return this.props.openExternalLink(EXTENSION_LINK);
  };

  private renderUpsell() {
    return (
      <Box style={{ minHeight: 'min-content' }} flex="grow" justify="center" pad="25px">
        <Text size="xsmall" color={theme.colors.textMuted} textAlign="center">
          Make money by letting your audience request songs using bits.
          <br />
          <a onClick={this.openExtensionLink}>Install the Pretzel Twitch Extension</a>
        </Text>
      </Box>
    );
  }

  private renderCurrentSongRequest = () => {
    let list = null;
    let height = '0px';
    if (this.props.playToken && isSongRequest(this.props.playToken.source.details)) {
      const details = this.props.playToken.source.details;
      list = <SongRequesterList request={details.songRequest} />;
      height = getSongRequesterListHeight(details.songRequest);
    }
    return (
      <TransitioningBox basis={height} justify="end" pad={{ horizontal: '16px' }}>
        {list}
      </TransitioningBox>
    );
  };
}

function mapContextToProps(c: PlayerContext): PropsFromPlayer {
  return {
    playToken: c.playToken,
  };
}
function mapSLContextToProps(c: PlatformContext): PropsFromContext {
  return {
    openExternalLink: c.openExternalLink,
  };
}
export const SongRequests = compose(
  graphql(songRequestsQueueQuery),
  withPlayerContext(mapContextToProps),
  withPlatformContext<PropsFromContext>(mapSLContextToProps)
)(SongRequestsPresentation);
