import styled, { css } from 'styled-components';
import { theme } from '../../Styled';
import { Focus } from '../../Styled/Focus';
import { Heading } from '../Heading';

export const StyledCarousel = styled.div<{
  hero?: boolean;
  showArrow?: boolean;
}>`
  position: relative;

  ${({ hero }) =>
    hero &&
    css`
      margin: 0 -${theme.space.md};
    `};

  ${theme.mediaQueries.large} {
    margin: 0;
    width: ${({ hero }) => (hero ? `calc(100% + calc(${theme.space.lg} * 2))` : `calc(100% + ${theme.space.lg})`)};

    ${({ hero }) =>
      hero &&
      css`
        left: -${theme.space.lg};
      `};

    ${({ showArrow }) =>
      showArrow
        ? css`
            width: calc(100% + ${theme.space.lg});
          `
        : css`
            width: 100%;
          `};
  }
`;

export const StyledCarouselTrack = styled.div<{
  hero?: boolean;
  numberOfSlides?: number;
  slideSize?: string;
}>`
  cursor: grab;
  display: grid;
  grid-gap: ${({ hero }) => (hero ? 0 : '1.5rem')};
  grid-template-columns: ${({ hero, numberOfSlides, slideSize }) =>
    hero ? `repeat(1, ${theme.card.size.featured})` : `repeat(${numberOfSlides || 18}, ${slideSize || theme.card.size.normal})`};
  grid-template-rows: 1fr;
  left: 0%;
  overflow-x: auto;
  padding-bottom: 1.5rem;
  position: relative;
  scrollbar-width: none;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  /* width */
  ::-webkit-scrollbar {
    display: none;
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.bgMain};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.primary};
  }

  ${({ hero }) =>
    hero &&
    css`
      align-items: center;
      cursor: default;
      overflow-x: hidden;
      height: calc(350px + ${theme.space.lg});

      ${theme.mediaQueries.large} {
        height: calc(450px + ${theme.space.lg});
      }

      ::-webkit-scrollbar {
        display: none;
      }
    `};
`;

export const StyledCarouselArrow = styled.div<{ hero?: boolean; position?: 'left' | 'right' }>`
  align-items: center;
  background-color: rgba(26, 36, 44, 0.5);
  border-radius: ${theme.card.borderRadius} 0 0 ${theme.card.borderRadius};
  cursor: pointer;
  display: flex;
  height: ${({ hero }) => (hero ? theme.card.size.featured : theme.card.size.normal)};
  justify-content: center;
  position: absolute;
  top: 0;
  width: 4rem;
  z-index: 1;

  ${({ position }) => (position === 'left' ? 'left: 0;' : 'right: 0;')};

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const StyledCarouselButtonContainer = styled.div<{ hero?: boolean }>`
  align-items: center;
  display: flex;
  position: absolute;
  right: ${theme.space.lg};
  top: -50px;
  z-index: 2;

  ${({ hero }) =>
    hero &&
    css`
      bottom: calc(${theme.space.lg} * 2);
      right: 50%;
      top: initial;
      transform: translateX(-50%);
    `}
`;

export const StyledCarouselButton = styled.button<{ isActive: boolean }>`
  appearance: none;
  background-color: ${props => (props.isActive ? theme.colors.primary : theme.colors.light1)};
  border-radius: 3px;
  border: none;
  cursor: pointer;
  height: 4px;
  margin-right: 0.25rem;
  outline: none;
  width: 16px;
  box-shadow: 0px 0px 2px ${theme.colors.bgMain};
`;

export const StyledHeroContainer = styled.div<{ activeSlide: boolean }>`
  display: flex;
  height: 100%;
  left: 0;
  opacity: ${({ activeSlide }) => (activeSlide ? 1 : 0)};
  pointer-events: ${({ activeSlide }) => (activeSlide ? 'all' : 'none')};
  position: absolute;
  top: 0;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  padding-bottom: ${theme.space.lg};

  ${theme.mediaQueries.medium} {
    padding: ${theme.space.lg} 0;
  }
`;

export const StyledHeroContent = styled.div`
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 61.6%);
  position: relative;
  padding: ${theme.space.md};
  width: 100%;

  ${theme.mediaQueries.large} {
    padding: ${theme.space.lg} ${theme.space.lg} ${theme.space.lg} 5rem;
    min-width: 380px;
    max-width: 430px;
  }

  h2 {
    position: relative;
    z-index: 1;
    text-shadow: 2px 2px 10px #000;
  }
`;

export const StyledHeroImageContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: ${theme.space.sm} 0 0 ${theme.space.sm};
  position: relative;
  width: 100%;

  img {
    border-radius: 4px;
    height: 100%;
    transform: translateY(-2rem);
  }
`;

export const StyledHeroImage = styled.div<{ image?: string }>`
  background-color: rgba(255, 255, 255, 0.05);
  background-image: url(${({ image }) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${theme.borders.radius};
  height: 100%;
  position: relative;
  width: 100%;

  ${theme.mediaQueries.large} {
    left: -${theme.space.lg};
    top: -${theme.space.lg};
  }
`;

export const StyledHR = styled.hr`
  background-image: linear-gradient(90deg, #21c1b1 0%, #0e8f83 100%);
  border-radius: ${theme.borders.radius};
  border: none;
  height: 4px;
  margin: 0 0 ${theme.space.lg} 0;
  transform: translateX(-2.5rem);
  width: 82px;
`;

export const StyledLabel = styled.span`
  display: none;

  ${theme.mediaQueries.large} {
    display: block;
    color: ${theme.colors.primary};
    left: 0;
    position: absolute;
    top: 7rem;
    transform: rotate(-90deg);
  }
`;

export const StyledCarouselArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: absolute;
  bottom: ${theme.space.md};
  left: 0;
  right: 0;

  ${theme.mediaQueries.medium} {
    bottom: 0;
    padding: ${theme.space.lg};
  }

  ${theme.mediaQueries.large} {
    bottom: ${theme.space.lg};
    left: -${theme.space.lg};
    padding: ${theme.space.lg} 4rem ${theme.space.lg} ${theme.space.lg};
  }

  > div {
    ${Focus};

    > svg {
      cursor: pointer;

      &:hover {
        color: ${theme.colors.primary};
      }
    }
  }
`;

export const StyledPlayContainer = styled.div`
  align-items: center;
  display: flex;

  > div {
    margin-right: ${theme.space.lg};
  }
`;

export const StyledHeading = styled(Heading)`
  font-size: 2.5rem;
  white-space: normal;

  ${theme.mediaQueries.large} {
    white-space: nowrap;
    font-size: 4rem;
  }
`;
