import * as React from "react";
import { theme } from "@pretzel-aux/common/src/Styled/theme";

const Like = () => {
  const [fill, setFill] = React.useState<string>(theme.colors.offWhite);

  return (
    <svg onMouseEnter={() => setFill(theme.colors.brandGreen)} onMouseLeave={() => setFill(theme.colors.offWhite)}  width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title id="likes-button">Likes</title>
      <path d="M18.876 48H23.964V46.5H20.58V39.996H18.876V48ZM25.8772 41.352C26.3812 41.352 26.7892 40.944 26.7892 40.44C26.7892 39.936 26.3812 39.528 25.8772 39.528C25.3852 39.528 24.9652 39.936 24.9652 40.44C24.9652 40.944 25.3852 41.352 25.8772 41.352ZM25.1212 48H26.6452V42.204H25.1212V48ZM31.9124 48H33.8204L31.4564 44.832L33.7484 42.204H31.8764L29.6804 44.784V39.996H28.1564V48H29.6804V46.512L30.3764 45.768L31.9124 48ZM33.9485 45.096C33.9485 46.956 35.2805 48.144 37.0445 48.144C37.9445 48.144 38.8445 47.88 39.4325 47.34L38.7605 46.356C38.3885 46.716 37.7525 46.932 37.2125 46.932C36.2285 46.932 35.6405 46.32 35.5445 45.588H39.8525V45.252C39.8525 43.356 38.6765 42.06 36.9485 42.06C35.1845 42.06 33.9485 43.416 33.9485 45.096ZM36.9485 43.272C37.9565 43.272 38.3405 44.004 38.3765 44.556H35.5205C35.5925 43.98 36.0005 43.272 36.9485 43.272ZM40.4493 47.244C41.0613 47.808 42.0333 48.144 43.0413 48.144C44.6493 48.144 45.5493 47.352 45.5493 46.284C45.5493 44.844 44.2173 44.58 43.2213 44.388C42.5733 44.256 42.1173 44.124 42.1173 43.764C42.1173 43.428 42.4773 43.224 43.0293 43.224C43.7373 43.224 44.3973 43.524 44.7453 43.896L45.3453 42.852C44.7813 42.396 44.0013 42.06 43.0173 42.06C41.4933 42.06 40.6413 42.912 40.6413 43.884C40.6413 45.264 41.9253 45.504 42.9213 45.696C43.5813 45.828 44.0853 45.972 44.0853 46.38C44.0853 46.74 43.7733 46.968 43.1133 46.968C42.3933 46.968 41.5293 46.572 41.1093 46.176L40.4493 47.244Z" fill={fill}/>
      <path d="M37.3332 25.4393V25.4393C37.6904 25.4393 38.0206 25.6299 38.1992 25.9393C38.3778 26.2487 38.3778 26.6299 38.1992 26.9393C38.0206 27.2487 37.6904 27.4393 37.3332 27.4393H36.6665C37.2188 27.4393 37.6665 27.887 37.6665 28.4393C37.6665 28.992 37.2185 29.106 36.6665 29.106H32.3332C30.4325 29.106 29.9998 28.7726 27.6665 28.4393V22.7726C29.2998 22.7726 31.9998 19.7726 31.9998 17.106C31.9998 16.052 33.4592 15.6593 33.9998 17.5853C34.3332 18.7726 33.3332 21.106 33.3332 21.106H38.6665C39.2188 21.106 39.6665 21.5537 39.6665 22.106C39.6665 22.6586 39.2185 23.4393 38.6665 23.4393H37.9998C38.5521 23.4393 38.9998 23.887 38.9998 24.4393C38.9998 24.9916 38.5521 25.4393 37.9998 25.4393H37.3332" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.3333 21.7726H27.6666V29.7726H24.3333V21.7726Z" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.1667 28.106C26.0746 28.106 26 28.1806 26 28.2726C26 28.3647 26.0746 28.4393 26.1667 28.4393C26.2587 28.4393 26.3333 28.3647 26.3333 28.2726C26.3333 28.1806 26.2587 28.106 26.1667 28.106" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Like;
