import * as React from "react";

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M25.312 22.592c-.352 0-.736-.16-.896-.352l-8.32-9.952-8.512 9.76c-.352.544-1.088.544-1.632.192-.512-.352-.512-1.088-.16-1.632 0 0 0-.192.16-.192L15.36 9.568c.384-.544 1.28-.544 1.632 0L26.4 20.416c.352.544.352 1.28-.192 1.632-.352.384-.704.384-.896.544z" />
    </svg>
  );
}

export default SvgArrowUp;
