import * as React from 'react';
import { withMutation } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { SettingsContext, SettingsKeys, withSettingsContext } from '../Settings/Settings';
import { PlayerContext, withPlayerContext } from '../Player/PlayerContext';
import { PlatformContext, withPlatformContext } from '../Platform';
import { loader } from 'graphql.macro';
import { Mutation } from '../GraphQL';
import {
  SetTrackBlacklistedInput,
  SetTrackBlacklistedMutation,
  SetTrackLikedInput,
  SetTrackLikedMutation,
} from '../Player/Components/PlayerControls/schema';
import { AnalyticsEventType } from '../Analytics';
import { KeybindConfiguration } from '../../Components/Settings/Sections/Keybinds/KeyAction';

const likeTrackMutation = loader('../Player/Queries/set-track-liked.graphql');
const blacklistTrackMutation = loader('../Player/Queries/set-track-blacklisted.graphql');

interface GraphQLProps {
  likeTrack: Mutation<SetTrackLikedInput, SetTrackLikedMutation>;
  blacklistTrack: Mutation<SetTrackBlacklistedInput, SetTrackBlacklistedMutation>;
}

type PropsFromSettings = Pick<
  SettingsContext,
  | SettingsKeys.BindMediaHotkeys
  | SettingsKeys.GlobalHotKeyConfiguration
  | SettingsKeys.AllowExplicit
  | SettingsKeys.InstrumentalOnly
  | SettingsKeys.YoutubeSafe
  | SettingsKeys.NotifyInChat
  | 'setAllowExplicit'
  | 'setInstrumentalOnly'
  | 'setYoutubeSafe'
  | 'setNotifyInChat'
  | 'setAudioOutputDevice'
>;

type PropsFromPlayer = Pick<
  PlayerContext,
  | 'next'
  | 'previous'
  | 'pause'
  | 'togglePause'
  | 'schedulePause'
  | 'playToken'
  | 'sendAnalyticsEvent'
  | 'increaseVolume'
  | 'decreaseVolume'
  | 'setVolume'
  | 'toggleMute'
  | 'skipForward'
  | 'skipBack'
>;

type PropsFromPlatform = Pick<PlatformContext, 'features' | 'registerHotkey' | 'unregisterHotkey' | 'pretzelUser'>;

type Props = PropsFromPlayer & PropsFromPlatform & PropsFromSettings & GraphQLProps;

enum BindingState {
  Unbound,
  Binding,
  Bound,
}
interface State {
  mediaKeys: BindingState;
  domKeys: BindingState;
}

export class HotkeysPresentation extends React.Component<Props, State> {
  state = {
    mediaKeys: BindingState.Unbound,
    domKeys: BindingState.Unbound,
  };

  registeredHotkeys: KeybindConfiguration[] = [];

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.props.bindMediaHotkeys && this.state.mediaKeys === BindingState.Unbound) {
      this.bindMediaKeys();
    } else if (!this.props.bindMediaHotkeys && prevProps.bindMediaHotkeys && this.state.mediaKeys !== BindingState.Unbound) {
      this.unbindMediaKeys();
    }

    if (this.state.domKeys === BindingState.Unbound) {
      this.bindDomKeys();
    }

    this.updateGlobalHotkeys();
  }

  public render(): React.ReactDOM {
    return null;
  }

  private updateGlobalHotkeys() {
    if (this.propsMatchRegisteredGlobal()) {
      return;
    }

    this.registeredHotkeys.forEach(conf => {
      if (conf.error || !conf.accelerator) {
        return;
      }
      this.props.unregisterHotkey(conf.accelerator);
    });

    // The contents of the globalHotKeyConfiguration array are objects that are modified in place by
    // the Hotkey management component. We are using stringify + parse to get a copy of the objects
    // we assign so that they can't change from other code.
    this.registeredHotkeys = JSON.parse(JSON.stringify(this.props.globalHotKeyConfiguration));
    this.registeredHotkeys.forEach(conf => {
      if (conf.error || !conf.accelerator) {
        return;
      }
      this.props.registerHotkey(conf.accelerator, this.generateGlobalHandler(conf));
    });
  }

  private propsMatchRegisteredGlobal(): boolean {
    const newConf = this.props.globalHotKeyConfiguration;
    const registeredConf = this.registeredHotkeys;

    if (newConf === registeredConf) {
      return true;
    }
    if (!newConf) {
      return true;
    }
    if (newConf.length !== registeredConf.length) {
      return false;
    }

    for (let i = 0; i < newConf.length; i++) {
      const a = newConf[i];
      const b = registeredConf[i];

      if (a.accelerator !== b.accelerator || a.error !== b.error || a.selectedAction !== b.selectedAction || a.value !== b.value) {
        return false;
      }
    }

    return true;
  }

  private generateGlobalHandler(conf: KeybindConfiguration) {
    return () => {
      switch (conf.selectedAction) {
        case 'togglePause':
          this.props.togglePause();
          break;
        case 'pauseAfterCurrent':
          this.props.schedulePause(true);
          break;
        case 'next':
          this.props.next();
          break;
        case 'prev':
          this.props.previous();
          break;
        case 'like':
          this.handleLike();
          break;
        case 'blacklist':
          this.handleDislike();
          break;
        case 'toggleMute':
          this.props.toggleMute();
          break;
        case 'increaseVolume':
          this.props.increaseVolume();
          break;
        case 'decreaseVolume':
          this.props.decreaseVolume();
          break;
        case 'setVolume':
          this.props.setVolume(parseFloat(conf.value.toString()));
          break;
        case 'toggleSetting':
          this.toggleSetting(conf.value.toString());
          break;
        case 'toggleStatusBar':
          console.log('TODO toggleStatusBar');
          break;
        case 'setOutput':
          this.setOutput(conf.value.toString());
          break;
      }
    };
  }

  private setOutput(output: string) {
    this.props.setAudioOutputDevice(output);
  }

  private toggleSetting(setting: string) {
    switch (setting) {
      case 'instrumentalOnly':
        this.props.setInstrumentalOnly(!this.props.instrumentalOnly);
        break;
      case 'allowExplicit':
        this.props.setAllowExplicit(!this.props.allowExplicit);
        break;
      case 'notifyInChat':
        if (this.props.pretzelUser.premium) {
          this.props.setNotifyInChat(!this.props.notifyInChat);
        }
        break;
      case 'youtubeSafe':
        this.props.setYoutubeSafe(!this.props.youtubeSafe);
        break;
      default:
        break;
    }
  }

  private bindMediaKeys = () => {
    this.setState({ mediaKeys: BindingState.Binding });
    if (this.props.features.systemKeybinds) {
      // Next/Previous events are different based on if it's a global or local hotkey :facepalm:
      // Media Keys only work on Windows, not on OSX or Linux
      this.props.registerHotkey('MediaStop', this.props.pause, false);
      this.props.registerHotkey('MediaPlayPause', this.props.togglePause, false);
      this.props.registerHotkey('MediaPreviousTrack', this.props.previous, false);
      this.props.registerHotkey('MediaNextTrack', this.props.next, false);
      this.props.registerHotkey('CommandOrControl+MediaStop', this.props.pause, false);
      this.props.registerHotkey('CommandOrControl+MediaPlayPause', this.props.togglePause, false);
      this.props.registerHotkey('CommandOrControl+MediaPreviousTrack', this.props.previous, false);
      this.props.registerHotkey('CommandOrControl+MediaNextTrack', this.props.next, false);
    } else {
      // If we bind the system wide keybind we don't want a local handler too
      this.props.registerHotkey('MediaStop', this.props.pause, true);
      this.props.registerHotkey('MediaPlayPause', this.props.togglePause, true);
      this.props.registerHotkey('MediaTrackPrevious', this.props.previous, true);
      this.props.registerHotkey('MediaTrackNext', this.props.next, true);
    }
    console.debug('Media Keys Bound');
    this.setState({ mediaKeys: BindingState.Bound });
  };

  private bindDomKeys = () => {
    this.setState({ domKeys: BindingState.Binding });
    this.props.registerHotkey('ArrowLeft', this.props.previous, true);
    this.props.registerHotkey('ArrowRight', this.props.next, true);
    this.props.registerHotkey(' ', this.props.togglePause, true);
    this.props.registerHotkey('b', this.handleDislike, true);
    this.props.registerHotkey('B', this.handleDislike, true);

    if (this.props.pretzelUser?.jwt.tier !== 'free') {
      this.props.registerHotkey(',', () => this.props.skipBack(15), true);
      this.props.registerHotkey('.', () => this.props.skipForward(15), true);
    }

    this.props.registerHotkey('l', this.handleLike, true);
    this.props.registerHotkey('L', this.handleLike, true);
    this.props.registerHotkey('+', this.props.increaseVolume, true);
    this.props.registerHotkey('=', this.props.increaseVolume, true);
    this.props.registerHotkey('-', this.props.decreaseVolume, true);
    this.props.registerHotkey('1', this.handleSetVolume, true);
    this.props.registerHotkey('2', this.handleSetVolume, true);
    this.props.registerHotkey('3', this.handleSetVolume, true);
    this.props.registerHotkey('4', this.handleSetVolume, true);
    this.props.registerHotkey('5', this.handleSetVolume, true);
    this.props.registerHotkey('6', this.handleSetVolume, true);
    this.props.registerHotkey('7', this.handleSetVolume, true);
    this.props.registerHotkey('8', this.handleSetVolume, true);
    this.props.registerHotkey('9', this.handleSetVolume, true);
    this.props.registerHotkey('0', this.handleSetVolume, true);
    this.setState({ domKeys: BindingState.Bound });
  };

  private unbindMediaKeys = () => {
    this.props.unregisterHotkey('MediaTrackPrevious');
    this.props.unregisterHotkey('MediaTrackNext');
    this.props.unregisterHotkey('MediaPlayPause');
    console.debug('Media Keys Unbound');
    this.setState({ mediaKeys: BindingState.Unbound });
  };

  handleLike = () => {
    this.props.likeTrack({
      variables: {
        trackId: this.props.playToken.track.id,
        value: !this.props.playToken.track.self.liked,
      },
    });
    this.props.sendAnalyticsEvent(AnalyticsEventType.Dislike, this.props.playToken, 'hotkey');
  };

  handleDislike = () => {
    this.props
      .blacklistTrack({
        variables: {
          trackId: this.props.playToken.track.id,
          value: !this.props.playToken.track.self.blacklisted,
        },
      })
      .then(() => {
        this.props.next();
      });
    this.props.sendAnalyticsEvent(AnalyticsEventType.Dislike, this.props.playToken, 'hotkey');
  };

  handleSetVolume = (e: KeyboardEvent) => {
    switch (e.key) {
      case '1':
        this.props.setVolume(0.1);
        break;
      case '2':
        this.props.setVolume(0.2);
        break;
      case '3':
        this.props.setVolume(0.3);
        break;
      case '4':
        this.props.setVolume(0.4);
        break;
      case '5':
        this.props.setVolume(0.5);
        break;
      case '6':
        this.props.setVolume(0.6);
        break;
      case '7':
        this.props.setVolume(0.7);
        break;
      case '8':
        this.props.setVolume(0.8);
        break;
      case '9':
        this.props.setVolume(0.9);
        break;
      case '0':
        this.props.setVolume(1);
        break;
    }
  };
}

function mapPlayerToProps(c: PlayerContext): PropsFromPlayer {
  return {
    next: c.next,
    previous: c.previous,
    togglePause: c.togglePause,
    toggleMute: c.toggleMute,
    pause: c.pause,
    schedulePause: c.schedulePause,
    playToken: c.playToken,
    sendAnalyticsEvent: c.sendAnalyticsEvent,
    increaseVolume: c.increaseVolume,
    decreaseVolume: c.decreaseVolume,
    setVolume: c.setVolume,
    skipBack: c.skipBack,
    skipForward: c.skipForward,
  };
}

function mapPlatformToProps(p: PlatformContext): PropsFromPlatform {
  return {
    unregisterHotkey: p.unregisterHotkey,
    registerHotkey: p.registerHotkey,
    pretzelUser: p.pretzelUser,
    features: p.features,
  };
}

function mapSettingsToProps(c: SettingsContext): PropsFromSettings {
  return {
    bindMediaHotkeys: c.bindMediaHotkeys,
    globalHotKeyConfiguration: c[SettingsKeys.GlobalHotKeyConfiguration] || [],
    allowExplicit: c[SettingsKeys.AllowExplicit],
    instrumentalOnly: c[SettingsKeys.InstrumentalOnly],
    youtubeSafe: c[SettingsKeys.YoutubeSafe],
    notifyInChat: c[SettingsKeys.NotifyInChat],
    setAllowExplicit: c.setAllowExplicit,
    setInstrumentalOnly: c.setInstrumentalOnly,
    setYoutubeSafe: c.setYoutubeSafe,
    setNotifyInChat: c.setNotifyInChat,
    setAudioOutputDevice: c.setAudioOutputDevice,
  };
}

export const Hotkeys = compose(
  withPlayerContext(mapPlayerToProps),
  withPlatformContext(mapPlatformToProps),
  withSettingsContext(mapSettingsToProps),
  withMutation(likeTrackMutation, { name: 'likeTrack' }),
  withMutation(blacklistTrackMutation, { name: 'blacklistTrack' })
)(HotkeysPresentation);
