import * as React from 'react';
import { Motion, spring } from 'react-motion';
import { Handle, Wrapper } from './Styles';

interface IDrawerProps {
  children: React.ReactElement;
  height?: string | number;
  open?: boolean;
  yOpen?: number;
  yClosed?: number;
  style?: React.CSSProperties;
}

const Drawer = ({ children, open, height, yOpen, yClosed, style }: IDrawerProps) => {
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  React.useEffect(() => {
    setOpenDrawer(open);
  }, [open]);

  return (
    <Motion style={{ y: spring(openDrawer ? yOpen || 0 : yClosed || 100) }}>
      {({ y }) => (
        <Wrapper
          className="no-drag"
          style={{
            transform: `translateY(${y}%)`,
            ...style,
            height,
          }}
        >
          <div onClick={() => setOpenDrawer(!openDrawer)} style={{ cursor: 'pointer' }}>
            <Handle />
          </div>
          {children}
        </Wrapper>
      )}
    </Motion>
  );
};

export default Drawer;
