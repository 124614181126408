import * as React from 'react';
import { flowRight as compose } from 'lodash';
import { AudioOutputTitle, AudioOutputWrapper, AudioSelectorWrapper, SelectWrapper } from './Styles';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { AudioPermissionsAlert } from '../AudioPermissionsAlert/AudioPermissionsAlert';
import { customDropDownStyles } from '../CustomSelectStyles';
import { AudioOutput, SettingsContext, withSettingsContext } from '../../../../../../Core/Settings/Settings';

interface PropsFromContext {
  audioOutputList: AudioOutput[];
  audioOutputDevice: string;
  refreshAudioOutputList: () => void;
  setAudioOutputDevice: (value: string) => void;
}

interface ISelectOption {
  value: string;
  label: string;
}

interface IState {
  showPermissionDialog: boolean;
  triedPermissions: boolean;
}

type IAudioOutputProps = PropsFromContext;

const AudioOutputSelectorPresentation = ({ audioOutputList, audioOutputDevice, refreshAudioOutputList, setAudioOutputDevice }: IAudioOutputProps) => {
  const [state, setState] = React.useState<IState>({
    showPermissionDialog: false,
    triedPermissions: false,
  });

  const handleClickRefresh = () => {
    if (state.triedPermissions || !navigator.mediaDevices) {
      refreshAudioOutputList();
    } else {
      setState({ ...state, showPermissionDialog: true });
    }
  };

  const handleOutputChanged = (o: ValueType<ISelectOption>) => {
    setAudioOutputDevice(o['value']);
  };

  const handleGetPermissionsForOutput = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => refreshAudioOutputList())
      .then(() => setState({ ...state, showPermissionDialog: false }));
  };

  const options: ISelectOption[] = audioOutputList
    .map(output => ({
      label: output.label,
      value: output.device.deviceId,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

  const selected = options.find(option => option.value === audioOutputDevice);

  return (
    <AudioOutputWrapper style={{ marginLeft: 0, marginRight: 0 }}>
      <AudioOutputTitle>Audio output devices</AudioOutputTitle>
      <AudioSelectorWrapper>
        <SelectWrapper className="no-drag">
          <Select
            name="outputs"
            value={selected}
            options={options}
            onChange={handleOutputChanged}
            className="audioOutputs"
            placeholder="Default"
            styles={customDropDownStyles}
          />
        </SelectWrapper>
      </AudioSelectorWrapper>
      <div style={{ paddingTop: '0.625rem', cursor: 'pointer' }} onClick={handleClickRefresh}>
        Click to refresh your audio devices.
      </div>
      {state.showPermissionDialog && <AudioPermissionsAlert onClick={handleGetPermissionsForOutput} />}
    </AudioOutputWrapper>
  );
};

function mapContextToProps(c: SettingsContext): PropsFromContext {
  return {
    audioOutputList: c.audioOutputList,
    audioOutputDevice: c.audioOutputDevice,
    refreshAudioOutputList: c.refreshAudioOutputList,
    setAudioOutputDevice: c.setAudioOutputDevice,
  };
}

export const AudioOutputSelector = compose(withSettingsContext(mapContextToProps))(AudioOutputSelectorPresentation);
