import * as React from "react";

function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M22.592 6.688c0 .352-.16.736-.352.896l-9.952 8.512 9.76 8.48c.544.384.544 1.088.192 1.632s-1.088.544-1.632.192c0 0-.192 0-.192-.192L9.568 16.8c-.512-.352-.512-1.248 0-1.6l10.848-9.408c.544-.384 1.28-.384 1.632.16.384.192.384.544.544.736z" />
    </svg>
  );
}

export default SvgArrowLeft;
