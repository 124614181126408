import * as React from 'react';
import { Square } from './Aspect';
import { Gradient } from '../Components/StationsList/Components/Styles';
import { Box } from 'grommet';
import { LibraryIcons } from './Icon';
import { theme } from './';

interface StationArtProperties {
  color1: string;
  color2: string;
  theIcon: string;
}
export const StationArt = (props: StationArtProperties) => {
  const TheIcon = LibraryIcons[props.theIcon] || LibraryIcons['Logo'];
  return (
    <Square>
      <Gradient colors={[props.color1, props.color2]} />
      <Box fill justify="center" align="center" style={{ position: 'relative' }}>
        <div style={{ width: 72, height: 72 }}>
          <TheIcon color={theme.colors.textTitles} fill={theme.colors.baseWhite} />
        </div>
      </Box>
    </Square>
  );
};
