import { UpgradeToPremiumModal } from '@pretzel-aux/common/src/Components/UpgradeToPremiumModal';
import { ControlItem } from '@pretzel-aux/common/src/Core/Player/Components/PlayerControls/Components/ControlItem/ControlItem';
import { isUserEntitled } from '@pretzel-aux/common/src/Util/Entitlements';
import { debounce } from 'lodash';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { DownloadProps } from './types';
import { StyledPlaylistContainer } from '../../styled';
import { DownloadFileTypeSelectModal } from '../../../DownloadFileTypeSelectModal';
import { Tooltip } from '../../../Tooltip';
import { getSignedTrackUrl } from '../../../../Api';
import { AnalyticsEventType } from '../../../../Core/Analytics';
import platformContext, { PlatformContext, Tier } from '../../../../Core/Platform';
import settingsContext, { DownloadFileType, SettingsContext } from '../../../../Core/Settings/Settings';
import { DownloadToastConfig } from '../../../../Styled/ToastContainer';
import { sendAnalyticsEvent } from '../../../../Styled/TrackList/Utils';

export const Download = ({ track, heapid, testid, curations, curationsHandleNonPremiumClick }: DownloadProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadFileTypeSelectModalOpen, setIsDownloadFileTypeSelectModalOpen] = useState<boolean>(false);
  const { pretzelUser } = useContext<PlatformContext>(platformContext);
  const { downloadFileType, setDownloadFileType } = useContext<SettingsContext>(settingsContext);
  const isDownloadableTrack = track.downloadable;
  const isProOrAdmin = isUserEntitled(pretzelUser?.jwt.tier, [Tier.ZeroCpm, Tier.Pro, Tier.Admin]);
  const buttonTooltip = isProOrAdmin
    ? isDownloadableTrack
      ? 'Download track'
      : 'Not Downloadable'
    : !isDownloadableTrack
    ? 'Not Downloadable'
    : 'Upgrade to Pro to download tracks';
  const tooltipStyle = isProOrAdmin
    ? isDownloadableTrack
      ? 'left: -0.7rem;'
      : 'left: -1rem;'
    : !isDownloadableTrack
    ? 'left: -1rem;'
    : 'left: -1.7rem;';
  const tooltipStyleCurations = isProOrAdmin ? 'left: -1.4rem; top: -0.1rem;' : 'left: -2.4rem; top: -0.1rem;';

  const debouncedDownload = debounce(async (downloadFileType: DownloadFileType): Promise<void> => {
    toast('Downloading your track', DownloadToastConfig);
    let response;

    try {
      const signedUrl = await getSignedTrackUrl(track.id, downloadFileType);

      if (!signedUrl) {
        throw Error();
      }

      response = await fetch(signedUrl);
    } catch (error) {
      console.error('Download failed');
      toast('Error downloading track', DownloadToastConfig);
      return;
    }

    const downloadElement = document.createElement('a');

    downloadElement.href = URL.createObjectURL(await response?.blob());
    downloadElement.download = `${track.title}.${downloadFileType.split('_')[1]}`;

    document.body.append(downloadElement);
    downloadElement.click();
    downloadElement.remove();

    setTimeout(() => URL.revokeObjectURL(downloadElement.href), 60000);
    sendAnalyticsEvent(AnalyticsEventType.Download, track);
  }, 500);

  const handleDownload = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    if (!downloadFileType) {
      setIsDownloadFileTypeSelectModalOpen(true);
      return;
    }

    event.stopPropagation();
    debouncedDownload(downloadFileType as DownloadFileType);
  };

  const showUpgradeModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation();
    if (!isProOrAdmin) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const onDownloadFileTypeSelectModalSubmit = async (selectedDownloadFileType: DownloadFileType): Promise<void> => {
    setDownloadFileType(selectedDownloadFileType);
    setIsDownloadFileTypeSelectModalOpen(false);
    debouncedDownload(selectedDownloadFileType as DownloadFileType);
  };

  return (
    <StyledPlaylistContainer>
      <Tooltip text={buttonTooltip} show styles={curations ? tooltipStyleCurations : tooltipStyle}>
        <ControlItem
          isLoading={false}
          isDisabled={curations ? !isProOrAdmin : !isProOrAdmin || !isDownloadableTrack}
          active={false}
          name="Download"
          {...(curations
            ? isProOrAdmin
              ? { onClick: handleDownload }
              : { onClick: curationsHandleNonPremiumClick }
            : isProOrAdmin && isDownloadableTrack
            ? { onClick: handleDownload }
            : { onClick: showUpgradeModal })}
          data-heapid={heapid}
          data-testid={testid}
          style={curations ? { height: '24px', width: '24px', transform: 'translateY(7px)' } : {}}
        />
      </Tooltip>
      <UpgradeToPremiumModal
        displayCloseIcon={false}
        setIsOpen={setIsModalOpen}
        pro
        isOpen={isModalOpen}
        buttons={[
          {
            color: 'outline',
            onClick: () => {
              setIsModalOpen(false);
            },
            label: 'Cancel',
          },
        ]}
      />
      {isDownloadFileTypeSelectModalOpen && (
        <DownloadFileTypeSelectModal setOpen={setIsDownloadFileTypeSelectModalOpen} onSubmit={onDownloadFileTypeSelectModalSubmit} />
      )}
    </StyledPlaylistContainer>
  );
};
