export const getAuthUrl = (provider: string): string => {
  const source = /^beta\.|--beta/.test(window.location.hostname) ? 'beta' : 'play';

  let auth = `/auth/${provider}?source=${source}`;
  if (process.env.NODE_ENV === 'development') {
    auth += `&useLocal=true`;
  }

  if (process.env.REACT_APP_NETLIFY) {
    if (process.env.REACT_APP_CONTEXT !== 'production') {
      if (process.env.REACT_APP_DEPLOY_PRIME_URL) {
        const [, domain] = process.env.REACT_APP_DEPLOY_PRIME_URL.split('//');
        const [subdomain] = domain.split('.');
        auth += `&useNetlify=${subdomain}`;
      }
    }
  }

  let link = `https://api.pretzel.rocks${auth}`;

  if (process.env.REACT_APP_API_URL) {
    link = `${process.env.REACT_APP_API_URL}${auth}`;
  }

  return link;
};
