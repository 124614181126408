import * as React from 'react';
import { addStickyBreadcrumb, createContext } from '@pretzel-aux/common/src/Util';
import jwt_decode from 'jwt-decode';
import { Analytics } from '../Analytics';
import * as Sentry from '@sentry/browser';
import { IpcRequest } from '../IPC/IPCRequest';
import { ModalProps } from 'common/src/Components/Modal/types';

export interface PretzelJwt {
  user_id: string;
  guid: string;
  alpha: string;
  display_name: string;
  hash: string;
  premium: boolean;
  banned: boolean;
  avatar: string;
  username: string | null;
  song_requests: boolean;
  exp: number;
  test: boolean;
  tier: string;
  email: string;
}

export interface Features {
  writeToFile: boolean;
  songRequests: boolean;
  customKeybinds: boolean;
  systemKeybinds: boolean;
  compactMode: boolean;
  showDesktopDownloads: boolean;
}

export enum URLS {
  AFFILIATE = 'https://www.pretzel.rocks/affiliate',
  CLIENT_OPEN_SOURCE_LICENSES = 'https://www.pretzel.rocks/clients-licenses.html',
  COOKIE_POLICY = 'https://www.pretzel.rocks/cookie-policy',
  DISCORD_INVITE = 'https://discord.gg/XmtkJ4F',
  DMCA = 'https://www.pretzel.rocks/dmca',
  DOWNLOAD_MAC = 'https://download.pretzel.rocks/PretzelDesktop.dmg',
  DOWNLOAD_WINDOWS = 'https://download.pretzel.rocks/PretzelDesktop.exe',
  FACEBOOK = 'https://www.facebook.com/PretzelRocks/',
  INSTAGRAM = 'https://www.instagram.com/pretzelrocks/',
  FAQS = 'https://help.pretzel.rocks/pretzel',
  FEEDBACK = 'https://feedback.pretzel.rocks/',
  HELP = 'https://help.pretzel.rocks/',
  LICENSE_SERVICES_AGREEMENT = 'https://www.pretzel.rocks/license-services-agreement',
  PREMIUM = 'https://www.pretzel.rocks/premium',
  PRETZEL_BLOG = 'https://blog.pretzel.rocks/',
  PRICING = 'https://www.pretzel.rocks/pricing',
  PRIVACY = 'https://www.pretzel.rocks/privacy',
  RECURLY = 'https://pretzel.recurly.com/subscribe/rocksprem_i_m_v2',
  RECURLY_PREMIUM_ANNUALLY = 'https://pretzel.recurly.com/subscribe/rocksprem_i_y_v2',
  RECURLY_PREMIUM_ANNUALLY_STAGING = 'https://pretzel-staging.recurly.com/subscribe/rocksprem_i_y_v2',
  RECURLY_PREMIUM_MONTHLY = 'https://pretzel.recurly.com/subscribe/rocksprem_i_m_v2',
  RECURLY_PREMIUM_MONTHLY_STAGING = 'https://pretzel-staging.recurly.com/subscribe/rocksprem_i_m_v2',
  RECURLY_GIFT_CARD = 'https://pretzel.recurly.com/purchase/gift_card/pretzel_gift_card',
  SETTINGS = 'https://www.pretzel.rocks/settings',
  TERMS = 'https://www.pretzel.rocks/terms',
  TWITCH = 'https://www.twitch.tv/pretzelrocks',
  TWITTER = 'https://twitter.com/pretzel_rocks',
  USER_LICENSE_AGREEMENT = 'https://www.pretzel.rocks/user-license-agreement',
}

export enum Tier {
  Admin = 'admin',
  Free = 'free',
  PremiumDownload = 'premDownload',
  PremiumStream = 'premStream',
  Pro = 'pro',
  ZeroCpm = '0cpm',
}

export interface PretzelUser {
  id: string;
  name: string;
  premium: boolean;
  consent?: {
    legal: boolean;
    marketing: boolean;
  };
  jwt: PretzelJwt;
  pretzelJWT: string;
  platformJWT?: string;
}

export interface PretzelUserDetails {
  name_change_eligible: boolean;
  user: {
    user_id: string;
    guid: string;
    display_name: string;
    hash: string;
    premium: boolean;
    tier: Tier;
    banned: boolean;
    avatar: string;
    username: string;
    test: boolean;
    email: string;
    created_at: string;
  };
  subscription: {
    provider: string;
    state: string;
    plan: string;
    balance: string | null;
    first_billing_date: string;
    paid_through_date: string;
    next_billing_date: string;
    next_billing_period_amount: number;
    edit_token: string | null;
  };
}

interface AuthorizationWindowEvent {
  type: 'auth_redirect' | 'show' | 'close';
  url: string;
}

export enum OS {
  Windows = 'windows',
  Mac = 'mac',
  Other = 'other',
}

export enum STORAGE_KEYS {
  YOUTUBE_MODAL_RETRY_DATE = 'youtube_modal_retry_date',
}

export function osFromNavigator(): OS {
  const platform = navigator && navigator.platform;
  if (platform.match(/Win/)) {
    return OS.Windows;
  } else if (platform.match(/Mac/)) {
    return OS.Mac;
  } else {
    return OS.Other;
  }
}

interface PlatformDetails {
  description: string;
  os: OS;
}

export interface PlatformContext {
  loaded: boolean;
  showingAuth: boolean;
  pretzelUser?: PretzelUser;
  pretzelUserDetails?: PretzelUserDetails;
  features: Features;
  loadData: (key: string) => Promise<string>;
  saveData: (key: string, value: string) => void;
  notify: (message: string) => void;
  openExternalLink: (link: string) => boolean;
  getPlatformAuthorizationToken: () => string;
  setPretzelUser: (user: PretzelUser) => void;
  unloadApp: () => void;
  registerHotkey: (key: string, callback: (e?: KeyboardEvent) => void, local?: boolean) => void;
  unregisterHotkey: (key: string) => void;
  platformDetails: PlatformDetails;

  sendIPC: <T>(channel: string, message: IpcRequest) => Promise<T>;
  // This is here because SLOBS needs it... it's gross, but the alternatives are worse
  showAuthorizationWindow: (url: string, options: { width: number; height: number }, eventHandler: (event: AuthorizationWindowEvent) => void) => void;
  isUpgradeToPremiumModalOpen: boolean;
  setIsUpgradeToPremiumModalOpen: ModalProps['setIsOpen'];
}

export const {
  context: platformContext,
  withContext: withPlatformContext,
  context: { Provider: BasePlatformProvider },
  context: { Consumer: PlatformConsumer },
} = createContext<PlatformContext>('Platform', {
  loaded: false,
  showingAuth: false,
  features: {
    writeToFile: false,
    songRequests: false,
    compactMode: false,
    customKeybinds: false,
    systemKeybinds: false,
    showDesktopDownloads: false,
  },
  loadData: () => Promise.resolve(''),
  saveData: () => {},
  notify: () => {},
  openExternalLink: () => false,
  setPretzelUser: () => {},
  getPlatformAuthorizationToken: () => '',
  unloadApp: () => {},
  registerHotkey: () => {},
  unregisterHotkey: () => {},

  // @ts-ignore
  sendIPC: () => Promise.resolve({}),
  showAuthorizationWindow: () => {},
});

export default platformContext;

export function parsePretzelJWT(jwt: string): PretzelUser {
  const decoded: any = jwt_decode(jwt);
  Sentry.configureScope(scope => {
    scope.setUser({
      guid: decoded.guid,
      name: decoded.display_name,
      username: decoded.username,
    });
  });
  Analytics.defineUser(decoded);
  console.debug('Pretzel User Loaded', decoded);

  addStickyBreadcrumb('ug', decoded.guid);
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then(registration => {
      registration.active.postMessage({
        type: 'context',
        context: { ug: decoded.guid },
      });
    });
  }

  return {
    id: decoded.guid,
    name: decoded.display_name,
    premium: decoded.premium,
    jwt: decoded,
    pretzelJWT: jwt,
  };
}
