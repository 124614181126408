import { theme } from '../../Styled/theme';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  border-top: 1px solid ${theme.colors.translucentWhite};
  ${theme.mediaQueries.medium} {
    padding: ${theme.space.lg} 0 ${theme.space.md};
  }
`;

export const MainHeading = styled.h2`
  font-size: 1.25rem;
  color: ${theme.colors.offWhite} !important;
  font-weight: bold;
`;

// FIXME: TS complains of a circular dependency - so we're forced to use 'any', apparently
// updating the TS and styled components package resolves the issue
// https://github.com/microsoft/TypeScript/issues/37597
export const SubHeading = styled(MainHeading as any)`
  font-size: 1rem;
  margin-top: ${theme.space.sm};
`;

export const LinkContainer = styled.div`
  margin-bottom: 0.35rem;
`;

export const Link = styled.a`
  color: ${theme.colors.baseWhite} !important;

  &:hover,
  &:focus {
    color: ${theme.colors.teal} !important;
  }
`;

export const SocialLink = styled(Link as any)`
  margin-left: ${theme.space.md};

  :hover {
    svg {
      rect {
        stroke: ${theme.colors.brandGreen};
      }
      path {
        fill: ${theme.colors.brandGreen};
      }
    }
  }

  &:not(:first-of-type) {
    margin-left: ${theme.space.md};
  }
`;

export const FooterElement = styled.div`
  margin: 2rem 0;

  ${theme.mediaQueries.small} {
    width: 50%;
    flex-grow: 1;
    margin: 0;
  }

  ${theme.mediaQueries.large} {
    width: initial;
  }

  ${theme.mediaQueries.extraLarge} {
    flex-grow: initial;
  }
`;

export const Logo = styled(FooterElement as any)`
  ${theme.mediaQueries.large} {
    flex-grow: 1;
  }
`;

export const SectionDivider = styled(FooterElement as any)`
  ${theme.mediaQueries.extraLarge} {
    border-right: 1px solid ${theme.colors.translucentWhite};
    padding-right: 3rem;
    margin-right: 3rem;
  }
`;

export const Copyright = styled.div`
  color: ${theme.colors.light6};
  flex-grow: 1;
  font-size: 0.75rem;
`;

export const DownloadLink = styled.a`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const TopRow = styled.div`
  ${theme.mediaQueries.small} {
    display: flex;
    flex-wrap: wrap;
  }

  ${theme.mediaQueries.large} {
    flex-wrap: nowrap;
  }
`;

export const BottomRow = styled.div`
  margin-top: ${theme.space.lg};

  ${theme.mediaQueries.small} {
    display: flex;
    align-items: center;
  }
`;

export const SocialLinkContainer = styled.div`
  display: flex;
  align-items: center;
  order: 2;
  margin-bottom: ${theme.space.md};

  ${theme.mediaQueries.small} {
    margin-bottom: 0;
  }
`;

export const DownloadTitle = styled.div`
  margin-left: ${theme.space.md};
`;

export const DownloadContainer = styled.div`
  ${theme.mediaQueries.small} {
    display: flex;
  }
`;
