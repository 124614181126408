import { useEffect } from 'react';
import { flowRight as compose } from 'lodash';
import { withPlatformContext } from '../Platform';
import ReactHeap from 'reactjs-heap';
import { PlayerContext, withPlayerContext } from '../Player/PlayerContext';
import { PlayerMode } from '../Analytics';

declare global {
  interface Window {
    heap: any;
  }
}

interface IProps {
  userId: string;
  userGuid: string;
  planType: string;
  isTestUser: boolean;
  email: string;
  playerMode: PlayerMode;
  username?: string;
}

if (process.env.REACT_APP_HEAP_ID) {
  ReactHeap.initialize(process.env.REACT_APP_HEAP_ID);
}

const Heap = ({ userId, userGuid, planType, isTestUser, email, playerMode, username }: IProps): null => {
  useEffect(() => {
    if (!window.heap) {
      return;
    }

    userGuid ? window.heap.identify(userGuid) : window.heap.resetIdentity();

    window.heap.addUserProperties({
      userId,
      userGuid,
      isTestUser,
      email,
      planType,
      username: username ?? 'Anonymous',
    });

    window.heap.clearEventProperties();
    window.heap.addEventProperties({
      planType,
      ...(playerMode != PlayerMode.Init && { playerMode }),
      playerHeight: window.innerHeight,
      playerWidth: window.innerWidth,
    });
  }, [userGuid, playerMode, planType]);

  return null;
};

export default compose(
  withPlayerContext((c: PlayerContext) => ({
    playerMode: c.playerMode,
  })),
  withPlatformContext(c => ({
    userId: c.pretzelUser.jwt.user_id,
    userGuid: c.pretzelUser.jwt.guid,
    planType: c.pretzelUser.jwt.tier,
    isTestUser: c.pretzelUser.jwt.test,
    email: c.pretzelUser.jwt.email,
    username: c.pretzelUser.jwt.username,
  }))
)(Heap);
