import * as React from "react";

function SvgEmptyPlaylist(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M16 16.576c-2.496 0-4.544 1.632-4.544 3.68h.736c0-1.632 1.696-2.912 3.808-2.912s3.808 1.28 3.808 2.912h.736c-.032-2.048-2.016-3.68-4.544-3.68zm-2.912-4.608c.704 0 1.216.512 1.216 1.216s-.512 1.248-1.216 1.248-1.248-.544-1.248-1.248.544-1.216 1.248-1.216zm5.824 0c.704 0 1.248.512 1.248 1.216s-.544 1.248-1.248 1.248-1.216-.544-1.216-1.248.512-1.216 1.216-1.216zm9.696.416C27.04 6.784 21.856 2.848 16 2.848S4.96 6.784 3.392 12.384a2.212 2.212 0 00-.864 1.792v3.648c0 1.504 1.632 2.72 3.712 2.72 1.76 3.68 5.504 6.272 9.824 6.272s8.064-2.592 9.792-6.272c2.048 0 3.744-1.216 3.744-2.72v-3.648c-.096-.704-.416-1.28-.992-1.792zM16 3.552c5.248 0 9.92 3.328 11.68 8.256-.512-.256-1.152-.352-1.856-.416-1.76-3.68-5.504-6.24-9.824-6.24s-8.064 2.56-9.824 6.24c-.672 0-1.312.096-1.856.416C6.08 6.944 10.752 3.552 16 3.552zM3.2 17.824v-3.648c0-.992 1.184-1.856 2.688-1.984-.448 1.184-.704 2.464-.704 3.808s.256 2.624.704 3.808C4.384 19.68 3.2 18.816 3.2 17.824zM16 26.112c-5.536 0-10.112-4.576-10.112-10.112S10.464 5.888 16 5.888 26.112 10.464 26.112 16 21.536 26.112 16 26.112zm12.8-8.288c0 .992-1.184 1.856-2.688 1.984.448-1.184.704-2.464.704-3.808s-.256-2.624-.704-3.808c1.44.128 2.688.992 2.688 1.984v3.648zM9.824 13.792l-1.12 2.432a1.117 1.117 0 00-.192.576c0 .704.608 1.312 1.312 1.312s1.28-.608 1.28-1.312c0-.16-.064-.384-.128-.576l-1.152-2.432zm0 3.68c-.352 0-.704-.32-.704-.704 0-.128 0-.256.096-.352l.608-1.28.576 1.28c.064.096.064.224.064.288.064.448-.256.768-.64.768z" />
    </svg>
  );
}

export default SvgEmptyPlaylist;
