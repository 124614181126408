import { theme } from '@pretzel-aux/common/src/Styled';
import styled from 'styled-components';

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 1.5rem;
`;

const AccordionToggle = styled.button`
  background-color: ${theme.colors.sidebarBlack};
  color: ${theme.colors.baseWhite};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  text-align: left;
  padding: 0;
  margin-top: 1rem;
`;

const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.6s ease;
`;

export { AccordionContainer, Divider, AccordionToggle, AccordionContent };
