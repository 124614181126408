import { theme } from '../../Styled';
import styled from 'styled-components';

export const StyledH1 = styled.h1`
  font-size: ${theme.headings.h1.fontSize};
  line-height: 1;
  margin: 0 0 ${theme.space.md};
  position: relative;
`;

export const StyledH2 = styled.h2`
  font-size: ${theme.headings.h2.fontSize};
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;
`;

export const StyledH3 = styled.h3`
  font-size: ${theme.headings.h3.fontSize};
  font-weight: 300;
`;

export const StyledH4 = styled.h4`
  font-size: ${theme.headings.h4.fontSize};
  margin: 0;
`;

export const StyledH5 = styled.h5``;

export const StyledFeaturedHeading = styled.h2`
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: ${theme.space.md};
  white-space: nowrap;
`;
