import * as React from "react";

function Hand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="23.5" stroke="#A6A6A6"/>
      <path d="M28.07 30.4708L32.5 28.9998C33.6108 28.6704 34.809 29.0698 35.5 29.9998C23.271 36.1128 27.228 36.0768 16.5 32.4998L12.5 34.9998" stroke="#A6A6A6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.5 31H27C27.3999 31.0718 27.8042 30.8985 28.0279 30.5592C28.2515 30.2199 28.2515 29.7801 28.0279 29.4408C27.8042 29.1015 27.3999 28.9282 27 29H24C22.8147 27.7771 21.202 27.0603 19.5 27H16.5L12.5 29" stroke="#A6A6A6" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5045 25.298C30.9005 25.298 33.6535 22.545 33.6535 19.149C33.6535 15.753 30.9005 13 27.5045 13C24.1085 13 21.3555 15.753 21.3555 19.149C21.3555 22.545 24.1085 25.298 27.5045 25.298Z" stroke="#A6A6A6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26 17V16" stroke="#A6A6A6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.6988 17H25.074C24.5594 17.0003 24.1173 17.3043 24.0196 17.7249C23.922 18.1455 24.1962 18.5647 24.6736 18.7247L26.3264 19.2753C26.8038 19.4353 27.078 19.8545 26.9804 20.2751C26.8827 20.6957 26.4406 20.9997 25.926 21H24.2964" stroke="#A6A6A6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26 22V21" stroke="#A6A6A6" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="30" cy="18" r="1.5" stroke="#A6A6A6"/>
    </svg>
  );
}

export default Hand;
