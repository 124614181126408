import { theme } from '../../Styled';
import styled from 'styled-components';

export const StyledTitle = styled.div`
  text-align: center;
  color: ${theme.colors.baseWhite};
  line-height: 1.15em;
`;

export const StyledContent = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;
